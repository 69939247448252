import { Tooltip, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';
import * as React from 'react';

import { ImageMap } from '../../../../solution/types/record';

interface OwnProps {
  marker: ImageMap;
}

const useStyles = makeStyles((theme: any) => ({
  root: {},
  markerTitle: {
    fontWeight: 700,
  },
  tooltip: {
    overflow: 'hidden',
    border: '1px solid #fff',
    width: '40px',
    height: '40px',
    textAlign: 'center',
    borderRadius: '50%',
    background: theme.palette.green.main,
    position: 'relative',
    color: '#fff',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover $tooltipHover': {
      display: 'flex',
    },
  },
  tooltipHover: {
    display: 'none',
    background: theme.palette.green.main,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    zIndex: 2,
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  muiTooltip: {
    background: theme.palette.brightBlue.main,
    padding: theme.spacing(1),
    borderRadius: 0,
    maxWidth: '50vw',
  },
  muiTooltipArrow: {
    color: theme.palette.brightBlue.main,
  },
  tooltipTitle: {
    marginBottom: theme.spacing(1),
  },
}));

export const HiddenMarker: React.FC<OwnProps> = (props) => {
  const { marker } = props;
  const classes = (useStyles as any)(props);

  return (
    <React.Fragment>
      <div
        style={{
          position: 'absolute',
          left: marker.location[0] + '%',
          top: marker.location[1] + '%',
          transform: 'rotate(' + marker.rotation + 'deg)',
        }}
      >
        <Tooltip
          title={
            <React.Fragment>
              {marker.title && (
                <Typography gutterBottom variant={'h5'} className={classes.tooltipTitle} color="inherit">
                  {marker.title}
                </Typography>
              )}

              <Typography variant={'body2'} color="inherit">
                {marker.description}
              </Typography>
            </React.Fragment>
          }
          placement="top"
        >
          <div
            style={{
              height: marker.height + 'px',
              width: marker.width + 'px',
              cursor: 'pointer',
              // border: "1px dashed red", //Enable this when adding a new solution to check for hidden markers
            }}
          ></div>
        </Tooltip>
      </div>
    </React.Fragment>
  );
};
