// import { Design } from "../design/design";
import { observable } from 'mobx';
import { User } from './user';
import { Auth } from '@aws-amplify/auth';

export interface UserInteractor {
  currentUser: User;
  legalAgreements: boolean;
  agreementData: any;
  logout: () => void;
}

export default class DefaultUserInteractor implements UserInteractor {
  @observable public _currentUser: User | null = null;
  @observable public _legalAgreements: boolean = false;
  @observable public _agreementData: any;

  public get currentUser(): User {
    if (!this._currentUser) {
      throw new Error('User not set');
    }
    return this._currentUser;
  }

  public get legalAgreements(): boolean {
    return this._legalAgreements;
  }

  public get agreementData() {
    return this._agreementData;
  }

  public set currentUser(user: User) {
    this._currentUser = user;
  }

  public set legalAgreements(boolean: boolean) {
    this._legalAgreements = boolean;
  }

  public set agreementData(data: any) {
    this._agreementData = data;
  }

  public logout = async () => {
    await Auth.signOut()
      .then(() => {
        window.location.assign('/');
      })
      .catch(console.error);
    // window.location.reload();
  };
}
