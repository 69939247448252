import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@mui/material';

export const SectionTitle: React.FC<{ title: string; icon?: IconProp }> = ({ title, icon }) => {
  return (
    <Box display="flex" marginBottom={3} marginTop={3}>
      <Typography variant="h4">
        {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: 16 }} fontSize={22} />}
        {title}
      </Typography>
    </Box>
  );
};
