import * as React from 'react';

import { DrawerNotifications } from '../../view/interface/drawers/notifications';
import { DrawerContent } from '../../view/interface/drawers/content';

import { InterfaceData, InterfaceActions, InterfaceTools } from '../../view/interface/default-interface-presenter';

export interface ToolConfig {
  title: string;
  width: number;
  active: boolean;
  render(data: InterfaceData, actions: InterfaceActions): React.ReactNode;
}

export interface ToolRenderProps {
  data: InterfaceData;
  actions: InterfaceActions;
}

export const toolConfig: Record<InterfaceTools, ToolConfig> = {
  notifications: {
    title: 'Notifications',
    width: 312,
    active: false,
    render: (data, actions) => {
      return React.createElement(DrawerNotifications);
    },
  },
  'page-info': {
    title: 'Information',
    width: 312,
    active: false,
    render: () => {
      return React.createElement(DrawerContent);
    },
  },

  'sign-out': {
    title: 'Information',
    width: 312,
    active: false,
    render: () => {
      return React.createElement(DrawerContent);
    },
  },
  'not-set': {
    title: '',
    width: 0,
    active: false,
    render: () => {
      return undefined;
    },
  },
};
