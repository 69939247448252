import { Box, Divider } from '@mui/material';
import { HeaderPropertyValue } from '../../../../__old__/v2/common/layout/header-prop-value';
import { CalculationTextField } from '../../../../__old__/v2/common/ui/calculation-textfield';
import { CalculationsRecord } from '../../../../__old__/v2/feature/projection/helpers/calculation-values';
import { CalculationValue } from '../../../../__old__/v2/feature/solution/types/record';

export interface HydraulicCleanSettingsProps {
  solution: string;
  inputs: Partial<CalculationsRecord>;
  advisedInputs: Partial<CalculationsRecord>;
  onChange(calculationValue: CalculationValue): void;
  onError?(id: string, error: string | false): void;
}

export const HydraulicCleanSettings: React.FC<HydraulicCleanSettingsProps> = (props) => {
  const { inputs, advisedInputs, onChange, onError, solution } = props;

  const notXiga = solution !== 'xiga';

  return (
    <Box width={500}>
      <Box display={'flex'}>
        <HeaderPropertyValue horizontal marginRight={1} calculationValue={inputs['ucl_overdesign']} />
      </Box>

      <Divider style={{ margin: '24px 0' }} />

      <CalculationTextField
        calculationValue={inputs['backwash_flux']}
        advisedValue={advisedInputs['backwash_flux']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:350'}
        type="number"
        inputProps={{ max: 350, step: 1 }}
        showEgu
        eguDisabled
        eguWidth={100}
      />

      <CalculationTextField
        calculationValue={inputs['backwash_duration']}
        advisedValue={advisedInputs['backwash_duration']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:90'}
        type="number"
        inputProps={{ max: 90, step: 1 }}
        showEgu
        eguDisabled
        eguWidth={100}
      />

      {notXiga && (
        <CalculationTextField
          calculationValue={inputs['forward_flush_flux']}
          advisedValue={advisedInputs['forward_flush_flux']}
          onChange={onChange}
          onError={onError}
          validations={'required|numberMax:250'}
          type="number"
          inputProps={{ max: 250, step: 1 }}
          showEgu
          eguDisabled
          eguWidth={100}
        />
      )}

      {notXiga && (
        <CalculationTextField
          calculationValue={inputs['forward_flush_duration']}
          advisedValue={advisedInputs['forward_flush_duration']}
          onChange={onChange}
          onError={onError}
          validations={'required|numberMax:300'}
          type="number"
          inputProps={{ max: 300, step: 1 }}
          showEgu
          eguDisabled
          eguWidth={100}
        />
      )}

      {['aquaflex', 'aquaflex-hs', 'aquaflex-new'].includes(solution) && (
        <CalculationTextField
          calculationValue={inputs['airflush_duration']}
          advisedValue={advisedInputs['airflush_duration']}
          onChange={onChange}
          onError={onError}
          validations={'required|numberMax:50'}
          type="number"
          inputProps={{ max: 50, step: 1 }}
          showEgu
          eguDisabled
          eguWidth={100}
        />
      )}

      <CalculationTextField
        calculationValue={inputs['valve_switching']}
        advisedValue={advisedInputs['valve_switching']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:90'}
        type="number"
        inputProps={{ max: 90, step: 1 }}
        showEgu
        eguDisabled
        eguWidth={100}
      />

      <CalculationTextField
        calculationValue={inputs['backwash_pump_ramping']}
        advisedValue={advisedInputs['backwash_pump_ramping']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:90|numberMin:0'}
        type="number"
        inputProps={{ min: 0, max: 90, step: 1 }}
        showEgu
        eguDisabled
        eguWidth={100}
      />

      <CalculationTextField
        calculationValue={inputs['backwash_dead_time']}
        advisedValue={advisedInputs['backwash_dead_time']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:1000'}
        type="number"
        inputProps={{ max: 1000, step: 1 }}
        showEgu
        eguDisabled
        eguWidth={100}
      />
    </Box>
  );
};
