import { usePresenter } from "./use-presenter";
import { UserInteractorPresenter } from "../v2/feature/user/common/user-interactor-presenter";

export function useCheckPermission() {
  const presenter = usePresenter(
    ({ interactor }) => new UserInteractorPresenter(interactor.user)
  );

  return (permission: string) => {
    return presenter.checkPermission(permission);
  };
}
