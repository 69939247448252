import { BaseGraphQLProvider, GraphQLProvider } from "../../base/graphql/graphql-provider";
import {
  AssistantResponse,
  AssistantResponseRecord,
  AssistantResponseModel,
} from "./assistant-response";

import * as Mutation from "./graphql/mutations";
//import { graphqlOperation } from "aws-amplify";
import { graphqlOperation } from "@aws-amplify/api";

export interface AssistantProvider
  extends GraphQLProvider<AssistantResponseRecord, AssistantResponse> {
  startSession(): Promise<string>;
  reset(): void;
  sendMessage(msg: string): Promise<AssistantResponse>;
}

export class DefaultAssistantProvider
  extends BaseGraphQLProvider<AssistantResponseRecord, AssistantResponse>
  implements AssistantProvider {
  public model = AssistantResponseModel;
  protected sessionId: string | undefined;

  public reset = () => {
    this.sessionId = undefined;
    this.store.emptyStorage();
  };

  protected startSessionOperation = () => {
    return graphqlOperation(Mutation.startSession);
  };

  protected sendMessageOperation = (text: string) => {
    return graphqlOperation(Mutation.sendMessage, { sessionId: this.sessionId, text });
  };

  public startSession = async () => {
    if (!this.sessionId) {
      const response = await this.query(null, this.startSessionOperation());
      const record = this.store.setRecord({
        ...response.assistantCreateSession,
        id: Date.now().toString(),
      });
      this.sessionId = record.sessionId;
    }

    return this.sessionId || "";
  };

  public sendMessage = async (msg: string) => {
    const response = await this.query(null, this.sendMessageOperation(msg));
    if (response) {
      const id = Date.now().toString();
      this.store.setRecord({ ...response.assistantPostText, id });
      return this.get(id);
    }

    throw new Error("Something went wrong");
  };
}
