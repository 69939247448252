import { Table, Typography, TableBody, TableRow, TableCell, TableHead, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

import * as React from 'react';

import { CalculationValue } from '../../../../v2/feature/solution/types/record';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons';

interface OwnProps {
  ceb_1a_chemical_1?: CalculationValue;
  ceb_1a_chemical_2?: CalculationValue;
  ceb_1b_chemical_1?: CalculationValue;
  ceb_1b_chemical_2?: CalculationValue;
  ceb_2a_chemical_1?: CalculationValue;
  ceb_2a_chemical_2?: CalculationValue;
  ceb_2b_chemical_1?: CalculationValue;
  ceb_2b_chemical_2?: CalculationValue;
  title: string;
  print?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0, 0, 3, 0),
    border: 'none',
    minWidth: '200px',
    pageBreakInside: 'avoid',
  },
  tableRow: {
    border: 'none',
  },
  tableCell: {
    fontWeight: 600,
    padding: theme.spacing(0.2, 0, 0.2, 0),
    border: 0,
  },
  tableValueCell: { fontWeight: 400 },
  before: {
    marginRight: theme.spacing(2),
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  name: {},
  value: {},
  suggestedValue: { opacity: 0.5 },
  unit: {},
  listname: {
    paddingRight: theme.spacing(2),
    color: theme.palette.silverGray.main,
  },
  bold: { fontWeight: 700 },
  icon: {
    width: theme.spacing(2) + 'px !important',
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
})) as any;

const getValueLabelForChemical = (calcVal?: CalculationValue) => {
  const value = calcVal?.values[0];
  let label = value;

  if (!value || value === '' || value === 'null') {
    label = 'N.A.';
  } else {
    let selectedOption = calcVal?.valueOptions?.find((valueOption) => valueOption.id === calcVal?.values[0]);

    label = selectedOption?.label;
  }

  return label;
};

export const ProjectionDataTableCEBChemicals: React.FC<OwnProps> = (props) => {
  const {
    ceb_1a_chemical_1,
    ceb_1a_chemical_2,
    ceb_1b_chemical_1,
    ceb_1b_chemical_2,
    ceb_2a_chemical_1,
    ceb_2a_chemical_2,
    ceb_2b_chemical_1,
    ceb_2b_chemical_2,
    title,
    print,
  } = props;
  const classes = (useStyles as any)();

  return (
    <div className={classes.root} style={props.print ? { marginBottom: '14px' } : undefined}>
      <div className={classes.titleWrap} style={{ marginBottom: props.print ? '6px' : undefined }}>
        <FontAwesomeIcon
          className={classes.icon}
          icon={faClipboardListCheck}
          style={
            props.print
              ? {
                  marginRight: '12px',
                  maxWidth: 20,
                  maxHeight: 20,
                }
              : undefined
          }
        />
        <Typography variant="h4" style={{ fontSize: print ? '12px' : undefined }}>
          {title}
        </Typography>
      </div>
      <Table style={{ fontSize: print ? undefined : '0.825em' }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{
                height: 'auto',
                width: props.print ? '30%' : '20%',
                fontSize: print ? 12 : undefined,
                border: 'none',
              }}
              padding="none"
              className={classnames(classes.tableCell, classes.listname)}
            >
              CEB Step
            </TableCell>
            <TableCell
              style={{ height: 'auto', width: '30%', border: 'none' }}
              padding="none"
              className={classnames(classes.tableCell, classes.listname)}
            >
              Chemical 1
            </TableCell>
            <TableCell
              style={{ height: 'auto', border: 'none' }}
              padding="none"
              className={classnames(classes.tableCell, classes.listname)}
            >
              Chemical 2
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            key={'ceb1a'}
            className={classes.tableRow}
            style={props.print ? { backgroundColor: 'rgba(0,0,0,0.03)' } : undefined}
          >
            <TableCell
              className={classnames(classes.tableCell, classes.listname)}
              sx={{ border: 'none' }}
              padding="none"
            >
              <Typography
                variant="body2"
                gutterBottom
                color={'inherit'}
                style={{ fontSize: print ? 12 : undefined }}
                lineHeight={props.print ? '12px' : undefined}
                fontWeight={600}
              >
                CEB 1A
              </Typography>
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.value)} sx={{ border: 'none' }} padding="none">
              <Typography
                variant="body2"
                gutterBottom
                style={{ fontSize: print ? 12 : undefined }}
                lineHeight={props.print ? '12px' : undefined}
              >
                {getValueLabelForChemical(ceb_1a_chemical_1)}
              </Typography>
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.value)} sx={{ border: 'none' }} padding="none">
              <Typography
                variant="body2"
                gutterBottom
                style={{ fontSize: print ? 12 : undefined }}
                lineHeight={props.print ? '12px' : undefined}
              >
                {getValueLabelForChemical(ceb_1a_chemical_2)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow key={'ceb1b'} className={classes.tableRow}>
            <TableCell
              className={classnames(classes.tableCell, classes.listname)}
              sx={{ border: 'none' }}
              padding="none"
            >
              <Typography
                variant="body2"
                gutterBottom
                color={'inherit'}
                style={{ fontSize: print ? 12 : undefined }}
                fontWeight={600}
                lineHeight={props.print ? '12px' : undefined}
              >
                CEB 1B
              </Typography>
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.value)} sx={{ border: 'none' }} padding="none">
              <Typography
                variant="body2"
                gutterBottom
                style={{ fontSize: print ? 12 : undefined }}
                lineHeight={props.print ? '12px' : undefined}
              >
                {getValueLabelForChemical(ceb_1b_chemical_1)}
              </Typography>
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.value)} sx={{ border: 'none' }} padding="none">
              <Typography
                variant="body2"
                gutterBottom
                style={{ fontSize: print ? 12 : undefined }}
                lineHeight={props.print ? '12px' : undefined}
              >
                {getValueLabelForChemical(ceb_1b_chemical_2)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow
            key={'ceb2a'}
            className={classes.tableRow}
            style={props.print ? { backgroundColor: 'rgba(0,0,0,0.03)' } : undefined}
          >
            <TableCell
              className={classnames(classes.tableCell, classes.listname)}
              sx={{ border: 'none' }}
              padding="none"
            >
              <Typography
                variant="body2"
                gutterBottom
                color={'inherit'}
                style={{ fontSize: print ? 12 : undefined }}
                fontWeight={600}
                lineHeight={props.print ? '12px' : undefined}
              >
                CEB 2A
              </Typography>
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.value)} sx={{ border: 'none' }} padding="none">
              <Typography
                variant="body2"
                gutterBottom
                style={{ fontSize: print ? 12 : undefined }}
                lineHeight={props.print ? '12px' : undefined}
              >
                {getValueLabelForChemical(ceb_2a_chemical_1)}
              </Typography>
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.value)} sx={{ border: 'none' }} padding="none">
              <Typography
                variant="body2"
                gutterBottom
                style={{ fontSize: print ? 12 : undefined }}
                lineHeight={props.print ? '12px' : undefined}
              >
                {getValueLabelForChemical(ceb_2a_chemical_2)}
              </Typography>
            </TableCell>
          </TableRow>
          <TableRow key={'ceb2b'} className={classes.tableRow}>
            <TableCell
              className={classnames(classes.tableCell, classes.listname)}
              sx={{ border: 'none' }}
              padding="none"
            >
              <Typography
                variant="body2"
                gutterBottom
                color={'inherit'}
                style={{ fontSize: print ? 12 : undefined }}
                lineHeight={props.print ? '12px' : undefined}
                fontWeight={600}
              >
                CEB 2B
              </Typography>
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.value)} sx={{ border: 'none' }} padding="none">
              <Typography
                variant="body2"
                gutterBottom
                style={{ fontSize: print ? 12 : undefined }}
                lineHeight={props.print ? '12px' : undefined}
              >
                {getValueLabelForChemical(ceb_2b_chemical_1)}
              </Typography>
            </TableCell>
            <TableCell className={classnames(classes.tableCell, classes.value)} sx={{ border: 'none' }} padding="none">
              <Typography
                variant="body2"
                gutterBottom
                style={{ fontSize: print ? 12 : undefined }}
                lineHeight={props.print ? '12px' : undefined}
              >
                {getValueLabelForChemical(ceb_2b_chemical_2)}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};
