import { Table, Typography, TableBody, TableRow, TableCell, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';

import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons';

interface Item {
  title: string;
  value: string;
}

interface OwnProps {
  data: Item[];
  title: string;
  print?: boolean;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0, 0, 3, 0),
    border: 'none',
  },
  tableRow: {
    border: 'none',
  },
  tableCell: {
    fontWeight: 600,
    padding: theme.spacing(0.2, 0, 0.2, 0),
    border: 0,
    // [theme.breakpoints.down("md")]: {
    //   minWidth: 150,
    // },
  },
  tableValueCell: { fontWeight: 400 },
  before: {
    marginRight: theme.spacing(2),
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  name: {},
  value: {},
  suggestedValue: { opacity: 0.5 },
  unit: {},
  listname: {
    paddingRight: theme.spacing(2),
    color: theme.palette.silverGray.main,
  },
  bold: { fontWeight: 700 },
  icon: {
    width: theme.spacing(2) + 'px !important',
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
}));

export const ProjectionDataTableKeyValue: React.FC<OwnProps> = (props) => {
  const { data, title, print } = props;
  const classes = (useStyles as any)(props);

  return (
    <div className={classes.root} style={props.print ? { marginBottom: '14px' } : undefined}>
      <div className={classes.titleWrap} style={{ marginBottom: props.print ? '6px' : undefined }}>
        <FontAwesomeIcon
          className={classes.icon}
          icon={faClipboardListCheck}
          style={
            props.print
              ? {
                  marginRight: '12px',
                  maxWidth: 20,
                  maxHeight: 20,
                }
              : undefined
          }
        />
        <Typography variant="h4" style={{ fontSize: print ? '12px' : undefined }}>
          {title}
        </Typography>
      </div>
      <Table style={{ fontSize: print ? undefined : '0.825em' }}>
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow
                key={index}
                className={classes.tableRow}
                style={props.print && Math.abs(index % 2) === 0 ? { backgroundColor: 'rgba(0,0,0,0.03)' } : undefined}
              >
                <TableCell
                  className={classnames(classes.tableCell, classes.listname)}
                  sx={{ border: 'none', width: props.print ? '60%' : undefined }}
                  padding="none"
                >
                  <Typography
                    gutterBottom
                    variant="body2"
                    fontSize={props.print ? 12 : undefined}
                    fontWeight={600}
                    color={'inherit'}
                    lineHeight={props.print ? '12px' : undefined}
                  >
                    {row.title}
                  </Typography>
                </TableCell>

                <TableCell
                  className={classnames(classes.tableCell, classes.value)}
                  sx={{ border: 'none' }}
                  padding="none"
                >
                  <Typography
                    gutterBottom
                    variant="body2"
                    fontSize={props.print ? 12 : undefined}
                    lineHeight={props.print ? '12px' : undefined}
                  >
                    {row.value}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
