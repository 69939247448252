import { Button, Box, Card, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PageLoading } from '../../__old__/v2/common/pages/page-loading';
import { useAuth } from './auth-provider';
import { AuthTemplate } from './auth-template';
import { SignInForm } from './forms/sign-in';

export const AuthStateSignedOut = () => {
  const { t } = useTranslation();
  const { federatedSignin, awaitingSignedInUser } = useAuth();

  if (awaitingSignedInUser) {
    return (
      <Box width="100vw" height="100vh" display="flex">
        <PageLoading />
      </Box>
    );
  }

  return (
    <AuthTemplate>
      <AuthTemplate>
        <Card style={{ width: '100%', maxWidth: 400, padding: 24 }}>
          <Typography variant="h3" paragraph>
            {t('cognitoSignIn', { defaultValue: 'Cognito Sign In' })}
          </Typography>

          <SignInForm />

          <Divider style={{ margin: '24px 0 12px 0' }} />

          <Button fullWidth onClick={() => federatedSignin('pentair-salesforce-sso')} style={{ marginBottom: 12 }}>
            {t('pentairPartnerPortal', { defaultValue: 'Pentair Partner Portal' })}
          </Button>
          <Button fullWidth onClick={() => federatedSignin('pentair-iam-sso')}>
            {t('pentairIdentityCenter', { defaultValue: 'Pentair Identity center' })}
          </Button>
        </Card>
      </AuthTemplate>
    </AuthTemplate>
  );
};
