import { cloneDeep, get } from 'lodash';
import { ProjectionRecord } from '../../../../../application/resources/projection/projection';
import { projectionCalculationCreate } from '../../../../../application/resources/projection/v2/functions/get-advised-values';
import { CalculationValue, State } from '../../../solution/types/record';
import { setupCalculationInputs } from '../calculations/calculation-inputs';
import { calculateCEBResults } from '../calculations/ceb-caculations';
import { projectionGuidelines } from '../guildelines';
import {
  makeInputsBasedOnCalcField,
  makeOutputsBasedOnCalcField,
  makeProjectionInputs,
  makeProjectionOutputs,
} from './projection-values';

import { getCalculationValue } from '../../helpers/calculation-values';
import { WaterFeed } from '../../helpers/dt';
import { defaultProjectionDescription } from './default-descriptions';

import { applyAdvicedInputs } from './map-adviced-inputs';
import { applyAdvicedOutputs } from './map-adviced-outputs';

export const finalizeProjection = async (
  record: Partial<ProjectionRecord>,
  setStatus: (msg: string) => void = () => {}
) => {
  record = cloneDeep(record);

  let projectionInputs = makeProjectionInputs(record);

  let projectionOutputs = makeProjectionOutputs(record);
  const inputsBasedOnCalcfields = makeInputsBasedOnCalcField(record);
  const outputsBasedOnCalcfields = makeOutputsBasedOnCalcField(record);

  setStatus('Preparing / Uploading data to cloud..');
  record.solution = record.solution || {};
  const calculationInputs = setupCalculationInputs(projectionInputs, get(record, 'solution.id'));

  // First get the advices inputs
  let advisedInputs;
  try {
    setStatus('Running calculations..');
    advisedInputs = await projectionCalculationCreate({
      variables: { inputs: JSON.stringify(calculationInputs) },
    });
  } catch (err: any) {
    throw new Error(err);
  }

  const hasError = get(advisedInputs, 'data.projectionCalculationCreate.status') === 'error';

  if (hasError) {
    throw new Error(get(advisedInputs, 'data.projectionCalculationCreate.message'));
  }

  setStatus('Processing calculation result..');
  const rawCalculationState = get(advisedInputs, 'data.projectionCalculationCreate.state');

  if (!rawCalculationState) {
    throw new Error('Advised input state not set');
  }

  const calculationState: State = JSON.parse(rawCalculationState);

  // console.log(
  //   'variables',
  //   record?.inputs?.find((val) => {
  //     return val?.id === 'membrane_element';
  //   })
  // );

  // Apply advised inputs to the projection inputs
  applyAdvicedInputs(
    record.solution.id || '',
    calculationState.advice.inputs,
    projectionInputs,
    inputsBasedOnCalcfields
  );

  // Retrieve CEB Values and apply to projection inputs
  await calculateCEBResults(projectionInputs, inputsBasedOnCalcfields, calculationState);

  // Apply advised outputs to the projection inputs & outputs
  applyAdvicedOutputs(
    calculationState.advice.outputs,
    projectionInputs,
    projectionOutputs,
    inputsBasedOnCalcfields,
    outputsBasedOnCalcfields
  );

  // Check water source
  const waterSource = getCalculationValue(projectionInputs, 'water_source', 0);

  // Finalize projection record data
  record.inputs = Object.values(projectionInputs).filter(Boolean) as CalculationValue[];
  record.outputs = Object.values(projectionOutputs).filter(Boolean) as CalculationValue[];
  record.advisedInputs = Object.values(projectionInputs).filter(Boolean) as CalculationValue[];
  record.description = defaultProjectionDescription(record.solution?.id || '', waterSource as WaterFeed);
  record.calcState = rawCalculationState;
  record.note = 'Adviced settings';
  record.guidelines = [projectionGuidelines[get(record, 'solution.id') || 'undefined'] || ''];
  record.solution = (typeof record.solution === 'object' ? record.solution.id : record.solution) as any;

  return record;
};
