//? Module Federation imports
// import graphql_headers from 'IAM/graphqlHeaders';

//? GraphQL related imports
import { GraphQLResult, GraphQLAPIClass } from '@aws-amplify/api-graphql';
import { Auth } from '@aws-amplify/auth';
import { DocumentNode } from 'graphql';
import { amplify } from '../__old__/config/amplify';

// Auth.configure({
//   identityPoolId: process.env.REACT_APP_SuedmoIdentityPoolId,
//   region: 'us-east-1',
// });

export class awsAPI {
  private AmplifyApi: GraphQLAPIClass;

  constructor(
    private config = {
      aws_appsync_graphqlEndpoint: '',
      aws_appsync_region: '',
      aws_appsync_apiKey: '',
      cacheOptions: {
        addTypename: true,
      },
    }
  ) {
    this.AmplifyApi = new GraphQLAPIClass(config);
  }
  /**
   * @description sets headers for AppSync request based on ENV
   * @returns copy of the set config
   */
  private configure = (useApiKey?: boolean) => {
    // aws api key
    if (useApiKey) {
      const config: any = {
        aws_appsync_graphqlEndpoint: this.config.aws_appsync_graphqlEndpoint,
        aws_appsync_authenticationType: 'API_KEY',
        aws_appsync_apiKey: this.config.aws_appsync_apiKey || '',
      };
      this.AmplifyApi.configure(config);
      return config;
    }
    // cognito user pool
    else {
      const config: any = {
        aws_appsync_graphqlEndpoint: amplify.aws_appsync_graphqlEndpoint,
        aws_appsync_region: amplify.aws_appsync_region,
        aws_appsync_authenticationType: amplify.Auth.authenticationFlowType,
        graphql_headers: generateHeader,
      };
      this.AmplifyApi.configure(config);
      return config;
    }
  };

  /**
   * @async
   * @description sends a GraphQL query to appsync backend
   * @param query gql query to run
   * @param variables variables to use in query
   * @param useApiKey force the use of an api key
   * @returns GraphQL query results
   */
  public query = async <TData>(query: DocumentNode, variables?: { [key: string]: any }, useApiKey?: boolean) => {
    try {
      // need to update the headers
      this.configure(useApiKey ? true : false);

      const response = await this.AmplifyApi.graphql({
        query,
        variables,
      });

      if ('subscribe' in response) {
        throw new Error('Please use subscribe instead of query when calling subscriptions');
      }

      // run query
      return response;
    } catch (err: any) {
      console.error('Error ferching data: ', err);
      return err as GraphQLResult<TData>;
    }
  };

  public subscribe = async (
    querystring: any,
    variables: any = undefined,
    next: (payload: any) => void,
    useApiKey?: boolean
  ) => {
    // need to update the headers
    this.configure(useApiKey ? true : false);

    const subscription = await this.AmplifyApi.graphql({
      query: querystring,
      variables,
    });

    if (!('subscribe' in subscription)) {
      throw new Error('Please use query instead of subscription for mutations and queries');
    }
    return subscription.subscribe({
      next,
    });
  };
}

export const api = new awsAPI({
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_SuedmoEcommerceUrl || '',
  aws_appsync_region: 'us-east-1',
  aws_appsync_apiKey: '',
  cacheOptions: {
    addTypename: true,
  },
});

export default api;

export const generateHeader = async () => {
  try {
    const token = ((await Auth.currentSession()) as any).idToken.jwtToken;
    return { Authorization: token };
  } catch (e) {
    return {};
  }
};
