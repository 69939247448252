import { Permission, DefaultPermission, PermissionRecord } from "./permission";
import { GraphQLProvider, BaseGraphQLProvider } from "../../base/graphql/graphql-provider";
import { QueryPermissionCollection, QueryPermission } from "./graphql/queries";
//import { graphqlOperation } from "aws-amplify";
import { graphqlOperation } from "@aws-amplify/api";
import { UpdatePermission } from "./graphql/mutations";

export interface PermissionProvider extends GraphQLProvider<PermissionRecord, Permission> {
  //
}

export class DefaultPermissionProvider extends BaseGraphQLProvider<PermissionRecord, Permission>
  implements PermissionProvider {
  public model = DefaultPermission;

  public listOperation = () => {
    return graphqlOperation(QueryPermissionCollection);
  };

  public fetchOperation = (permission: Permission) => {
    return graphqlOperation(QueryPermission, { id: permission.id });
  };

  public updateOperation = (permission: Permission) => {
    return graphqlOperation(UpdatePermission, permission);
  };
}
