import { getCalculationValue, setCalculationValues } from '../../helpers/calculation-values';
import { CalculationValues } from '../projection/projection-values';

export const applyInternalRecirculation = (inputs: CalculationValues<'id'>) => {
  const turbidity = getCalculationValue(inputs, 'design_turbidity', 0, '4');

  // If dt <= 70 disabled
  if (turbidity) {
    const internalRecirculationKeyVal = getCalculationValue(inputs, 'internal_recirculation');

    if (internalRecirculationKeyVal) {
      const isEnabled = parseFloat(turbidity || '0') > 70 ? 'true' : 'false';
      setCalculationValues(inputs, 'internal_recirculation', [isEnabled]);
    }
  }

  return inputs;
};
