import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButtonProps, IconButton as MuiIconButton, Box } from '@mui/material';
import { styled } from '@mui/styles';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Link, LinkProps } from 'react-router-dom';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';
import { faSpinner as faSpinnerSolid } from '@fortawesome/pro-solid-svg-icons';

interface OwnProps extends Partial<IconButtonProps & Partial<LinkProps>> {
  icon: IconProp;
  iconSolid: IconProp;
  active?: boolean;
  loading?: boolean;
}

const PentairIconButton = styled(MuiIconButton)(({ theme, color, size }) => ({
  backgroundColor: (color ? theme.palette[color]?.main || color : color) + ' !important',
  color: (color ? theme.palette[color]?.contrastText || color : color) + ' !important',
  width: size === 'small' ? 36 : 48,
  height: size === 'small' ? 36 : 48,
  '&:hover': {
    '& div': {
      // '& >:first-child': {
      '& >:first-of-type': {
        opacity: 0,
      },
      '& >:last-child': {
        opacity: 1,
      },
    },
  },
}));

const PentairIconButtonIcon = styled(Box)({
  fontSize: 18,
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  // '& >:first-child': {
  '& >:first-of-type': {
    opacity: 1,
    position: 'absolute',
  },
  '& >:last-child': {
    opacity: 0,
    position: 'absolute',
  },
});

// remove react error
const removeIconFromProps = (props) => {
  let newProps = { ...props };
  if (newProps.icon) delete newProps.icon;
  if (newProps.iconSolid) delete newProps.iconSolid;
  if (typeof newProps.loading !== 'undefined') delete newProps.loading;
  return newProps;
};

export function IconButton(props: OwnProps) {
  const linkComponent = props.to ? Link : undefined;

  return (
    <PentairIconButton
      LinkComponent={linkComponent}
      {...removeIconFromProps(props)}
      onClick={!Boolean(props.loading) ? props.onClick : undefined}
    >
      <PentairIconButtonIcon>
        {/* loading */}
        {Boolean(props.loading) && (
          <>
            <FontAwesomeIcon icon={faSpinner} spin />
            <FontAwesomeIcon icon={faSpinnerSolid} spin />
          </>
        )}

        {/* not loading */}
        {!Boolean(props.loading) && (
          <>
            <FontAwesomeIcon icon={props.icon} />
            <FontAwesomeIcon icon={props.iconSolid} />
          </>
        )}
      </PentairIconButtonIcon>
    </PentairIconButton>
  );
}
