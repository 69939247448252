import { Box, Divider } from '@mui/material';
import { HeaderPropertyValue } from '../../../../__old__/v2/common/layout/header-prop-value';
import { CalculationTextField } from '../../../../__old__/v2/common/ui/calculation-textfield';
import { CalculationsRecord } from '../../../../__old__/v2/feature/projection/helpers/calculation-values';
import { isValueNegative } from '../../helpers/calculation-value';
import { CalculationValue } from '../../types/calculation-value';

export interface CEBSettingsProps {
  inputs: Partial<CalculationsRecord>;
  advisedInputs: Partial<CalculationsRecord>;
  onChange(calculationValue: CalculationValue): void;
  onError?(id: string, error: string | false): void;
}

export const MainSettings: React.FC<CEBSettingsProps> = (props) => {
  const { inputs, advisedInputs, onChange, onError } = props;

  return (
    <Box width={500}>
      <Box display={'flex'}>
        <HeaderPropertyValue
          horizontal
          marginRight={1}
          calculationValue={inputs['ucl_overdesign']}
          error={isValueNegative(inputs['ucl_overdesign'])}
        />
        <HeaderPropertyValue horizontal marginRight={1} calculationValue={inputs['mb_out_calc_recovery']} />
      </Box>

      <Divider style={{ margin: '24px 0' }} />

      <CalculationTextField
        calculationValue={inputs['filtration_flux']}
        advisedValue={advisedInputs['filtration_flux']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:150|numberMin:25'}
        type="number"
        inputProps={{ min: 25, max: 150, step: 1 }}
        showEgu
        eguDisabled
        eguWidth={100}
      />

      <CalculationTextField
        calculationValue={inputs['filtration_duration']}
        advisedValue={advisedInputs['filtration_duration']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:240|numberMin:5'}
        type="number"
        inputProps={{ min: 5, max: 240, step: 1 }}
        showEgu
        eguWidth={100}
      />

      {inputs['internal_recirculation'] && (
        <CalculationTextField
          select
          calculationValue={inputs['internal_recirculation']}
          advisedValue={advisedInputs['internal_recirculation']}
          onChange={onChange}
          onError={onError}
          validations={'required'}
        />
      )}
    </Box>
  );
};
