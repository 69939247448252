import { lazy } from 'react';
import { createRoute } from '../../application/helpers/route';

// Lazy loading components
const ConfigureXpert = lazy(() => import('../../../pages/configure-xpert'));

export const routesConfigure = {
  // Configure xpert (A proposal at a time though)
  'configure.xpert': createRoute({
    path: '/configure/:segment',
    title: 'Manage Settings',
    component: ConfigureXpert,
    requiredPermissions: ['ui.show.manage-settings'],
    breadcrumbs: [
      {
        route: 'configure.xpert',
        label: 'Configure',
      },
      {
        route: 'configure.xpert',
        label: ({ match }) => {
          return match.params.segment;
        },
      },
    ],
  }),
};
