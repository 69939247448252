import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import { MessageType } from '../../../../__old__/application/business/interactor/message-interactor';

import { PreventCloseDialog } from '../../../../__old__/v2/common/ui/dialog/prevent-close-dialog';
import { Message } from '../../../../__old__/v2/common/ui/message';
import { PentairTabs, TabInfo } from '../../../../__old__/v2/common/ui/pentair-tabs';
import { ProjectionContext } from '../../../../__old__/v2/feature/projection/context/projection-context';
import { ProcessingCalculationDialog } from '../../../../__old__/v2/feature/projection/view/dialog/processing-calculation-dialog';

import { useDeprecatedProjection } from '../../hooks/use-deprecated-projection';
import { FeedPumpConfiguration } from './feed-pump-configuration';
import { CoagulantConfiguration } from './coagulant-configuration';
import { DialogHeader } from '../../../../theme/components/dialog-header';
import { useValidationErrors } from '../../hooks/use-validation-errors';
import { ProjectionRecord } from '../../../../__old__/application/resources/projection/projection';
import { faClipboardListCheck, faSpinner } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';

export interface FeedDialogProps extends Omit<DialogProps, 'onChange'> {
  onChange: (key: keyof ProjectionRecord, value: any) => void;
}

export const FeedDialog: React.FC<FeedDialogProps> = ({ onChange, ...props }) => {
  return (
    <Dialog
      maxWidth={false}
      scroll={'paper'}
      {...props}
      onClose={undefined}
      PaperProps={{ style: { maxWidth: 'none' } }}
      disableEnforceFocus
    >
      <FeedDialogContent onClose={props.onClose} onChange={onChange} />
    </Dialog>
  );
};

const FeedDialogContent: React.FC<{
  onClose: DialogProps['onClose'];
  onChange: (key: keyof ProjectionRecord, value: any) => void;
}> = ({ onClose, onChange }) => {
  const { t } = useTranslation();

  const projection = useContext(ProjectionContext);
  const {
    inputs,
    changes,
    advisedInputs,
    updateCalculationValue,
    applyChanges,
    calculationRunning,
    activeProcess,
    calculationError,
    revertToAdvised,
  } = useDeprecatedProjection(projection, { onChange });

  const tabs = useMemo(() => {
    return [
      { value: 'feed_pump_configuration', label: 'Feed pump configuration', disabled: false },
      { value: 'coagulant_configuration', label: 'Coagulant configuration', disabled: false },
    ];
  }, []);

  const [activeTab, setActiveTab] = useState<TabInfo>(tabs[0]);
  const [isClosing, setIsClosing] = useState(false);
  const [isWTCalculating, setIsWTCalculating] = useState(false);

  const hasChanges = Boolean(Object.keys(changes).length);
  const { validationErrors, handleValidationError } = useValidationErrors();

  const disabled = Boolean(isWTCalculating || calculationRunning || !hasChanges || validationErrors);

  const handleClose = useCallback(
    (e: any) => {
      if (calculationRunning) {
        e.preventDefault();
        return;
      }

      if (isClosing || !hasChanges) {
        onClose && onClose(e, 'backdropClick');
      } else {
        setIsClosing(true);
      }
    },
    [calculationRunning, hasChanges, isClosing, onClose]
  );

  return (
    <Box
      style={{
        transition: 'height 0.3s ease, width 0.3s ease',
      }}
    >
      <DialogHeader
        title={t('projections:dialog.feed.title', { defaultValue: 'Feed' })}
        subtitle={activeTab.label}
        onReset={revertToAdvised}
        onClose={handleClose}
      />
      <PentairTabs tabs={tabs} onTabClick={setActiveTab} />
      <DialogContent style={{ paddingTop: 24 }}>
        <Message
          show={Boolean(calculationError)}
          type={MessageType.Error}
          children={calculationError}
          style={{ marginBottom: 24 }}
        />

        {activeTab.value === 'feed_pump_configuration' && (
          <FeedPumpConfiguration
            inputs={inputs}
            advisedInputs={advisedInputs}
            onChange={updateCalculationValue}
            onError={handleValidationError}
          />
        )}

        {activeTab.value === 'coagulant_configuration' && (
          <CoagulantConfiguration
            inputs={inputs}
            advisedInputs={advisedInputs}
            onChange={updateCalculationValue}
            onError={handleValidationError}
            onIsCalculatingChange={setIsWTCalculating}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          onClick={() => applyChanges(inputs)}
          disabled={disabled}
          startIcon={
            isWTCalculating || calculationRunning ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <FontAwesomeIcon icon={faClipboardListCheck} />
            )
          }
        >
          Update
        </Button>
      </DialogActions>

      <ProcessingCalculationDialog
        open={calculationRunning}
        activeMessage={activeProcess}
        title={'Just a few seconds'}
        subtitle={'Processing your inputs'}
      />

      <PreventCloseDialog
        title={'Warning'}
        subtitle={'Confirm your action'}
        message={
          <>
            One or more fields are changed but the calculations are not updated.
            <br />
            <br />
            Click <strong>UPDATE</strong> to run calculations with the changed values.
            <br />
            <br />
            If you do not want to update calculations click
            <strong> DISCARD</strong> to discard changes made and continue.
          </>
        }
        open={isClosing}
        onClose={handleClose}
        onConfirm={() => {
          applyChanges(inputs);
          setIsClosing(false);
        }}
        confirmDisabled={disabled}
        confirmButtonText={'UPDATE'}
        closeButtonText={'DISCARD'}
      />
    </Box>
  );
};
