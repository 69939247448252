import { Presenter } from "../../../../hooks/use-presenter";
import { observable } from "mobx";

interface ComponentProps {
  img: Array<string>;
}

export class ProjectionInteractiveImagePresenter implements Presenter {
  public images: Array<string>;
  @observable public loaded: boolean = false;

  constructor(protected props: ComponentProps) {
    this.images = props.img;
  }

  public mount = () => {};

  public unmount = () => {};

  public imageLoaded = (e: any) => {
    this.loaded = true;
  };
}
