import { useState, useCallback } from 'react';
import { convertWtPercent } from '../../../__old__/v2/feature/calculation/functions/convert-wt-percent';

export const useWTCalculation = () => {
  const [isConverting, setConverting] = useState(false);

  const convertWtPercentage = useCallback(async (coagulant: string, wtpercent: number, type?: string) => {
    try {
      if (
        !wtpercent ||
        wtpercent < 0 ||
        (wtpercent as any) === 'NaN' ||
        !coagulant ||
        ['D2', 'TBD', 'null'].includes(coagulant)
      ) {
        return;
      }

      setConverting(true);

      const result = await convertWtPercent({
        variables: {
          inputs: JSON.stringify({
            id: coagulant,
            wtpercent,
            type: type || 'chemical',
          }),
        },
      });

      const response = result?.data?.projectionCalculationConvertWtPct;
      setConverting(false);
      return response?.active_concentration?.toFixed(2);
    } catch (e: any) {
      console.error(e);
    }

    setConverting(false);
  }, []);

  return {
    isConverting,
    convertWtPercentage,
  };
};
