import { WaterFeed } from '../../helpers/dt';

const defaultDescriptions: Record<WaterFeed, string> = {
  // City water
  'City water': `
    <p>PRE-TREATMENT: To be confirmed - Automatic strainer < 300 µm</p>

    <p>OTHER FEED WATER PARAMETERS TO UF:<br/>
    Dissolved Fe<0.2 mg/l; Dissolved Mn<0.05 mg/l; Dissolved Al<0.1 mg/l; Ammonia<0.1 mg/l; Phosphate<0.01 mg/l; Oil & Grease<0.1 mg/l; BOD5<1 mg/l O2; Hardness<150 mg/l CaCO3; Langelier Saturation Index (LSI)<0.5; Colloidal silica<1 mg/l;</p>
    
    <p>DESIGN GUIDELINES:<br/>
    Please refer to the guidelines section for more detailed Ultrafiltration system design guidelines.</p> 
    `,

  // Well water
  'Well water': `
    <p>PRE-TREATMENT: To be confirmed - Automatic strainer < 300 µm</p>

    <p>OTHER FEED WATER PARAMETERS TO UF:<br/>
    Dissolved Fe<0.2 mg/l; Dissolved Mn<0.05 mg/l; Dissolved Al<0.1 mg/l; Ammonia<0.1 mg/l; Phosphate<0.01 mg/l; Oil & Grease<0.1 mg/l; BOD5<1 mg/l O2; Hardness<150 mg/l CaCO3; Langelier Saturation Index (LSI)<0.5; Colloidal silica<1 mg/l;                
    
    <p>DESIGN GUIDELINES:<br/>
    Please refer to the guidelines section for more detailed Ultrafiltration system design guidelines.
    `,

  //Surface water
  'Surface water': `
    <p>PRE-TREATMENT: To be confirmed - Automatic strainer < 300 µm; In the case of direct intake with no pre-treatment, a feed strainer of a maximum of 100 µm is required to avoid the entrance of shellfish eggs, which could grow/accumulate in the UF system and harm the membranes.</p>

    <p>OTHER FEED WATER PARAMETERS TO UF:<br/>
    Dissolved Fe<0.2 mg/l; Dissolved Mn<0.05 mg/l; Dissolved Al<0.1 mg/l; Ammonia<0.1 mg/l; Phosphate<0.01 mg/l; Oil & Grease<0.1 mg/l; BOD5<5 mg/l O2; Hardness<100 mg/l CaCO3; Langelier Saturation Index (LSI)<0.5; Chlorophyll<1 µg/l; Colloidal silica<1 mg/l;                
    
    <p>DESIGN GUIDELINES:<br/>
    Please refer to the guidelines section for more detailed Ultrafiltration system design guidelines.
    `,

  //Sea water
  'Sea water': `
    <p>PRE-TREATMENT: To be confirmed - Automatic strainer < 300 µm; In the case of direct intake with no pre-treatment, a feed strainer of a maximum of 100 µm is required to avoid the entrance of shellfish eggs, which could grow/accumulate in the UF system and harm the membranes.</p>

    <p>OTHER FEED WATER PARAMETERS TO UF:<br/>
    Dissolved Fe<0.2 mg/l; Dissolved Mn<0.05 mg/l; Dissolved Al<0.1 mg/l; Ammonia<0.1 mg/l; Phosphate<0.01 mg/l; Oil & Grease<0.1 mg/l; BOD5<3 mg/l O2; Hardness<6,900 mg/l CaCO3; Chlorophyll<1 µg/l;                
    
    <p>DESIGN GUIDELINES:<br/>
    Please refer to the guidelines section for more detailed Ultrafiltration system design guidelines.
    `,

  //Treated effluent
  'Treated effluent': `
    <p>PRE-TREATMENT: To be confirmed - Automatic strainer < 300 µm</p>

    <p>OTHER FEED WATER PARAMETERS TO UF:<br/>
    Dissolved Fe<0.2 mg/l; Dissolved Mn<0.05 mg/l; Dissolved Al<0.1 mg/l; Ammonia<1 mg/l; Phosphate<1 mg/l; Oil & Grease<1 mg/l; BOD5<10 mg/l O2; Hardness<150 mg/l CaCO3; Langelier Saturation Index (LSI)<0.5; Colloidal silica<1 mg/l;                
    
    <p>DESIGN GUIDELINES:<br/>
    Please refer to the guidelines section for more detailed Ultrafiltration system design guidelines.
    `,

  // Sewage
  Sewage: ``,
  'Industrial waste': ``,
  'Emulsified oil': ``,
};

const aquaflexHsDescriptions: Record<WaterFeed, string> = {
  // City water
  'City water': `
    <p>PRE-TREATMENT: To be confirmed - Automatic strainer < 300 µm</p>

    <p>OTHER FEED WATER PARAMETERS TO UF:<br/>
    Dissolved Fe<0.2 mg/l; Dissolved Mn<0.05 mg/l; Dissolved Al<0.1 mg/l; Ammonia<0.1 mg/l; Phosphate<0.01 mg/l; Oil & Grease<0.1 mg/l; BOD5<1 mg/l O2; Hardness<150 mg/l CaCO3; Langelier Saturation Index (LSI)<0.5; Colloidal silica<1 mg/l;</p>
    
    <p>DESIGN GUIDELINES:<br/>
    Please refer to the guidelines section for more detailed Ultrafiltration system design guidelines.</p> 
    `,

  // Well water
  'Well water': `
    <p>PRE-TREATMENT: To be confirmed - Automatic strainer < 1 mm for XF33R or 2 mm for Compact 33V/Compact 22V</p>

    <p>OTHER FEED WATER PARAMETERS TO UF:<br/>
    Dissolved Fe<0.2 mg/l; Dissolved Mn<0.05 mg/l; Dissolved Al<0.1 mg/l; Ammonia<0.1 mg/l; Phosphate<0.01 mg/l; Oil & Grease<0.1 mg/l; Hardness<150 mg/l CaCO3; Langelier Saturation Index (LSI)<0.5; Colloidal silica<1 mg/l;                
    
    <p>DESIGN GUIDELINES:<br/>
    Please refer to the guidelines section for more detailed Ultrafiltration system design guidelines.
    `,

  //Surface water
  'Surface water': `
    <p>PRE-TREATMENT: To be confirmed - Automatic strainer < 1 mm for XF33R or 2 mm for Compact 33V/Compact 22V</p>

    <p>OTHER FEED WATER PARAMETERS TO UF:<br/>
    Dissolved Fe<0.2 mg/l; Dissolved Mn<0.05 mg/l; Dissolved Al<0.1 mg/l; Ammonia<0.1 mg/l; Phosphate<0.01 mg/l; Oil & Grease<0.1 mg/l; Hardness<100 mg/l CaCO3; Langelier Saturation Index (LSI)<0.5; Chlorophyll<1 µg/l; Colloidal silica<1 mg/l;                
    
    <p>DESIGN GUIDELINES:<br/>
    Please refer to the guidelines section for more detailed Ultrafiltration system design guidelines.
    `,

  //Sea water
  'Sea water': `
    <p>PRE-TREATMENT: To be confirmed - Automatic strainer < 1 mm for XF33R or 2 mm for Compact 33V/Compact 22V</p>

    <p>OTHER FEED WATER PARAMETERS TO UF:<br/>
    Dissolved Fe<0.2 mg/l; Dissolved Mn<0.05 mg/l; Dissolved Al<0.1 mg/l; Ammonia<0.1 mg/l; Phosphate<0.01 mg/l; Oil & Grease<0.1 mg/l; Hardness<6,900 mg/l CaCO3; Chlorophyll<1 µg/l;                
    
    <p>DESIGN GUIDELINES:<br/>
    Please refer to the guidelines section for more detailed Ultrafiltration system design guidelines.
    `,

  //Treated effluent
  'Treated effluent': `
    <p>PRE-TREATMENT: To be confirmed - Automatic strainer < 1 mm for XF33R or 2 mm for Compact 33V/Compact 22V</p>

    <p>OTHER FEED WATER PARAMETERS TO UF:<br/>
    Dissolved Fe<0.2 mg/l; Dissolved Mn<0.05 mg/l; Dissolved Al<0.1 mg/l; Ammonia<1 mg/l; Phosphate<1 mg/l; Oil & Grease<1 mg/l; Hardness<150 mg/l CaCO3; Langelier Saturation Index (LSI)<0.5; Colloidal silica<1 mg/l;                
    
    <p>DESIGN GUIDELINES:<br/>
    Please refer to the guidelines section for more detailed Ultrafiltration system design guidelines.
    `,

  // Sewage
  Sewage: ``,
  'Industrial waste': ``,
  'Emulsified oil': ``,
};

export const defaultProjectionDescription = (
  solution: string,
  watersource: WaterFeed
) => {
  let descriptions = defaultDescriptions;

  if (solution === 'aquaflex-hs') {
    descriptions = aquaflexHsDescriptions;
  }

  return descriptions[watersource] || '';
};
