import { makeStyles } from '@mui/styles';
import { ImageMap } from '../../../../solution/types/record';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { ProjectionClass } from '../../../../../../application/resources/projection/v2/single';
import { useCheckPermission } from '../../../../../../hooks/use-check-permission';
import { CEBDialog } from '../../../../../../../feature/projections/dialogs/dialog-ceb/dialog-ceb';
import { ConfigurationDialog } from '../../../../../../../feature/projections/dialogs/dialog-configuration/dialog-configuration';
import { BackwashDialog } from '../../../../../../../feature/projections/dialogs/dialog-backwash/dialog-backwash';
import { FeedDialog } from '../../../../../../../feature/projections/dialogs/dialog-feed/dialog-feed';
import { ProjectionRecord } from '../../../../../../application/resources/projection/projection';
import { AquaflexInletDialog } from '../../../../solution/view/aquaflex/dialogs/inlet-dialog';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons';

interface DialogMarkerProps {
  projection: ProjectionClass;
  marker: ImageMap;
  onChange: (key: keyof ProjectionRecord, value: any) => void;
}

const useStyles = makeStyles((theme: any) => ({
  root: {},
  icon: {
    marginRight: theme.spacing(1),
  },
  markerTitle: {
    fontWeight: 600,
  },
  tooltip: {
    padding: theme.spacing(0.75, 1),
    background: theme.palette.blue.main,
    position: 'relative',
    color: '#fff',
    cursor: 'pointer',
    '&::after': {
      borderRight: 'solid 6px transparent',
      borderLeft: 'solid 6px transparent',
      borderTop: 'solid 6px ' + theme.palette.blue.main,
      transform: 'translateX(-50%)',
      position: 'absolute',
      zIndex: '1',
      content: "''",
      top: '100%',
      left: '50%',
      height: '0',
      width: '0',
    },
  },
  tooltipArrowTop: {
    '&::after': {
      top: '-6px',
      transform: 'translateX(-50%) rotate(180deg)',
    },
  },
  tooltipArrowLeft: {
    '&::after': {
      left: '-3px',
      transform: 'translateX(-50%) rotate(90deg)',
      top: '17px',
    },
  },
  tooltipArrowRight: {
    '&::after': {
      right: '-3px',
      left: 'auto',
      transform: 'translateX(50%) rotate(270deg)',
      top: '17px',
    },
  },
}));

export const DialogMarker: React.FC<DialogMarkerProps> = (props) => {
  const { marker, onChange, projection } = props;
  const classes = (useStyles as any)(props);
  const [openDialog, setOpenDialog] = React.useState(false);
  const checkPermission = useCheckPermission();

  return (
    <React.Fragment>
      <div
        style={{
          position: 'absolute',
          left: marker.location[0] + '%',
          top: marker.location[1] + '%',
        }}
      >
        <div
          className={classnames([
            classes.tooltip,
            (marker.arrow_position === 'top' && classes.tooltipArrowTop) ||
              (marker.arrow_position === 'left' && classes.tooltipArrowLeft) ||
              (marker.arrow_position === 'right' && classes.tooltipArrowRight),
          ])}
          onClick={() => setOpenDialog(true)}
        >
          <FontAwesomeIcon
            className={classes.icon}
            /* icon={['fal', marker.icon]} they are all the same */ icon={faClipboardList}
          />
          <strong className={classes.markerTitle}>{marker.title}</strong>
        </div>
      </div>
      {marker.content === 'input.feed_section' && openDialog && !checkPermission('ui.basic.projection') && (
        <FeedDialog
          // marker={marker}
          open={openDialog}
          // projection={projection}
          onClose={() => setOpenDialog(false)}
          onChange={onChange}
        />
      )}
      {marker.content === 'input.backwash_section' && openDialog && !checkPermission('ui.basic.projection') && (
        <BackwashDialog
          // marker={marker}
          open={openDialog}
          // projection={projection}
          onClose={() => setOpenDialog(false)}
          onChange={onChange}
        />
      )}
      {marker.content === 'input.configuration' && openDialog && !checkPermission('ui.basic.projection') && (
        <ConfigurationDialog
          // marker={marker}
          open={openDialog}
          // projection={projection}
          onClose={() => setOpenDialog(false)}
          onChange={onChange}
        />
      )}
      {marker.content === 'input.ceb' && openDialog && !checkPermission('ui.basic.projection') && (
        <CEBDialog
          // marker={marker}
          open={openDialog}
          // projection={projection}
          onClose={() => setOpenDialog(false)}
          onChange={onChange}
        />
      )}
      {marker.content === 'input.inlet' && openDialog && (
        <AquaflexInletDialog
          open={openDialog}
          projection={projection}
          onClose={() => setOpenDialog(false)}
          onChange={onChange}
        />
      )}
    </React.Fragment>
  );
};
