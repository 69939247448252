/**
 *
 *      1. maps membrane element to its available housings
 *
 *      2. backward compatible updater function
 *
 */

export type MembraneHosingListItem = {
  id: string;
  label: string;
};

export const useAvailableMembraneHousings = (
  solution: string | undefined,
  membraneElement?: string | number,
  flow?: string | number
): MembraneHosingListItem[] | undefined => {
  // starting with nothing found
  let availableHousings: MembraneHosingListItem[] = [];

  // easy fix for the calculationvalue.values[0] type being "number | string"
  if (typeof membraneElement === 'number') {
    membraneElement = String(membraneElement);
  }

  // if flow is string; convert
  if (typeof flow === 'string') {
    flow = parseFloat(flow);
  }

  switch (solution) {
    // XIGA
    case 'xiga':
      switch (membraneElement) {
        case 'XF75':
          availableHousings.push({ id: '90HXF12-6', label: '90HXF12-6' });
          break;
        case 'XF64':
        case 'XF55':
        case 'HFS60': // not available anymore since feb 2024
          availableHousings.push({ id: '90L12-6', label: '90L12-6' }); // default/fallback
          if (!flow || (flow > 0 && flow < 15)) {
            availableHousings.push({ id: '90L12-3', label: '90L12-3' });
          }
          availableHousings.push({ id: '90S30-6 (Code Compliant)', label: '90S30-6 (Code Compliant)' });
          break;
        case 'XF53':
          availableHousings.push({ id: '80L15-6', label: '80L15-6' });
          break;
        case 'XF40':
          availableHousings.push({ id: '80L15-6', label: '80L15-6' }); // default/fallback
          if (!flow || flow <= 15) {
            availableHousings.push({ id: '80L15-3', label: '80L15-3' });
          }
          availableHousings.push({ id: '80S15-6 (Non-coded)', label: '80S15-6 (Non-coded)' });
          availableHousings.push({ id: '80S15-6 (Code Compliant)', label: '80S15-6 (Code Compliant)' });
          break;
        default:
          break;
      }
      break;

    // special case: no housings
    case 'aquaflex-hs':
    case 'xline':
      return undefined;

    // all other solutions
    default:
      // these all have a 'no' options
      availableHousings.push({ id: 'No', label: 'No' });

      // add depending on membrane element
      switch (membraneElement) {
        case 'XF64':
        case 'XF55':
        case 'HFS60': // not available anymore since feb 2024
          availableHousings.push({ id: '87L09-1.5', label: '87L09-1.5' });
          break;
        default:
          break;
      }
      break;
  }

  return availableHousings;
};
export default useAvailableMembraneHousings;

// to fix old values -> new values
export const backwardCompatibleMembraneHousings = (oldValue: string | number, membrane?: string | number) => {
  // easy fix for the calculationvalue.values[0] type being "number | string"
  if (typeof oldValue === 'number') {
    oldValue = String(oldValue);
  }
  if (typeof membrane === 'number') {
    membrane = String(membrane);
  }

  switch (oldValue) {
    case '87L09': // PXX-1392;
      return '87L09-1.5';
    case 'Yes': // PXX-1393
    case 'yes':
      if (membrane && membrane === 'XF40') {
        return '80L15-6';
      }
      return '90L12-6';
    default:
      return oldValue;
  }
};
