/*

  By Code.Rehab

*/
import { createRoot } from 'react-dom/client';
import App from './app';

import './__old__/css/amplify.css';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
/**
 * 
 * needed a change, for a new deployment
 * 
 */