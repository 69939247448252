/*

  By Code.Rehab

*/

import { useState, useCallback } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';
import { amplify } from '../../../__old__/config/amplify';
import { ProjectRecord } from '../../../__old__/application/resources/project/project';
import { ProjectionRecord } from '../../../__old__/application/resources/projection/projection';
import { GetProjectAndProjectionQuery } from '../graphql-queries/project-projection-query';
import { ProjectionClass } from '../../../__old__/application/resources/projection/v2/single';
import { GetSolutionQuery } from '../graphql-queries/solution-query';
import { merge } from 'lodash';

export const usePrintPreview = (projectId: string, projectionId: string, token: string, version?: string) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [project, setProject] = useState<ProjectRecord | undefined>(undefined);
  const [projection, setProjection] = useState<ProjectionRecord | undefined>(undefined);
  const [projectionClass, setProjectionClass] = useState<ProjectionClass | undefined>(undefined);

  const fetchData = useCallback(async () => {
    try {
      // configure api with given token
      await API.configure({
        aws_appsync_graphqlEndpoint: amplify.aws_appsync_graphqlEndpoint,
        aws_appsync_region: amplify.aws_appsync_region,
        aws_appsync_authenticationType: amplify.Auth.authenticationFlowType,
        graphql_headers: async () => {
          return {
            Authorization: token,
          };
        },
      });

      // do request
      const result: any = await API.graphql(
        graphqlOperation(GetProjectAndProjectionQuery, {
          projectId: projectId,
          projectionid: projectionId,
          version: version || undefined,
        })
      );

      if (result && result.errors) {
        console.error('usePrintPreview fetchData() getProjectAndProjection error:', result.errors);
        return false;
      }

      if (result && result.data && result.data.Projection && result.data.Project) {
        // do request for solution
        const result2: any = await API.graphql(
          graphqlOperation(GetSolutionQuery, {
            id: result.data.Projection.solution.id,
          })
        );

        if (result2 && result2.errors) {
          console.error('usePrintPreview fetchData() getSolution error:', result2.errors);
          return false;
        }

        if (result2 && result2.data && result2.data.Solution) {
          let projection = result.data.Projection;

          const inputs = result2.data.Solution.inputs;
          const outputs = result2.data.Solution.outputs;

          projection.inputs = merge([], inputs, projection.inputs);
          projection.outputs = merge([], outputs, projection.outputs);

          const pc = new ProjectionClass();
          pc.record = projection;

          // convert a bit projectV2 -> projectV1
          result.data.Project.owner = {
            id: result.data.Project.createdBy.id,
            attributes: {
              family_name: result.data.Project.createdBy.lastName,
              given_name: result.data.Project.createdBy.firstName,
            },
          };

          setProject(result.data.Project);
          setProjection(projection);
          setProjectionClass(pc);
        }
      }

      setLoading(false);
      return true;
    } catch (err) {
      console.error('usePrintPreview fetchData() error:', err);
      return false;
    }
  }, [token, projectionId, projectId, version]);

  return {
    loading: loading,
    project: project,
    projection: projection,
    projectionClass: projectionClass,
    fetchData: fetchData,
  };
};
