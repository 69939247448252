import { convertWtPercent } from '../../../calculation/functions/convert-wt-percent';
import { stockConcentrationTableCEB } from '../../../solution/helpers/stock-concentration';
import { CalculationValue, State } from '../../../solution/types/record';
import {
  getCalculationPropertyBasedOnCalcField,
  setCalculationValues,
} from '../../helpers/calculation-values';
import { CalculationValues } from '../projection/projection-values';

export const getCEB = (calcVal: CalculationValue) => {
  const ceb = calcVal.id.substr(calcVal.id.indexOf('ceb_') + 4, 3);
  const type = calcVal.id.charAt(calcVal.id.length - 1);

  return {
    ceb,
    type,
    cebKey: `ceb_${ceb}_chemical_stock_concentration_${type}_wtpercent` as CalculationValue['id'],
    glKey: `ceb_${ceb}_chemical_stock_concentration_${type}` as CalculationValue['id'],
    concentrationAgentKey: `ceb_${ceb}_concentration_agent_${type}` as CalculationValue['id'],
  };
};

export const calculateCEBResults = async (
  inputs: CalculationValues<'id'>,
  inputsBasedOnCalcfields: CalculationValues<'calculation_field'>,
  calculationState: State
) => {
  const cebInputs = Object.entries(calculationState.advice.inputs).filter((entry) => {
    const [key] = entry;

    if (!key.includes('ucp_passthrough_wtpct_agent')) {
      const calcVal = getCalculationPropertyBasedOnCalcField(key, inputsBasedOnCalcfields, inputs);

      if (
        calcVal &&
        calcVal.id &&
        calcVal.id.includes('_chemical_agent_') &&
        calcVal.values[0] !== 'null'
      ) {
        return true;
      }
    }

    return false;
  });

  return await Promise.all(
    cebInputs.map((input) =>
      calculateGramsPerLiters(input[0], input[1], inputs, inputsBasedOnCalcfields)
    )
  );
};

export const calculateGramsPerLiters = async (
  key: any,
  value: any,
  inputs: CalculationValues<'id'>,
  inputsBasedOnCalcfields: CalculationValues<'calculation_field'>
) => {
  const calcVal = inputsBasedOnCalcfields[key];
  const response: any = {
    cebKey: '',
    ceb: undefined,
    cebGLKey: '',
    cebGL: undefined,
    cebGLResponse: undefined,
  };

  if (calcVal) {
    const { cebKey, glKey } = getCEB(calcVal);
    response.cebKey = cebKey;
    response.cebGLKey = glKey;

    // update CEB
    if (inputs[cebKey]) {
      setCalculationValues(inputs, cebKey, [stockConcentrationTableCEB[String(value)]]);
      response.ceb = stockConcentrationTableCEB[String(value)];
    }

    // Update GL
    if (value && inputs[glKey]) {
      const data = {
        id: String(value),
        wtpercent: stockConcentrationTableCEB[String(value)],
        type: 'chemical',
      };

      const result = await convertWtPercent({
        variables: {
          inputs: JSON.stringify(data),
        },
      });

      response.cebGLResponse = result;

      if (result.data && result.data.projectionCalculationConvertWtPct.status === 'ok') {
        const glResult = result.data.projectionCalculationConvertWtPct.active_concentration.toFixed(
          2
        );
        setCalculationValues(inputs, glKey, [glResult]);
        response.cebGL = glResult;
      }
    }
  }

  return response;
};
