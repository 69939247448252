/*

  By Code.Rehab

*/

import gql from "graphql-tag";


export const GetSolutionQuery = gql`
  query Solution($id: ID!) {
    Solution(id: $id) {
      id
      inputs {
        id
        name
        values
        recommendedValues
        unit
        unitOptions
        valueOptions {
          id
          label
        }
        disabled
        error
        category
        decimals
        calculation_field
      }
      outputs {
        id
        name
        values
        recommendedValues
        unit
        unitOptions
        valueOptions {
          id
          label
        }
        disabled
        error
        category
        decimals
        calculation_field
      }
    }
  }
`