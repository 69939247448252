import * as React from 'react';
import { Theme, StyledComponentProps } from '@mui/material';
import classnames from 'classnames';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { BoxProps } from '@mui/system';

type PageContentVariant = 'contained' | 'centered' | 'padded' | 'fullscreen' | 'fullscreenPaper';

interface OwnProps
  extends StyledComponentProps<
    'root' | 'containedVariant' | 'paddedVariant' | 'centeredVariant' | 'fullscreenVariant' | 'fullscreenPaperVariant'
  > {
  variant?: PageContentVariant;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: 'flex', flexDirection: 'column', flex: 1 },
  centeredVariant: {
    margin: 'auto',
    justifyContent: 'center',
  },
  fullscreenVariant: {
    paddingTop: theme.spacing(6),
  },
}));

export const PageContent: React.FC<React.PropsWithChildren<OwnProps & BoxProps>> = (props) => {
  const classes = (useStyles as any)(props);
  const { className, variant, ...boxProps } = props;
  const variantClass = classes[((variant || 'contained') + 'Variant') as 'containedVariant'];

  return (
    <Box component="article" className={classnames(className, classes.root, variantClass)} {...boxProps}>
      {props.children}
    </Box>
  );
};
