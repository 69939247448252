import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from 'react';

import { CognitoUserSession } from 'amazon-cognito-identity-js';

import { Auth, CognitoUser } from '@aws-amplify/auth';
import { AuthFlow } from './auth-flow';
import { amplify } from '../../__old__/config/amplify';

interface User {
  id: string;
  username: string;
  authProvider: string;
  attributes: Record<string, string>;
  status: string;
}

export enum AuthState {
  SignedIn = 'SIGNEDIN',
  SignedOut = 'SIGNEDOUT',
  PPPMigrationRequired = 'PPP_MIGRATION_REQUIRED',
  PasswordResetRequired = 'PASSWORD_RESET_REQUIRED',
}

interface AuthContextState {
  completeNewPasswordChallenge(newPassword: string, requiredAttributes?: Record<string, unknown>): void;
  signIn(username: string, password: string): void;
  signOut(): void;
  error: Error | null;
  loading: boolean;
  cognitoUser: CognitoUser | null;
  user: User | null;
  session: CognitoUserSession | null;
  isAuthenticated: boolean;
  awaitingSignedInUser: boolean;
  federatedSignin(provider: string): void;
  authState: AuthState;
  isPentairUser: boolean;
}

const AuthContext = createContext<AuthContextState | null>(null);

Auth.configure(amplify.Auth);
const token = new URLSearchParams(window.location.search).get('token');

export const AuthProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [session, setSession] = useState<CognitoUserSession | null>(null);
  const [cognitoUser, setCognitoUser] = useState<CognitoUser | null>(null);
  const [user, setUser] = useState<User | null>(null);
  const [awaitingSignedInUser, setAwaitingSignedInUser] = useState(true);
  const username = cognitoUser?.getUsername?.() || '';

  const isPentairUser = Boolean(user?.attributes.email?.toLowerCase().endsWith('@pentair.com'));

  let authProvider = 'Cognito';
  const maybeProvider = username.split('_');
  if (['pentair-iam-sso', 'pentair-salesforce-sso', 'pentair-partner-portal'].includes(maybeProvider[0])) {
    if (maybeProvider[0] === 'pentair-partner-portal') {
      if (isPentairUser) {
        maybeProvider[0] = 'pentair-iam-sso';
      } else {
        maybeProvider[0] = 'pentair-salesforce-sso';
      }

      maybeProvider[0] = 'pentair-salesforce-sso';
    }
    authProvider = maybeProvider[0];
  }

  // callback to sign in a user with a username and password
  const signIn = useCallback(async (username: string, password: string) => {
    setLoading(true);
    try {
      const cognitoUser: CognitoUser = await Auth.signIn(username, password);
      setCognitoUser(cognitoUser);
    } catch (error: any) {
      setError(error);
      console.error('error signing in', error);
    }
    setLoading(false);
  }, []);

  // callback to sign out the current logged in user
  const signOut = useCallback(async () => {
    setLoading(true);
    try {
      await Auth.signOut();
      setCognitoUser(null);
      setSession(null);
      setUser(null);
    } catch (error: any) {
      setError(error);
      console.error('error signing out: ', error);
    }
    setLoading(false);
  }, []);

  // callback to complete the new password challenge
  const completeNewPasswordChallenge = useCallback(
    (newPassword: string, requiredAttributes: Record<string, unknown> = {}) => {
      setLoading(true);
      try {
        cognitoUser?.completeNewPasswordChallenge(newPassword, requiredAttributes, {
          onSuccess: (result) => {
            setSession(result);
            setLoading(false);
          },
          onFailure: (error) => {
            console.error(error);
            setLoading(false);
            setError(error);
          },
        });
      } catch (error: any) {
        setError(error);
        console.error('error completing new password challenge: ', error);
      }
    },
    [cognitoUser]
  );

  const federatedSignin = useCallback(async (provider: string) => {
    setLoading(true);

    //pentair-iam-sso
    try {
      await Auth.federatedSignIn({
        customProvider: provider,
      });
    } catch (error: any) {
      setError(error);
      console.error('error federated signin: ', error);
    }
    setLoading(false);
  }, []);

  // Check if the user is already signed in when the component mounts
  useEffect(() => {
    setLoading(true);

    if (token) {
      setCognitoUser({} as CognitoUser);
      setAwaitingSignedInUser(false);
      setLoading(false);
      setUser({
        id: '',
        attributes: {},
        username: 'Token',
        authProvider: 'token',
        status: 'ACTIVE',
      });

      return;
    }

    Auth.currentAuthenticatedUser()
      .then((cognitoUser) => {
        setCognitoUser(cognitoUser);
      })
      .catch((error) => {
        console.error(error);
        // signOut();
        setAwaitingSignedInUser(false);
      })
      .finally(() => setLoading(false));
  }, [signOut]);

  // ensure that we update the user and session when the cognitoUser changes
  useEffect(() => {
    if (token) {
      setUser((current) => {
        const attributes = {},
          user = current || {
            id: '',
            attributes,
            username,
            authProvider,
            status: 'ACTIVE',
          };
        return user;
      });

      return;
    }

    // if there is no cognitoUser, then we should clear the session and user
    if (!cognitoUser) {
      // signOut();
      return;
    }

    // if the user is in the NEW_PASSWORD_REQUIRED state, then we should
    // set the user and clear the session
    if ((cognitoUser as any)?.challengeName === 'NEW_PASSWORD_REQUIRED') {
      setUser((current) => {
        const attributes = (cognitoUser as any)?.challengeParam?.userAttributes,
          user = current || {
            id: attributes.sub,
            attributes,
            username,
            authProvider,
            status: cognitoUser?.getSignInUserSession()?.getIdToken().decodePayload().status,
          };
        return user;
      });
    }

    // otherwise, we should get the session and user attributes
    else {
      Auth.currentSession()
        .then((session) => {
          setSession(session);
        })
        .catch((error) => {
          setSession(null);
        });

      Auth.userAttributes(cognitoUser).then((attributes) => {
        setUser((user) => {
          user = user || {
            id: attributes.find((attr) => attr.Name === 'sub')?.Value || '',
            username,
            authProvider,
            attributes: {},
            status: '',
          };

          user.attributes = attributes.reduce((attr, next) => {
            attr[next.Name.replace('custom:', '')] = next.Value;
            return attr;
          }, {} as Record<string, string>);

          user.id = user.attributes.sub || user.id;

          return user;
        });

        setAwaitingSignedInUser(false);
      });
    }
  }, [authProvider, cognitoUser, signOut, username]);

  let authState: AuthState = AuthState.SignedOut;
  const isAuthenticated = Boolean(token) || (!!user && !!session);

  if (isAuthenticated) {
    authState = AuthState.SignedIn;

    if (user?.attributes['status'] === 'PPP_MIGRATION_REQUIRED') {
      authState = AuthState.PPPMigrationRequired;
    }
  }

  if ((cognitoUser as any)?.challengeName === 'NEW_PASSWORD_REQUIRED') {
    authState = AuthState.PasswordResetRequired;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        signIn,
        signOut,
        completeNewPasswordChallenge,
        cognitoUser,
        error,
        loading,
        awaitingSignedInUser,
        session,
        user,
        federatedSignin,
        authState,
        isPentairUser,
      }}
    >
      <AuthFlow>{children}</AuthFlow>
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const auth = useContext(AuthContext);

  if (!auth) throw new Error('useAuth must be used within an AuthProvider');

  return auth;
};
