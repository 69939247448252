import { Inputs } from "../../feature/solution/types/record";

export const removeDuplicates = (
  newVals: Partial<Inputs>,
  oldVals: Partial<Inputs>
) => {
  const result = newVals;
  for (const [key, value] of Object.entries(newVals)) {
    if (oldVals[key] === value) {
      delete result[key];
    }
  }

  return result;
};

export const keepDuplicates = (newVals: string[], oldVals: string[]) => {
  return newVals.filter((val) => oldVals.includes(val));
};
