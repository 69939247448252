import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';

import { faFlag } from '@fortawesome/pro-light-svg-icons';
import { Box, Step, StepIconProps, StepLabel, Stepper, styled, Typography } from '@mui/material';

interface OwnProps {
  steps: string[];
  activeStep: string;
  onStepClick: React.Dispatch<React.SetStateAction<string>>;
}

export const SteppedProgressBar: React.FC<OwnProps> = (props) => {
  const { steps, activeStep, onStepClick } = props;
  const onClickHandler = (step: string) => {
    if (steps.indexOf(activeStep) > steps.indexOf(step)) {
      onStepClick(step);
    }
  };

  return (
    <Box>
      <Stepper activeStep={steps.indexOf(activeStep)} orientation="horizontal">
        {steps.slice(0, -1).map((step, index) => {
          return (
            <Step key={step} completed={steps.lastIndexOf(activeStep) > index} active={activeStep === step}>
              <StepLabel
                StepIconComponent={CustomStepperIcon}
                onClick={() => onClickHandler(step)}
                sx={{ cursor: 'pointer' }}
              />
            </Step>
          );
        })}
        <Step>
          <StepLabel StepIconComponent={CustomStepperIcon} StepIconProps={{ isFinal: true } as any} />
        </Step>
      </Stepper>
    </Box>
  );
};

const StepIconRoot = styled('div')<{ ownerState: { active?: boolean; completed?: boolean } }>(
  ({ ownerState, theme }) => ({
    display: 'flex',
    height: 24,
    width: 24,
    borderRadius: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 13,
    color: theme.palette.white.main,
    backgroundColor: ownerState.completed ? theme.palette.green.main : theme.palette.softGray.main,
    ...(ownerState.active && {
      color: theme.palette.white.main,
      backgroundColor: theme.palette.brightBlue.main,
    }),
  })
);

export const CustomStepperIcon = (props: StepIconProps & { isFinal?: boolean }) => {
  const { active, completed, className, icon: number, isFinal } = props;

  return (
    <StepIconRoot ownerState={{ active, completed }} className={className}>
      {isFinal ? (
        <FontAwesomeIcon icon={faFlag} />
      ) : (
        <Typography variant="body2" fontWeight={600} color={'white.main'}>
          {number}
        </Typography>
      )}
    </StepIconRoot>
  );
};

export const PentairProgressStepperIcon = (props: StepIconProps & { isFinal?: boolean }) => {
  const { active, completed, className, icon } = props;

  return (
    <StepIconRoot ownerState={{ active, completed }} className={className}>
      {icon}
    </StepIconRoot>
  );
};
