import { BaseGraphQLModel, GraphQLModel } from '../../base/graphql/graphql-model';
import { RecordData } from '../../base/general/store';
import { v4 as uuid } from 'uuid';
import { observable } from 'mobx';

export interface CustomAssistantAttributes {
  currentSlot: string;
  technologies: string[];
  tries: Record<string, number>;
}

export interface AssistentResponseMessage {
  messageText: string;
  egu: string;
  inputFormat: string;
  skipValue: string;
  isRephrasePrompt: string;
}

export interface AssistentResponseCard {
  version: string;
  contentType: string;
  genericAttachments: {
    title: string;
    subTitle: string;
    attachmentLinkUrl: string;
    imageUrl: string;
    buttons: {
      text: string;
      value: string;
    }[];
  }[];
}

export interface AssistantResponseRecord extends RecordData {
  sessionId: string;
  sessionAttributes: string;
  dialogState: string;
  slotToElicit: string;
  slots: string;
  message: AssistentResponseMessage;
  responseCard: AssistentResponseCard;
}
export interface AssistantResponse extends GraphQLModel<AssistantResponseRecord> {
  sessionAttributes: CustomAssistantAttributes;
  givenAnswer: string;
}

export class AssistantResponseModel
  extends BaseGraphQLModel<AssistantResponseRecord>
  implements AssistantResponse {
  @observable public givenAnswer = '';
  public defaultValues = {
    id: uuid(),
    sessionId: '',
    sessionAttributes: '',
    dialogState: '',
    slotToElicit: '',
    slots: '{}',
    message: {
      messageText: '',
      egu: '',
      inputFormat: '',
      skipValue: '',
      isRephrasePrompt: '',
    },
    responseCard: {
      version: '',
      contentType: '',
      genericAttachments: [],
    },
  };

  public get sessionAttributes(): CustomAssistantAttributes {
    const parsed = JSON.parse(this.record.sessionAttributes || '{}');
    return {
      currentSlot: parsed.CurrentSlot || '',
      technologies: JSON.parse(parsed.Technologies || '[]').map((tech: string) =>
        tech.toLowerCase()
      ),
      tries: JSON.parse(parsed.Tries || '{}'),
    };
  }
}
