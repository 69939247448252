import * as React from 'react';
import { Box, StyledComponentProps, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { PageFooter } from './page-footer';

type PageVariant = 'contained' | 'centered' | 'padded' | 'fullscreen';

interface OwnProps
  extends StyledComponentProps<
    | 'root'
    | 'content'
    | 'containedVariant'
    | 'paddedVariant'
    | 'centeredVariant'
    | 'fullscreenVariant'
    | 'fullscreenPaperVariant'
    | 'footer'
  > {
  variant?: PageVariant;
  backgroundImage?: string;
  backgroundPosition?: string;
  backgroundSize?: string;
  color?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: { display: 'flex', flexDirection: 'column', flex: 1 },
  containedVariant: {
    width: '100%',
    maxWidth: theme.spacing(140),
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 'calc(' + theme.spacing(1) + ' + 2vw)',
    paddingBottom: 0,
  },
  paddedVariant: { padding: 'calc(' + theme.spacing(1) + ' + 2vw)' },
  centeredVariant: {
    width: '100%',
    flexGrow: 1,
    margin: 'auto',
    background: '#eaeaea',
  },
  footer: {
    paddingTop: theme.spacing(2),
    marginTop: 'auto',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));

export const Page: React.FC<React.PropsWithChildren<OwnProps>> = (props) => {
  const classes = (useStyles as any)(props);
  const { variant, backgroundImage, backgroundPosition, backgroundSize } = props;
  const variantClass = classes[((variant || 'contained') + 'Variant') as 'containedVariant'];

  return (
    <>
      <Box
        minHeight="100%"
        // flex={1}
        className={classnames(classes.root, variantClass)}
        sx={{
          backgroundImage: backgroundImage ? `url(${backgroundImage})` : undefined,
          backgroundPosition,
          backgroundSize: backgroundSize || 'cover',
          // backgroundRepeat: 'no-repeat',
          // backgroundPositionX: 'center',
          // backgroundPositionY: 'center',
          // backgroundAttachment: 'fixed',
          color: props.color || 'inherit',
        }}
      >
        {props.children}

        <PageFooter paddingTop={2} marginTop="auto" color={props.color} />
      </Box>
    </>
  );
};
