import * as Fragment from "../../profile/graphql/fragments";

export const UserProfileFragment = `
id
role
groups
preferences {
  id
  value
} 
profile {
    created_at
    description
    id
    name
    updated_at
    permissions {
      allow
      deny
    }
  }
${Fragment.PermissionFragment}
`;
