import { toolConfig } from "./components/interface";
import authTheme from "./theme/auth";
import { theme } from "./theme/pentair";
import { routeConfig } from "./routes/routes";
import { ApplicationConfig } from "../application/app-types";
import { redirections } from "./routes/redirections";

export type RouteNames = keyof typeof routeConfig;
export const config: ApplicationConfig = {
  routes: {
    redirections: redirections,
    routes: routeConfig,
  },
  theme: {
    login: authTheme,
    materials: theme,
  },
  components: {
    interface: {
      toolbar: toolConfig,
    },
  },
};
