/*

  By Code.Rehab

*/

import { lazy } from 'react';
import { createRoute } from '../../application/helpers/route';

const PageUserAccount = lazy(() => import('../../view/pages/user/page-user-account'));
const PageUserSingle = lazy(() => import('../../view/pages/user/page-user-single'));
const PageLogout = lazy(() => import('../../../pages/page-logout'));
const PageUserIndex = lazy(() => import('../../v2/feature/user/view/pages'));

export const routesUser = {
  'user.account': createRoute({
    path: '/account',
    title: 'My account',
    component: PageUserAccount,
    requiredPermissions: ['users.user.get'],
    breadcrumbs: [
      {
        route: 'user.account',
        label: 'My account',
      },
    ],
  }),
  'user.index': createRoute({
    path: '/manage/user/index',
    title: 'User management',
    component: PageUserIndex,
    requiredPermissions: ['users.user.delete'],
    breadcrumbs: [
      {
        route: 'app.administration',
        label: 'Manage',
      },
      {
        route: 'user.index',
        label: 'Users',
      },
    ],
  }),
  'user.create': createRoute({
    path: '/manage/user/create',
    title: '404 - Resource not found',
    component: PageUserSingle,
    requiredPermissions: ['users.user.create'],
    breadcrumbs: [
      {
        route: 'app.administration',
        label: 'Manage',
      },
      {
        route: 'user.index',
        label: 'Users',
      },
      {
        route: 'user.create',
        label: 'Create',
      },
    ],
  }),
  'user.edit': createRoute({
    path: '/manage/user/:userid',
    title: '404 - Resource not found',
    component: PageUserSingle,
    requiredPermissions: ['users.user.update'],
    breadcrumbs: [
      {
        route: 'app.administration',
        label: 'Manage',
      },
      {
        route: 'user.index',
        label: 'Users',
      },
      {
        route: 'user.edit',
        label: 'Edit',
      },
    ],
  }),
  'user.logout': createRoute({
    path: '/logout',
    title: 'Logout',
    component: PageLogout,
    public: true,
    requiredPermissions: ['users.user.update'],
    breadcrumbs: [
      {
        route: 'user.logout',
        label: 'Logout',
      },
    ],
  }),
};
export default routesUser;
