import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField, Button, CircularProgress } from '@mui/material';
import { useState, useCallback } from 'react';
import { useAuth } from '../auth-provider';

export const ForceResetPasswordForm = () => {
  const { loading, cognitoUser, completeNewPasswordChallenge } = useAuth();
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      completeNewPasswordChallenge(newPassword);
    },
    [completeNewPasswordChallenge, newPassword]
  );

  return (
    <form onSubmit={onSubmit} style={{ display: 'flex', flexFlow: 'column' }}>
      <TextField
        label="Username"
        id="username"
        name="username"
        value={(cognitoUser as any)?.challengeParam?.userAttributes?.email}
        type="text"
        placeholder="E-mail address"
        disabled
        margin="dense"
      />

      <TextField
        label="Password"
        id="password"
        name="password"
        type="password"
        value={password}
        onChange={(e: any) => setPassword(e.target.value)}
        placeholder="E-mail address"
        disabled={loading}
        margin="dense"
      />

      <TextField
        label="New password"
        id="new-password"
        name="new-password"
        type="password"
        value={newPassword}
        onChange={(e: any) => setNewPassword(e.target.value)}
        placeholder="E-mail address"
        disabled={loading}
        margin="dense"
      />

      <Button
        type="submit"
        disabled={loading}
        style={{ marginTop: 24 }}
        endIcon={loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faArrowRight} />}
      >
        Update password
      </Button>
    </form>
  );
};
