/*

  By Code.Rehab

*/

import { lazy } from 'react';
import { createRoute } from '../../application/helpers/route';
import Error404 from '../../view/pages/errors/error-404'; // DO NOT LAZY LOAD THIS PAGE, WILL make it stop working

// lazy loading components
const PageDashboard = lazy(() => import('../../../pages/page-dashboard'));
const PageAssistant = lazy(() => import('../../view/pages/assistant/page-assistant'));
const PageUserIndex = lazy(() => import('../../v2/feature/user/view/pages'));
const ThemeDemoPage = lazy(() => import('../../../theme/demopage/demopage'));

export const routesDefault = {
  homepage: createRoute({
    path: '/',
    title: 'Xpert',
    component: PageDashboard,
  }),
  'app.homepage': createRoute({
    path: '/home',
    title: 'Xpert Home',
    component: PageDashboard,
    breadcrumbs: [
      {
        route: 'app.homepage',
        label: 'Home',
      },
    ],
  }),
  'app.xpert-sign-in': createRoute({
    path: '/xpert-aws-login',
    title: 'Xpert Home',
    component: PageDashboard,
    breadcrumbs: [
      {
        route: 'app.xpert-sign-in',
        label: 'Xpert Sign In',
      },
    ],
  }),
  'app.administration': createRoute({
    path: '/administration',
    title: 'Manage Xpert',
    component: PageUserIndex,
    breadcrumbs: [
      {
        route: 'app.administration',
        label: 'Manage',
      },
      {
        route: 'app.administration',
        label: 'Users',
      },
    ],
  }),
  'app.assistant': createRoute({
    path: '/assistant',
    title: 'Xpert assistant',
    component: PageAssistant,
    breadcrumbs: [
      {
        route: 'app.assistant',
        label: 'Assistant',
      },
    ],
  }),

  'theme.demo': createRoute({
    path: '/theme/pentair/*',
    title: 'Pentair Theme',
    component: ThemeDemoPage,
    breadcrumbs: [
      {
        route: 'app.assistant',
        label: 'Assistant',
      },
    ],
  }),
  'error.404': createRoute({
    path: '*',
    title: '404 - Resource not found',
    component: Error404,
    public: true,
  }),
};
export default routesDefault;
