/*

  By Code.Rehab

*/

// import React from 'react';
import { lazy } from 'react';
import { createRoute } from '../../application/helpers/route';

// lazy loading components
const DocumentsIndex = lazy( () => import("../../../feature/documents/pages/documents-index") );

// document routes
export const routesDocuments = {

  // One to rule them all, including subroutes :P
  'documents': createRoute({
    path: '/documentation/:view?/:catName?',
    pathNotExact: true,
    title: 'Xpert Documentation',
    component: DocumentsIndex,
    requiredPermissions: ['files.document.list', 'files.document.categories'],
    breadcrumbs: [
      {
        route: 'documents',
        paramsToRemoveFromRoute: ['view', 'catName'],
        label: 'Xpert Documentation',
      },
      {
        route: 'documents',
        paramsToRemoveFromRoute: ['view', 'catName'],
        label: ({ match }) => (match.params.view || "").replaceAll("-", " "),
      },
      {
        route: 'documents',
        paramsToRemoveFromRoute: [],
        label: ({ match }) => (match.params.catName || "").replaceAll("-", " "),
      },
    ],
  }),
};
export default routesDocuments;
