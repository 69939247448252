import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import { SolutionFragment } from "../graphql/fragment";
import { SolutionRecord } from "../types/record";

interface QuerySolutionArgs {
  id: string;
}

const buildQuery = (fragment: string = SolutionFragment) => gql`
  query Solution($id: ID!) {
    Solution(id: $id) {
      ${fragment}
    }
  }
`;

export const fetchSolution = async (
  options: RunQueryOptions<QuerySolutionArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<QuerySolutionArgs, { Solution: SolutionRecord }>({
    query,
    variables: options.variables,
  });
};
