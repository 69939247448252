import { stockConcentrationTableCEB } from '../../../solution/helpers/stock-concentration';
import { Outputs } from '../../../solution/types/record';
import { getCalculationProperty, setCalculationValues } from '../../helpers/calculation-values';
import { CalculationValues } from './projection-values';

export const applyAdvicedOutputs = (
  advicedOutputs: Outputs,
  inputs: CalculationValues<'id'>,
  outputs: CalculationValues<'id'>,
  inputsBasedOnCalcField: CalculationValues<'calculation_field'>,
  outputsBasedOnCalcfield: CalculationValues<'calculation_field'>
) => {
  //Parse Outputs
  for (const [key, value] of Object.entries(advicedOutputs)) {
    // Find index of the projectionValue we want to change.

    const inputCalcVal = getCalculationProperty(inputsBasedOnCalcField, key as any);
    const outputCalcVal = getCalculationProperty(outputsBasedOnCalcfield, key as any);

    // Update value in our projectionValues array
    if (inputCalcVal) {
      setCalculationValues(inputs, inputCalcVal.id, [String(value)]);
    }

    // Update value in our projectionValues array
    if (outputCalcVal && !key.includes('ucp_passthrough_wtpct_chemical_')) {
      let parsedVal = value;
      if (
        outputCalcVal &&
        outputCalcVal.id &&
        outputCalcVal.id.includes('ucp_chemical_agent_') &&
        outputCalcVal.values[0] !== 'null'
      ) {
        const numbr = outputCalcVal.id.substr(-1);
        const keyToUpdate = ('ucp_passthrough_wtpct_chemical_' + numbr) as any;
        const keyVal = outputs[keyToUpdate];

        if (keyVal && keyVal.id) {
          setCalculationValues(outputs, keyToUpdate, [stockConcentrationTableCEB[String(value)]]);
        }
      }

      setCalculationValues(outputs, outputCalcVal.id, [parsedVal]);
    }
  }

  return { inputs: { ...inputs }, outputs: { ...outputs } };
};
