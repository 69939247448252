/*

  By Code.Rehab

*/

import gql from "graphql-tag";

export const GetVersion = gql`
  query GetVersion {
    getXpertVersion {
      lastModified
      modifiedBy
      version
    }
  }
`;
