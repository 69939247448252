import gql from 'graphql-tag';
import { useCallback } from 'react';
import { finalizeProjection } from '../../../../../v2/feature/projection/business/projection/finalize-projection';
import { SolutionRecord } from '../../../../../v2/feature/solution/types/record';
import { runQuery, RunQueryOptions } from '../../../../network/run-query';
import { ProjectRecord } from '../../../project/project';
import { ProjectionFragment } from '../../graphql/fragments';
import { ProjectionRecord } from '../../projection';
import { makeProjectionInput } from './make-input';

export interface UserSummary {
  id: string;
  attributes: {
    given_name: string;
    family_name: string;
  };
}

export interface InputProjection {
  id?: string;
  name?: string;
  project?: Partial<ProjectRecord>;
  inputs?: Array<any>;
  outputs?: Array<any>;
  advisedInputs?: Array<any>;
  version?: string;
  status?: string;
  type?: string;
  code?: string;
  country?: string;
  company?: string;
  pdf?: string;
  account?: string;
  opportunity?: string;
  case?: string;
  units?: string;
  owner?: UserSummary;
  approver?: UserSummary;
  solution?: Partial<SolutionRecord>;
  solutionId?: string;
  note?: string;
  calcState?: string;
  description?: string;
  guidelines?: string[];
  awaitingReview?: boolean;
  stockChemicals?: {
    id: string;
    name: string;
    wtValue: string;
    glValue: string;
  }[];
}

interface MutationCreateProjectionArgs {
  input: InputProjection;
}

const buildQuery = (fragment: string = ProjectionFragment) => gql`
  mutation CreateProjection($input: inputNewProjection!) {
    createProjection(input: $input) {
      ${fragment}
    }
  }
`;

export const createProjection = async (options: RunQueryOptions<MutationCreateProjectionArgs>) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<MutationCreateProjectionArgs, { createProjection: ProjectionRecord }>({
    query,
    variables: options.variables,
  });
};

export const useCreateProjection = (
  record: Partial<ProjectionRecord>,
  handleResponse: any,
  fragment: string = ProjectionFragment
) => {
  return useCallback(
    async (setStatus: (msg: string) => void = () => {}) => {
      const recordf = await finalizeProjection(record, setStatus);
      const input: any = await makeProjectionInput(recordf);
      const res = await createProjection({
        variables: { input },
        query: { fragment },
      });

      setStatus('All done, redirecting you to the projection');

      handleResponse(res.data && res.data.createProjection, res.errors);

      return res;
    },
    [record, handleResponse, fragment]
  );
};
