import { BoxProps } from '@mui/material';
import { PropsWithChildren } from 'react';

// const LoadingIconSvg: React.FC<{ scale?: number; text?: string }> = ({ scale, text, ...props }) => {
//   return (
//     // <Fade in={true} timeout={1000}>
//     <div {...props}>
//       <div
//         style={{
//           transform: 'scale(' + (scale || 1) + ')',
//           marginBottom: 25 * (scale || 1),
//           display: 'flex',
//           justifyContent: 'center',
//         }}
//       >
//         <svg
//           width="80px"
//           height="80px"
//           viewBox="0 0 250 250"
//           version="1.1"
//           xmlns="https://www.w3.org/2000/svg"
//           xmlnsXlink="https://www.w3.org/1999/xlink"
//         >
//           <title>Artboard</title>
//           <desc>Created with Sketch.</desc>
//           <defs />
//           <g id="Artboard" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
//             <polygon
//               id="triangle_5"
//               fill="#0C3471"
//               points="88 67.74453 108.18479 47.55974 108.18479 87.925 128.36527 67.74453 128.36527 108.1098 108.18479 87.925 108.18479 128.29027 88 108.1098 88 166.69019 166.76951 87.925 88 9"
//             />
//             <polygon id="triangle_2" fill="#64A70B" points="88 68 88 107.841797 107.746094 87.9208984" />
//             <polygon id="triangle_4" fill="#64A70B" points="68 48 68 87.8417969 87.7460938 67.9208984" />
//             <polygon id="triangle_3" fill="#64A70B" points="68 88 68 127.841797 87.7460938 107.920898" />
//             <polygon id="triangle_1" fill="#64A70B" points="48 68 48 107.841797 67.7460938 87.9208984" />
//           </g>
//         </svg>
//       </div>
//       {text && (
//         <Typography variant="caption" fontSize={`${scale || 1}rem`} paddingLeft={scale || 1} color={'yellow'}>
//           {text}
//         </Typography>
//       )}
//     </div>
//     // </Fade>
//   );
// };

// export const LoadingIcon = styled(LoadingIconSvg)(({ theme }) => ({
//   width: 'fit-content',
//   alignSelf: 'center',
//   justifySelf: 'center',
//   margin: 'auto',

//   '@keyframes loading': {
//     '0%': {
//       opacity: 0,
//       transform: 'translateX(-50px)',
//     },

//     '10%': {
//       opacity: 0,
//       transform: 'translateX(-50px)',
//     },
//     '40%': {
//       opacity: 1,
//       transform: 'translateX(0px)',
//       animationTimingFunction: 'cubic-bezier(0.36, 0, 0.66, -0.56)',
//     },

//     '80%': {
//       opacity: 0,
//       transform: 'translateX(50px)',
//       animationTimingFunction: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
//     },

//     '100%': {
//       opacity: 0,
//       transform: 'translateX(50px)',
//       animationTimingFunction: 'cubic-bezier(0.34, 1.56, 0.64, 1)',
//     },
//   },

//   '& p': {
//     margin: '-40px 0 0 0',
//     fontWeight: '500',
//     color: theme.palette.blue.main,
//     textAlign: 'center',
//   },

//   '& svg': {
//     margin: '0 0 0 25px',
//   },
//   '& polygon': {
//     position: 'relative',
//     animation: 'loading 2s infinite',
//     opacity: 0,
//     transform: 'translateX(-50px)',

//     '&:nth-of-type(1)': {
//       animationDelay: '0s',
//     },
//     '&:nth-of-type(2)': {
//       animationDelay: '0.1s',
//     },
//     '&:nth-of-type(3)': {
//       animationDelay: '0.2s',
//     },
//     '&:nth-of-type(4)': {
//       animationDelay: '0.3s',
//     },
//     '&:nth-of-type(5)': {
//       animationDelay: '0.4s',
//     },
//   },
// }));

// export type LoadingIconProps = React.ComponentProps<typeof LoadingIcon>;

import { LoadingContainer, LoadingIcon, LoadingIconProps } from '@pentair/react-mui-5';

export { LoadingContainer, LoadingIcon };

// /**
//  * Container for the loading icon
//  * It will be center its contents vertically and horizontally
//  */
// export const LoadingContainer = styled(Box)({
//   position: 'relative',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
// });

/**
 * Component to show a loading icon with a text below it
 * It will show the children if loading is false
 * The loading icon will be centered in the container.
 */
export const Loading: React.FC<PropsWithChildren<BoxProps & LoadingIconProps> & { loading: boolean }> = ({
  children,
  scale,
  text,
  loading,
  ...containerProps
}) => {
  if (!loading) return <>{children}</>;

  return (
    <LoadingContainer paddingTop={2} paddingBottom={5} {...containerProps}>
      {loading && <LoadingIcon scale={scale} text={text} />}
    </LoadingContainer>
  );
};

export default Loading;
