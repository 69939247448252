export type Param = {
  water_feed: WaterFeed;
  cod?: number;
  toc?: number;
  color?: number;
} & (
  | {
      turbidity: number;
      tss?: number;
    }
  | {
      turbidity?: undefined;
      tss: number;
    }
);

export type WaterFeed =
  | 'City water'
  | 'Well water'
  | 'Surface water'
  | 'Sea water'
  | 'Treated effluent'
  | 'Sewage'
  | 'Industrial waste'
  | 'Emulsified oil';

type Ratio = { tss: number; toc: number; cod: number; color: number };
type LoadFactor = { toc: number; cod: number; color: number };

const dt_values: {
  [f in WaterFeed]: { ratio: Ratio; load_factor: LoadFactor };
} = {
  'City water': {
    ratio: { tss: 2, toc: 0.5, cod: 0.5, color: 1 },
    load_factor: { toc: 2, cod: 10, color: 10 },
  },
  'Well water': {
    ratio: { tss: 2, toc: 0.5, cod: 0.5, color: 1 },
    load_factor: { toc: 2, cod: 10, color: 10 },
  },
  'Surface water': {
    ratio: { tss: 2, toc: 2, cod: 2, color: 10 },
    load_factor: { toc: 2, cod: 10, color: 10 },
  },
  'Sea water': {
    ratio: { tss: 2, toc: 1, cod: 1, color: 5 },
    load_factor: { toc: 2, cod: 10, color: 10 },
  },
  'Treated effluent': {
    ratio: { tss: 2.5, toc: 2, cod: 5, color: 25 },
    load_factor: { toc: 2, cod: 10, color: 10 },
  },
  Sewage: {
    ratio: { tss: 2.5, toc: 2, cod: 5, color: 25 },
    load_factor: { toc: 2, cod: 10, color: 10 },
  },
  'Industrial waste': {
    ratio: { tss: 2.5, toc: 2, cod: 5, color: 25 },
    load_factor: { toc: 2, cod: 10, color: 10 },
  },
  'Emulsified oil': {
    ratio: { tss: 2.5, toc: 2, cod: 5, color: 25 },
    load_factor: { toc: 2, cod: 10, color: 10 },
  },
};

export function dt(p: Param): number {
  let values = dt_values[p.water_feed];
  if (typeof values === 'undefined') {
    console.warn(`No DT VALUES found for water feed type ${p.water_feed}`);
    values = {
      ratio: { tss: 2.5, toc: 2, cod: 5, color: 25 },
      load_factor: { toc: 2, cod: 10, color: 10 },
    };
  }

  // max solid load is based on provided turbidity or TSS
  let solids_load: number[] = [];
  if (p.turbidity !== undefined) {
    solids_load.push(p.turbidity);
  }
  if (p.tss !== undefined) {
    solids_load.push(p.tss / values.ratio.tss);
  }
  if (solids_load.length === 0) {
    throw new Error('Either turbidity or TSS is needed');
  }
  const max_solids_load = Math.max(...solids_load);

  // max organic load is the sum of the weighted value for each of the items
  const max_organic_load = (['cod', 'color', 'toc'] as ['cod', 'color', 'toc'])
    .map((k) => {
      let value = p[k] || 0;
      return Math.max(0, (value - max_solids_load * values.ratio[k]) / values.load_factor[k]);
    })
    .reduce((a, b) => Math.max(a, b));

  const dt = max_solids_load + max_organic_load;

  return dt;
}
export default dt;
