import { CalculationValueOptions } from "../technology/calculation-values";
import {
  GraphQLModel,
  BaseGraphQLModel,
} from "../../base/graphql/graphql-model";
import { computed } from "mobx";
import { removeNullValues } from "../../helpers/serialize";
import { ProjectRecord } from "../project/project";
import { v4 as uuid } from "uuid";
import {
  CalculationValue,
  SolutionRecord,
} from "../../../v2/feature/solution/types/record";

interface UserSummary {
  id: string;
  attributes: {
    given_name: string;
    family_name: string;
    email?: string;
    account_name?: string;
  };
}

export interface ProjectionRecord {
  id: string;
  name: string;
  description: string;
  guidelines: string[];
  project: Partial<ProjectRecord> | null;
  advisedInputs: CalculationValue[];
  inputs: CalculationValue[];
  outputs?: CalculationValue[];
  version: string;
  status: string;
  pdf: string;
  units: string;
  createdAt: string;
  updatedAt: string;
  createdBy?: UserSummary;
  approver?: UserSummary;
  updatedBy?: UserSummary;
  solution: Partial<SolutionRecord> | null;
  solutionId: string;
  versions: ProjectionVersions[];
  note?: string;
  designedWith: "direct" | "compared" | "assistant";
  calcState: string;
  awaitingReview?: boolean;
  stockChemicals?: {
    id: string;
    name: string;
    wtValue: string;
    glValue: string;
  }[];
}

export interface ProjectionVersions {
  isLatest: boolean;
  lastModified: string;
  versionId: string | undefined;
}

export interface ProjectionRevision {
  id: string | undefined;
  isLatest: boolean;
  lastModified: string;
  designedBy: Partial<UserSummary>;
  approvedBy: Partial<UserSummary>;
  note: string;
}

export interface ProjectionListBySolution {
  id: string;
  name: string;
  items: Partial<ProjectionRecord>[];
}

export interface Projection extends GraphQLModel<ProjectionRecord> {
  categorizedValues: Record<string, CalculationValue[]>;
  updateValue(id: string, valueIndex: number, value: string): void;
  value(id: CalculationValueOptions): any;
  valueMap: any;
}

export class DefaultProjection
  extends BaseGraphQLModel<ProjectionRecord>
  implements Projection {
  public typename: string = "Projection";

  public defaultValues: ProjectionRecord = {
    id: uuid(),
    name: "Some projection name",
    project: null,
    description: "",
    guidelines: [""],
    inputs: [],
    advisedInputs: [],
    version: "0.1",
    status: "Concept",
    pdf: "",
    units: "",
    solution: null,
    versions: [],
    note: "Adviced settings",
    solutionId: "",
    createdAt: "",
    updatedAt: "",
    designedWith: "direct",
    calcState: "",
    stockChemicals: []
  };

  public value = (id: CalculationValueOptions) => {
    return {
      ...this.valueMap[id],
    };
  };

  public get code() {
    return this.id.substr(0, 8).toUpperCase();
  }

  public updateValue = (id: string, valueIndex: number, value: string) => {
    const calcVal = this.record.inputs.find((val) => val.id === id);

    if (calcVal) {
      const values = [...(calcVal.values || [])];
      values[valueIndex] = value;

      calcVal.values = values;
      this.valueMap[id].values = values;
    }
  };

  @computed public get categorizedValues() {
    const values = this.record.inputs || [];
    return values.reduce(
      (
        result: Record<string, [CalculationValue]>,
        valueData: CalculationValue
      ) => {
        if (valueData.category) {
          result[valueData.category] = result[valueData.category] || [];
          result[valueData.category].push(valueData);
        }
        return result;
      },
      {}
    );
  }

  @computed public get valueMap() {
    const values = this.record.inputs || [];
    return values.reduce((result, valueData: CalculationValue) => {
      if (valueData.id) {
        result[valueData.id] = valueData;
      }
      return result;
    }, {} as Record<CalculationValueOptions, CalculationValue>);
  }

  public serialize = () => {
    const serialized: Partial<ProjectionRecord> = { ...this.record };
    delete serialized.createdBy;
    delete serialized.versions;
    delete serialized.solutionId;
    delete serialized.createdAt;
    delete serialized.updatedAt;
    delete (serialized as any).water_source;

    (serialized as any).solution =
      (this.record.solution && this.record.solution.id) || "";

    return removeNullValues(serialized);
  };
}
