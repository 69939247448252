import { AppModule } from '../helpers/app-module';
import { Interactors } from '../app-types';
import DefaultUserInteractor from '../resources/user/user-interactor';
import { DefaultInterfaceInteractor } from './interactor/interface-interactor';
import DefaultTechnologyInteractor from '../resources/technology/technology-interactor';
import { DefaultMessageInteractor } from './interactor/message-interactor';

export interface InteractorModule extends Interactors {}

export class DefaultInteractorModule extends AppModule<Interactors> implements InteractorModule {
  public get user() {
    return this.loadModule('user', DefaultUserInteractor);
  }

  public get technology() {
    return this.loadModule('technology', DefaultTechnologyInteractor);
  }

  public get interface() {
    return this.loadModule('interface', DefaultInterfaceInteractor, this.user);
  }

  public get message() {
    return this.loadModule('message', DefaultMessageInteractor);
  }
}
