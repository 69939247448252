import gql from 'graphql-tag';
import { useCallback } from 'react';
import { runQuery, RunQueryOptions } from '../../../../network/run-query';
import { ProjectionCalculationUpdateFragment } from '../../graphql/fragments';

interface ProjectionCalculationUpdateResult {
  message: string;
  state: string;
  status: ProjectionCalculationStatus;
  options: string;
}

enum ProjectionCalculationStatus {
  OK = 'ok',
  ERROR = 'error',
}

interface QueryProjectionCalculationUpdateArgs {
  inputs: string;
  state: string;
}

const buildQuery = (fragment: string = ProjectionCalculationUpdateFragment) => gql`
  mutation ProjectionCalculationUpdate($inputs: AWSJSON!, $state: AWSJSON!) {
    projectionCalculationUpdate(inputs: $inputs, state: $state) {
      ${fragment}
    }
  }
`;

export const projectionCalculationUpdate = async (
  options: RunQueryOptions<QueryProjectionCalculationUpdateArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;

  return await runQuery<
    QueryProjectionCalculationUpdateArgs,
    {
      projectionCalculationUpdate: ProjectionCalculationUpdateResult;
    }
  >({ query, variables: options.variables });
};

export const useProjectionCalculate = () => {
  return useCallback(async (inputs: string, state: string) => {
    const res = await projectionCalculationUpdate({
      variables: { inputs, state },
      query: { fragment: ProjectionCalculationUpdateFragment },
    });

    return res.data && res.data.projectionCalculationUpdate;
  }, []);
};
