import { AppModule } from "../helpers/app-module";
import { NetworkModule } from "../network/network";
import { DefaultUserProvider } from "../resources/user/user-provider";
import { DefaultUserProfileProvider } from "../resources/user-profile/user-profile-provider";
import { Providers, ApplicationConfig } from "../app-types";
import { DefaultRouteProvider } from "./provider/routes";
import { DefaultProfileProvider } from "../resources/profile/profile-provider";
import DefaultTechnologyProvider from "../resources/technology/technology-provider";
import { DefaultPermissionProvider } from "../resources/permission/permission-provider";
import { DefaultProjectionProvider } from "../resources/projection/projection-provider";
import { DefaultAssistantProvider } from "../resources/assistant/assistant-provider";
import { DefaultProjectProvider } from "../resources/project/project-provider";
import { DefaultUserLegalProvider } from "../resources/user-legal/user-legal-provider";

export interface ProviderModule extends Providers {}

export class DefaultProviderModule extends AppModule<Providers>
  implements ProviderModule {
  constructor(
    protected network: NetworkModule,
    protected config: ApplicationConfig
  ) {
    super();
  }

  public get user() {
    return this.loadModule(
      "user",
      DefaultUserProvider,
      this.network.appsync,
      this.userProfile
    );
  }

  public get assistant() {
    return this.loadModule(
      "assistant",
      DefaultAssistantProvider,
      this.network.appsync
    );
  }

  public get userProfile() {
    return this.loadModule(
      "userProfile",
      DefaultUserProfileProvider,
      this.network.appsync
    );
  }

  public get profile() {
    return this.loadModule(
      "profile",
      DefaultProfileProvider,
      this.network.appsync
    );
  }

  public get technology() {
    return this.loadModule(
      "technology",
      DefaultTechnologyProvider,
      this.network.appsync
    );
  }

  public get permission() {
    return this.loadModule(
      "permission",
      DefaultPermissionProvider,
      this.network.appsync
    );
  }

  public get projection() {
    return this.loadModule(
      "projection",
      DefaultProjectionProvider,
      this.network.appsync
    );
  }

  public get project() {
    return this.loadModule(
      "project",
      DefaultProjectProvider,
      this.network.appsync
    );
  }

  public get userLegal() {
    return this.loadModule(
      "userLegal",
      DefaultUserLegalProvider,
      this.network.appsync
    );
  }

  public get routes() {
    return this.loadModule("routes", DefaultRouteProvider, this.config.routes);
  }
}
