import * as React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';

// import { useRoute } from "../../../hooks/use-route";
import { TopbarLogo } from './topbar-logo';
import { Breadcrumbs } from './breadcrumbs';
import { Box } from '@mui/material';

interface OwnProps {
  classes?: Record<'root', string>;
}

const stage = () => {
  const stageKey = window.location.hostname.split('.pentairxpert.com')[0];

  if (stageKey.toLowerCase() === 'dev') {
    return 'XPERT DEV';
  }

  if (stageKey.toLowerCase() === 'qa') {
    return 'XPERT QA';
  }

  if (stageKey.toLowerCase() === 'uat') {
    return 'XPERT UAT';
  }

  // if (window.location.hostname === 'pentairxpert.com') {
  //   return null;
  // }

  if (window.location.hostname.includes('localhost')) {
    return 'LOCAL DEVELOPMENT';
  }

  return null;
};

export const TopBar: React.FC<OwnProps> = (props) => {
  return (
    <Box flex={1}>
      <AppBar position="static" color="inherit">
        <Toolbar variant="dense">
          <TopbarLogo appname="X-Flow" />
          <Typography variant="h4" color="green.main">
            {stage()}
          </Typography>
          <Breadcrumbs />

          <Typography color={'secondary'} fontWeight={600} marginLeft={'auto'}>
            Smart, Sustainable Solutions. FOR LIFE.
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
