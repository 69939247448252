import { Presenter } from "../../../hooks/use-presenter";
import { UserInteractor } from "../../../application/resources/user/user-interactor";
import {
  DefaultNotification,
  NotificationRecord,
  Notification,
} from "../../../application/resources/notification/notification";

export class DrawerNotificationsPresenter implements Presenter {
  constructor(private _userInteractor: UserInteractor) {
    //
  }

  protected get userInteractor() {
    return this._userInteractor;
  }

  public get notifications(): Notification[] {
    return [
      new DefaultNotification({} as NotificationRecord),
      new DefaultNotification({} as NotificationRecord),
      new DefaultNotification({} as NotificationRecord),
    ];
  }

  mount = () => {};
  unmount = () => {};
}
