import { Box, styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useAuth } from './auth-provider';

const AuthBackground = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100vw',
  height: '100vh',
  bgcolor: 'primary.main',
  padding: '5vw',
  backgroundImage: 'url(/images/landingpage.jpg)',
  // backgroundRepeat: 'no-repeat',
  // backgroundPositionX: 'center',
  // backgroundPositionY: 'center',
  // backgroundAttachment: 'fixed',
  backgroundSize: 'cover',
  backgroundPosition: 'bottom',
}));

export const AuthTemplate: React.FC<PropsWithChildren<{ title?: string; containerSize?: number }>> = ({ children }) => {
  return <AuthBackground>{children}</AuthBackground>;
};

export const AuthError = () => {
  const { error } = useAuth();

  return (
    (error && (
      <>
        {/* <Alert severity="error" sx={{ marginTop: 2 }}> */}
        {error.toString()}
        {/* </Alert> */}
      </>
    )) ||
    null
  );
};
