import { Card, Divider, Typography } from '@mui/material';
import { AuthError, AuthTemplate } from './auth-template';
import { ForceResetPasswordForm } from './forms/force-reset-password';

export const AuthStatePasswordResetRequired = () => {
  return (
    <AuthTemplate title="Password Reset Required">
      <Card style={{ width: '100%', maxWidth: 400, padding: 24 }}>
        <Typography variant="h3" paragraph>
          Password Reset Required
        </Typography>
        <Divider style={{ margin: '24px 0 12px 0' }} />
        <ForceResetPasswordForm />

        <AuthError />
      </Card>
    </AuthTemplate>
  );
};
