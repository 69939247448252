import { GraphQLProvider, BaseGraphQLProvider } from '../../base/graphql/graphql-provider';
import { ProjectionRecord, Projection, DefaultProjection, ProjectionRevision } from './projection';
// import { GraphQLAPI } from "../../base/types";
//import { graphqlOperation } from "aws-amplify";
import { graphqlOperation } from '@aws-amplify/api';
import * as Query from './graphql/queries';
import * as Mutation from './graphql/mutations';

export interface ProjectionProvider extends GraphQLProvider<ProjectionRecord, Projection> {
  getRevisions(id: string): Promise<[ProjectionRevision]>;
  listByProject(projectId?: string): Promise<Projection[]>;
  clone(id: string, name: string): Promise<Projection>;
}

export interface ProjectionValidations {
  country?: string;
  company?: string;
}

export interface ValidationError {
  property: string;
  errorKey: string[];
}

export class DefaultProjectionProvider
  extends BaseGraphQLProvider<ProjectionRecord, Projection>
  implements ProjectionProvider
{
  // constructor(_api: GraphQLAPI) {
  //   super(_api);
  // }

  public model = DefaultProjection;

  public createOperation = (projection: Projection) => {
    return graphqlOperation(Mutation.Create, {
      input: { ...projection.serialize() },
    });
  };

  public fetchOperation = (projection: Projection, version?: string) => {
    if (version === 'latest') {
      version = undefined;
    }

    return graphqlOperation(Query.Projection, {
      id: projection.id,
      version,
    });
  };

  public updateOperation = (projection: Projection) => {
    return graphqlOperation(Mutation.Update, { input: projection.serialize() });
  };

  public deleteOperation = (projection: Projection) => {
    return graphqlOperation(Mutation.Delete, { id: projection.id });
  };

  public listOperation = (projectId?: string) => {
    return graphqlOperation(Query.ProjectionCollection, { projectId });
  };

  public listByProject = async (projectId?: string) => {
    const operation = graphqlOperation(Query.ProjectionCollectionByProject, {
      projectId,
    });
    const result: Projection[] = await this.query(null, operation);

    const projections = result.map((projection) => this.createInstance(projection));

    return projections;
  };

  public getRevisions = async (id: string) => {
    const operation = graphqlOperation(Query.ProjectionRevisions, { id: id });
    const result = await this.query(null, operation);

    return result;
  };

  public getVersions = async (id: string) => {
    const operation = graphqlOperation(Query.ProjectionVersions, { id: id });
    const result = await this.query(null, operation);
    return result.versions;
  };

  public clone = async (id: string, name: string) => {
    const operation = graphqlOperation(Mutation.Clone, { id: id, name: name });
    const result = await this.query(null, operation);
    return result;
  };
}
