import * as React from 'react';
import { Typography, Grid, Button, StyledComponentProps, CardContent, CardProps, CardActions } from '@mui/material';

import { Page } from '../__old__/view/components/page/page-wrapper';
import { PageContent } from '../__old__/view/components/page/page-content';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faLockAlt } from '@fortawesome/pro-light-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Link } from '@mui/material';
import { Card } from '@mui/material';
import { Box } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useAuth } from '../feature/auth/auth-provider';
import { PageLoading } from '../__old__/v2/common/pages/page-loading';
import { useLocation } from 'react-router';

import { AuthStateSignedOut } from '../feature/auth/authstate-signed-out';
import { Trans, useTranslation } from 'react-i18next';

type OwnProps = StyledComponentProps<'root' | 'headline1' | 'headline2' | 'headline3' | 'logo'>;

export const PageHome: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { awaitingSignedInUser } = useAuth();

  if (awaitingSignedInUser) {
    return (
      <Box minWidth="100vw" minHeight="100vh" display="flex">
        <PageLoading />
      </Box>
    );
  }

  if (location.pathname === '/xpert-aws-login') {
    return <AuthStateSignedOut />;
  }

  return (
    <Box minWidth="100vw" height="100vh" display="flex">
      <Page
        variant="centered"
        backgroundImage="/images/landingpage.jpg"
        backgroundPosition="bottom 15% center"
        backgroundSize="cover"
        color="#fff"
      >
        <PageContent variant="centered" color="transparent">
          <Grid
            container
            spacing={4}
            justifyContent="center"
            sx={{ maxWidth: '1200px', width: '85vw', color: '#fff', paddingY: [10, 10, 0] }}
          >
            <Grid item xs={12} md={6} lg={7}>
              <img
                src="/images/logo-x-flow-white.svg"
                height={'68'}
                alt="X-Flow Logo"
                style={{ marginLeft: '-62px' }}
              />
              <Typography
                variant={'h1'}
                color="white.main"
                paragraph
                fontWeight={300}
                fontSize="2.5em"
                marginTop="-15px"
                textTransform={'uppercase'}
              >
                {t('xpert', { defaultValue: 'XPERT' })}
              </Typography>
              <Typography variant={'h3'} color="white.main" paragraph>
                {t('global:page.home.title', { defaultValue: 'Interactive Web App for membrane filtration solutions' })}
              </Typography>
              <Typography variant="body2" color="white.main" paragraph>
                {t('global:page.home.intro.one', {
                  defaultValue:
                    "Pentair X-Flow Xpert Web App features the world's first conversational interface in the membrane filtration industry. The App guides you through helping to select the ideal Pentair X-Flow membrane filtration solution and configuration for water and wastewater treatment applications by answering a few questions.",
                })}
              </Typography>
              <Typography variant="body2" color="white.main">
                {t('global:page.home.intro.two', {
                  defaultValue: `It enables you to design entire filtration systems, including all ancillary equipment and all
                operational requirements that mobilize decades of experience of Pentair X-Flow membrane filtration
                solutions. In addition, it has various unique new features.`,
                })}
              </Typography>
              <Button
                endIcon={<FontAwesomeIcon icon={faYoutube} />}
                sx={{
                  marginTop: 2,
                }}
                LinkComponent={Link}
                href={'https://www.youtube.com/watch?v=jxnSZaXZ_EE'}
                target="_blank"
              >
                {t('global:button.learnMore', { defaultValue: 'Learn more' })}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} lg={5}>
              <SignIn />
            </Grid>
          </Grid>
        </PageContent>
      </Page>
    </Box>
  );
};

export default PageHome;

const SignIn: React.FC<CardProps> = (props) => {
  const { t } = useTranslation();

  const { loading, federatedSignin, awaitingSignedInUser } = useAuth();

  return (
    <Card {...props}>
      <CardContent>
        <Typography variant={'h3'} paragraph>
          <FontAwesomeIcon icon={faLockAlt} style={{ marginRight: 12 }} />
          {t('global:page.home.signin.title', { defaultValue: 'Sign in' })}
        </Typography>
        <Typography variant="body2">
          {t('global:page.home.signin.subtitle', {
            defaultValue:
              'Sign in to the Pentair X-Flow Xpert Web App to start designing you membrane filtration systems.',
          })}
        </Typography>
      </CardContent>
      <CardActions>
        <Box>
          <Button
            color="primary"
            startIcon={
              loading || awaitingSignedInUser ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                <FontAwesomeIcon icon={faLockAlt} />
              )
            }
            onClick={() => federatedSignin('pentair-iam-sso')}
            disabled={loading || awaitingSignedInUser}
          >
            {t('global:button.employee', { defaultValue: 'Pentair employee Sign in' })}
          </Button>
          <br />
          <Button
            sx={{ marginTop: 1 }}
            startIcon={
              loading || awaitingSignedInUser ? (
                <CircularProgress size={22} color="inherit" />
              ) : (
                <FontAwesomeIcon icon={faLockAlt} />
              )
            }
            onClick={() => federatedSignin('pentair-salesforce-sso')}
            disabled={loading || awaitingSignedInUser}
          >
            {t('global:button.customer', { defaultValue: 'Pentair customer Sign in' })}
          </Button>
          <Typography variant="body2" style={{ marginTop: '12px' }}>
            <Trans i18nKey={'global:page.home.contact'}>
              For more details, contact your{' '}
              <Link
                style={{ textTransform: 'none', cursor: 'pointer' }}
                target="_blank"
                href="https://xflow.pentair.com/en/contact?utm_source=pentairxpert.com&utm_medium=landingpage"
              >
                Pentair Account Manager
              </Link>
            </Trans>
          </Typography>
        </Box>
      </CardActions>
    </Card>
  );
};
