export const ProjectionFragment = `
  id
  name
  note
  version
  calcState
  description
  guidelines
  awaitingReview
  versions {
    versionId
    lastModified
    designedBy {
      id
      attributes {
        given_name
        family_name
      }
    }
    approvedBy {
      id
      attributes {
        given_name
        family_name
      }
    }
    isLatest 
    note
  }
  project {
    id
    name
  }
  advisedInputs {
    id
    name
    values
    recommendedValues
    unit
    unitOptions
    valueOptions {
      id
      label
    }
    disabled
    error
    category
    decimals
    calculation_field
  }
  inputs {
    id
    name
    values
    recommendedValues
    unit
    unitOptions
    valueOptions {
      id
      label
    }
    disabled
    error
    category
    decimals
    calculation_field
  }
  outputs {
    id
    name
    values
    recommendedValues
    unit
    unitOptions
    valueOptions {
      id
      label
    }
    disabled
    error
    category
    decimals
    calculation_field
  }
  createdBy {
    id
    attributes {
      given_name
      family_name
      account_name
    }
  }
  updatedBy {
    id
    attributes {
      given_name
      family_name
    }
  }
  updatedAt
  approver {
    id
    attributes {
      given_name
      family_name
    }
  }
  solution {
    id
    name
    oneliner
    description
    image
    techImage
    order
    techImageMap {
      type
      icon
      title
      description
      location
      number
      info_label
      values
      dialog
      key
      rotation
      content
      height
      width
      arrow_position
      splitKeyValue
      centeredText
    }
  }
  stockChemicals {
    id
    name
    wtValue
    glValue
  }
`;

export const ProjectionIndexFragment = `
  id
  name
  awaitingReview
  solution {
    id
    name
    order
  }
  version
  createdBy {
    id
    attributes {
      given_name
      family_name
    }
  }
  approver {
    id
    attributes {
      given_name
      family_name
    }
  }
  status
  createdAt
  updatedAt
  updatedBy {
    id
    attributes {
      given_name
      family_name
    }
  }
  note
`;

export const ProjectionCalculationCreateFragment = `
  message
  state
  status
`;

export const ProjectionCalculationUpdateFragment = `
  message
  state
  status
  options
`;
