//import { graphqlOperation } from "aws-amplify";
import { graphqlOperation } from "@aws-amplify/api";
import {
  BaseGraphQLProvider,
  GraphQLProvider,
} from "../../base/graphql/graphql-provider";
import { DefaultUserLegal, UserLegal, UserLegalRecord } from "./user-legal";
import * as Query from "./graphql/queries";
import * as Mutation from "./graphql/mutations";

export interface UserLegalProvider
  extends GraphQLProvider<UserLegalRecord, UserLegal> {}

export class DefaultUserLegalProvider
  extends BaseGraphQLProvider<UserLegalRecord, UserLegal>
  implements UserLegalProvider {
  public model = DefaultUserLegal;

  protected fetchOperation = (userLegal: UserLegal) => {
    return graphqlOperation(Query.UserLegal, { id: userLegal.id });
  };

  protected createOperation = (userLegal: UserLegal) => {
    return graphqlOperation(Mutation.CreateUserLegal, {
      input: userLegal.serialize(),
    });
  };

  protected updateOperation = (userLegal: UserLegal) => {
    return graphqlOperation(Mutation.UpdateUserLegal, {
      input: userLegal.serialize(),
    });
  };

  protected deleteOperation = (userLegal: UserLegal) => {
    return graphqlOperation(Mutation.DeleteUserLegal, { id: userLegal.id });
  };
}
