import { ProjectFragment } from "./fragments";

export const Create = `
    mutation CreateProject($input:inputProject!) {
        createProject(input:$input) {
            ${ProjectFragment}
        }
    }
`;

export const Update = `
    mutation UpdateProject($input:inputProject!) {
        updateProject(input:$input) {
            ${ProjectFragment}
        }
    }
`;

export const Delete = `
    mutation DeleteProject($id:ID!) {
        deleteProject(id:$id) {
            id
        }
    }
`;
