import * as React from 'react';
import { useObserver } from 'mobx-react';
import { usePresenter } from '../../../../../hooks/use-presenter';
import { ProjectionInteractiveImagePresenter } from '../projection-interactive-image-presenter';
import { CalculationValue } from '../../../../../v2/feature/solution/types/record';

interface OwnProps {
  img: Array<string>;
  inputs: CalculationValue[];
}

export const AquaflexInteractiveImage: React.FC<OwnProps> = (props) => {
  const presenter = usePresenter(
    () => new ProjectionInteractiveImagePresenter(props)
  );

  return useObserver(() => {
    const { imageLoaded, images } = presenter;
    const data = props.inputs || [];

    const calculationValueById = (id: string) => {
      return data.find((item) => item.id === id);
    };

    const hideNumberLogic = {
      '1':
        calculationValueById('internal_recirculation')?.values[0] === 'false'
          ? true
          : false,
      '2':
        parseFloat(
          (calculationValueById('airflush_duration')?.values[0] as string) ||
            '0'
        ) < 1
          ? true
          : false,
      '3':
        calculationValueById('chm_coagulant')?.values[0] === '' ? true : false,
      '4': false,
      '5': false,
    };

    return (
      <React.Fragment>
        {images.map((image: string, index) => {
          if (index === 0) {
            return (
              <img
                style={{ maxWidth: '100%', height: 'auto', display: 'block' }}
                key={index}
                src={image}
                onLoad={imageLoaded}
                alt="techimage"
              />
            );
          }

          if (index !== 0 && !hideNumberLogic[index])
            return (
              <img
                key={index}
                src={image}
                style={{
                  position: 'absolute',
                  height: 'auto',
                  top: 0,
                  left: 0,
                  maxWidth: '100%',
                }}
                onLoad={imageLoaded}
                alt="techimage"
              />
            );

          //return <React.Fragment key={index}></React.Fragment>;
          return null;
        })}
      </React.Fragment>
    );
  });
};
