import gql from 'graphql-tag';
import { userFragment } from '../../projects/api/fragments';

export const imageMapV2Fragment = gql`
  fragment ImageMapV2Fragment on ImageMapV2 {
    arrow_position
    centeredText
    content
    description
    dialog
    height
    icon
    info_label
    key
    location
    number
    rotation
    splitKeyValue
    title
    type
    values
    width
  }
`;

export const calculationValueOptionsFragment = gql`
  fragment CalculationValueOptionsFragment on CalculationValueOptions {
    id
    label
  }
`;

export const calculationValueFragment = gql`
  ${calculationValueOptionsFragment}
  fragment CalculationValueFragment on CalculationValue {
    calculation_field
    category
    decimals
    disabled
    displayUnit
    error
    id
    name
    recommendedValues
    tooltip
    unit
    unitOptions
    valueOptions {
      ...CalculationValueOptionsFragment
    }
    values
  }
`;

export const solutionV2Fragment = gql`
  ${calculationValueFragment}
  ${imageMapV2Fragment}
  fragment SolutionV2Fragment on SolutionV2 {
    active
    comparableTechnologies
    description
    id
    image
    inputs {
      ...CalculationValueFragment
    }
    name
    oneliner
    order
    outputs {
      ...CalculationValueFragment
    }
    techImage
    techImageMap {
      ...ImageMapV2Fragment
    }
    versionId
  }
`;

// Discard the V1 indexSolution  very soon
export const indexSolutionV1Fragment = gql`
  fragment IndexSolutionV1Fragment on IndexSolution {
    active
    comparableTechnologies
    description
    id
    image
    name
    oneliner
    order
  }
`;

export const indexSolutionV2Fragment = gql`
  fragment IndexSolutionV2Fragment on IndexSolutionV2 {
    active
    comparableTechnologies
    description
    id
    image
    name
    oneliner
    order
    versionId
  }
`;

export const projectionsVersionV2Fragment = gql`
  ${userFragment}

  fragment ProjectionsVersionV2Fragment on ProjectionVersionV2 {
    approvedBy {
      ...UserFragment
    }
    designedBy {
      ...UserFragment
    }
    isLatest
    lastModified
    note
    versionId
  }
`;

export const projectionsFragment = gql`
  ${userFragment}
  ${projectionsVersionV2Fragment}
  ${solutionV2Fragment}

  fragment ProjectionsFragment on ProjectionV2 {
    advisedInputs {
      ...CalculationValueFragment
    }
    approver {
      ...UserFragment
    }
    awaitingReview
    calcState
    createdAt
    createdBy {
      ...UserFragment
    }
    description
    designedWith
    guidelines
    id
    inputs {
      ...CalculationValueFragment
    }
    name
    note
    outputs {
      ...CalculationValueFragment
    }
    projectId
    solution {
      ...SolutionV2Fragment
    }
    status
    units
    updatedAt
    updatedBy {
      ...UserFragment
    }
    version
    versions {
      ...ProjectionsVersionV2Fragment
    }
  }
  fragment IndexProjectionsFragment on IndexProjectionV2 {
    approver {
      ...UserFragment
    }
    awaitingReview
    calcState
    createdAt
    createdBy {
      ...UserFragment
    }
    description
    designedWith
    guidelines
    id
    name
    note
    projectId
    solution {
      order
    }
    status
    units
    updatedAt
    updatedBy {
      ...UserFragment
    }
  }
`;

export const projectionFragmentOld = gql`
  fragment ProjectionFragmentOld on Projection {
    id
    name
    note
    version
    calcState
    description
    guidelines
    awaitingReview
    versions {
      versionId
      lastModified
      designedBy {
        id
        attributes {
          given_name
          family_name
        }
      }
      approvedBy {
        id
        attributes {
          given_name
          family_name
        }
      }
      isLatest
      note
    }
    project {
      id
      name
    }
    advisedInputs {
      id
      name
      values
      recommendedValues
      unit
      unitOptions
      valueOptions {
        id
        label
      }
      disabled
      error
      category
      decimals
      calculation_field
    }
    inputs {
      id
      name
      values
      recommendedValues
      unit
      unitOptions
      valueOptions {
        id
        label
      }
      disabled
      error
      category
      decimals
      calculation_field
    }
    outputs {
      id
      name
      values
      recommendedValues
      unit
      unitOptions
      valueOptions {
        id
        label
      }
      disabled
      error
      category
      decimals
      calculation_field
    }
    createdBy {
      id
      attributes {
        given_name
        family_name
        account_name
      }
    }
    updatedBy {
      id
      attributes {
        given_name
        family_name
      }
    }
    updatedAt
    approver {
      id
      attributes {
        given_name
        family_name
      }
    }
    solution {
      id
      name
      oneliner
      description
      image
      techImage
      order
      techImageMap {
        type
        icon
        title
        description
        location
        number
        info_label
        values
        dialog
        key
        rotation
        content
        height
        width
        arrow_position
        splitKeyValue
        centeredText
      }
    }
    stockChemicals {
      id
      name
      wtValue
      glValue
    }
  }
`;

export const indexProjectionCollectionV2Fragment = gql`
  fragment IndexProjectionCollectionV2Fragment on IndexProjectionV2 {
    id
    name
    projectId
    solution {
      name
      id
    }
    status
    note
    updatedAt
    awaitingReview
    summary {
      considerStandbyUnits
      membraneElementType
      totalMembraneElements {
        value
        valX
        valNX
      }
    }
    products {
      id
      name
      status
    }
    approver {
      initialsAndLastName
    }
    createdBy {
      initialsAndLastName
    }
  }
`;

export const projectionVersionV2Fragment = gql`
  ${userFragment}
  fragment ProjectionVersionV2Fragment on ProjectionVersionV2 {
    approvedBy {
      ...UserFragment
    }
    designedBy {
      ...UserFragment
    }
    isLatest
    lastModified
    note
    versionId
  }
`;

export const projectionV2Fragment = gql`
  ${calculationValueFragment}
  ${userFragment}
  ${solutionV2Fragment}
  ${projectionVersionV2Fragment}
  fragment ProjectionV2Fragment on ProjectionV2 {
    advisedInputs {
      ...CalculationValueFragment
    }
    approver {
      ...UserFragment
    }
    awaitingReview
    calcState
    createdAt
    createdBy {
      ...UserFragment
    }
    description
    designedWith
    guidelines
    id
    inputs {
      ...CalculationValueFragment
    }
    name
    note
    outputs {
      ...CalculationValueFragment
    }
    projectId
    solution {
      ...SolutionV2Fragment
    }
    status
    units
    updatedAt
    updatedBy {
      ...UserFragment
    }
    version
    versions {
      ...ProjectionVersionV2Fragment
    }
  }
`;
