import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useRef } from 'react';
import { collectSolutions } from '../solution/functions/collect';
import { SolutionRecord } from '../solution/types/record';

export const CacheContext = createContext<Record<string, any>>({});
export const CacheProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const cacheRef = useRef({});
  const cache = cacheRef.current;

  return <CacheContext.Provider value={cache}>{children}</CacheContext.Provider>;
};

export const useCache = <T extends Record<string, any>>(feature: 'solutions' | 'projects' | 'projections') => {
  const cache = useContext(CacheContext);

  cache[feature] = cache[feature] || {
    collections: {},
    records: {},
  };

  const getRecord = useCallback((key: any): T => cache[feature].records[key], [cache, feature]);
  const setRecord = useCallback(
    (key: string, val: T) => {
      return (cache[feature].records[key] = { ...getRecord(key), ...val });
    },
    [cache, getRecord, feature]
  );

  const getCollection = useCallback((key: any): T[] => cache[feature].collections[key], [cache, feature]);
  const setCollection = useCallback(
    (key: string, val: T[]) => {
      cache[feature].collections[key] = val;

      val.forEach((item: any) => {
        if (item.id) {
          setRecord(item.id, item);
        }
      });
    },
    [cache, setRecord, feature]
  );

  return { setRecord, getRecord, getCollection, setCollection };
};

export const PreloadSolutions: React.FC = () => {
  const { setCollection } = useCache<Partial<SolutionRecord>>('solutions');
  useEffect(() => {
    // TODO CHECK IF CACHE IS ALREADY SET....

    collectSolutions({}).then(({ data }) => {
      setCollection('all', (data && data.SolutionCollection.solutions) || []);
    });
  }, [setCollection]);

  return null;
};
