import * as React from 'react';
import { Typography, Theme, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NotificationRecord } from '../../../../application/resources/notification/notification';
import { useObserver } from 'mobx-react';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

interface OwnProps {
  notification: NotificationRecord;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    paddingBottom: 12,
    marginBottom: 18,
    display: 'flex',
  },
  body: {
    margin: '6px 0',
  },
  icons: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexGrow: 0,
    flexShrink: 0,
  },
}));

export const DrawerNotification: React.FC<OwnProps> = ({ notification }) => {
  const classes = (useStyles as any)();
  const theme = useTheme();

  return useObserver(() => {
    return (
      <div className={classes.root}>
        <div>
          <Typography variant="h6">{notification.name}</Typography>
          <Typography variant="body1" gutterBottom className={classes.body}>
            {notification.description}
          </Typography>

          <Typography variant="body1">
            <small>{notification.createdAt}</small>
          </Typography>
        </div>
        <div className={classes.icons}>
          <FontAwesomeIcon size={'xs'} icon={faChevronRight} color={theme.palette.secondary.main} />
          <FontAwesomeIcon icon={faExclamationCircle} color="#D31E1E" />
        </div>
      </div>
    );
  });
};
