// import { TechnologyFragment } from "./fragments";

export const CreateTechnology = `
mutation CreateTechnology($input:inputTechnology!) {
  createTechnology(input:$input){
    id
    name
    oneliner
    active
    description
    image
    techImage
    comparableTechnologies
    techValues {
      id
      name
      values
      recommendedValues
      unit
      unitOptions
      valueOptions {
        id
        label
      }
      disabled
      error
      category
      decimals
    }
    techImageMap {
      title
      titleVariant
      location
      rotation
      width
      values
      align
      line
      info
    }
  }
}
`;

export const UpdateTechnology = `
mutation updateTechnology($input:inputTechnology!) {
  updateTechnology(input:$input) {
    id
    name
    oneliner
    active
    description
    image
    techImage
    comparableTechnologies
    techValues {
      id
      name
      values
      recommendedValues
      unit
      unitOptions
      valueOptions {
        id
        label
      }
      disabled
      error
      category
      decimals
    }
    techImageMap {
      title
      titleVariant
      location
      rotation
      width
      values
      align
      line
      info
    }
  }
}
`;

export const DeleteTechnology = `
mutation DeleteTechnology($id:ID!) {
  deleteTechnology(id:$id){
    id
    name
    description
  }
}
`;

export const TechnologyAddUser = `
mutation TechnologyAddUser($id:ID!, $userID:ID!) {
  technologyAddUser(id:$id, userID: $userID){
    id
    users{
      id
      username
      email
    }
  }
}
`;

export const TechnologyRequestApproval = `
mutation TechnologyRequestApproval($id:ID!) {
  technologyRequestApproval(id:$id){
    id
    version
    status
  }
}
`;
