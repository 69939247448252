import { Button, Dialog, DialogActions, DialogContent, Theme, TextField, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import InfoLabel from '../../../__old__/view/components/form/info-label';
import { DialogHeader } from '../../../theme/components/dialog-header';
import { VersionContext } from '../context/version-context';
import { faCodeBranch, faSpinner, faTimes } from '@fortawesome/pro-light-svg-icons';

interface OwnProps {
  open: boolean;
  onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  dangerText: {
    textTransform: 'uppercase',
    color: theme.palette.error.main,
  },
  buttonDanger: {
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.main,
    },
  },
  buttonCancel: {
    backgroundColor: '#f2f2f2',
    color: theme.palette.darkGray.main,
    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  },
}));

export const EditVersionDialog: React.FC<OwnProps> = (props) => {
  const { onClose, open } = props;
  const classes = (useStyles as any)(props);

  // context
  const { update: updateVersion, version } = React.useContext(VersionContext);

  // states
  const [versionString, setVersionString] = React.useState<string>(version || '');
  const [updating, setUpdating] = React.useState(false);

  // handlers
  const onUpdate = React.useCallback(() => {
    setUpdating(true);
    updateVersion(versionString).then(() => {
      setUpdating(false);
      onClose();
    });
  }, [onClose, updateVersion, versionString]);

  //
  React.useEffect(() => {
    if (version) {
      setVersionString(version);
    }
  }, [version]);

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth={'xs'} fullWidth>
      <DialogHeader title="Edit Xpert Version" subtitle={'Current version: ' + version} onClose={() => onClose()} />
      <DialogContent>
        <Typography variant="body2">
          <span>Please enter the new Xpert Version Number</span>
        </Typography>
        <br />

        <TextField
          disabled={updating}
          required
          name="project-name"
          id="project-name"
          inputProps={{ maxLength: 121 }}
          value={versionString}
          onChange={(e) => {
            setVersionString(e.target.value);
          }}
          fullWidth
          label={<InfoLabel title={'Xpert Version'} description={'Enter the updated version number for Xpert'} />}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={updating}
          children="Update version"
          startIcon={(updating && <FontAwesomeIcon icon={faSpinner} spin />) || <FontAwesomeIcon icon={faCodeBranch} />}
          onClick={onUpdate}
        />
        <Button
          disabled={updating}
          classes={{ root: classes.buttonCancel }}
          className={classes.buttonCancel}
          children="Cancel"
          startIcon={<FontAwesomeIcon icon={faTimes} />}
          onClick={() => onClose()}
        />
      </DialogActions>
    </Dialog>
  );
};
