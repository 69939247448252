import {
  UserProfile,
  DefaultUserProfile,
  UserProfileRecord,
} from "./user-profile";
import {
  GraphQLProvider,
  BaseGraphQLProvider,
} from "../../base/graphql/graphql-provider";
import { graphqlOperation } from "@aws-amplify/api";
import * as Query from "./graphql/queries";

export interface UserProfileProvider
  extends GraphQLProvider<UserProfileRecord, UserProfile> {}

export class DefaultUserProfileProvider
  extends BaseGraphQLProvider<UserProfileRecord, UserProfile>
  implements UserProfileProvider {
  public model = DefaultUserProfile;

  protected fetchOperation = (profile: UserProfile) => {
    return graphqlOperation(Query.ResolvedUserProfile, { id: profile.id });
  };
}
