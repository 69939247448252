import { DefaultAppsyncApi } from "./api/appsync-api";
import { AppModule } from "../helpers/app-module";
import { GraphQLAPI } from "../base/types";
import { MessageInteractor } from "../business/interactor/message-interactor";

interface Modules {
  appsync: GraphQLAPI;
}

export interface NetworkModule extends Modules {}
export class DefaultNetworkModule extends AppModule<Modules> implements NetworkModule {
  constructor(protected _messageInteractor: MessageInteractor) {
    super();
    //
  }

  public get appsync(): GraphQLAPI {
    return this.loadModule("appsync", DefaultAppsyncApi, this._messageInteractor);
  }
}
