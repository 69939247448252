import { ApplicationApi, ApplicationConfig } from "./app-types";
import { AppModule } from "./helpers/app-module";
import { DefaultNetworkModule, NetworkModule } from "./network/network";
import { config } from "../config/application";
import { observable } from "mobx";
import { Presenter } from "../hooks/use-presenter";
import { DefaultValidator } from "./business/utils/validator";
import { DefaultProviderModule, ProviderModule } from "./business/providers";
import {
  DefaultInteractorModule,
  InteractorModule,
} from "./business/interactor";

export interface Application {
  api: ApplicationApi;
}

export interface ApplicationCache {
  presenters: Record<string, Presenter>;
}

export class DefaultApplication extends AppModule<any> implements Application {
  @observable public config: ApplicationConfig = config;
  public cache: ApplicationCache;

  public utils = {
    validator: new DefaultValidator(),
  };

  constructor() {
    super();
    this.reset();
  }

  public get interactor(): InteractorModule {
    return this.loadModule("interactor", DefaultInteractorModule);
  }

  private get network(): NetworkModule {
    return this.loadModule(
      "network",
      DefaultNetworkModule,
      this.interactor.message
    );
  }

  public get provider(): ProviderModule {
    return this.loadModule(
      "provider",
      DefaultProviderModule,
      this.network,
      this.config
    );
  }

  public get api() {
    return {
      provider: this.provider,
      interactor: this.interactor,
      utils: this.utils,
      reset: this.reset,
      cache: this.cache,
      network: this.network,
    };
  }

  private reset = () => {
    this.clearModules();
    this.cache = { presenters: {} };
  };
}

const applicationInstance = new DefaultApplication();
export const application: ApplicationApi = applicationInstance.api;
