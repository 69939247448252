import { Inputs } from '../../../solution/types/record';
import { getCalculationPropertyBasedOnCalcField, setCalculationValues } from '../../helpers/calculation-values';
import { calculateProjectionValue } from '../calculations';
import { calcModuleNameFromCalculations } from './membrane-module';
import { CalculationValues } from './projection-values';

export const applyAdvicedInputs = (
  solution: string,
  advicedInputs: Inputs,
  inputs: CalculationValues<'id'>,
  inputsBasedOnCalcField: CalculationValues<'calculation_field'>
) => {
  // console.clear();
  // console.log('In: ', inputs.membrane_element?.values[0]);
  for (const [key, value] of Object.entries(advicedInputs)) {
    // remove items of which the key contains "ucp_passthrough_wtpct_agent"
    if (key.includes('ucp_passthrough_wtpct_agent')) {
      continue;
    }

    // get calculation input based on the calculation field
    const calcVal = getCalculationPropertyBasedOnCalcField(key, inputsBasedOnCalcField, inputs);

    if (calcVal && calcVal.id) {
      let parsedVal = String(value);

      if (key === 'all_module_name') {
        parsedVal = calcModuleNameFromCalculations(solution, parsedVal);
      }

      if (key === 'all_cap_based_on') {
        parsedVal = calculateProjectionValue.all_cap_based_on(value as number);
      }

      setCalculationValues(inputs, calcVal.id, [parsedVal]);
    }
  }

  // console.log('Out: ', inputs.membrane_element?.values[0]);

  return { ...inputs };
};
