import { Box, Divider, Grid } from '@mui/material';
import { HeaderPropertyValue } from '../../../../__old__/v2/common/layout/header-prop-value';
import { CalculationTextField } from '../../../../__old__/v2/common/ui/calculation-textfield';
import { CalculationsRecord } from '../../../../__old__/v2/feature/projection/helpers/calculation-values';
import { CalculationValue } from '../../../../__old__/v2/feature/solution/types/record';
import { CEBChemicalAgentFields } from './ceb-chemical-agent';

export interface CEBSettingsProps {
  ceb: 'ceb_1_a' | 'ceb_1_b' | 'ceb_2_a' | 'ceb_2_b';
  inputs: Partial<CalculationsRecord>;
  advisedInputs: Partial<CalculationsRecord>;
  onChange(calculationValue: CalculationValue): void;
  onError?(id: string, error: string | false): void;
  onIsCalculatingChange?(isCalculating: boolean): void;
}

export const CEBSettings: React.FC<CEBSettingsProps> = (props) => {
  const { ceb, inputs, advisedInputs, onChange, onError, onIsCalculatingChange } = props;
  const CEBBackwashFluxValue = inputs[`${ceb}_backwash_flux`]?.values[0];

  return (
    <Box width={800}>
      <Box display={'flex'}>
        <HeaderPropertyValue horizontal marginRight={1} calculationValue={inputs['ucl_overdesign']} />
      </Box>

      <Divider style={{ margin: '24px 0' }} />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <CEBChemicalAgentFields {...props} agent="agent_1" onIsCalculatingChange={onIsCalculatingChange} />
          <CEBChemicalAgentFields {...props} agent="agent_2" onIsCalculatingChange={onIsCalculatingChange} />

          <CalculationTextField
            calculationValue={inputs[`${ceb}_ph_acid`]}
            advisedValue={advisedInputs[`${ceb}_ph_acid`]}
            onChange={onChange}
            onError={onError}
            validations={'required|numberMax:14|numberMin:0'}
            type="number"
            inputProps={{ min: 0, max: 14, step: 1 }}
          />

          <CalculationTextField
            calculationValue={inputs[`${ceb}_ph_base`]}
            advisedValue={advisedInputs[`${ceb}_ph_base`]}
            onChange={onChange}
            onError={onError}
            validations={'required|numberMax:14|numberMin:0'}
            type="number"
            inputProps={{ min: 0, max: 14, step: 1 }}
          />
        </Grid>
        <Grid item xs={6}>
          <CalculationTextField
            calculationValue={inputs[`${ceb}_dosing_flux`]}
            advisedValue={advisedInputs[`${ceb}_dosing_flux`]}
            onChange={onChange}
            onError={onError}
            validations={'required|numberMax:' + (CEBBackwashFluxValue || '350') + '|numberMin:0'}
            type="number"
            inputProps={{
              min: 0,
              max: parseInt(CEBBackwashFluxValue) || 350,
              step: 1,
            }}
          />

          <CalculationTextField
            calculationValue={inputs[`${ceb}_dosing_duration`]}
            advisedValue={advisedInputs[`${ceb}_dosing_duration`]}
            onChange={onChange}
            onError={onError}
            validations={'required|numberMax:180|numberMin:0'}
            type="number"
            inputProps={{ min: 0, max: 180, step: 1 }}
          />

          <CalculationTextField
            calculationValue={inputs[`${ceb}_post_dosing_duration`]}
            advisedValue={advisedInputs[`${ceb}_post_dosing_duration`]}
            onChange={onChange}
            onError={onError}
            validations={'required|numberMax:180|numberMin:0'}
            type="number"
            inputProps={{ min: 0, max: 180, step: 1 }}
          />

          <CalculationTextField
            calculationValue={inputs[`${ceb}_soaking_time`]}
            advisedValue={advisedInputs[`${ceb}_soaking_time`]}
            onChange={onChange}
            label="Soaking Duration"
            onError={onError}
            validations={'required|numberMax:1440|numberMin:0'}
            type="number"
            inputProps={{ min: 0, max: 1440, step: 1 }}
          />

          <CalculationTextField
            calculationValue={inputs[`${ceb}_rinsing_flux`]}
            advisedValue={advisedInputs[`${ceb}_rinsing_flux`]}
            onChange={onChange}
            onError={onError}
            validations={'required|numberMax:350|numberMin:0'}
            type="number"
            inputProps={{ min: 0, max: 350, step: 1 }}
          />

          <CalculationTextField
            label="Rinsing Duration"
            calculationValue={inputs[`${ceb}_rinsing_duration`]}
            advisedValue={advisedInputs[`${ceb}_rinsing_duration`]}
            onChange={onChange}
            onError={onError}
            validations={'required|numberMax:180|numberMin:0'}
            type="number"
            inputProps={{ min: 0, max: 180, step: 1 }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
