import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import * as React from 'react';
import { DialogHeader } from '../../../../../theme/components/dialog-header';

interface OwnProps {
  title?: string;
  subtitle?: string;
  message?: React.ReactNode;
  open: boolean;
  onClose: any;
  onConfirm: any;
  confirmButtonText?: string;
  confirmDisabled?: boolean;
  closeButtonText?: string;
}

export const PreventCloseDialog: React.FC<OwnProps> = (props) => {
  const { open, onClose, onConfirm, subtitle, title, message, closeButtonText, confirmButtonText, confirmDisabled } =
    props;

  return (
    <Dialog open={open} onClose={() => {}} maxWidth={'sm'}>
      <DialogHeader
        title={title || 'Are you sure you want to abort create projection?'}
        subtitle={subtitle || ''}
        onClose={onClose}
      />
      <DialogContent>
        <Typography variant="body2">
          {message || 'If you click YES, all data entered are permanently not recoverable.'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant={'contained'}
          color="secondary"
          children={closeButtonText || 'No'}
          startIcon={<FontAwesomeIcon icon={faTimes} />}
          onClick={onClose}
          sx={{ marginRight: 1 }}
        />
        <Button
          variant={'contained'}
          children={confirmButtonText || 'Yes'}
          startIcon={<FontAwesomeIcon icon={faCheck} />}
          onClick={onConfirm}
          disabled={confirmDisabled}
        />
      </DialogActions>
    </Dialog>
  );
};
