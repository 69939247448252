export class BaseModule<Providers, Interactors, Modules> {
  /* tslint:disable:no-implicit-any */

  protected _cache: any = {
    provider: {},
    interactor: {},
    module: {}
  };

  // tslint:disable-next-line
  protected loadProvider<ClassName extends { new (...args: any): any }>(
    key: keyof Providers,
    ProviderClass: ClassName,
    ...providerProps: ConstructorParameters<ClassName>
  ) {
    if (!this._cache.provider[key]) {
      this._cache.provider[key] = new ProviderClass(...providerProps);
    }

    return this._cache.provider[key];
  }

  // tslint:disable-next-line
  protected loadInteractor<ClassName extends { new (...args: any): any }>(
    key: keyof Interactors,
    InteractorClass: ClassName,
    ...providerProps: ConstructorParameters<ClassName>
  ) {
    if (!this._cache.interactor[key]) {
      this._cache.interactor[key] = new InteractorClass(...providerProps);
    }

    return this._cache.interactor[key];
  }

  protected loadModule<ClassName extends { new (...args: any): any }>(
    key: keyof Modules,
    ModuleClass: ClassName,
    ...providerProps: ConstructorParameters<ClassName>
  ) {
    if (!this._cache.module[key]) {
      this._cache.module[key] = new ModuleClass(...providerProps);
    }

    return this._cache.module[key];
  }
}
