import { CheckboxClassKey } from '@mui/material/Checkbox';
import { FormHelperTextClassKey } from '@mui/material/FormHelperText';
import { FormLabelClassKey } from '@mui/material/FormLabel';
import { InputClassKey } from '@mui/material/Input';
import { InputBaseClassKey } from '@mui/material/InputBase';
import { InputLabelClassKey } from '@mui/material/InputLabel';
import { RadioClassKey } from '@mui/material/Radio';
import { SelectClassKey } from '@mui/material/Select';

import { PentairBrightBlue, PentairDarkGray } from '../colors';
import { typography } from '../typography';

export const MuiInputBase: Partial<Record<InputBaseClassKey, any>> = {
  root: {
    ...typography('body2'),
    fontWeight: 400,
  },
};

export const MuiInput: Partial<Record<InputClassKey, any>> = {
  root: {
    '&:hover': {},
    '&$error': {
      '&$underline:before': {
        borderBottom: '1px solid ' + PentairDarkGray[50],
      },
      '&$underline:hover:not($disabled):before': {
        borderBottom: '1px solid ' + PentairDarkGray[200],
      },
      '&$underline:after': {
        transform: 'scale(0)',
        borderBottom: '2px solid ' + PentairDarkGray[200],
      },
      '&$underline$focused:not($disabled):after': {
        transform: 'scale(1)',
      },
    },
    '&$underline': {
      '&:before': {
        borderBottom: '1px solid #DADADA',
      },
      '&:hover:not($disabled):before': {
        borderBottom: '1px solid ' + PentairDarkGray[200],
      },
      '&:after': {
        borderBottom: '2px solid ' + PentairDarkGray[200],
      },
      '&:hover:not($disabled):after': {
        borderBottom: '2px solid ' + PentairDarkGray[200],
      },
    },
    '&$input': {
      '&::placeholder': {
        color: PentairDarkGray[300],
      },
    },
  },
};

export const MuiFormLabel: Partial<Record<FormLabelClassKey, any>> = {
  root: {},
};

export const MuiSelect: Partial<Record<SelectClassKey, any>> = {
  // root: {},
  icon: {
    fill: PentairBrightBlue[500],
    width: 31,
    height: 31,
    top: 'calc(50% - 15px)',
  },
};

export const MuiFormHelperText: Partial<Record<FormHelperTextClassKey, any>> = {
  root: {
    textTransform: 'uppercase',
    fontWeight: 700,
    color: PentairDarkGray[600],
    fontSize: '12px',
  },
};

export const MuiInputLabel: Partial<Record<InputLabelClassKey, any>> = {
  root: {
    '&$error': {
      color: PentairDarkGray[600],
    },
  },
  shrink: {
    textTransform: 'uppercase',
    fontWeight: 700,
    color: PentairDarkGray[600],
    fontSize: '16px',
  },
};

export const MuiCheckbox: Partial<Record<CheckboxClassKey, any>> = {
  root: {
    color: PentairDarkGray[200],

    '&$checked svg': {
      fill: PentairBrightBlue[500],
    },
    '& svg': {
      fontSize: '24px',
    },
  },
};

export const MuiRadio: Partial<Record<RadioClassKey, any>> = {
  root: {
    color: PentairDarkGray[200],
    '&$checked': {
      color: PentairBrightBlue[500],
    },
    '& svg': {
      fontSize: '24px',
    },
  },
};
