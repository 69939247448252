import { Button, Card, Divider, Typography } from '@mui/material';
import { AuthTemplate } from '../auth/auth-template';
import { usePppMigration } from './use-ppp-migration';

export const AuthStatePPPMigration = () => {
  const { migratePPPUser } = usePppMigration();

  return (
    <AuthTemplate title="Migrate Partner Portal Data" containerSize={620}>
      <Card style={{ width: '100%', maxWidth: 550, padding: 24 }}>
        <Typography variant="h3" paragraph>
          Migrate Partner Portal Data
        </Typography>

        <Typography paragraph>
          We've noticed that you previously signed in to the application using an older version of our Partner Portal.In
          order to prevent any data loss we will migrate your data to the new version of the Partner Portal.
        </Typography>

        <Typography>In order to complete the migration, please sign out and back in again</Typography>

        <Divider style={{ marginTop: 24, marginBottom: 24 }} />

        <Button onClick={migratePPPUser}>Complete migration</Button>
      </Card>
    </AuthTemplate>
  );
};
