import { useState, useEffect } from "react";
import { ApplicationApi } from "../application/app-types";
import { application } from "../application/application";

export interface Presenter {
  mount(): void;
  unmount(): void;
}

type PresenterProvider<P> = (app: ApplicationApi) => P;

export function usePresenter<P extends Presenter>(_provider: PresenterProvider<P>): P {
  const [presenter] = useState<P>(() => _provider(application));

  useEffect(() => {
    if (presenter) {
      presenter.mount();
      return presenter.unmount;
    }
    return;
  }, [presenter]);

  if (!presenter) {
    throw new Error("Presenter is undefined");
  }

  return presenter;
}
