import * as React from 'react';
import { Typography, Theme } from '@mui/material';
import { PageContent } from '../layout/page-content';
import LoadingIcon from '../ui/loading-icon';
import { makeStyles } from '@mui/styles';

interface OwnProps {
  classes?: Record<'root' | 'icon' | 'message', string>;
  message?: string;
  iconScale?: number;
  textVariant?: 'body1' | 'body2' | 'caption';
}

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.primary.main,
    textAlign: 'center',
  },
  icon: {},
  message: {
    marginTop: -48,
  },
}));

export const PageLoading: React.FC<OwnProps> = (props) => {
  let classes2 = (useStyles as any)();

  const { message, iconScale, textVariant } = props;

  return (
    <PageContent variant="centered" classes={{ root: classes2.root }}>
      <div>
        <div className={classes2.icon}>
          <LoadingIcon scale={iconScale || 0.9}></LoadingIcon>
        </div>
        {message && (
          <Typography variant={textVariant || 'body1'} className={classes2.message}>
            {message}
          </Typography>
        )}
      </div>
    </PageContent>
  );
};
