import { ButtonClassKey } from '@mui/material/Button';
import { FabClassKey } from '@mui/material/Fab';
import { IconButtonClassKey } from '@mui/material/IconButton';
import { LinkClassKey } from '@mui/material/Link';

import { PentairBrightBlue, PentairGreen, PentairSilverGray, PentairWhite } from '../colors';

import { SwitchClassKey } from '@mui/material/Switch';
import { typography } from '../typography';

export const MuiLink: Partial<Record<LinkClassKey, any>> = {
  root: {
    ...typography('button'),
    color: PentairGreen[400],
    '&:hover': {},
  },
};

export const MuiFab: Partial<Record<FabClassKey, any>> = {
  root: {
    ...typography('button'),
    color: PentairGreen[400],
    boxShadow: 'none',
  },
  primary: {
    backgroundColor: PentairGreen[400],
    '&:hover': { backgroundColor: PentairGreen[400] },
  },
  secondary: {
    backgroundColor: PentairBrightBlue[500],
    '&:hover': { backgroundColor: PentairBrightBlue[500] },
  },
  sizeSmall: {
    width: 36,
    height: 36,
  },
};

export const MuiSwitch: Partial<Record<SwitchClassKey, any>> = {
  switchBase: {
    height: 38,
    width: 38,
  },
};

export const MuiIconButton: Partial<Record<IconButtonClassKey, any>> = {
  root: {
    height: 48,
    width: 48,
    backgroundColor: 'transparent',
    '&:hover, $active': {
      color: PentairBrightBlue[400],
      backgroundColor: 'transparent',
    },
  },
  // label: { width: 18, height: 18 },
};

export const MuiButton: Partial<Record<ButtonClassKey, any>> = {
  root: {
    padding: '0px 24px',
    height: 48,
    borderRadius: 0,
    position: 'relative',
    textDecoration: 'none !important',
    display: 'inline-flex',
    overflow: 'hidden',

    '&:before': {
      backgroundColor: PentairSilverGray[500],
      width: '125%',
      height: '100%',
      content: "''",
      display: 'block',
      position: 'absolute',
      transform: 'rotate(-45deg)',
      transformOrigin: 'top right',
      transition: 'all 0.2s',
      top: '60%',
      right: 0,
      opacity: 0.25,
    },

    '&:hover': {
      boxShadow: 'none !important',
    },

    '&:hover:before': {
      transform: 'rotate(0deg)',
      top: 0,
    },

    '& $label': {
      position: 'relative',
    },
  },

  sizeSmall: {
    padding: '0px 12px',
    height: 36,
  },
  startIcon: {
    marginRight: 12,
  },
  endIcon: {
    marginLeft: 12,
  },
  iconSizeMedium: {
    // '& > *:first-child': {
    '& > *:first-of-type': {
      fontSize: '18px',
    },
  },
  contained: {
    boxShadow: 'none',
  },

  text: {
    '&:before': {
      content: 'none',
    },
    color: PentairSilverGray[500],
    '&:hover': {
      backgroundColor: 'transparent',
      color: PentairBrightBlue[500],
    },
  },

  textPrimary: {
    color: PentairSilverGray[500],
    '&:hover': {
      backgroundColor: 'transparent',
      color: PentairBrightBlue[500],
    },
  },

  textSecondary: {
    color: PentairSilverGray[500],
    '&:hover': {
      backgroundColor: 'transparent',
      color: PentairBrightBlue[500],
    },
  },

  containedPrimary: {
    backgroundColor: PentairGreen[500],
    '&:hover': {
      backgroundColor: PentairGreen[500],
    },
  },

  containedSecondary: {
    color: PentairWhite[500],
    backgroundColor: PentairBrightBlue[500],
    '&:hover': {
      backgroundColor: PentairBrightBlue[500],
    },
  },

  outlined: {
    padding: 'calc(0.8rem - 2px) calc(2rem - 2px)',
    transition: 'color 0.4s',
    color: PentairSilverGray[500],
    border: `2px solid ${PentairSilverGray[500]}`,

    '&:before': {
      opacity: 1,
    },

    '&:hover': {
      color: PentairWhite[500],
    },

    '&$disabled': {
      color: `${PentairSilverGray[100]}`,
      borderColor: `${PentairSilverGray[100]}`,
    },

    '&$disabled:before': {
      backgroundColor: `${PentairSilverGray[100]}`,
    },
  },

  outlinedPrimary: {
    color: PentairGreen[500],
    border: `2px solid ${PentairGreen[500]}`,
    '&:hover': {
      color: '#fff',
      border: `2px solid ${PentairGreen[500]}`,
    },
    '&:before': {
      backgroundColor: PentairGreen[500],
    },
  },
  outlinedSecondary: {
    color: PentairBrightBlue[500],
    border: `2px solid ${PentairBrightBlue[500]}`,
    '&:hover': {
      color: '#fff',
      border: `2px solid ${PentairBrightBlue[500]}`,
    },
    '&:before': {
      backgroundColor: PentairBrightBlue[500],
    },
  },
  sizeLarge: {
    padding: '1.1rem 2rem',
    fontSize: '1.1rem',
  },
};
