import { ProjectFragment } from "./fragments";

export const ProjectCollection = `
    query ProjectCollection {
        ProjectCollection {
            projects {
                id
                owner {
                    id
                    attributes {
                        given_name
                        family_name
                    }
                }
                opportunity {
                    opportunityNumber
                    account {
                        name
                    }
                }
                name
                destination {
                    id
                    name
                }
                updatedAt
                createdAt
                updatedBy {
                    id
                    attributes {
                        given_name
                        family_name
                    }
                }
                awaitingOpportunity
            }
        }
    }
`;

export const ProjectCollectionWithFilter = `
    query ProjectCollection($filter: [inputFilterOptions], $pagination: inputPaginationOptions, $searchTerm: String) {
        ProjectCollection(filters: $filter, pagination: $pagination, searchTerm: $searchTerm) {
            projects {
                id
                owner {
                    id
                    attributes {
                        given_name
                        family_name
                    }
                }
                opportunity {
                    opportunityNumber
                    account {
                        name
                    }
                }
                name
                destination {
                    id
                    name
                }
                updatedAt
                createdAt
                updatedBy {
                    id
                    attributes {
                        given_name
                        family_name
                    }
                }
                awaitingOpportunity
            }
        }
    }
`;

export const Project = `
    query Project($id:ID!) {
        Project(id:$id){
            ${ProjectFragment}
        }
    }
`;

export const ProjectUsers = `
    query Project($id:ID!) {
        Project(id:$id){
            users {
                id
                attributes {
                    given_name
                    family_name
                }
            }
        }
    }
`;
