import gql from 'graphql-tag';

export const profileFragment = gql`
  fragment ProfileFragment on Profile {
    id
    name
    description
    permissions {
      allow
      deny
    }
  }
`;
