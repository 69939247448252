import gql from 'graphql-tag';

export const permissionFragment = gql`
  fragment PermissionFragment on Permission {
    created_at
    description
    field
    id
    name
    resolver
    route
    type
    updated_at
  }
`;
