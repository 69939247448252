/*

  By Code.Rehab

*/

import { lazy } from 'react';
import { createRoute } from '../../application/helpers/route';

// const PageProjectIndex = lazy(() => import('../../v2/feature/project/view/pages'));
const PageProjectionComparison = lazy(() => import('../../v2/feature/projection/view/pages/projection-comparison'));
const PageAssistant = lazy(() => import('../../view/pages/assistant/page-assistant'));
// const PageProjectSingle = lazy(() => import('../../view/pages/project/single/single'));
const PageProjectionSingle = lazy(() => import('../../view/pages/projection/page-projection-single'));

export const routesProject = {
  // NEW
  // 'projects.index': createRoute({
  //   path: '/projects',
  //   title: 'Projects',
  //   component: PageProjectIndex,
  //   breadcrumbs: [
  //     {
  //       route: 'projects.index',
  //       label: 'Projects',
  //     },
  //   ],
  //   public: false,
  //   requiredPermissions: ['projections.project.list'],
  // }),
  // 'projects.single': createRoute({
  //   path: '/projects/:projectId',
  //   title: 'Projects',
  //   component: PageProjectSingle,
  //   breadcrumbs: [
  //     {
  //       route: 'projects.index',
  //       label: 'Projects',
  //     },
  //     {
  //       route: 'projects.single',
  //       label: 'Project',
  //     },
  //   ],
  //   public: false,
  //   requiredPermissions: ['projections.project.get'],
  // }),

  // OLD
  // 'project.index': createRoute({
  //   path: '/project/index',
  //   title: 'Projects',
  //   component: PageProjectIndex,
  //   breadcrumbs: [
  //     {
  //       route: 'project.index',
  //       label: 'Projects',
  //     },
  //     {
  //       route: 'project.index',
  //       label: 'Created by me',
  //     },
  //   ],
  //   public: false,
  //   requiredPermissions: ['projections.project.list'],
  // }),
  // 'project.single': createRoute({
  //   path: '/project/single/:projectId/',
  //   title: 'View projection',
  //   component: PageProjectSingle,
  //   public: false,
  //   breadcrumbs: [
  //     {
  //       route: 'project.index',
  //       label: 'Projects',
  //     },
  //     {
  //       route: 'project.single',
  //       label: 'Details',
  //     },
  //   ],
  //   requiredPermissions: ['projections.project.get'],
  // }),
  'projection.single': createRoute({
    path: '/project/:projectId/projection/:projectionid/',
    title: 'View projection',
    component: PageProjectionSingle,
    public: false,
    requiredPermissions: ['projections.projection.get'],
    breadcrumbs: [
      {
        route: 'projectV2.index',
        label: 'Project',
        params: { segment: 'projections' },
      },
      {
        route: 'projection.single',
        label: 'Projection',
      },
    ],
  }),
  'projection.single.tab': createRoute({
    path: '/project/:projectId/projection/:projectionid/:tab/',
    title: 'View projection',
    breadcrumbs: [
      {
        route: 'project.index',
        label: 'Projects',
      },
      {
        route: 'project.single',
        label: 'Project',
      },
      {
        route: 'projection.single',
        label: 'Projection',
      },
      {
        route: 'projection.single.tab',
        label: ({ match }) => match.params.tab,
      },
    ],
    component: PageProjectionSingle,
    public: false,
    requiredPermissions: ['projections.projection.get'],
  }),
  'projection.single.tab.version': createRoute({
    path: '/project/:projectId/projection/:projectionid/:tab/:version',
    title: 'View projection',
    breadcrumbs: [
      {
        route: 'projectsV2.index',
        label: 'Projects',
      },
      {
        route: 'projectV2.index',
        label: 'Project',
      },
      {
        route: 'projection.single',
        label: 'Projection',
      },
      {
        route: 'projection.single.tab.version',
        label: 'Revision',
      },
      {
        route: 'projection.single.tab',
        label: ({ match }) => match.params.tab,
      },
    ],
    component: PageProjectionSingle,
    public: false,
    requiredPermissions: ['projections.projection.get'],
  }),
  'projection.create': createRoute({
    path: '/project/:projectId/projection/create/:projectionid/',
    title: 'View projection',
    component: PageProjectionSingle,
    breadcrumbs: [
      {
        route: 'project.index',
        label: 'Projects',
      },
      {
        route: 'project.single',
        label: 'Project',
      },
      {
        route: 'projection.create',
        label: 'Create projection',
      },
    ],
    public: false,
    requiredPermissions: ['projections.projection.get'],
  }),
  'app.assistant.project': createRoute({
    path: '/project/:projectId/assistant',
    title: 'Xpert assistant',
    breadcrumbs: [
      {
        route: 'projectV2.index',
        label: 'Project',
      },
      {
        route: 'app.assistant.project',
        label: 'Create Projection',
      },
      {
        route: 'app.assistant.project',
        label: 'Assistant',
      },
    ],
    component: PageAssistant,
  }),
  'projection.compare': createRoute({
    path: '/project/:projectId/projection1/:projectionid/projection2/:projectionid2',
    title: 'Compare Projections',
    component: PageProjectionComparison,
    public: false,
    requiredPermissions: ['projections.projection.get'],
    breadcrumbs: [
      {
        route: 'project.index',
        label: 'Projects',
      },
      {
        route: 'project.single',
        label: 'Project',
      },
      {
        label: 'Compare projections',
        route: 'projection.compare',
      },
    ],
  }),
  'projection.compare3': createRoute({
    path: '/project/:projectId/projection1/:projectionid/projection2/:projectionid2/projection3/:projectionid3',
    title: 'Compare Projections',
    component: PageProjectionComparison,
    public: false,
    requiredPermissions: ['projections.projection.get'],
    breadcrumbs: [
      {
        route: 'project.index',
        label: 'Projects',
      },
      {
        route: 'project.single',
        label: 'Project',
      },
      {
        label: 'Compare projections',
        route: 'projection.compare',
      },
    ],
  }),
};
export default routesProject;
