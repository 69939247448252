import gql from 'graphql-tag';
import { useCallback } from 'react';
import api from '../../api/api';
import { useAuth } from '../auth/auth-provider';

export const usePppMigration = () => {
  const { signOut } = useAuth();

  const migratePPPUser = useCallback(async () => {
    try {
      await api.query(
        gql`
          mutation MigratePPPUser {
            migratePPPUser
          }
        `
      );
    } catch (e) {
      console.error(e);
    }

    signOut();
  }, [signOut]);

  return {
    migratePPPUser,
  };
};
