import { Box, FormHelperText, FormLabel, MenuItem, TextField, TextFieldProps } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { usePermissions } from '../../../../feature/auth/auth-permissions';
import {
  filteredUnitOptions,
  fromDisplayUnit,
  toDisplayUnit,
} from '../../../view/components/form/fields/projection-calculation-value-convert';
import { useFormValidation } from '../../../view/components/form/hooks/use-form-validation';
import InfoLabel from '../../../view/components/form/info-label';
import { correctDecimals, updateCalculationValue } from '../../feature/projection/helpers/calculation-values';
import { CalculationValue } from '../../feature/solution/types/record';

interface CalculationTextFieldProps {
  select?: boolean;
  calculationValue?: CalculationValue | null;
  advisedValue?: CalculationValue | null;
  showEgu?: boolean;
  showName?: boolean;
  showValue?: boolean;
  type?: 'string' | 'number';
  disabled?: boolean;
  highlighted?: boolean;
  validations?: string;
  label?: string;
  eguWidth?: number;
  eguDisabled?: boolean;
  onChange?: (calculationValue: CalculationValue, e: any) => void;
  onBlur?: (calculationValue: CalculationValue, e: any) => void;
  onError?: (id: string, error: string | false) => void;
  inputProps?: TextFieldProps['inputProps'];
  defaultValue?: string | number;
  debounce?: number;
  error?: boolean;
  helperText?: string;
  disableValidation?: boolean;
}

/**
 *  the calculation textfield
 *  EGU calculations will not happen if type != number
 *
 */
export const CalculationTextField: React.FC<CalculationTextFieldProps> = ({
  select,
  showEgu,
  showName,
  showValue,
  type,
  label,
  eguWidth,
  disabled,
  highlighted,
  validations,
  onChange,
  onError,
  onBlur,
  calculationValue,
  inputProps,
  advisedValue,
  eguDisabled,
  defaultValue,
  debounce,
  disableValidation,
  error: _error,
  helperText,
}) => {
  showName = showName || showName === undefined || false;
  showValue = showValue || showValue === undefined || false;
  showEgu = Boolean(showEgu);

  const description = calculationValue?.description || '';
  const inputLabel = label ?? (calculationValue?.name || '');
  const [value, setValue] = useState(parseValue(calculationValue?.values?.[0], type));
  const egu = calculationValue?.displayUnit || calculationValue?.unit || '';
  const decimals = parseFloat(String(calculationValue?.decimals)) || 0;
  const step = decimals > 0 ? (0.1 ** decimals).toFixed(decimals) : undefined;
  const unitOptions = filteredUnitOptions(calculationValue);
  const hasUnitOptions = unitOptions && unitOptions.length > 1;

  const debounceRef = useRef<null | ReturnType<typeof setTimeout>>(null);

  const { userProfile } = usePermissions();
  const engineeringUnit = userProfile.preferences?.find((p) => p?.id === 'engineering_unit')?.value || 'metric';

  const validationError = useFormValidation(
    value,
    validations ?? 'required|max:120',
    (error) => onError && calculationValue?.id && onError(calculationValue?.id, error)
  );

  const errorText = (_error && helperText) ?? (validationError || calculationValue?.error);
  const error = (!disabled && _error) ?? Boolean(errorText);

  // cleanup
  useEffect(() => {
    return () => {
      if (debounceRef && debounceRef.current) {
        clearTimeout(debounceRef.current);
        debounceRef.current = null;
      }
    };
  }, []);

  const isEditing = useRef(false);
  useEffect(() => {
    if (!calculationValue) return;

    if (!calculationValue.displayUnit) {
      if (engineeringUnit === 'imperial') {
        calculationValue.displayUnit = calculationValue.unitOptions?.[calculationValue.unitOptions.length - 1];
      } else if (engineeringUnit === 'metric') {
        calculationValue.displayUnit = undefined;
      }
    }

    setValue((_currentvalue) => {
      const parsed = parseValue(calculationValue?.values?.[0], type);
      if (parsed && type === 'number') {
        return correctDecimals(toDisplayUnit(calculationValue, Number(parsed)) || 0, decimals);
      } else {
        return parsed;
      }
    });

    // only update value when engineering unit changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engineeringUnit, decimals, calculationValue]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!calculationValue) return;
      isEditing.current = true;
      const isNumber = type === 'number';
      const _val = isNumber ? fromDisplayUnit(calculationValue, Number(e.target.value)) : e.target.value;
      setValue(e.target.value);

      if (calculationValue) {
        if (onChange) {
          const values: any[] = [];

          if (_val !== undefined && _val !== null) {
            values.push(_val);
          }

          if (debounceRef && debounceRef.current) {
            clearTimeout(debounceRef.current);
          }
          debounceRef.current = setTimeout(() => {
            onChange(updateCalculationValue(calculationValue, values), e);
          }, debounce ?? 750);
        }
      }
    },
    [calculationValue, type, onChange, debounce]
  );

  const handleBlur = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (calculationValue) {
        if (onBlur) {
          const values: any[] = [];
          if (e.target.value) {
            values.push(e.target.value);
          }

          onBlur(updateCalculationValue(calculationValue, values), e);
        }
      }
    },
    [onBlur, calculationValue]
  );

  const handleUnitChange = useCallback(
    (e) => {
      isEditing.current = true;
      if (!calculationValue) return;
      const newCalcVal = { ...calculationValue, displayUnit: e.target.value };

      const value = Number(newCalcVal?.values?.[0]?.toString());
      if (!isNaN(value)) {
        setValue(correctDecimals(toDisplayUnit(newCalcVal, value) || 0, decimals));
      }

      if (onChange) {
        onChange(newCalcVal, e);
      }
    },
    [calculationValue, onChange, decimals]
  );

  const isAdvised = useMemo(() => {
    const _advisedValue = advisedValue?.values[0];
    const _currentvalue = calculationValue?.values[0];
    return parseValue(_advisedValue) === parseValue(_currentvalue);
  }, [calculationValue, advisedValue]);

  highlighted = highlighted ?? !isAdvised;

  return (
    <Box width={'100%'}>
      {showName && (
        <FormLabel style={{ fontSize: 12 }} disabled={disabled}>
          <InfoLabel title={inputLabel} description={description} />
        </FormLabel>
      )}
      <Box display="flex">
        {showValue && (
          <TextField
            select={select}
            name={calculationValue?.id + '_value'}
            id={calculationValue?.id + '_value'}
            lang={'EN'}
            disabled={disabled}
            type={type}
            value={((value === '' && defaultValue) || value) ?? defaultValue ?? ''}
            inputProps={{ step, ...inputProps }}
            error={error}
            style={{
              width: '100%',
              marginTop: 0,
              backgroundColor: highlighted ? '#fcf7e8' : '#fff',
            }}
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            placeholder={advisedValue?.values[0]?.toString()}
          >
            {select &&
              calculationValue?.valueOptions?.map((option, index) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
        )}

        {showEgu && (
          <TextField
            id={calculationValue?.id + '_unit'}
            name={calculationValue?.id + '_unit'}
            required
            lang={'EN'}
            select={hasUnitOptions}
            disabled={eguDisabled || disabled || !hasUnitOptions}
            value={egu || ''}
            error={error}
            style={{
              width: eguWidth || egu.length * 15,
              marginTop: 0,
              marginLeft: showValue ? '12px' : 0,
            }}
            margin="normal"
            onChange={handleUnitChange}
          >
            {unitOptions?.map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      {error && <FormHelperText error={error}>{errorText}</FormHelperText>}
    </Box>
  );
};

// helpers
const parseValue = (value: string | number | null | undefined | typeof NaN, type: 'number' | 'string' = 'string') => {
  let val = value;
  if (type === 'number') {
    val = parseFloat(String(value || '0')) || 0;
  }
  return val;
};
