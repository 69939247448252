import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export type StyleDefaultPages = {
  classes?: Record<'root' | 'bigText' | 'icon', string>;
};

export const useErrorPageStyles = makeStyles((theme: Theme) => ({
  root: {
    color: '#ccc',
    textAlign: 'center',
  },
  bigText: {
    ...theme.typography.h1_condensed,
    fontSize: 'calc(1rem + 2vw)',
  },
  icon: {
    width: `calc(${theme.spacing(4)}px + 2vw)`,
    height: `calc(${theme.spacing(4)}px + 2vw)`,
    margin: theme.spacing(2),
  },
})) as any;
