import { Box } from '@mui/material';
import { CalculationTextField } from '../../../../__old__/v2/common/ui/calculation-textfield';
import { CalculationsRecord } from '../../../../__old__/v2/feature/projection/helpers/calculation-values';
import { CalculationValue } from '../../../../__old__/v2/feature/solution/types/record';

export interface FeedPumpConfigurationProps {
  inputs: Partial<CalculationsRecord>;
  advisedInputs: Partial<CalculationsRecord>;
  onChange(calculationValue: CalculationValue): void;
  onError?(id: string, error: string | false): void;
}

export const FeedPumpConfiguration: React.FC<FeedPumpConfigurationProps> = (props) => {
  const { inputs, advisedInputs, onChange, onError } = props;

  return (
    <Box width={500}>
      <CalculationTextField
        calculationValue={inputs['number_of_feed_pumps']}
        advisedValue={advisedInputs['number_of_feed_pumps']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:100|numberMin:1'}
        type="number"
        inputProps={{ min: 1, max: 100, step: 1 }}
      />
    </Box>
  );
};
