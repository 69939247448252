import * as React from 'react';
import { Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { MessageType } from '../../../application/business/interactor/message-interactor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faExclamationCircle, faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

interface OwnProps {
  type?: MessageType;
  show?: boolean;
  onClose?(): void;
  style?: React.CSSProperties;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0.25, 0),
    color: '#fff',
    display: 'flex',
    // '&:first-child': {
    '&:first-of-type': {
      marginTop: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  text: { padding: theme.spacing(1.125, 1.125, 1.125, 0), flex: 1 },
  strong: { fontWeight: 600 },
  info: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
}));

export const Message: React.FC<React.PropsWithChildren<OwnProps>> = (props) => {
  const classes = (useStyles as any)(props);
  const type = props.type || MessageType.Info;

  if (!props.show) {
    return null;
  }

  return (
    <div className={classnames(classes.root, classes[type])} style={props.style}>
      {type === MessageType.Info && (
        <>
          <div className={classes.icon}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>
          <div className={classes.text}>
            <Typography sx={(theme) => ({ color: theme.palette.info.contrastText })}>
              <strong className={classes.strong}>Info: </strong>
              {props.children}
            </Typography>
          </div>
        </>
      )}

      {type === MessageType.Warning && (
        <>
          <div className={classes.icon}>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </div>
          <div className={classes.text}>
            <Typography sx={(theme) => ({ color: theme.palette.warning.contrastText })}>
              <strong className={classes.strong}>Warning: </strong>
              {props.children}
            </Typography>
          </div>
        </>
      )}

      {type === MessageType.Error && (
        <>
          <div className={classes.icon}>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </div>
          <div className={classes.text}>
            <Typography sx={(theme) => ({ color: theme.palette.error.contrastText })}>
              <strong className={classes.strong}>Error: </strong>
              {props.children}
            </Typography>
          </div>
        </>
      )}

      {props.onClose && (
        <div className={classes.icon} onClick={props.onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
    </div>
  );
};
