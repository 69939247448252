/*

  By Code.Rehab

*/

import { Box, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';

export const PrintPreviewFooter: React.FC<{
  printedBy?: string | null;
  version: string;
}> = (props) => {
  return (
    <>
      <Typography variant="body2" style={{ color: '#4f4f4f' }} fontSize={'10px'}>
        Disclaimer: The Xpert web application is intended to be used by people that have adequate technical skills and
        experience with membrane system design. The membrane system design produced by this web application is intended
        for dead-end operation only. The program’s results are for informational purposes only and do not express nor
        imply membrane or system performance. Pentair is not liable for errors or miscalculations in the Xpert web
        application and its results cannot be used to raise any claim(s) for liability or warranty.
      </Typography>
      <Grid container>
        <Grid item>
          <Box component={'p'} sx={{ fontSize: '11px' }}>
            {/* <Typography variant="body2"> */}
            <strong>Xpert version: </strong>
            {props.version}
            {/* </Typography> */}
          </Box>
        </Grid>
        <Grid item xs></Grid>
        {props.printedBy && (
          <Grid item>
            {/* <Typography variant="body2"> */}
            <Box component={'p'} sx={{ fontSize: '11px' }}>
              <strong>Printed By: </strong>
              {props.printedBy || ''}
              {/* </Typography> */}
            </Box>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default PrintPreviewFooter;
