import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import * as React from 'react';
import { ProjectionRecord } from '../../../../../application/resources/projection/projection';
import { ProjectionClass } from '../../../../../application/resources/projection/v2/single';
import { DialogHeader } from '../../../../../../theme/components/dialog-header';
import { ImageMap } from '../../../solution/types/record';
import { FormProjectionsInputsCategoryTable } from '../form/form-projection-inputs-table';
import { faCheck, faSpinner } from '@fortawesome/pro-light-svg-icons';

interface OwnProps {
  open: boolean;
  onClose: () => void;
  classes?: Record<'root', string>;
  projection: ProjectionClass;
  marker: ImageMap;
  onChange: (key: any, value: any) => void;
}

export const ProjectionMarkerFormDialog: React.FC<OwnProps> = (props) => {
  const { open, onClose, projection, marker } = props;
  const [loading, setLoading] = React.useState(false);
  const dialogFields = marker.content || 'input.ceb'; // marker.dialog;

  const onChange = React.useCallback(
    (key: keyof ProjectionRecord) => (value: any) => {
      // If projectionValue is being updated run different logic
      if (key === 'inputs') {
        // Set current projectionValues
        const inputs = projection.record.inputs || [];

        // Find index of the projectionValue we want to change.
        const calcValIndex = inputs.findIndex((input) => input.id === value.id);

        // Update value in our projectionValues array
        inputs[calcValIndex] = value;

        // Update projection with our new values;
        projection.change(key, inputs);
        props.onChange(key, inputs);
      } else {
        projection.change(key, value);
        props.onChange(key, value);
      }
    },
    [projection, props]
  );

  const getInputsByCategory = (category: string) => {
    const values = projection.record.inputs || [];
    return values.filter((input) => input.category === category);
  };

  React.useEffect(() => {
    if (!projection.record.name) {
      setLoading(true);
      projection.fetch().then(() => {
        setLoading(false);
      });
    }
  }, [projection]);

  if (loading) {
    return null;
  }

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth={'xs'} fullWidth>
      <DialogHeader title={'Projection Inputs'} subtitle={marker.title} onClose={onClose} />
      <DialogContent>
        <Typography variant="body2" paragraph>
          {marker.description}
        </Typography>
        <FormProjectionsInputsCategoryTable
          onChange={onChange}
          data={getInputsByCategory(dialogFields)}
          title={marker.title}
          onValidate={() => {}}
        />
      </DialogContent>
      <DialogActions>
        <Button
          children="Save"
          disabled={loading}
          startIcon={(loading && <FontAwesomeIcon icon={faSpinner} spin />) || <FontAwesomeIcon icon={faCheck} />}
          onClick={onClose}
        />
      </DialogActions>
    </Dialog>
  );
};
