import { Box, Divider } from '@mui/material';
import { HeaderPropertyValue } from '../../../../__old__/v2/common/layout/header-prop-value';
import { CalculationTextField } from '../../../../__old__/v2/common/ui/calculation-textfield';
import { CalculationsRecord } from '../../../../__old__/v2/feature/projection/helpers/calculation-values';
import { CalculationValue } from '../../../../__old__/v2/feature/solution/types/record';

export const GeneralCEBSettings: React.FC<{
  inputs: Partial<CalculationsRecord>;
  advisedInputs: Partial<CalculationsRecord>;
  onChange(calculationValue: CalculationValue): void;
  onError?(id: string, error: string | false): void;
}> = ({ inputs, advisedInputs, onChange, onError }) => {
  const CEB1AEnabled = inputs['ceb_1_a_enabled']?.values[0] === 'true';
  const CEB1BEnabled = inputs['ceb_1_b_enabled']?.values[0] === 'true';
  const CEB2AEnabled = inputs['ceb_2_a_enabled']?.values[0] === 'true';
  const CEB2BEnabled = inputs['ceb_2_b_enabled']?.values[0] === 'true';

  const CEB1CounterEnabled = CEB1AEnabled || CEB1BEnabled;
  const CEB2CounterEnabled = CEB2AEnabled || CEB2BEnabled;

  return (
    <Box width={500}>
      <Box display={'flex'}>
        <HeaderPropertyValue horizontal marginRight={1} calculationValue={inputs['ucl_overdesign']} />
        <HeaderPropertyValue
          horizontal
          marginRight={1}
          calculationValue={inputs['mb_out_ceb_interval']}
          value={(parseFloat(String(inputs['mb_out_ceb_interval']?.values[0])) / 3600).toFixed(1)}
        />
        <HeaderPropertyValue
          horizontal
          marginRight={1}
          calculationValue={inputs['mb_out_ceb_interval_2']}
          value={(parseFloat(String(inputs['mb_out_ceb_interval_2']?.values[0])) / 3600).toFixed(1)}
        />
      </Box>

      <Divider style={{ margin: '24px 0' }} />

      <CalculationTextField
        label="CEB 1A Enabled"
        select
        calculationValue={inputs['ceb_1_a_enabled']}
        advisedValue={advisedInputs['ceb_1_a_enabled']}
        onChange={onChange}
        onError={onError}
      />

      <CalculationTextField
        label="CEB 1B Enabled"
        select
        calculationValue={inputs['ceb_1_b_enabled']}
        advisedValue={advisedInputs['ceb_1_b_enabled']}
        onChange={onChange}
        onError={onError}
      />

      <CalculationTextField
        label="CEB 2A Enabled"
        select
        calculationValue={inputs['ceb_2_a_enabled']}
        advisedValue={advisedInputs['ceb_2_a_enabled']}
        onChange={onChange}
        onError={onError}
      />

      <CalculationTextField
        label="CEB 2B Enabled"
        select
        calculationValue={inputs['ceb_2_b_enabled']}
        advisedValue={advisedInputs['ceb_2_b_enabled']}
        onChange={onChange}
        onError={onError}
      />

      <CalculationTextField
        type="number"
        inputProps={{ min: 0, max: 999999 }}
        calculationValue={inputs['ceb_1_counter']}
        advisedValue={advisedInputs['ceb_1_counter']}
        onChange={onChange}
        disabled={!CEB1CounterEnabled}
        showEgu
        eguWidth={100}
        validations={'required|numberMax:1000000|numberMin:0'}
        onError={onError}
      />

      <CalculationTextField
        type="number"
        inputProps={{ min: 0, max: 999999 }}
        calculationValue={inputs['ceb_2_counter']}
        advisedValue={advisedInputs['ceb_2_counter']}
        onChange={onChange}
        disabled={!CEB2CounterEnabled}
        showEgu
        eguWidth={100}
        validations={'required|numberMax:1000000|numberMin:0'}
        onError={onError}
      />

      <CalculationTextField
        select
        calculationValue={inputs['bw_gross_flux_is_ceb_dosing_flux']}
        advisedValue={advisedInputs['bw_gross_flux_is_ceb_dosing_flux']}
        onChange={onChange}
        onError={onError}
      />
    </Box>
  );
};
