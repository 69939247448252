import { UserInteractor } from "../../../../application/resources/user/user-interactor";
import { Presenter } from "../../../../hooks/use-presenter";

export class UserInteractorPresenter implements Presenter {
  constructor(protected _userInteractor: UserInteractor) {}

  public mount = () => {};

  public unmount = () => {};

  public get userCanReview() {
    return this._userInteractor.currentUser.can("sharing.projection.review");
  }

  public get currentUser() {
    return this._userInteractor.currentUser;
  }

  public checkPermission = (permission: string) => {
    return this._userInteractor.currentUser.can(permission);
  };

  public get isPentairUser() {
    return this._userInteractor.currentUser.can(["ui.admin.project.create"]);
  }

  public userInteractor = this._userInteractor;
}
