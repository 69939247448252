import { errorMessages } from './error-messages';

export type ValidationHandler<ErrorType extends string = string, Args extends any[] = any[]> = (
  value: string | boolean | number,
  ...args: Args
) => true | ErrorType;

export interface Validator<TData extends Record<string, any> = any> {
  validate(
    data: Record<keyof TData, PossibleValues> | Partial<Record<keyof TData, PossibleValues>>,
    validations: Record<keyof TData, string>,
    messageOverrides?: Record<string, string>
  ): false | ValidationErrors<TData>;
  validateRequired: ValidationHandler<string | ValidationError.Required>;
  validateEmail: ValidationHandler<string | ValidationError.InvalidEmail>;
  validateRegex: ValidationHandler<string | ValidationError.Invalid, [RegExp | string]>;
  validators: Record<ValidationType, ValidationHandler>;
}

// export type PossibleValues = string | number | boolean | null | undefined;
export type PossibleValues = any;

export enum ValidationError {
  Required = 'This field is required',
  Invalid = 'This is an invalid input',
  InvalidEmail = 'This is not a valid email',
  InvalidDecimal = 'This is not a valid decimal number',
  InvalidLength = 'Too many characters',
  InvalidNumber = 'Number out of range',
  numberMax = 'Your value is too high',
  numberMin = 'Your value is too low',
  MaxMembranes = 'Number of membrane elements cannot be higher than maximum membrane elements. Please update this field to ≤ maximum membrane elements.',
  MaxMembranesXiga = 'Number of membrane housings per unit cannot be higher than the maximum membrane housings per unit. Please update your value to ≤ maximum membrane housings per unit',
  MaxMembranesRowXline = 'Maximum membrane elements per row are limited to 16 to avoid excessive tension and mechanical stress to the X-line rack.',
}

export enum ValidationType {
  Required = 'required',
  Regex = 'regex',
  Email = 'email',
  Decimal = 'decimal',
  Max = 'max',
  NumberMax = 'numberMax',
  NumberMin = 'numberMin',
  MaxMembranes = 'maxMembranes',
  MaxMembranesXiga = 'maxMembranesXiga',
  maxMembranesRowXline = 'maxMembranesRowXline',
}

export type ValidationErrors<TData extends Record<string, any> = any> = Record<keyof TData, ValidationError>;

export class DefaultValidator implements Validator {
  public validate = (
    data: Record<string, PossibleValues> | Partial<Record<string, PossibleValues>>,
    validations: Record<string, string>,
    messageOverrides: Record<string, string> = {}
  ) => {
    const errors = Object.keys(validations).reduce((errors: ValidationErrors, key) => {
      const validators = validations[key].split('|');
      const errs = validators.reduce((errs: ValidationError[], validator) => {
        const keyval = validator.split(':');
        const result = this.validators[keyval[0]](
          data[key],
          ...keyval.slice(1, keyval.length),
          messageOverrides[[key, keyval[0]].join('.')]
        );

        if (result !== true) {
          errs.push(this.getErrorMessage(result, validator, messageOverrides[[key, keyval[0]].join('.')]));
        }
        return errs;
      }, []);

      if (errs[0]) {
        errors[key] = errs[0];
      }
      return errors;
    }, {});

    return Object.keys(errors).length ? errors : false;
  };

  public validateRequired = (value: PossibleValues, customMessage?: string) => {
    const valid = String(value) !== 'null' && String(value) !== 'undefined' && value !== '';
    return valid || ValidationError.Required;
  };

  public validateEmail = (value: PossibleValues, customMessage?: string) => {
    const regex =
      // @ts-ignore : email validation is hard
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const valid = value && regex.test(value.toString());
    return valid || ValidationError.InvalidEmail;
  };

  public validateDecimal = (value: PossibleValues, customMessage?: string) => {
    const regex = /^(\d+\.?\d*|\.\d+)$/;
    const valid = (value && +value && regex.test(value.toString())) || value === '0';
    return valid || ValidationError.InvalidDecimal;
  };

  public validateRegex = (value: PossibleValues, regex: RegExp | string, customMessage?: string) => {
    if (typeof regex === 'string') {
      regex = new RegExp(regex);
    }

    const valid = value && regex.test(value.toString());
    return valid || ValidationError.Invalid;
  };

  public validateMax = (value: PossibleValues, max: string, customMessage?: string) => {
    const valid = (value || '').toString().length <= parseInt(max);

    return valid || ValidationError.InvalidLength;
  };

  public validateNumberMax = (value: PossibleValues, max: string, customMessage?: string) => {
    const valid = parseInt(value || 0) <= parseInt(max);

    return valid || ValidationError.numberMax;
  };

  public validateMaxMembranes = (value: PossibleValues, max: string, customMessage?: string) => {
    const valid = parseInt(value || 0) <= parseInt(max);

    return valid || ValidationError.MaxMembranes;
  };

  public validateMaxMembranesXiga = (value: PossibleValues, max: string, customMessage?: string) => {
    const valid = parseInt(value || 0) <= parseInt(max);

    return valid || ValidationError.MaxMembranesXiga;
  };

  public validateNumberMin = (value: PossibleValues, min: string, customMessage?: string) => {
    const valid = parseInt(value || 0) >= parseInt(min);

    return valid || ValidationError.numberMin;
  };

  public validateMaxMembranesRowXline = (value: PossibleValues, max: string, customMessage?: string) => {
    const valid = parseInt(value || 0) <= parseInt(max);

    return valid || ValidationError.MaxMembranesRowXline;
  };

  public validators = {
    required: this.validateRequired,
    regex: this.validateRegex,
    email: this.validateEmail,
    decimal: this.validateDecimal,
    max: this.validateMax,
    numberMax: this.validateNumberMax,
    numberMin: this.validateNumberMin,
    maxMembranes: this.validateMaxMembranes,
    maxMembranesXiga: this.validateMaxMembranesXiga,
    maxMembranesRowXline: this.validateMaxMembranesRowXline,
  };

  public getErrorMessage = (error: string, errorParams: any, customMessage: string) => {
    let message = customMessage || errorMessages[error] || error;

    errorParams.split(',').forEach((paramStr: string) => {
      const param = paramStr.split(':');

      message = message.replace('%' + param[0] + '%', param[1]);
    });

    return message;
  };
}
