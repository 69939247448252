import { PropsWithChildren } from 'react';
import { AuthState, useAuth } from './auth-provider';
import { AuthStatePasswordResetRequired } from './authstate-password-reset';
import { AuthStatePPPMigration } from '../ppp-migration/authstate-ppp-migration';

import { PageHome } from '../../pages/page-home';

export const AuthFlow: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const { authState } = useAuth();

  switch (authState) {
    case AuthState.PPPMigrationRequired:
      return <AuthStatePPPMigration />;

    case AuthState.SignedIn:
      return <>{children}</>;

    case AuthState.SignedOut:
      return <PageHome />;

    case AuthState.PasswordResetRequired:
      return <AuthStatePasswordResetRequired />;

    default:
      return <div>Unknown Auth State: {authState}</div>;
  }
};
