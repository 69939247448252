import { TableClassKey } from '@mui/material/Table';
import { TableCellClassKey } from '@mui/material/TableCell';
import { TableHeadClassKey } from '@mui/material/TableHead';
import { TableRowClassKey } from '@mui/material/TableRow';

export const MuiTable: Partial<Record<TableClassKey, any>> = {
  root: {
    // margin: spacing(-1),
    // marginTop: spacing(-3)
  },
};

export const MuiTableRow: Partial<Record<TableRowClassKey, any>> = {
  root: {
    transition: 'background 0.3s',
    borderBottom: '1px solid #eee',
    '&:hover': {
      background: '#f2f2f2',
    },
  },
};

export const MuiTableCell: Partial<Record<TableCellClassKey, any>> = {
  root: {
    borderBottom: 0,
  },
  head: {
    fontSize: '0.75rem',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: '#000',
  },
};

export const MuiTableHead: Partial<Record<TableHeadClassKey, any>> = {
  root: {},
};
