import { alpha, Box } from '@mui/material';
import { BoxProps, styled } from '@mui/material';
import * as React from 'react';

const Footer = styled(Box)(({ color, theme }) => ({
  textAlign: 'right',
  color: alpha((color as any) || theme.palette.darkGray.main, 0.7),
  fontSize: 13,
  padding: theme.spacing(2),
  // paddingBottom: 315,
  '& a': {
    padding: '6px',
    transition: 'color 0.2s',
    color: 'inherit',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: alpha((color as any) || theme.palette.darkGray.main, 1),
    },
  },
}));

export const PageFooter: React.FC<BoxProps> = (props) => {
  return (
    <Footer component="footer" {...props}>
      ©{new Date().getFullYear()} Pentair. All rights reserved. <br />
      <a target="_blank" rel="noreferrer" href={'/agreements/Xpert_TermsOfService_ENG_V2137.pdf'}>
        Terms of service
      </a>{' '}
      |{' '}
      <a target="_blank" rel="noreferrer" href={'https://www.pentair.com/content/pentair/en/privacy-notice.html'}>
        Privacy policy
      </a>
    </Footer>
  );
};
