import * as React from 'react';
import { usePermissions } from '../../../feature/auth/auth-permissions';
import { useAuth } from '../../../feature/auth/auth-provider';

export interface IsPermittedProps {
  UnauthorizedComponent?: React.ReactElement;
  roles?: string[];
  permission?: string | string[];
  reversed?: boolean;
}

export const IsPermitted: React.FC<React.PropsWithChildren<IsPermittedProps>> = ({
  children,
  UnauthorizedComponent,
  roles,
  permission,
  reversed,
}) => {
  const { isPermitted } = usePermissions();
  const { isAuthenticated } = useAuth();

  const allowed = React.useMemo(() => {
    try {
      let allowed = false;
      allowed = Boolean(roles?.includes('public')) || isAuthenticated;
      return allowed && isPermitted(permission);
    } catch (err) {
      return false;
    }
  }, [isAuthenticated, isPermitted, permission, roles]);

  if (!reversed && allowed) {
    return <>{children}</>;
  }

  if (reversed && !allowed) {
    return <>{children}</>;
  }

  return UnauthorizedComponent ? UnauthorizedComponent : null;
};

export const CanView = IsPermitted;
export type CanViewProps = IsPermittedProps;
