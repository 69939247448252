/**
 *
 *
 *
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, FormHelperText, Grid, TextField } from '@mui/material';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { DefaultValidator } from '../../../../__old__/application/business/utils/validator';
import { convertWtPercent } from '../../../../__old__/v2/feature/calculation/functions/convert-wt-percent';
import { CalculationsRecord } from '../../../../__old__/v2/feature/projection/helpers/calculation-values';
import {
  stockConcentrationTableAgentCEB,
  stockConcentrationTableCEB,
} from '../../../../__old__/v2/feature/solution/helpers/stock-concentration';
import { CalculationValue } from '../../../../__old__/v2/feature/solution/types/record';
import { faArrowToRight, faSpinner } from '@fortawesome/pro-light-svg-icons';

const validator = new DefaultValidator();

// ===============================================================================================================
// chemicals list
const chemicals = {
  'N/A': { name: 'N/A', validations: 'numberMax:0.00|numberMin:0.00' },
  D2: { name: 'Commercial', validations: 'numberMax:10000.00|numberMin:0.01' },
  HCl: { name: 'HCl', validations: 'numberMax:40.001|numberMin:0.01' },
  H2SO4: { name: 'H2SO4', validations: 'numberMax:100.00|numberMin:0.01' },
  HNO3: { name: 'HNO3', validations: 'numberMax:100.00|numberMin:0.01' },
  NaHSO3: { name: 'NaHSO3', validations: 'numberMax:100.00|numberMin:0.01' },
  NaOCl: { name: 'NaOCl', validations: 'numberMax:15.00|numberMin:0.01' },
  NaOH: { name: 'NaOH', validations: 'numberMax:50.00|numberMin:0.01' },
  H2O2: { name: 'H2O2', validations: 'numberMax:100.00|numberMin:0.01' },
  H3PO4: { name: 'H3PO4', validations: 'numberMax:100.00|numberMin:0.01' },
};

// all CEB settings in inputs object
const allCEBs = [
  { id: 1, ceb: 'ceb_1_a', agent: 'agent_1' },
  { id: 2, ceb: 'ceb_1_a', agent: 'agent_2' },
  { id: 3, ceb: 'ceb_1_b', agent: 'agent_1' },
  { id: 4, ceb: 'ceb_1_b', agent: 'agent_2' },
  { id: 5, ceb: 'ceb_2_a', agent: 'agent_1' },
  { id: 6, ceb: 'ceb_2_a', agent: 'agent_2' },
  { id: 7, ceb: 'ceb_2_b', agent: 'agent_1' },
  { id: 8, ceb: 'ceb_2_b', agent: 'agent_2' },
];

// ===============================================================================================================
// chemical input row
interface CebStockChemicalTextFieldProps {
  chemical: {
    name: string;
    validations: string;
  };
  wtValue: string;
  wtOnChange: (wtValue: string) => void;
  glValue: string;
  glOnChange: (glValue: string) => void;
  onCalculation: (calculating: boolean) => void;
  onError?: (id: string, error: string | false) => void;
  inCeb?: boolean;
}

const CebStockChemicalTextField: React.FC<CebStockChemicalTextFieldProps> = (props) => {
  // props
  const {
    chemical,
    wtValue: wtValueProp,
    wtOnChange,
    glValue: glValueProp,
    glOnChange,
    onCalculation,
    onError,
    // inCeb,
  } = props;

  // states
  const [wtValue, setWtValue] = useState<string>(
    wtValueProp || stockConcentrationTableCEB[chemical.name].toString() || '0'
  );
  const [glValue, setGlValue] = useState<string>(
    glValueProp || stockConcentrationTableCEB[chemical.name].toString() || '0'
  );
  const [calculating, setCalculating] = useState(false);
  const [error, setError] = useState<string | false>(false);

  // wt onChange
  const handleWtOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      setWtValue(value);
      wtOnChange && wtOnChange(value);
    },
    [wtOnChange]
  );

  // gl onChange
  const handleGlOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      setGlValue(value);
      glOnChange && glOnChange(value);
    },
    [glOnChange]
  );

  // wt onBlur
  const handleWtOnBlur = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      try {
        const value = event.target.value;
        setCalculating(true);
        const result = await convertWtPercent({
          variables: {
            inputs: JSON.stringify({
              id: chemical.name,
              wtpercent: parseFloat(value),
              type: 'chemical',
            }),
          },
        });
        const response = result?.data?.projectionCalculationConvertWtPct;
        if (response.status === 'error') {
          console.error('handleWtOnBlur error: failed to calculate g/l from wt%');
          setCalculating(false);
          return;
        }
        const glValue = response?.active_concentration?.toFixed(2);

        if (glValue) {
          setGlValue(glValue);
          glOnChange(glValue);
        } else {
          console.error('handleWtOnBlur error: No G/L Value received from request');
        }

        setCalculating(false);
      } catch (err) {
        console.error('handleWtOnBlur error:', err);
        setCalculating(false);
      }
    },
    [chemical.name, glOnChange]
  );

  // validations for wt% values
  useEffect(() => {
    if (wtValue) {
      const wtError: any = validator.validate(
        { value: wtValue },
        { value: chemical.validations ?? 'required|max:120' }
      );
      if (wtError && wtError.value) {
        setError(wtError.value);
        onError && onError(chemical.name, wtError);
      } else {
        setError(false);
        onError && onError(chemical.name, false);
      }
    }
  }, [wtValue, chemical.validations, chemical.name, onError]);

  // validations for g/l values
  useEffect(() => {
    if (glValue && chemical.name === 'Commercial') {
      // only validate on "Commercial chemicals (D2)"
      const glError: any = validator.validate(
        { value: glValue },
        { value: chemical.validations ?? 'required|max:120' }
      );
      if (glError && glError.value) {
        setError(glError.value);
        onError && onError(chemical.name, glError);
      } else {
        setError(false);
        onError && onError(chemical.name, false);
      }
    }
  }, [glValue, chemical.validations, chemical.name, onError]);

  // are we calculating? let the parent know
  useEffect(() => {
    calculating ? onCalculation && onCalculation(true) : onCalculation && onCalculation(false);
  }, [calculating, onCalculation]);

  return (
    <Grid container>
      <Grid item xs={12} sm={3} style={{ paddingRight: 16 }}>
        <TextField fullWidth value={chemical.name} margin={'dense'} />
      </Grid>
      <Grid item xs={12} sm={9}>
        <Box display={'flex'} style={{ maxWidth: '100%' }}>
          <Box display={'flex'}>
            <TextField
              value={wtValue}
              // style={{ width: '100%' }}
              fullWidth
              type={'number'}
              onChange={(e) => handleWtOnChange(e)}
              onBlur={(e) => handleWtOnBlur(e)}
              disabled={calculating}
              margin={'dense'}
            />
            <TextField
              value={'wt%'}
              style={{
                width: 45,
                paddingLeft: '4px',
              }}
              margin={'dense'}
            />
          </Box>
          <FontAwesomeIcon
            spin={calculating}
            icon={calculating ? faSpinner : faArrowToRight}
            style={{
              margin: '22px 12px',
              marginBottom: 0,
              opacity: calculating ? 0.5 : 1,
            }}
          />
          <Box display={'flex'}>
            <TextField
              value={glValue}
              style={{ width: '100%' }}
              type={'number'}
              disabled={chemical.name !== 'Commercial'}
              onChange={(e) => handleGlOnChange(e)}
              margin={'dense'}
            />
            <TextField
              value={'g/l'}
              style={{
                width: 45,
                marginLeft: '4px',
              }}
              margin={'dense'}
            />
          </Box>
        </Box>
        {error && (
          <Grid item xs={12}>
            <FormHelperText error={Boolean(error)}>{error}</FormHelperText>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

// ===============================================================================================================
// main component props
export interface CebStockChemicalsProps {
  inputs: Partial<CalculationsRecord>;
  advisedInputs: Partial<CalculationsRecord>;
  onChange(calculationValue: CalculationValue): void;
  onError?(id: string, error: string | false): void;
  onIsCalculatingChange?(isCalculating: boolean): void;
  tabs: { value: string; label: string; disabled: boolean }[];
}

// main component
export const CebStockChemicalsV3: React.FC<CebStockChemicalsProps> = (props) => {
  // props
  const {
    inputs,
    // advisedInputs,
    onChange,
    onError,
    onIsCalculatingChange,
    // stockChemicalValues: propStockChemicalValues,
    // setStockChemicalValues,
  } = props;

  // handlers
  const handleWtOnChange = useCallback(
    (chemical: string, value: string) => {
      // find & update calculationvalue which uses the selected chemical
      allCEBs.forEach((ceb) => {
        // format objectkeys
        const coagulantKey = `${ceb.ceb}_chemical_${ceb.agent}`;
        const stockWTKey = `${ceb.ceb}_chemical_stock_concentration_${ceb.agent.replace('agent_', '')}_wtpercent`;

        // valid?
        if (!(coagulantKey in inputs)) {
          return;
        }

        // input set?
        const cebWTInput = inputs[stockWTKey];
        const cebSelectedChemical = inputs[coagulantKey].values[0].toString();

        // chemical the same as the one we are updating?
        if (cebSelectedChemical !== chemical) {
          return;
        }

        cebWTInput.values[0] = parseFloat(value);
        onChange && onChange(cebWTInput);
      });
    },
    [inputs, onChange]
  );

  // handlers
  const handleGlOnChange = useCallback(
    (chemical: string, value: string) => {
      // find & update calculationvalue which uses the selected chemical
      allCEBs.forEach((ceb) => {
        // format objectkeys
        const coagulantKey = `${ceb.ceb}_chemical_${ceb.agent}`;
        const stockGLKey = `${ceb.ceb}_chemical_stock_concentration_${ceb.agent.replace('agent_', '')}`;

        // valid?
        if (!(coagulantKey in inputs)) {
          return;
        }

        // input set?
        const cebGLInput = inputs[stockGLKey];
        const cebSelectedChemical = inputs[coagulantKey].values[0].toString();

        // chemical the same as the one we are updating?
        if (cebSelectedChemical !== chemical) {
          return;
        }

        // update value
        cebGLInput.values[0] = parseFloat(value);

        // trigger onChange
        onChange && onChange(cebGLInput);
      });
    },
    [inputs, onChange]
  );

  return (
    <Grid container style={{ maxWidth: 480 }}>
      {Object.keys(chemicals).map((chemicalKey) => {
        // the N/A chemical is not available here
        if (chemicalKey === 'N/A' || chemicalKey === 'null') {
          return null;
        }

        // set default values
        let wtValue = chemicalKey === 'D2' ? '0' : stockConcentrationTableCEB[chemicalKey];
        let glValue = chemicalKey === 'D2' ? '0' : stockConcentrationTableAgentCEB[chemicalKey];
        let inCEB = false;

        // look for the chemical in the ceb agent inputs and overwrite the default value
        allCEBs.forEach((ceb) => {
          // format keys
          const agentNr = ceb.agent === 'agent_1' ? '1' : '2';
          const coagulantKey = `${ceb.ceb}_chemical_${ceb.agent}`;

          // value is not set or value does not match the current chemical; skip this CEB
          if (
            !(coagulantKey in inputs) ||
            inputs[coagulantKey].values.length < 1 ||
            inputs[coagulantKey].values[0].toString() !== chemicalKey
          ) {
            return;
          }

          // object keys of where the chemical wt% & g/l are stored in inputs
          const inputGLKey = `${ceb.ceb}_chemical_stock_concentration_${agentNr}`;
          const inputWTKey = `${ceb.ceb}_chemical_stock_concentration_${agentNr}_wtpercent`;

          // is the g/l value set in inputs
          if (
            inputGLKey in inputs &&
            inputs[inputGLKey].values &&
            inputs[inputGLKey].values.length > 0 &&
            typeof inputs[inputGLKey].values[0] !== 'undefined'
          ) {
            const val = inputs[inputGLKey].values[0].toString();
            if (val !== 'null') {
              glValue = val;
            }
          }

          // is the wt% value set in inputs
          if (
            inputWTKey in inputs &&
            inputs[inputWTKey].values &&
            inputs[inputWTKey].values.length > 0 &&
            typeof inputs[inputWTKey].values[0] !== 'undefined'
          ) {
            const val = inputs[inputWTKey].values[0].toString();
            inCEB = true;
            if (val !== 'null') {
              wtValue = val;
            }
          }
        });

        return (
          <Fragment key={chemicalKey}>
            <CebStockChemicalTextField
              chemical={chemicals[chemicalKey]}
              onCalculation={(isCalculating) => onIsCalculatingChange && onIsCalculatingChange(isCalculating)}
              glOnChange={(value) => handleGlOnChange(chemicalKey, value)}
              glValue={glValue}
              wtValue={wtValue}
              wtOnChange={(value) => handleWtOnChange(chemicalKey, value)}
              onError={onError}
              inCeb={inCEB}
            />
          </Fragment>
        );
      })}
    </Grid>
  );
};
export default CebStockChemicalsV3;
