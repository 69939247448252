import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

interface OwnProps {
  title: React.ReactNode;
  description?: React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.main,
    fontSize: 20,
  },
}));

export default function InfoLabel({ title, description }: OwnProps) {
  const classes = (useStyles as any)();

  if (!description) {
    return <React.Fragment>{title}</React.Fragment>;
  }

  return (
    <Tooltip title={description}>
      <span>
        <FontAwesomeIcon icon={faInfoCircle} className={classes.icon} />
        {title}
      </span>
    </Tooltip>
  );
}
