import { UserLegalFragment } from "./fragments";

export const CreateUserLegal = `
    mutation createUserLegal($input: inputUserLegal!) {
        createUserLegal(input: $input) {
            ${UserLegalFragment}
        }
    }
`;

export const UpdateUserLegal = `
    mutation updateUserLegal($input: inputUserLegal!) {
        updateUserLegal(input: $input) {
            ${UserLegalFragment}
        }
    }
`;

export const DeleteUserLegal = `
    mutation deleteUserLegal($id:ID!) {
        deleteUserLegal(id:$id){
            ${UserLegalFragment}
        }
    }
`;
