import { useObserver } from 'mobx-react';
import * as React from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { AuthRoute } from './auth/auth-route';
import { RoutesPresenter } from './routes-presenter';
import { usePresenter } from '../hooks/use-presenter';
import { PageContainer } from './components/page/page-container';
import { DefaultInterface } from './interface/default-interface';
import { ScrollToTop } from '../v2/common/ui/scroll-to-top';
import PrintPreview from '../../feature/pdf/components/PrintPreview';
import { PageLoading } from '../v2/common/pages/page-loading';
import Error404 from './pages/errors/error-404';
import { useAuth } from '../../feature/auth/auth-provider';
// import { QuickProfileSwitcher } from '../../feature/debug-mode/quick-profile-switcher';

export const Routes: React.FC = (props) => {
  const presenter = usePresenter(({ provider }) => new RoutesPresenter(provider.routes));

  const { user } = useAuth();

  let location = useLocation();
  presenter.validateLocation(location.pathname);

  // sigh.. because of the auto route thingy, cannot use a normal route, so breaking/bypassing it!
  let routeMatch = useRouteMatch('/print/project/:projectId/projection/:projectionid');

  return useObserver(() => {
    const { routes, redirect, redirectLocation } = presenter;

    // need to go outside al the shit below, because we do NOT want any menu's etc
    if (routeMatch && routeMatch.isExact) {
      return (
        <Switch>
          <Route exact path="/print/project/:projectId/projection/:projectionid">
            {/* The component needs to be within a <switch><route> tag; or else it cannot get url params via useParams() */}
            <PrintPreview />
          </Route>
          <Route path="*">
            <Error404 />
          </Route>
        </Switch>
      );
    }

    if (redirect && redirectLocation) {
      return <Redirect to={redirectLocation} />;
    }

    return (
      <>
        <React.Suspense
          fallback={
            <div
              style={{
                display: 'flex',
                height: '100vh',
                alignItems: 'center',
                justifyContent: 'center',
                background: '#FFFFFF',
              }}
            >
              <DefaultInterface />
              <PageLoading message="Loading page" />
            </div>
          }
        >
          <Switch>
            {routes.map(
              (route) => user && <Route key={route.path} exact path={route.path} component={DefaultInterface} />
            )}
          </Switch>
          <ScrollToTop>
            <PageContainer>
              <Switch>
                {routes &&
                  routes.map((route) =>
                    route.public ? (
                      <Route
                        key={route.path}
                        exact={route.pathNotExact ? false : true}
                        path={route.path}
                        component={route.component}
                      />
                    ) : (
                      <AuthRoute key={route.path} exact path={route.path} component={route.component} route={route} />
                    )
                  )}
              </Switch>
            </PageContainer>
          </ScrollToTop>
        </React.Suspense>
      </>
    );
  });
};

export default Routes;
