import { computed, observable } from "mobx";
import { Presenter } from "../../hooks/use-presenter";
import { ToolConfig } from "../../config/components/interface";
import { UserInteractor } from "../../application/resources/user/user-interactor";
import { User } from "../../application/resources/user/user";
import { InterfaceInteractor } from "../../application/business/interactor/interface-interactor";

export enum InterfaceTools {
  // Menu = "menu",
  // Homepage = "homepage",
  Notifications = "notifications",
  PageInfo = "page-info",
  // Support = "support",
  SignOut = "sign-out",
  None = "not-set",
}

export interface InterfaceData {
  user: User | undefined;
  notifications: Notification[];
  pages: any[];
}

export interface InterfaceActions {
  userLogout(): void;
  notificationSelect(): void;
  notificationDismiss(): void;
}

export class InterfacePresenter implements Presenter {
  @observable public config: Record<InterfaceTools, ToolConfig>;
  public data: InterfaceData = {} as any;
  public actions: InterfaceActions = {} as any;

  @computed public get user(): User | null {
    try {
      return this._userInteractor.currentUser;
    } catch {
      return null;
    }
  }

  @computed public get legalAgreements(): boolean {
    return this._userInteractor.legalAgreements;
  }

  public get activeTool() {
    return this._interfaceInteractor.activeTool;
  }

  public set activeTool(tool: InterfaceTools) {
    this._interfaceInteractor.activeTool = tool;
  }

  constructor(
    private _userInteractor: UserInteractor,
    private _interfaceInteractor: InterfaceInteractor
  ) {
    this.config = _interfaceInteractor.config;
  }

  public mount = async () => {
    if (this.user) {
      this.data = {
        user: this.user,
        notifications: [],
        pages: [],
      };
    }

    this.actions = {
      userLogout: this.logout,
      notificationDismiss: () => {},
      notificationSelect: () => {},
    };

    this.activeTool = InterfaceTools.None;
  };

  public unmount() {
    // None
  }

  public toggleTool = (tool: InterfaceTools) => {
    this.activeTool = this.activeTool !== tool ? tool : InterfaceTools.None;
  };

  public logout = () => {
    if (this.user) {
      this._userInteractor.logout();
    }
  };
}
