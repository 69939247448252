import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { RouteProvider } from '../../../application/business/provider/routes';

import { useProvider } from '../../../hooks/use-provider';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';

interface OwnProps {
  classes?: Record<'root', string>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  crumbWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  crumbSpacerIcon: {
    color: theme.palette.softGray.main,
    height: theme.spacing(2),
    width: theme.spacing(1.5) + 'px !important',
    opacity: 0.3,
    marginRight: theme.spacing(1),
  },
  crumb: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
  },
  crumbLink: {
    textDecoration: 'none',
    color: theme.palette.silverGray.main,
  },
}));

export const Breadcrumbs: React.FC<OwnProps> = (props) => {
  const classes = (useStyles as any)(props);
  const provider = useProvider<RouteProvider<any>>('routes');
  const match = useRouteMatch();

  if (!match) {
    return null;
  }
  const currentRoute = provider.getRouteFromPath(match.path);

  if (!currentRoute) {
    return null;
  }

  const resolvedBreadcrumb = currentRoute.breadcrumbs.map((cfg) => {
    const route = provider.getOptions(cfg.route);
    let label: string = '';
    if (typeof cfg.label === 'function') {
      let val = cfg.label({ match });
      if (typeof val === 'string') {
        label = val;
      }
    }

    // remove any params from the url, if specified
    // use in case of a component handling routes
    let params = {};
    if (cfg.paramsToRemoveFromRoute && cfg.paramsToRemoveFromRoute.length > 0) {
      const list = cfg.paramsToRemoveFromRoute;
      Object.keys(match.params).forEach((paramKey) => {
        if (!list.includes(paramKey)) {
          params[paramKey] = match.params[paramKey];
        }
      });
    } else {
      params = match.params;
    }

    // Augment params to include custom params passed in the breadcrumbs if it exists
    if (cfg?.params) {
      const customParams = Object.assign({}, cfg?.params);
      params = { ...params, ...customParams };
    }

    return {
      href: provider.generatePath(cfg.route, params),
      label: label || cfg.label || route.title,
    };
  });

  return (
    <div className={classes.crumbWrap}>
      {resolvedBreadcrumb.map((breadcrumb, index) => {
        if (!breadcrumb.label || typeof breadcrumb.label !== 'string') {
          return null;
        }
        return (
          <div className={classes.crumb} key={index}>
            <FontAwesomeIcon className={classes.crumbSpacerIcon} icon={faChevronRight} />

            <Link to={breadcrumb.href} className={classes.crumbLink}>
              <Typography variant="h5">{breadcrumb.label as any}</Typography>
            </Link>
          </div>
        );
      })}
    </div>
  );
};
