import { Box, BoxProps, Typography } from '@mui/material';
import * as React from 'react';
import { CalculationValue } from '../../feature/solution/types/record';

interface OwnProps extends BoxProps {
  property?: string;
  value?: any;
  horizontal?: boolean;
  calculationValue?: CalculationValue;
  error?: boolean;
}

export const HeaderPropertyValue: React.FC<OwnProps> = (props) => {
  const { calculationValue, property: propertyOveride, value: valueOverride, horizontal, error, ...boxProps } = props;

  const value =
    (valueOverride || calculationValue?.values?.[0] || valueOverride) + (calculationValue?.unit || '') || '';

  const property = propertyOveride || calculationValue?.name || '';

  return (
    <Box {...boxProps} color={error ? 'error.main' : undefined}>
      <Typography variant="body2" color="inherit">
        <Typography variant="body2" component="strong" fontWeight={600} color={'inherit'} marginRight="4px">
          {property}:
        </Typography>
        {!horizontal && <br />}
        {value}
      </Typography>
    </Box>
  );
};
