import { observable } from "mobx";
import { BaseModel, Model } from "../general/base-model";
import { GraphQLProvider } from "./graphql-provider";
import { Provider } from "../types";
import { RecordData } from "../general/store";

export interface GraphQLModel<TRecord extends RecordData>
  extends Model<TRecord> {
  loading: boolean;
  fetched: boolean;
  isNew: boolean;
  fetch(version?: string): Promise<void>;
  save(): Promise<void>;
  delete(): Promise<void>;
  serialize(data?: TRecord): TRecord;
  provider: Provider<TRecord>;
}

export abstract class BaseGraphQLModel<
  TRecord extends RecordData
> extends BaseModel<TRecord, GraphQLProvider<TRecord>> {
  defaultValues: TRecord = {} as TRecord;

  @observable public loading: boolean = false;
  @observable public fetched: boolean = false;
  @observable public isNew: boolean = false;

  // constructor(
  //   _record: TRecord,
  //   _provider?: GraphQLProvider<TRecord> | undefined
  // ) {
  //   super(_record, _provider);
  // }

  public fetch = async (version?: string) => {
    if (this._provider) {
      this.loading = true;
      this.fetched = true;
      await this._provider.fetch(this, version);
      this.loading = false;
    }
  };

  public save = async () => {
    if (this._provider) {
      this.loading = true;
      if (this.isNew) {
        await this._provider.save(this);
        this.isNew = false;
      } else {
        await this._provider.update(this);
      }
      this.loading = false;
    }
  };

  public delete = async () => {
    if (this._provider) {
      this.loading = true;
      await this._provider.delete(this);
      this.loading = false;
    }
  };
}
