import { toDisplayUnit } from '../../../../../components/form/fields/projection-calculation-value-convert';
import { toFixedIfNecessary } from '../../../../../../application/resources/helper/to-fixed';
import { ProjectionClass } from '../../../../../../application/resources/projection/v2/single';

export const projectionValueToString = (key: string, projection: ProjectionClass, egu?: string) => {
  const data = [
    // ...(projection.record.advisedInputs || []),
    ...(projection.record.inputs || []),
    ...(projection.record.outputs || []),
  ];
  const calculationValue = data.find((item) => item.id === key);

  let unit = (calculationValue && calculationValue.unit && ' ' + calculationValue.unit) || '';

  if (calculationValue && calculationValue.valueOptions && calculationValue.valueOptions.length > 0) {
    const valueToReturn = calculationValue.valueOptions.find(
      (valueOption) => valueOption.id === calculationValue.values[0]
    );

    if (valueToReturn) {
      return (valueToReturn.label || '') + unit;
    }
  }

  if (egu === 'imperial') {
    if (calculationValue && calculationValue.unitOptions && (calculationValue.unitOptions || []).length > 1) {
      const displayUnit = calculationValue.unitOptions[calculationValue.unitOptions.length - 1];
      calculationValue.displayUnit = displayUnit;
      unit = ' ' + displayUnit;

      const value = toDisplayUnit(calculationValue, parseFloat(String(calculationValue.values[0])));

      let decimals = calculationValue.decimals || 0;

      let roundedValue = toFixedIfNecessary(String(value), decimals);
      let maxDecimals = 6;

      // if value is higher than 0, but rounds to 0, add more decimals
      // pxx-1425
      while (roundedValue === 0 && parseFloat(String(value)) > 0 && maxDecimals >= decimals) {
        decimals++;
        roundedValue = toFixedIfNecessary(String(value), decimals);
      }

      if (roundedValue === 'NaN') {
        console.log('We have a NaN on:', calculationValue.id, 'used value:', String(calculationValue.values[0]));
      }

      return roundedValue + unit;
    }
  }

  return ((calculationValue && calculationValue.values[0]) || '') + unit;
};
