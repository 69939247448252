/*

  By Code.Rehab

*/

import { useState, useCallback } from 'react';
import { API, graphqlOperation } from '@aws-amplify/api';
import { GetVersion } from '../graphql-queries/version-query';
import { amplify } from '../../../__old__/config/amplify';
import { generateHeader } from '../../../api/api';

export const useVersion = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [version, setVersion] = useState<string>();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);

      const config: any = {
        aws_appsync_graphqlEndpoint: amplify.aws_appsync_graphqlEndpoint,
        aws_appsync_region: amplify.aws_appsync_region,
        aws_appsync_authenticationType: amplify.Auth.authenticationFlowType,
        graphql_headers: generateHeader,
      };
      API.configure(config);

      // do request
      const result: any = await API.graphql(graphqlOperation(GetVersion, {}));

      if (result && result.errors) {
        console.error('useVersion fetchData() getVersion error:', result.errors);
        setLoading(false);
        return false;
      }

      if (result && result.data && result.data.getXpertVersion) {
        setLoading(false);
        setVersion(result.data.getXpertVersion.version || '--');
        return result.data.getXpertVersion.version;
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error('useVersion fetchData() error:', err);
      return false;
    }
  }, []);

  const updateData = useCallback(async (version: string) => {
    try {
      setLoading(true);

      const config: any = {
        aws_appsync_graphqlEndpoint: amplify.aws_appsync_graphqlEndpoint,
        aws_appsync_region: amplify.aws_appsync_region,
        aws_appsync_authenticationType: amplify.Auth.authenticationFlowType,
        graphql_headers: generateHeader,
      };
      API.configure(config);

      const result: any = await API.graphql(
        graphqlOperation(
          `
            mutation UpdateVersion($version: String) {
                updateXpertVersion(version: $version) {
                lastModified
                modifiedBy
                version
                }
            }`,
          { version: version }
        )
      );

      if (result && result.errors) {
        console.error('useVersion updateData() getVersion error:', result.errors);
        setLoading(false);
        return false;
      }

      if (result && result.data && result.data.updateXpertVersion) {
        setLoading(false);
        setVersion(result.data.updateXpertVersion.version);
        return result.data.updateXpertVersion.version;
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error('useVersion updateData() error:', err);
      return false;
    }
  }, []);

  return {
    loading: loading,
    fetch: fetchData,
    update: updateData,
    version: version,
  };
};
