//import { graphqlOperation } from "aws-amplify";
import { graphqlOperation } from "@aws-amplify/api";
import {
  BaseGraphQLProvider,
  GraphQLProvider,
} from "../../base/graphql/graphql-provider";
// import { GraphQLAPI } from "../../base/types";
import { DefaultProject, Project, ProjectRecord } from "./project";
import * as Query from "./graphql/queries";
import * as Mutation from "./graphql/mutations";

export interface ProjectProvider
  extends GraphQLProvider<ProjectRecord, Project> {
  listByFilter(filter: { key: string; value: string }[]): Promise<Project[]>;
}

export class DefaultProjectProvider
  extends BaseGraphQLProvider<ProjectRecord, Project>
  implements ProjectProvider {
  // constructor(_api: GraphQLAPI) {
  //   super(_api);
  // }

  public model = DefaultProject;

  public createOperation = (project: Project) => {
    return graphqlOperation(Mutation.Create, {
      input: { ...project.serialize() },
    });
  };

  public fetchOperation = (project: Project) => {
    return graphqlOperation(Query.Project, {
      id: project.id,
    });
  };

  public updateOperation = (project: Project) => {
    return graphqlOperation(Mutation.Update, { input: project.serialize() });
  };

  public deleteOperation = (project: Project) => {
    return graphqlOperation(Mutation.Delete, { id: project.id });
  };

  public listOperation = () => {
    return graphqlOperation(Query.ProjectCollection, {});
  };

  public listByFilter = async (filter: { key: string; value: string }[]) => {
    let projects: Project[] = [];
    const operation = graphqlOperation(Query.ProjectCollectionWithFilter, {
      filter,
    });
    const result: Project[] = await this.query(null, operation);

    if (result.length) {
      projects = result.map((project) => this.createInstance(project));
    }

    return projects;
  };
}
