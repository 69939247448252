export const typographyVariants = {
  h1: 'h1' as 'h1',
  h2: 'h2' as 'h2',
  h3: 'h3' as 'h3',
  h4: 'h4' as 'h4',
  h5: 'h5' as 'h5',
  h6: 'h6' as 'h6',
  body1: 'body1' as 'body1',
  body2: 'body2' as 'body2',
  condensed: {
    h1: 'h1_condensed' as 'h1',
    h2: 'h2_condensed' as 'h2',
    h3: 'h3_condensed' as 'h3',
    h4: 'h4_condensed' as 'h4',
    h5: 'h5_condensed' as 'h5',
    h6: 'h6_condensed' as 'h6',
  },
};

export const typographyMapping = {
  [typographyVariants.condensed.h1]: 'h1',
  [typographyVariants.condensed.h2]: 'h2',
  [typographyVariants.condensed.h3]: 'h3',
  [typographyVariants.condensed.h4]: 'h4',
  [typographyVariants.condensed.h5]: 'h5',
  [typographyVariants.condensed.h6]: 'h6',
};

export const themeConfig = {
  typography: {
    body1: { fontSize: '1.1em' },
    body2: { fontSize: '1em' },
    h1: {
      fontFamily: "'Barlow', sans-serif",
      fontWeight: 700,
      fontStyle: 'normal',
      color: 'inherit',
      fontSize: '2.125em',
      lineHeight: '1',
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: "'Barlow', sans-serif",
      fontWeight: 600,
      fontStyle: 'normal',
      textTransform: 'uppercase',
      color: 'inherit',
      fontSize: '1.5625em',
      lineHeight: '1',
    },
    h3: {
      fontFamily: "'Barlow', sans-serif",
      fontWeight: 600,
      fontStyle: 'normal',
      textTransform: 'uppercase',
      color: 'inherit',
      fontSize: '1.25em',
      lineHeight: '1',
    },
    h4: {
      fontFamily: "'Barlow', sans-serif",
      fontWeight: 600,
      fontStyle: 'normal',
      textTransform: 'uppercase',
      color: 'inherit',
      fontSize: '1em',
      lineHeight: '1',
    },
    h5: {
      fontWeight: 600,
      fontFamily: "'Barlow', sans-serif",
      fontStyle: 'normal',
      textTransform: 'uppercase',
      color: 'inherit',
      fontSize: '0.875em',
      lineHeight: '1em',
    },
    h6: {
      fontFamily: "'Barlow', sans-serif",
      fontWeight: 600,
      fontStyle: 'normal',
      textTransform: 'uppercase',
      color: 'inherit',
      fontSize: '0.75em',
      lineHeight: '1',
    },
    h1_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 300,
      fontStyle: 'normal',
      color: 'inherit',
      fontSize: '2.625em',
      lineHeight: '3.125em',
      textTransform: 'uppercase',
    },
    h2_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 300,
      fontStyle: 'normal',
      color: 'inherit',
      fontSize: '2.125em',
      lineHeight: '1',
      textTransform: 'uppercase',
    },
    h3_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 300,
      fontStyle: 'normal',
      color: 'inherit',
      fontSize: '1.62em',
      lineHeight: '1',
      textTransform: 'uppercase',
    },
    h4_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 300,
      fontStyle: 'normal',
      color: 'inherit',
      fontSize: '1.25em',
      lineHeight: '1',
      textTransform: 'uppercase',
    },
    h5_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 300,
      fontStyle: 'normal',
      color: 'inherit',
      fontSize: '1em',
      lineHeight: '1',
      textTransform: 'uppercase',
    },
    h6_condensed: {
      fontFamily: "'Barlow Condensed', sans-serif",
      fontWeight: 300,
      fontStyle: 'normal',
      color: 'inherit',
      fontSize: '0.875em',
      lineHeight: '1',
      textTransform: 'uppercase',
    },
    subtitle1: {},
    subtitle2: {},
    caption: {},
    overline: {},
    button: {
      fontWeight: 600,
      lineHeight: 1,
      textTransform: 'uppercase',
      textDecoration: 'none',
    },
  },
};

export const typography = (key: keyof typeof themeConfig.typography) => {
  return themeConfig.typography[key] as any;
};
