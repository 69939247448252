/**
 *
 *
 *
 */

// import gql from 'graphql-tag';

export const fetchMembraneElementsQuery = `
  query MembraneTypes($solution: String!) {
    MembraneTypes(solution: $solution) {
      error
      membranes
      status
    }
  }
`;
