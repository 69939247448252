import gql from 'graphql-tag';
import { useCallback } from 'react';
import { runQuery, RunQueryOptions } from '../../../../network/run-query';
import { ProjectionCalculationCreateFragment } from '../../graphql/fragments';

interface ProjectionCalculationCreateResult {
  message: string;
  state: string;
  status: ProjectionCalculationStatus;
}

enum ProjectionCalculationStatus {
  OK = 'ok',
  ERROR = 'error',
}

interface QueryProjectionCalculationCreateArgs {
  inputs: string;
}

const buildQuery = (fragment: string = ProjectionCalculationCreateFragment) => gql`
  mutation ProjectionCalculationCreate($inputs: AWSJSON!) {
    projectionCalculationCreate(inputs: $inputs) {
      ${fragment}
    }
  }
`;

export const projectionCalculationCreate = async (
  options: RunQueryOptions<QueryProjectionCalculationCreateArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;

  return await runQuery<
    QueryProjectionCalculationCreateArgs,
    {
      projectionCalculationCreate: ProjectionCalculationCreateResult;
    }
  >({ query, variables: options.variables });
};

export const useProjectionCalculationCreate = () => {
  return useCallback(async (inputs: string) => {
    const res = await projectionCalculationCreate({
      variables: { inputs },
      query: { fragment: ProjectionCalculationCreateFragment },
    });

    return res.data && res.data.projectionCalculationCreate;
  }, []);
};
