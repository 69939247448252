import * as React from 'react';
import { Route } from 'react-router-dom';

import { CanView } from './can-view';
import { ErrorUnauthorized } from '../pages/errors/error-unauthorized';
import { Box } from '@mui/material';

export const AuthRoute: React.FC<any> = (props) => {
  return (
    <Box flex="1" display={'flex'} position="relative">
      <CanView permission={props.route.requiredPermissions} UnauthorizedComponent={<ErrorUnauthorized />}>
        <Route {...props} />
      </CanView>
    </Box>
  );
};
