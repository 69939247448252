import { UserFragment } from "./fragments";

export const CreateUser = `
mutation CreateUser($input:inputUser!) {
  createUser(input:$input){
    ${UserFragment}
  }
}`;

export const UpdateUser = `
mutation updateUser($input:inputUser!) {
  updateUser(input:$input) {
    ${UserFragment}
  }
}
`;

export const DeleteUser = `
mutation DeleteUser($id:ID!) {
  deleteUser(id:$id){
    ${UserFragment}
  }
}
`;
