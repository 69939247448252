export const SolutionFragment = `
id
name
oneliner
active
description
image
techImage
comparableTechnologies
order
inputs {
  id
  name
  values
  recommendedValues
  unit
  unitOptions
  valueOptions {
    id
    label
  }
  disabled
  error
  category
  decimals
  calculation_field
  tooltip
}
outputs {
  id
  name
  values
  recommendedValues
  unit
  unitOptions
  valueOptions {
    id
    label
  }
  disabled
  error
  category
  decimals
  calculation_field
  tooltip
}
techImageMap {
  type
  icon
  title
  description
  location
  number
  info_label
  values
  dialog
  key
  rotation
  content
  height
  width
  arrow_position
  splitKeyValue
  centeredText
}
`;
