import { GraphQLResult } from '@aws-amplify/api-graphql';
import { application } from '../application';
interface QueryBuildOptions {
  fragment?: string;
  buildQuery?(fragment?: string): string;
}

export interface RunQueryOptions<TVariables = any> {
  query?: QueryBuildOptions;
  variables?: TVariables;
}

export const runQuery = async <TVariables extends Record<string, any>, TRecord extends Record<string, any>>({
  query,
  variables,
}: RunQueryOptions<TVariables>): Promise<GraphQLResult<TRecord>> => {
  if (!query || !query.buildQuery) {
    return {
      data: undefined,
    };
  }

  try {
    return await application.network.appsync.fetchv2(undefined, query.buildQuery(query.fragment), variables);
  } catch (error: any) {
    return {
      data: error.data,
      errors: error.errors,
    };
  }
};
