import {
  GraphQLModel,
  BaseGraphQLModel,
} from "../../base/graphql/graphql-model";
import { v4 as uuid } from "uuid";

export type ProfilePermissions = {
  allow: string[];
  deny: string[];
};

export type ProfileRecord = {
  id: string;
  name: string;
  description: string;
  permissions: ProfilePermissions;
};

export interface Profile extends GraphQLModel<ProfileRecord> {
  resolved: boolean;
  changePermission(key: keyof ProfilePermissions, value: string[]): void;
  checkPermissions(permission: string): boolean;
}

export class DefaultProfile
  extends BaseGraphQLModel<ProfileRecord>
  implements Profile {
  public resolved: boolean = false;

  public defaultValues = {
    id: uuid(),
    name: "Default name",
    description: "",
    permissions: {
      allow: [],
      deny: [],
    },
  };

  public checkPermissions = (_requested: string): boolean => {
    return true;
  };

  public changePermission = (
    key: keyof ProfilePermissions,
    value: string[]
  ) => {
    this.changes.permissions =
      this.record.permissions || this.changes.permissions || {};
    this.changes.permissions[key as string] = value;
  };
}
