import * as React from 'react';
import { makeStyles } from '@mui/styles';

import { ToolBar } from './partials/toolbar';
import { TopBar } from './partials/topbar';
import { useAuth } from '../../../feature/auth/auth-provider';

interface OwnProps {}

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    display: 'flex',
    alignItems: 'flex-start',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 10,
    pointerEvents: 'none',
    '& > *': {
      pointerEvents: 'auto',
    },
  },
}));

export const DefaultInterface: React.FC<OwnProps> = (props) => {
  const classes = useStyles(props);
  const { user } = useAuth();
  const legalAgreements = true;

  if (!user || !legalAgreements) {
    return null; //<PageLoading message="Retrieving User & Legal Information" />;
  }

  return (
    <div className={classes.root}>
      <ToolBar />
      <TopBar />
    </div>
  );
};
