import {
  GraphQLModel,
  BaseGraphQLModel,
} from "../../base/graphql/graphql-model";
import { CalculationValueOptions } from "./calculation-values";
import { v4 as uuid } from "uuid";
import { computed } from "mobx";
import { CalculationValue } from "../../../v2/feature/solution/types/record";

export interface TechnologyRecord {
  id: string;
  image: string;
  oneliner: string;
  techImage: Array<string>;
  techImageMap: Array<any>;
  name: string;
  active: boolean;
  description: string;
  inputs: CalculationValue[];
  comparableTechnologies: string[];
}

export interface Technology extends GraphQLModel<TechnologyRecord> {
  value(id: CalculationValueOptions): any;
  valueMap: any;
}

export class TechnologyModel
  extends BaseGraphQLModel<TechnologyRecord>
  implements Technology {
  public typename: string = "Technology";
  public useMock: boolean = false;

  public defaultValues: TechnologyRecord = {
    id: uuid(),
    name: "Some technology",
    active: false,
    image: "",
    oneliner: "Unknown",
    techImage: [],
    description: "Some description",
    inputs: [],
    techImageMap: [],
    comparableTechnologies: [],
  };

  public value = (id: CalculationValueOptions) => {
    return {
      ...this.valueMap[id],
    };
  };

  @computed public get valueMap() {
    return this.record.inputs.reduce((result, valueData: CalculationValue) => {
      if (valueData.id) {
        result[valueData.id] = valueData;
      }
      return result;
    }, {} as Record<CalculationValueOptions, CalculationValue>);
  }
}
