import { Presenter } from "../../../hooks/use-presenter";
import { InterfaceInteractor } from "../../../application/business/interactor/interface-interactor";
import { computed } from "mobx";
import {
  Message,
  MessageInteractor,
} from "../../../application/business/interactor/message-interactor";

export class PageContainerPresenter implements Presenter {
  @computed public get interfaceActive(): boolean {
    return this._interfaceInteractor.activated && this._interfaceInteractor.visible;
  }

  @computed public get toolWidth(): number {
    return this._interfaceInteractor.activeToolConfig.width;
  }

  @computed public get messages(): Message[] {
    return this._messageInteractor.messages;
  }

  constructor(
    protected _interfaceInteractor: InterfaceInteractor,
    protected _messageInteractor: MessageInteractor
  ) {
    //
  }

  public mount = () => {};
  public unmount = () => {};
}
