import { UserFragment } from "./fragments";

export const User = `
query User($id:ID!) {
  User(id:$id) {
    ${UserFragment}
  }
}`;

export const UserCollection = `
query UserCollection {
  UserCollection {
    ${UserFragment}
  }
}`;
