/**
 *
 *
 *
 *
 */

import { useCallback, useEffect, useState } from 'react';
import { CalculationValueOptions } from '../types/calculation-value';
import { fetchMembraneElementsQuery } from '../graphql-queries/fetch-membrane-elements';
import { API, GraphQLResult, graphqlOperation } from '@aws-amplify/api';

export const membraneElements = {};

export const useMembraneElements = (solution: 'aquaflex' | 'xline' | 'xiga' | 'aquaflex-hs' | string = 'aquaflex') => {
  const [loading, setLoading] = useState<boolean>(false);
  const [membraneElements, setMembraneElements] = useState<CalculationValueOptions[] | undefined>(undefined);

  // fetch membrane function
  const fetchMembraneElements = useCallback(async () => {
    try {
      setLoading(true);

      // solution id => solution name
      const solutionName = () => {
        if (['aquaflex', 'aquaflex-new'].includes(solution)) {
          return 'AquaFlex';
        } else if (solution === 'xline') {
          return 'XLine';
        } else if (solution === 'xiga') {
          return 'XIGA';
        } else if (solution === 'aquaflex-hs') {
          return 'AquaFlex HS';
        }
        return 'AquaFlex';
      };

      // fetch
      const apiResult = (await API.graphql(
        graphqlOperation(fetchMembraneElementsQuery, { solution: solutionName() })
      )) as GraphQLResult<any>;

      // errors?
      if (apiResult.errors) {
        throw new Error(apiResult.errors.join(',\n'));
      }

      // we have results
      if (apiResult.data && apiResult.data.MembraneTypes && apiResult.data.MembraneTypes.membranes) {
        const fetchedMembranes = JSON.parse(apiResult.data.MembraneTypes.membranes);
        const parsedMembranes: CalculationValueOptions[] = [];
        fetchedMembranes.forEach((membrane) => {
          if (membrane.commercialName !== 'Custom') {
            // set membranes
            parsedMembranes.push({
              id: membrane.commercialName,
              label: membrane.commercialName,
            });
          }
        });
        // const sorted = parsedMembranes.sort((a,b) => )
        setMembraneElements(parsedMembranes);
        setLoading(false);
        return parsedMembranes;
      }

      // we received nothing
      throw new Error('No results from API');
    } catch (err) {
      console.error('useMembraneElements() error', err);
      setMembraneElements([]);
      setLoading(false);
      return undefined;
    }
  }, [solution]);

  // fetch membrane elements from api
  useEffect(() => {
    if (!membraneElements) {
      fetchMembraneElements();
    }
  }, [fetchMembraneElements, membraneElements]);

  // useEffect(() => {
  //   console.log('membraneElements', membraneElements);
  // }, [membraneElements]);

  return {
    loading,
    membraneElements,
  };
};
export default useMembraneElements;
