/*

  By Code.Rehab

*/

import { Box, Theme } from '@mui/material';
import * as React from 'react';

import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { ProjectRecord } from '../../../__old__/application/resources/project/project';
import { ProjectionRecord } from '../../../__old__/application/resources/projection/projection';

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  logo: {
    display: 'inline-block',
    width: 'calc(30% - 26px)',
    verticalAlign: 'top',
    padding: 13,
  },
  titles: {
    marginTop: theme.spacing(0.5),
    display: 'inline-block',
    width: '70%',
    verticalAlign: 'top',
    textAlign: 'right',
  },
  bar: {
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    display: 'block',
    '& > span': {
      display: 'inline-block',
      marginRight: theme.spacing(2),
    },
    width: '100%',
  },
  text: {
    fontFamily: "'Barlow', Arial, Helvetica, FreeSans, sans-serif",
    fontSize: '11px',
    margin: 0,
  },
}));

type OwnProps = {
  project: Partial<ProjectRecord> | undefined;
  projection: Partial<ProjectionRecord> | undefined;
};

export const PrintPreviewHeader: React.FC<OwnProps> = (props) => {
  const classes = (useStyles as any)();
  const { project, projection } = props;
  const [accountName, setAccountName] = React.useState('-');

  const findValue = React.useCallback(
    (id: string) => {
      if (projection && projection.inputs) {
        const inputValue = projection?.inputs.find((input) => input.id === id) || undefined;
        return inputValue && inputValue.values[0];
      }
      return undefined;
    },
    [projection]
  );

  // set accountname
  React.useEffect(() => {
    if (projection && project) {
      let account = '-';
      if (projection.createdBy) {
        account = projection.createdBy.attributes.account_name?.split(' ').slice(0, 2).join(' ') || '-';
      }
      if (project.opportunity) {
        if (project.opportunity.account.name !== 'N/A') {
          account = project.opportunity.account.name.split(' ').slice(0, 2).join(' ') || '-';
        }
      }
      if (account !== accountName) {
        setAccountName(account);
      }
    }
  }, [accountName, project, projection]);

  if (!project || !projection) {
    return null;
  }

  return (
    <>
      <div className={classes.root}>
        <span className={classes.logo}>
          <img src="/images/logo-x-flow.svg" height="36" alt="logo" />
        </span>
        <span className={classes.titles}>
          <Box
            component={'h1'}
            sx={{
              fontSize: '19px',
              color: 'primary.main',
              textTransform: 'uppercase',
              marginBottom: '2px',
            }}
          >
            {project.name?.substring(0, 36)}
            {project && project.name && project.name.length > 36 ? '...' : ''}
          </Box>
          <Box
            component={'h2'}
            sx={{
              fontSize: '18px',
              lineHeight: '17px',
              color: 'secondary.main',
              textTransform: 'uppercase',
              fontWeight: 300,
              marginTop: '2px',
              marginBottom: '2px',
            }}
          >
            {projection.name?.substring(0, 50)}
            {projection && projection.name && projection.name.length > 45 ? '...' : ''}
          </Box>
        </span>
      </div>

      <div className={classes.bar}>
        <span>
          <Box component={'p'} className={classes.text}>
            <strong>Account</strong>
          </Box>
          <Box component={'p'} className={classes.text}>
            {/* show account name on opportunity, if not present use createdBy accountname */}
            {accountName}
          </Box>
        </span>
        <span>
          <Box component={'p'} className={classes.text}>
            <strong>Opportunity &#8470;</strong>
          </Box>
          <Box component={'p'} className={classes.text}>
            {project.opportunity?.opportunityNumber && project.opportunity?.opportunityNumber !== 'N/A'
              ? project.opportunity?.opportunityNumber
              : '-'}
          </Box>
        </span>
        <span>
          <Box component={'p'} className={classes.text}>
            <strong>Revision</strong>
          </Box>
          <Box component={'p'} className={classes.text}>
            {moment(projection.updatedAt).utc().format('YYMMDD.HHmm')}
          </Box>
        </span>

        <span>
          <Box component={'p'} className={classes.text}>
            <strong>Solution</strong>
          </Box>
          <Box component={'p'} className={classes.text}>
            {projection.solution && projection.solution.name ? projection.solution.name : '-'}
          </Box>
        </span>

        <span>
          <Box component={'p'} className={classes.text}>
            <strong>Membrane Element</strong>
          </Box>
          <Box component={'p'} className={classes.text}>
            {findValue('membrane_element') ? findValue('membrane_element') : '-'}
          </Box>
        </span>

        <span>
          <Box component={'p'} className={classes.text}>
            <strong>Designed by</strong>
          </Box>
          <Box component={'p'} className={classes.text}>
            {projection.createdBy && projection.createdBy.attributes.given_name
              ? projection.createdBy.attributes.given_name.charAt(0).toUpperCase() +
                '. ' +
                projection.createdBy.attributes.family_name
              : '-'}
          </Box>
        </span>

        <span>
          <Box component={'p'} className={classes.text}>
            <strong>Reviewed by</strong>
          </Box>
          <Box component={'p'} className={classes.text}>
            {projection.approver
              ? projection.approver.attributes.given_name.charAt(0).toUpperCase() +
                '. ' +
                projection.approver.attributes.family_name
              : '-'}
          </Box>
        </span>
      </div>
    </>
  );
};

export default PrintPreviewHeader;
