import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, Theme } from '@mui/material';

import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { DialogHeader } from '../../../../../../theme/components/dialog-header';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';

interface OwnProps {
  open: boolean;
  activeMessage: string;
  title: string;
  subtitle?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  spinner: {
    fontSize: '28px',
    paddingRight: theme.spacing(2),
    color: theme.palette.brightBlue.main,
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
}));

export const ProcessingCalculationDialog: React.FC<OwnProps> = (props) => {
  const { open, title, subtitle, activeMessage } = props;
  const classes = (useStyles as any)(props);

  return (
    <Dialog open={open} maxWidth={'xs'} onClose={() => {}}>
      <DialogHeader title={title} subtitle={subtitle || ''} />
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.spinner}>
            <FontAwesomeIcon icon={faSpinnerThird} spin />
          </div>
          {activeMessage}
        </div>
      </DialogContent>
    </Dialog>
  );
};
