import { ProfileFragment } from "./fragments";

export const Profile = `
query Profile($id:ID!) {
  Profile(id:$id) {
    ${ProfileFragment}
  }
}`;

export const ProfileCollection = `
query ProfileCollection {
  ProfileCollection {
    ${ProfileFragment}
  }
}`;
