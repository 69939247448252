import { PermissionFragment } from "./fragments";

export const QueryPermissionCollection = `
query PermissionCollection {
  PermissionCollection {
    ${PermissionFragment}
  }
}`;

export const QueryPermission = `
query Permission($id:ID!) {
  Permission(id:$id) {
    ${PermissionFragment}
  }
}`;

export const QueryResolvedPermission = `
query ResolvedPermission($id:ID!) {
  ResolvedPermission(id:$id) {
    ${PermissionFragment}
  }
}`;
