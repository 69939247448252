import { ListClassKey } from '@mui/material/List';
import { ListItemClassKey } from '@mui/material/ListItem';
import { MenuItemClassKey } from '@mui/material/MenuItem';

import { PentairBrightBlue, PentairDarkGray, PentairLightGray } from '../colors';
import { typography } from '../typography';

export const MuiList: Partial<Record<ListClassKey, any>> = {
  root: {},
  padding: {
    paddingTop: 0,
    paddingBottom: 0,
  },
};

export const MuiListItem: Partial<Record<ListItemClassKey, any>> = {
  root: {
    fontSize: typography('body1').fontSize,
  },
};

export const MuiMenuItem: Partial<Record<MenuItemClassKey, any>> = {
  root: {
    fontSize: typography('body2').fontSize,
    fontWeight: 300,
    color: PentairDarkGray[300],

    '&$selected': {
      backgroundColor: PentairLightGray[500],
      color: PentairBrightBlue[500],
    },

    '&:hover': {
      backgroundColor: PentairLightGray[500],
      color: PentairBrightBlue[500],
    },

    '&$selected:hover': {
      backgroundColor: PentairLightGray[900],
      color: PentairBrightBlue[500],
    },
  },
};
