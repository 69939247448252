import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, Alert } from '@mui/material';

import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { toFixedIfNecessary } from '../../../../../../application/resources/helper/to-fixed';
import { ProjectionRecord } from '../../../../../../application/resources/projection/projection';

import { PreventCloseDialog } from '../../../../../common/ui/dialog/prevent-close-dialog';
import { SteppedProgressBar } from '../../../../../common/ui/stepped-progress-bar';
import { FeedWaterName, ModuleTypeName, PresetConfiguration, State } from '../../../types/record';
import dt, { WaterFeed, Param } from '../../../../projection/helpers/dt';
import { FormProjectionInputsCategory } from '../../../../projection/view/form/form-projection-inputs-category';
import { FormProjectionInputsProducts } from '../../../../projection/view/form/form-projection-inputs-products';
import { FormTableProjectionsInputsCategory } from '../../../../projection/view/form/form-table-projection-inputs';
import { ProjectionCalculationValueList } from '../../../../projection/view/partials/projection-calculation-value-list';
import { ProcessingCalculationDialog } from '../../../../projection/view/dialog/processing-calculation-dialog';
import { ProjectionClass } from '../../../../../../application/resources/projection/v2/single';
import { ProjectionContext } from '../../../../projection/context/projection-context';
import { cloneDeep } from 'lodash';
import {
  // stockConcentrationTableAgentCEB,
  stockConcentrationTableCEB,
  stockConcentrationTableCoagulant,
} from '../../../helpers/stock-concentration';
import { convertWtPercent } from '../../../../calculation/functions/convert-wt-percent';
import { getSolutionIndex } from '../../../../projection/business/calculations/calculation-inputs';

import {
  calcModuleNameFromCalculations,
  calcModuleNameFromInputs,
} from '../../../../projection/business/projection/membrane-module';
import { DialogHeader } from '../../../../../../../theme/components/dialog-header';
import { faArrowLeft, faArrowRight, faCheck } from '@fortawesome/pro-light-svg-icons';

interface OwnProps {
  open: boolean;
  onClose: () => void;
  classes?: Record<'root', string>;
  projection: ProjectionClass;
  onChange: (key: keyof ProjectionRecord, value: any) => void;
}

const useStyles = makeStyles((theme: any) => ({
  root: {},
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}));

const dtFields = ['temperature', 'turbidity', 'tss', 'toc', 'cod', 'bod', 'color'];
const steps = ['inputs', 'feed', 'products', 'summary'];

export const AquaflexInletDialog: React.FC<OwnProps> = (props) => {
  const projection = React.useContext(ProjectionContext);
  const { open, onClose, onChange } = props;
  const [loading, setLoading] = React.useState(false);
  const [activeStep, setActiveStep] = React.useState('inputs');
  const [closeDialog, setCloseDialog] = React.useState(false);

  const [createError, setCreateError] = React.useState<string | undefined>();

  const getInputValue = React.useCallback(
    <TValue extends any>(id: string, defaultValue: TValue) => {
      const inputs = projection.record.inputs || [];
      const val = inputs.find((input) => input.id === id);
      return (val && (val.values[0] as TValue)) || defaultValue;
    },
    [projection.record.inputs]
  );

  // This is the logic that enables a reset dialog
  const [confirmResetDialog, setConfirmResetDialog] = React.useState(false);
  const [waterSourceCache, setWaterSourceCache] = React.useState<string>(getInputValue('water_source', ''));
  const [newWaterSourceCache, setNewWaterSourceCache] = React.useState<string>();

  const [activeStepHasErrors, setActiveStepHasErrors] = React.useState({});

  const [activeProcess, setActiveProcess] = React.useState('Uploading data to the cloud..');
  const [isRunningCalc, setRunningCalc] = React.useState(false);

  const data = React.useMemo(() => projection.record.inputs || [], [projection.record.inputs]);

  const getInput = React.useCallback(
    (id: string) => {
      const inputs = projection.record.inputs || [];
      return inputs.find((input) => input.id === id);
    },
    [projection.record.inputs]
  );

  const onResetDTFields = React.useCallback(() => {
    const inputs = projection.record.inputs || [];

    const keys = [...dtFields, 'design_turbidity', 'alcalinity', 'ph', 'tds', 'hardness'];
    keys.forEach((key) => {
      const calcValIndex = inputs.findIndex((input) => input.id === key);
      if (inputs[calcValIndex]?.values) {
        inputs[calcValIndex].values = [''];
      }
    });

    if (newWaterSourceCache) {
      const index = (projection.record.inputs || []).findIndex((input) => input.id === 'water_source');
      inputs[index].values = [newWaterSourceCache];
    }

    projection.change('inputs', inputs);
  }, [newWaterSourceCache, projection]);

  const changedFromCurrent = React.useCallback(() => {
    const nonEquals: string[] = [];
    // Only get non equal inputs
    const inputs = projection.savedRecordState.inputs || [];
    const changedInputs = projection.changes.inputs || [];

    if (inputs && changedInputs) {
      inputs.forEach((input) => {
        const currentInput = changedInputs.find((currentInput) => currentInput.id === input.id);

        if (currentInput && String(currentInput.values[0]) !== String(input.values[0])) {
          nonEquals.push(currentInput.id);
        }
      });
    }

    return nonEquals;
  }, [projection]);

  const onChangeCallback = React.useCallback(
    (key: keyof ProjectionRecord) => (value: any) => {
      // If projectionValue is being updated run different logic
      if (key === 'inputs') {
        if (value.id === 'water_source' && getInputValue('design_turbidity', '') !== '') {
          const inputs = projection.record.inputs || [];
          const index = inputs.findIndex((input) => input.id === 'water_source');
          setNewWaterSourceCache(cloneDeep(value.values[0]));
          inputs[index].values = [waterSourceCache || ''];
          projection.change('inputs', inputs);

          setConfirmResetDialog(true);
          return;
        } else {
          // Set current projectionValues
          const inputs = projection.record.inputs || [];

          // Find index of the projectionValue we want to change.
          const calcValIndex = inputs.findIndex((input) => input.id === value.id);

          if (value.decimals) {
            value.values[0] = String(
              toFixedIfNecessary(String(value.values[0]), parseFloat(String(value.decimals || '0')))
            );
          }

          inputs[calcValIndex] = value;

          // Update projection with our new values;
          projection.change(key, inputs);

          if (dtFields.includes(value.id)) {
            const inputs = projection.record.inputs || [];
            let waterSource = getInputValue('water_source', '');
            let codValue = getInputValue('cod', 0);
            let tocValue = getInputValue('toc', 0);
            let tssValue = getInputValue('tss', 0);
            let turbidity = getInputValue('turbidity', 0);
            let color = getInputValue('color', 0);

            if (waterSource && codValue && tocValue && tssValue && turbidity && color) {
              const param: Param = {
                water_feed: waterSource as WaterFeed,
                cod: codValue,
                toc: tocValue,
                color: color,
                tss: tssValue,
                turbidity: turbidity,
              };
              const designTurbidity = getInput('design_turbidity');
              if (designTurbidity) {
                designTurbidity.values = [dt(param)];

                const dtIndex = inputs.findIndex((input) => input.id === 'design_turbidity');

                inputs[dtIndex] = designTurbidity;
                projection.change(key, inputs);
              }
            }
          }
        }
      } else {
        projection.change(key, value);
      }
    },
    [getInput, getInputValue, projection, waterSourceCache]
  );

  const projectionValueToString = React.useCallback(
    (id: string) => {
      const calculationValue = getInput(id);
      const unit = (calculationValue && calculationValue.unit && ' ' + calculationValue.unit) || '';

      // valueOptions are wrong on input "membrane_element"
      if (
        calculationValue &&
        calculationValue.values &&
        calculationValue.values.length > 0 &&
        calculationValue.id === 'membrane_element'
      ) {
        return ((calculationValue && calculationValue.values[0]) || '') + unit;
      }

      if (calculationValue && calculationValue.valueOptions && calculationValue.valueOptions.length > 0) {
        const valueToReturn = calculationValue.valueOptions.find(
          (valueOption) => valueOption.id === calculationValue.values[0]
        );

        return ((valueToReturn && valueToReturn.label) || '') + unit;
      }

      return ((calculationValue && calculationValue.values[0]) || '') + unit;
    },
    [getInput]
  );

  const resultsData = [
    {
      key: 'Project',
      value: projection.record.project?.name,
    },
    {
      key: 'Design case',
      value: projection.record.name,
    },
    {
      key: 'Capacity',
      value: projectionValueToString('flow'),
    },
    {
      key: 'Capacity based on',
      value: projectionValueToString('flow_base'),
    },
    {
      key: 'Water source',
      value: projectionValueToString('water_source'),
    },
    {
      key: 'Calculated Turbidity Load',
      value: projectionValueToString('design_turbidity'),
    },
    {
      key: 'Solution',
      value: projection.record.solution?.name,
    },
    {
      key: 'Membrane element',
      value: projectionValueToString('membrane_element'),
    },
    {
      key: 'Membrane housing',
      value: projectionValueToString('membrane_housing'),
    },
  ];

  const getInputsByCategory = React.useCallback(
    (category: string) => {
      const values = data || [];
      return values.filter((input) => input.category === category);
    },
    [data]
  );

  const validateStep = React.useCallback((step: string, hasErrors: boolean) => {
    const stepValidation = {};
    stepValidation[step] = hasErrors;
    setActiveStepHasErrors({ ...stepValidation });
  }, []);

  const nextStep = React.useCallback(() => {
    if (activeStep === 'inputs') {
      const value = getInputValue('water_source', '');

      setWaterSourceCache(value);
    }
    setActiveStep(steps[steps.indexOf(activeStep) + 1]);
  }, [activeStep, getInputValue]);

  const previousStep = React.useCallback(() => {
    setActiveStep(steps[steps.indexOf(activeStep) - 1]);
  }, [activeStep]);

  const onSave = React.useCallback(() => {
    setRunningCalc(true);
    setCreateError(undefined);

    setActiveProcess('Preparing / Uploading data to cloud..');
    projection.change('note', 'Adviced settings');

    const calculationInputs: PresetConfiguration = {
      all_solution: getSolutionIndex(projection.record.solution?.id),
      all_tds: parseFloat(getInputValue('tds', '0')),
      all_high_solids: projection.record.solution?.id === 'aquaflex-hs',
      all_cap_based_on: getInputValue('flow_base', 'Permeate Production') === 'Permeate Production' ? 1 : 0,
      all_capacity: parseFloat(getInputValue('flow', '0')),
      all_design_turbidity: parseFloat(getInputValue('design_turbidity', '0')),
      all_feed_water: getInputValue('water_source', '') as FeedWaterName,
      all_temperature: parseFloat(getInputValue('temperature', '0')),
      all_module_name: calcModuleNameFromInputs(
        projection.record.solution?.id,
        getInputValue('membrane_element', '')
      ) as ModuleTypeName,
      chm_alkalinity: parseFloat(getInputValue('alcalinity', '0')),
      chm_ph: parseFloat(getInputValue('ph', '0')),
    };

    // pxx-1393
    if (projection.record.solution?.id === 'xiga') {
      const membraneElements = parseInt(getInputValue('membrane_elements', '4'));
      if (membraneElements === 2) {
        // @ts-expect-error: just testing here...
        calculationInputs.uc_in_user_max_elem_per_housing = 2;
      }
    }

    setActiveProcess('Running calculations..');
    projection
      .getAdvisedInputs(JSON.stringify(calculationInputs))
      .then((inputs) => {
        setActiveProcess('Processing calculation result..');
        setTimeout(() => {
          if (inputs && inputs.state) {
            projection.change('calcState', inputs.state);
            const calculationResult: State = JSON.parse(inputs.state);

            const projectionInputs = projection.record.inputs || [];
            const projectionOutputs = projection.record.outputs || [];

            for (const [key, value] of Object.entries(calculationResult.advice.inputs)) {
              if (!key.includes('ucp_passthrough_wtpct_agent')) {
                // Find index of the projectionValue we want to change.
                const calcValIndex = projectionInputs.findIndex((input) => input.calculation_field === key);
                const calcVal = projectionInputs[calcValIndex];
                if (calcVal && calcVal.id && calcVal.id.includes('_chemical_agent_') && calcVal.values[0] !== 'null') {
                  // Get CEB value
                  const ceb = calcVal.id.substr(calcVal.id.indexOf('ceb_') + 4, 3);
                  // Get CEB Agent Number
                  const type = calcVal.id.charAt(calcVal.id.length - 1);
                  const keyToUpdate = 'ceb_' + ceb + '_chemical_stock_concentration_' + type + '_wtpercent';
                  const glToUpdate = 'ceb_' + ceb + '_chemical_stock_concentration_' + type;

                  // const concentrationAgentToUpdate =
                  //   "ceb_" + ceb + "_concentration_agent_" + type;

                  const data = projectionInputs.find((input) => input.id === keyToUpdate);
                  if (data && data.id) {
                    const keyIndex = projectionInputs.findIndex((input) => input.id === keyToUpdate);
                    projectionInputs[keyIndex].values = [stockConcentrationTableCEB[String(value)]];
                  }

                  const gl = projectionInputs.find((input) => input.id === glToUpdate);

                  if (gl && gl.id && value) {
                    const keyIndex = projectionInputs.findIndex((input) => input.id === glToUpdate);

                    const data = {
                      id: String(value),
                      wtpercent: stockConcentrationTableCEB[String(value)],
                      type: 'chemical',
                    };
                    convertWtPercent({
                      variables: {
                        inputs: JSON.stringify(data),
                      },
                    }).then((result) => {
                      if (result.data) {
                        if (result.data.projectionCalculationConvertWtPct.status === 'ok') {
                          projectionInputs[keyIndex].values = [
                            result.data.projectionCalculationConvertWtPct.active_concentration.toFixed(2),
                          ];
                        }
                      }
                    });
                  }

                  // const concentrationAgent = projectionInputs.find(
                  //   (input) => input.id === concentrationAgentToUpdate
                  // );

                  // if (concentrationAgent && concentrationAgent.id) {
                  //   const keyIndex = projectionInputs.findIndex(
                  //     (input) => input.id === concentrationAgentToUpdate
                  //   );
                  //   projectionInputs[keyIndex].values = [
                  //     stockConcentrationTableAgentCEB[String(value)],
                  //   ];
                  // }
                }

                if (calcVal && calcVal.id && calcVal.id === 'chm_coagulant' && calcVal.values[0] !== 'null') {
                  const keyToUpdate = 'chm_coagulant_override_stock_conc_wtpercent';

                  const data = projectionInputs.find((input) => input.id === keyToUpdate);
                  if (data && data.id) {
                    const keyIndex = projectionInputs.findIndex((input) => input.id === keyToUpdate);
                    projectionInputs[keyIndex].values = [stockConcentrationTableCoagulant[String(value)]];
                  }
                }

                // Update value in our projectionValues array
                if (projectionInputs[calcValIndex]) {
                  let parsedVal: any = value;

                  if (key === 'all_module_name') {
                    parsedVal = calcModuleNameFromCalculations(projection.record.solution?.id, parsedVal);
                  }

                  if (key === 'all_cap_based_on') {
                    if (value === 1) {
                      parsedVal = 'Permeate Production';
                    } else if (value === 0) {
                      parsedVal = 'Feed flow';
                    }
                  }

                  if (projectionInputs[calcValIndex].decimals) {
                    parsedVal = toFixedIfNecessary(
                      String(value),
                      parseFloat(String(projectionInputs[calcValIndex].decimals || '0'))
                    );
                  }

                  projectionInputs[calcValIndex].values = [parsedVal as string];
                }
              }
            }

            //TODO: PARSE OUTPUTS
            for (const [key, value] of Object.entries(calculationResult.advice.outputs)) {
              // Find index of the projectionValue we want to change.
              const calcValIndexInputs = projectionInputs.findIndex((input) => input.calculation_field === key);
              const calcValIndexOutputs = projectionOutputs.findIndex((input) => input.calculation_field === key);

              // Update value in our projectionValues array
              if (projectionInputs[calcValIndexInputs]) {
                let parsedVal = value;
                if (projectionInputs[calcValIndexInputs].decimals) {
                  parsedVal = toFixedIfNecessary(
                    value,
                    parseFloat(String(projectionInputs[calcValIndexInputs].decimals || '0'))
                  );
                }

                projectionInputs[calcValIndexInputs].values = [parsedVal as string];
              }

              // Update value in our projectionValues array
              if (projectionOutputs[calcValIndexOutputs]) {
                if (!key.includes('ucp_passthrough_wtpct_chemical_')) {
                  let parsedVal = value;

                  if (projectionOutputs[calcValIndexOutputs].decimals) {
                    parsedVal = toFixedIfNecessary(
                      value,
                      parseFloat(String(projectionOutputs[calcValIndexOutputs].decimals || '0'))
                    );
                  }
                  const calcVal = projectionOutputs[calcValIndexOutputs];
                  if (
                    calcVal &&
                    calcVal.id &&
                    calcVal.id.includes('ucp_chemical_agent_') &&
                    calcVal.values[0] !== 'null'
                  ) {
                    const numbr = calcVal.id.substr(-1);
                    const keyToUpdate = 'ucp_passthrough_wtpct_chemical_' + numbr;

                    const data = projectionOutputs.find((input) => input.id === keyToUpdate);
                    if (data && data.id) {
                      const keyIndex = projectionOutputs.findIndex((input) => input.id === keyToUpdate);
                      projectionOutputs[keyIndex].values = [stockConcentrationTableCEB[String(value)]];
                    }
                  }

                  if (projectionOutputs[calcValIndexOutputs].decimals) {
                    parsedVal = toFixedIfNecessary(
                      String(value),
                      parseFloat(String(projectionOutputs[calcValIndexOutputs].decimals || '0'))
                    );
                  }

                  projectionOutputs[calcValIndexOutputs].values = [parsedVal as string];
                }
              }
            }

            const turbidity = getInputValue('design_turbidity', '4' as string);

            // If dt <= 70 disabled
            if (turbidity) {
              const internalRecirculationIndex = projectionInputs.findIndex(
                (input) => input.id === 'internal_recirculation'
              );

              if (projectionInputs[internalRecirculationIndex]) {
                const isEnabled = parseFloat(turbidity || '0') > 70 ? 'true' : 'false';

                projectionInputs[internalRecirculationIndex].values = [isEnabled];
              }
            }

            projection.change('inputs', projectionInputs);
            onChange('inputs', projectionInputs);
            projection.change('outputs', projectionOutputs);
            onChange('outputs', projectionOutputs);
            projection.change('advisedInputs', projectionInputs);
            onChange('advisedInputs', projectionInputs);

            setActiveProcess('Updating data..');
            setRunningCalc(false);
            projection.setSavedRecordState();
            onClose();
          } else {
            setRunningCalc(false);
          }
        }, 500);
      })
      .catch((err: any) => {
        setCreateError(err);
        console.error('err', err);
      });
  }, [getInputValue, onChange, onClose, projection]);

  React.useEffect(() => {
    if (!open) {
      setActiveStep('inputs');
      setCloseDialog(false);
      projection.reset();
    }
  }, [open]);

  React.useEffect(() => {
    if (!projection.record.name) {
      setLoading(true);
      projection.fetch().then(() => {
        setLoading(false);
      });
    }
    const inputs = data || [];
    let waterSource = getInputValue('water_source', '');
    let codValue = getInputValue('cod', 0);
    let tocValue = getInputValue('toc', 0);
    let tssValue = getInputValue('tss', 0);
    let turbidity = getInputValue('turbidity', 0);
    let color = getInputValue('color', 0);

    if (waterSource && codValue && tocValue && tssValue && turbidity && color) {
      const param: Param = {
        water_feed: waterSource as WaterFeed,
        cod: codValue,
        toc: tocValue,
        color: color,
        tss: tssValue,
        turbidity: turbidity,
      };
      const designTurbidity = getInput('design_turbidity');
      if (designTurbidity) {
        designTurbidity.values = [dt(param)];

        const dtIndex = inputs.findIndex((input) => input.id === 'design_turbidity');

        inputs[dtIndex] = designTurbidity;
        projection.change('inputs', inputs);
      }
    }
  }, [projection]);

  const classes = (useStyles as any)(props);

  if (loading) {
    return null;
  }

  return (
    <Dialog open={open} fullWidth maxWidth={(activeStep !== 'feed' && 'xs') || 'md'}>
      <DialogHeader
        title="Inlet"
        subtitle={projection.record.name || ''}
        onClose={() => {
          if (changedFromCurrent().length > 0) {
            setCloseDialog(true);
          } else {
            onClose();
          }
        }}
      />
      <DialogContent>
        <SteppedProgressBar steps={steps} activeStep={activeStep || 'inputs'} onStepClick={setActiveStep} />
        {activeStep === 'inputs' && (
          <FormProjectionInputsCategory
            onChange={onChangeCallback}
            onValidate={validateStep}
            data={getInputsByCategory('input.flow')}
          />
        )}
        {activeStep === 'feed' && (
          <FormTableProjectionsInputsCategory
            onValidate={validateStep}
            onChange={onChangeCallback}
            data={getInputsByCategory('input.feed.water')}
            waterSource={getInput('water_source')}
            backwashWaterReuse={getInput('ahs_bod_inlet')?.values[0] === 'yes'}
            title={'Feed water'}
            solution={(projection.record.solution && projection.record.solution.id) || ''}
          />

          // <FormProjectionInputsCategory
          //   onValidate={validateStep}
          //   onChange={onChange}
          //   data={getInputsByCategory("input.feed.water")}
          // />
        )}
        {activeStep === 'products' && (
          <FormProjectionInputsProducts
            onValidate={validateStep}
            onChange={onChangeCallback}
            data={getInputsByCategory('input.products')}
            projection={projection.record}
            solution={(projection.record.solution && projection.record.solution.id) || ''}
          />
          // <ProjectionInletProductSelectionForm
          //   onValidate={validateStep}
          //   onChange={onChangeCallback}
          //   data={getInputsByCategory('input.products')}
          //   projection={projection.record}
          //   solution={(projection.record.solution && projection.record.solution.id) || ''}
          // />
        )}
        {activeStep === 'summary' && (
          <>
            <ProjectionCalculationValueList data={resultsData} />
            {createError && <Alert severity="error">{createError}</Alert>}
          </>
        )}
      </DialogContent>
      <DialogActions classes={activeStep !== 'inputs' ? { root: classes.dialogActions } : {}}>
        {activeStep !== 'inputs' && (
          <Button
            variant={'text'}
            children={'Previous'}
            color="inherit"
            startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
            onClick={() => previousStep()}
            sx={{ marginRight: 'auto' }}
          />
        )}
        <Button
          disabled={activeStepHasErrors[activeStep]}
          children={activeStep !== steps[steps.length - 1] ? 'Next' : 'Re-run calculations'}
          startIcon={activeStep === steps[steps.length - 1] && <FontAwesomeIcon icon={faCheck} />}
          endIcon={activeStep !== steps[steps.length - 1] && <FontAwesomeIcon icon={faArrowRight} />}
          onClick={() => (activeStep !== steps[steps.length - 1] ? nextStep() : onSave())}
        />
      </DialogActions>
      <PreventCloseDialog
        title={'Warning'}
        subtitle={'Confirm your action'}
        message={
          <React.Fragment>
            Changing the feed water source will recalculate the calculated turbidity load and reset all the design and
            operating settings to advised values. Do want to proceed?
          </React.Fragment>
        }
        open={confirmResetDialog}
        onClose={() => {
          setConfirmResetDialog(false);
        }}
        onConfirm={() => {
          // projection.change("inputs");
          onResetDTFields();
          setConfirmResetDialog(false);
        }}
        confirmDisabled={false}
        confirmButtonText={'CONFIRM'}
        closeButtonText={'DISCARD'}
      />
      <PreventCloseDialog
        open={closeDialog}
        title={'Warning'}
        subtitle={'Confirm your action'}
        message={
          <React.Fragment>
            One or more fields are changed but the calculations are not updated.
            <br />
            <br />
            Click <strong>CONFIRM</strong> to close this dialog and continue editing. After reaching the final step you
            can re-calculate
            <br />
            <br />
            If you do not want to update calculations click
            <strong> DISCARD</strong> to discard changes made and continue.
          </React.Fragment>
        }
        onClose={onClose}
        confirmButtonText={'CONFIRM'}
        closeButtonText={'DISCARD'}
        onConfirm={() => {
          setCloseDialog(false);
        }}
      />
      <ProcessingCalculationDialog
        open={isRunningCalc}
        activeMessage={activeProcess}
        title={'Just a few seconds'}
        subtitle={'Processing your inputs'}
      />
    </Dialog>
  );
};
