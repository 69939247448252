import { Box, Divider } from '@mui/material';
import { HeaderPropertyValue } from '../../../../__old__/v2/common/layout/header-prop-value';
import { CalculationTextField } from '../../../../__old__/v2/common/ui/calculation-textfield';
import { CalculationsRecord } from '../../../../__old__/v2/feature/projection/helpers/calculation-values';
import { CalculationValue } from '../../../../__old__/v2/feature/solution/types/record';

export interface CEBSettingsProps {
  solution: string;
  inputs: Partial<CalculationsRecord>;
  advisedInputs: Partial<CalculationsRecord>;
  onChange(calculationValue: CalculationValue): void;
  onError?(id: string, error: string | false): void;
}

export const UnitConfiguration: React.FC<CEBSettingsProps> = (props) => {
  const { inputs, advisedInputs, onChange, onError, solution } = props;

  const maxMembraneElements = parseInt(String(inputs['plant_max_membrane_elements']?.values[0]) || '100');
  const maxMembraneElementsValidation = solution !== 'xline' ? 1000 : 16;
  const membraneElements = parseInt(String(inputs['membrane_elements']?.values[0]) || '0');
  const membraneHousings = parseInt(String(inputs['membrane_housings']?.values[0]) || '0');
  const spareMembraneElements =
    solution !== 'xiga'
      ? Math.max(0, maxMembraneElements - membraneElements) // normal situation
      : Math.max(0, maxMembraneElements - membraneHousings); // XIGA situation

  // Xline only
  let maxAllShorterRows = parseInt(String(inputs['all_user_housings']?.values[0]) || '0');
  if (maxAllShorterRows > 0) {
    maxAllShorterRows = maxAllShorterRows - 1;
  }

  return (
    <Box width={500}>
      <Box display={'flex'}>
        <HeaderPropertyValue horizontal marginRight={1} calculationValue={inputs['ucl_overdesign']} />
      </Box>
      <Divider style={{ margin: '24px 0' }} />
      <CalculationTextField
        label={'Total Duty Units (N)'}
        calculationValue={inputs['total_units']}
        advisedValue={advisedInputs['total_units']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:500|numberMin:1'}
        inputProps={{ min: 0, max: 500, step: 1 }}
        type="number"
      />
      {['xiga'].includes(solution) && (
        <CalculationTextField
          calculationValue={inputs['membrane_housings']}
          advisedValue={advisedInputs['membrane_housings']}
          onChange={onChange}
          onError={onError}
          validations={`required|maxMembranesXiga:${maxMembraneElements}|numberMin:0`}
          inputProps={{ min: 0, max: 500, step: 1 }}
          type="number"
          showEgu
          eguWidth={100}
        />
      )}
      {['xline'].includes(solution) && (
        <CalculationTextField
          calculationValue={inputs['all_user_housings']}
          advisedValue={advisedInputs['all_user_housings']}
          onChange={onChange}
          onError={onError}
          type="number"
          validations={`required|numberMin:1|numberMax:15`}
          inputProps={{ min: 0, max: 500, step: 1 }}
          showEgu
          eguWidth={100}
        />
      )}
      <CalculationTextField
        label={'Membrane Elements'}
        disabled={solution === 'xiga'}
        calculationValue={inputs['membrane_elements']}
        advisedValue={advisedInputs['membrane_elements']}
        onChange={onChange}
        onError={onError}
        type="number"
        validations={`required|maxMembranes:${maxMembraneElements}|numberMin:0`}
        inputProps={{ min: 0, max: maxMembraneElements, step: 1 }}
        showEgu
        eguWidth={100}
      />
      <CalculationTextField
        calculationValue={inputs['plant_spare_membrane_elements']}
        advisedValue={advisedInputs['plant_spare_membrane_elements']}
        onChange={onChange}
        onError={onError}
        validations={`required|numberMax:${spareMembraneElements}|numberMin:0`}
        type="number"
        inputProps={{ min: 0, max: spareMembraneElements, step: 1 }}
        showEgu
        eguWidth={100}
      />
      <CalculationTextField
        calculationValue={inputs['plant_max_membrane_elements']}
        advisedValue={advisedInputs['plant_max_membrane_elements']}
        onChange={onChange}
        onError={onError}
        validations={`required|maxMembranesRowXline:${maxMembraneElementsValidation}|numberMin:1`} // numberMax:1000
        type="number"
        inputProps={{ min: 0, max: maxMembraneElementsValidation, step: 1 }} // was 1000, step: 1
        showEgu
        eguWidth={100}
      />
      {['xline'].includes(solution) && (
        <CalculationTextField
          calculationValue={inputs['all_shorter_rows']}
          advisedValue={advisedInputs['all_shorter_rows']}
          onChange={onChange}
          onError={onError}
          validations={`required|numberMin:0|numberMax:${maxAllShorterRows}`}
          type="number"
          inputProps={{ min: 0, max: maxAllShorterRows, step: 1 }}
          showEgu
          eguWidth={100}
        />
      )}
      <CalculationTextField
        disabled
        calculationValue={inputs['plant_total_membrane_elements']}
        advisedValue={advisedInputs['plant_total_membrane_elements']}
        onChange={onChange}
        onError={onError}
        validations={`required`}
        type="number"
        inputProps={{ min: 0, max: spareMembraneElements, step: 1 }}
      />
      <CalculationTextField
        disabled
        calculationValue={inputs['plant_total_area']}
        advisedValue={advisedInputs['plant_total_area']}
        onChange={onChange}
        onError={onError}
        validations={`required`}
        type="number"
        showEgu
        eguWidth={100}
      />
    </Box>
  );
};
