import { ProjectionClass } from '../../../../../../application/resources/projection/v2/single';
import { calculationValueById } from './calculation-value-by-id';
import { projectionValueToString } from './projection-value-to-string';

const getPHValueType = (chemical?: string) => {
  const PHDisabledChemicals = ['NaOCl', 'H₂O₂', 'NAHSO₃'];
  const PHBaseChemicals = ['NaOH']; // max
  const PHAcidChemicals = ['HCl', 'H₂SO₄', 'HNO₃', 'H₃PO₄']; // min

  if (!chemical) return '';
  if (PHDisabledChemicals.includes(chemical)) return '';
  if (PHBaseChemicals.includes(chemical)) return 'ph_base';
  if (PHAcidChemicals.includes(chemical)) return 'ph_acid';

  return '';
};

export const CEBConsumptionsData = (projection: ProjectionClass, egu?: string) => {
  const getVal = (k: string) => projectionValueToString(k, projection, egu);

  const sections = ['ceb_1_a', 'ceb_1_b', 'ceb_2_a', 'ceb_2_b', 'ceb_3_a', 'ceb_3_b', 'ceb_4_a', 'ceb_4_b'];

  const parsedChemicals = sections.reduce((result: any[], section) => {
    const agent1 = getVal(`${section}_chemical_agent_1`);
    if (agent1) {
      result.push({
        section,
        agent: agent1,
        ph: getVal(section + '_' + getPHValueType(agent1)),
      });
    }

    const agent2 = getVal(`${section}_chemical_agent_2`);
    if (agent2) {
      result.push({
        section,
        agent: agent2,
        ph: getVal(section + '_' + getPHValueType(agent2)),
      });
    }
    return result;
  }, []);

  const getChemical = (chemical: string | undefined) => {
    const type = getPHValueType(chemical);
    const copy = [...parsedChemicals];

    copy.sort((a, b) => {
      const aVal = parseFloat(a.ph);
      const bVal = parseFloat(b.ph);

      if (type === 'ph_base') {
        if (aVal < bVal) return 1;
        if (aVal > bVal) return -1;
      } else {
        if (aVal > bVal) return 1;
        if (aVal < bVal) return -1;
      }

      return 0;
    });

    return chemical && copy.find((parsed) => parsed.agent === chemical);
  };

  const CEB1 = calculationValueById('ucp_chemical_agent_1', projection);
  const CEB1Value = CEB1 && getVal('ucp_chemical_agent_1');
  const CEB1StockConc = getVal('ucp_stock_conc_chemical_1');
  const CEB1Conc = getVal('ucp_conc_chemical_1');
  const CEB1Agent = getVal('ucp_chemical_agent_1');
  const CEB1Overall = getVal('ucp_overall_chemical_1');
  const CEB1PassthroughWT = getVal('ucp_passthrough_wtpct_chemical_1');

  const CEB1Chemical = getChemical(CEB1Value);
  const CEB1PHValue = CEB1Chemical?.ph;

  const CEB2 = calculationValueById('ucp_chemical_agent_2', projection);
  const CEB2Value = CEB2 && getVal('ucp_chemical_agent_2');
  const CEB2StockConc = getVal('ucp_stock_conc_chemical_2');
  const CEB2Conc = getVal('ucp_conc_chemical_2');
  const CEB2Agent = getVal('ucp_chemical_agent_2');
  const CEB2Overall = getVal('ucp_overall_chemical_2');
  const CEB2PassthroughWT = getVal('ucp_passthrough_wtpct_chemical_2');
  const CEB2Chemical = getChemical(CEB2Value);
  const CEB2PHValue = CEB2Chemical?.ph;

  const CEB3 = calculationValueById('ucp_chemical_agent_2', projection);
  const CEB3Value = CEB3 && getVal('ucp_chemical_agent_3');
  const CEB3StockConc = getVal('ucp_stock_conc_chemical_3');
  const CEB3Conc = getVal('ucp_conc_chemical_3');
  const CEB3Agent = getVal('ucp_chemical_agent_3');
  const CEB3Overall = getVal('ucp_overall_chemical_3');
  const CEB3PassthroughWT = getVal('ucp_passthrough_wtpct_chemical_3');
  const CEB3Chemical = getChemical(CEB3Value);
  const CEB3PHValue = CEB3Chemical?.ph;

  const values: any[] = [];

  if (CEB1Value) {
    values.push(
      {
        rowName: CEB1Value + ' Stock',
        rowValue: CEB1PassthroughWT + ' as ' + CEB1StockConc,
      },
      {
        rowName: CEB1Value + ' Concentration',
        rowValue: CEB1Conc + (CEB1PHValue ? ' @ pH ' + CEB1PHValue : ''),
      },
      {
        rowName: CEB1Agent + ' Consumption',
        rowValue: CEB1Overall,
      }
    );
  }

  if (CEB2Value) {
    values.push(
      {
        rowName: CEB2Value + ' Stock',
        rowValue: CEB2PassthroughWT + ' as ' + CEB2StockConc,
      },
      {
        rowName: CEB2Value + ' Concentration',
        rowValue: CEB2Conc + (CEB2PHValue ? ' @ pH ' + CEB2PHValue : ''),
      },
      {
        rowName: CEB2Agent + ' Consumption',
        rowValue: CEB2Overall,
      }
    );
  }

  if (CEB3Value) {
    values.push(
      {
        rowName: CEB3Value + ' Stock',
        rowValue: CEB3PassthroughWT + ' as ' + CEB3StockConc,
      },
      {
        rowName: CEB3Value + ' Concentration',
        rowValue: CEB3Conc + (CEB3PHValue ? ' @ pH ' + CEB3PHValue : ''),
      },
      {
        rowName: CEB3Agent + ' Consumption',
        rowValue: CEB3Overall,
      }
    );
  }

  return values;
};
