import { get, set } from "lodash";

// type DottedPath<T extends Record<string, any>> = Join<Paths<T>, ".">

// type Paths<T> = T extends string ? [] : T extends boolean ? [] : T extends number ? [] : {
//   [K in Extract<keyof T, string>]: [K, ...Paths<T[K]>]
// }[Extract<keyof T, string>];

// type Join<T extends string[], D extends string> =
//     T extends [] ? never :
//     T extends [infer F] ? F :
//     T extends [infer F, ...infer R] ?
//     F extends string ? string extends F ? string :
//     `${F}${D}${Join<Extract<R, string[]>, D>}` : never : string;

type takeResolver<T = any> = ((val: T) => any) | undefined;

export function take<TRecord extends Record<string, any>>(
  source: TRecord,
  keys: (keyof typeof source | [keyof typeof source, takeResolver])[],
  returnUndefinedValues: boolean = false
) {
  return keys.reduce((result, key) => {
    let keyGet = key;
    let keySet = key;
    let resolver: takeResolver = undefined;

    if (Array.isArray(key)) {
      keyGet = key[0];
      keySet = key[0];
      resolver = key[1];
    }

    let val = get(source, keyGet as string);

    if (resolver) {
      val = resolver(val);
    }

    if (val || returnUndefinedValues) {
      set(result, keySet as string, val);
    }

    return result;
  }, {} as any);
}
