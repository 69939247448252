import { useMemo, useState, useCallback } from 'react';
import { ProjectionClass } from '../../../__old__/application/resources/projection/v2/single';
import { CalculationValue } from '../../../__old__/v2/feature/solution/types/record';
import { keyBy, cloneDeep } from 'lodash';
import { runCalculation } from '../../../__old__/v2/common/helpers/run-calculation';
import { CalculationsRecord } from '../../../__old__/v2/feature/projection/helpers/calculation-values';
import { ProjectionRecord } from '../../../__old__/application/resources/projection/projection';
import merge from 'lodash/merge';
import union from 'lodash/union';

interface ProjectionManagerOptions {
  inputFilters?: string[];
  onChange: (key: keyof ProjectionRecord, value: any) => void;
}

export const useDeprecatedProjection = (
  projection: ProjectionClass,
  { inputFilters, onChange }: ProjectionManagerOptions
) => {
  const [calculationRunning, setCalculationRunning] = useState(false);
  const [calculationError, setCalculationError] = useState<string>();
  const [activeProcess, setActiveProcess] = useState('Uploading data to the cloud..');

  const [changes, setChanges] = useState<Partial<CalculationsRecord>>({});

  const inputs = useMemo(() => {
    let values = union(projection.record.inputs || [], projection.record.outputs || []);
    values = values.filter((input) => !inputFilters || inputFilters.includes(input.category || ''));
    const keyedInputs = keyBy(values, 'id');
    const changedInputs = merge({}, keyedInputs, changes);

    // pxx-1494
    if ('flow_base' in changedInputs) {
      if (changedInputs.flow_base?.values && changedInputs.flow_base.values[0] === 1) {
        changedInputs.flow_base.values[0] = 'Permeate Production';
      }
      if (changedInputs.flow_base?.values && changedInputs.flow_base.values[0] === 0) {
        changedInputs.flow_base.values[0] = 'Feed flow';
      }
    }

    return changedInputs;
  }, [projection.record.inputs, projection.record.outputs, inputFilters, changes]);

  const advisedInputs = useMemo(() => {
    return keyBy(projection.savedRecordState.advisedInputs || [], 'id');
  }, [projection.savedRecordState.advisedInputs]);

  const applyChanges = useCallback(
    (newInputs: Partial<CalculationsRecord>) => {
      if (onChange) {
        const inputsArray = JSON.stringify(Object.values(newInputs));
        onChange('inputs', inputsArray);
        // setChanges({}); //==> moved to onClose within the runCalculation below here
      }

      runCalculation(
        projection,
        setCalculationRunning,
        setActiveProcess,
        () => {
          setChanges({});
        },
        projection.change,
        () => {}, //projection.setSavedRecordState,
        () => {},
        inputFilters || [],
        Object.values(newInputs) as CalculationValue[],
        setCalculationError
      );
    },
    [inputFilters, onChange, projection]
  );

  const revertToAdvised = useCallback(() => {
    setChanges({});
    applyChanges(merge({}, inputs, advisedInputs));
  }, [inputs, applyChanges, advisedInputs]);

  const resetChanges = useCallback(() => {
    setChanges({});
    applyChanges(inputs);
  }, [inputs, applyChanges]);

  const updateCalculationValue = useCallback((value: CalculationValue) => {
    // no id; gtfo
    if (!value.id) return;

    // add / remove from changes if different than original.
    setChanges((oldChanges) => {
      let changesClone = cloneDeep(oldChanges);
      changesClone[value.id] = value;
      return changesClone;
    });
  }, []);

  return {
    inputs,
    changes,
    advisedInputs,
    revertToAdvised,
    resetChanges,
    updateCalculationValue,
    applyChanges,
    calculationRunning,
    calculationError,
    activeProcess,
    setCalculationError,
  };
};
