import { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useListSolutions } from '../../projections/api/list-solutions';

export const solutionCodes = ['AQF', 'AHS', 'XIG', 'XLR', 'ALT', 'MGB', 'RVT', 'CFM', 'CFU'] as const;
export type SolutionCodesType = (typeof solutionCodes)[number];

export const solutionNameCodes: Partial<Record<string, SolutionCodesType>> = {
  'aquaflex-new': 'AQF',
  'aquaflex-hs': 'AHS',
  xiga: 'XIG',
  xline: 'XLR',
  'airlift-skds': 'ALT',
  'airlift-mgb': 'MGB',
  reverta: 'RVT',
  crossflow: 'CFM',
  'crossflow-uf': 'CFU',
};

interface SolutionContextRecord {
  listSolutionsHandler(): void;
  findSolution(solutionId: string, withId?: boolean): string | null | undefined;
  /** Since, we have some conflicting solutionIds, such as aquaflex-new, we will use the find solution literal to get the name of the solution using the id */
  findSolutionLiteral(solutionId: string, withId?: boolean): string | null | undefined;
  loading: boolean;
  error: Error | undefined;
  solutions:
    | Array<{
        __typename?: 'IndexSolution';
        active?: string | null;
        comparableTechnologies?: Array<string | null> | null;
        description?: string | null;
        id: string;
        image?: string | null;
        name?: string | null;
        oneliner?: string | null;
        order?: string | null;
      } | null>
    | undefined;
  findSolutionCode(solutionId): string | null | undefined;
}

const SolutionContext = createContext<SolutionContextRecord | null>(null);

export const SolutionContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Hook to fetch solutions
  const [{ loading, error, response }, listSolutions] = useListSolutions();
  const solutions = useMemo(() => {
    return response?.data?.SolutionCollection?.solutions || [];
  }, [response]);

  const listSolutionsHandler = useCallback(() => {
    if (!solutions.length) {
      listSolutions({});
    }
  }, [listSolutions, solutions]);

  const findSolution = useCallback(
    (solutionId: string, /** Then return only the id */ withId?: boolean) => {
      if (!solutions?.length) return;

      const solution = solutions?.find(
        (_solution) =>
          _solution?.id?.toLowerCase() === solutionId?.toLowerCase() ||
          _solution?.name?.toLowerCase() === solutionId?.toLowerCase()
      );

      if (withId) {
        return solution?.id;
      }
      return solution?.name;
    },
    [solutions]
  );
  /** Use this to find solutions with mismatches, etc */
  const findSolutionLiteral = useCallback(
    (solutionId: string, /** Then return only the id */ withId?: boolean) => {
      if (!solutions?.length) return;

      // Remove extra characters and leave only text and/or numbers
      const solution = solutions?.find(
        (_solution) =>
          _solution?.id?.toLowerCase()?.replace(/[^a-zA-Z0-9]/g, '') ===
            solutionId?.toLowerCase()?.replace(/[^a-zA-Z0-9]/g, '') ||
          _solution?.name?.toLowerCase()?.replace(/[^a-zA-Z0-9]/g, '') ===
            solutionId?.toLowerCase()?.replace(/[^a-zA-Z0-9]/g, '')
      );

      if (withId) {
        return solution?.id;
      }
      return solution?.name;
    },
    [solutions]
  );

  const findSolutionCode = useCallback(
    (solutionId: string) => {
      const solution = findSolution(solutionId, true) || '';
      return solutionNameCodes[solution];
    },
    [findSolution]
  );

  useEffect(() => {
    listSolutionsHandler();
  }, [listSolutionsHandler]);

  return (
    <SolutionContext.Provider
      value={{ loading, error, solutions, listSolutionsHandler, findSolution, findSolutionCode, findSolutionLiteral }}
    >
      {children}
    </SolutionContext.Provider>
  );
};

/** Access solutions context data and methods anywhere in the application */
export const useSolutions = () => {
  const context = useContext(SolutionContext);

  if (!context) {
    throw new Error('Solution context is missing');
  }

  return context;
};
