import * as React from 'react';
import { makeStyles } from '@mui/styles';
import { InterfaceTools } from '../default-interface-presenter';
import { PentairBlue } from '../../../config/theme/colors';
import { IconButton } from '../../components/mui-extended/icon-button';
import { useRoute } from '../../../hooks/use-route';
import { useEventHandler } from '../../../hooks/use-eventmapper';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { CanView } from '../../auth/can-view';
import { IconButtonDropdown } from '../../../v2/common/ui/icon-button-dropdown';
import { VersionContext } from '../../../../feature/xpert-version/context/version-context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { EditVersionDialog } from '../../../../feature/xpert-version/components/edit-version-dialog';
import { useAuth } from '../../../../feature/auth/auth-provider';
import { usePermissions } from '../../../../feature/auth/auth-permissions';
import {
  faBug,
  faCog,
  faEdit,
  faExpandWide,
  faHistory,
  faHome,
  faInfoCircle,
  faPlusCircle,
  faQuestionCircle,
  faSignOut,
  faUserHeadset,
} from '@fortawesome/pro-light-svg-icons';
import {
  faHome as faHomeSolid,
  faCog as faCogSolid,
  faExpandWide as faExpandWideSolid,
  faSignOut as faSignOutSolid,
  faQuestionCircle as faQuestionCircleSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { ViewVersionDetailsDialog } from '../../../../feature/xpert-version/components/view-version-details-dialog';
import { useProposalDataVersion } from '../../../../feature/xpert-version/graphql-queries/proposal-data-version';

interface OwnProps {
  onToolSelect?(tool: InterfaceTools): void;
  active?: InterfaceTools;
  classes?: Record<'root', string>;
}

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: PentairBlue[500],
    '& > nav': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  userIconWrap: {
    padding: '8px',
    height: 48,
    width: 48,
    textDecoration: 'none',
    display: 'flex',
  },
  userIcon: {
    width: '100%',
    background: PentairBlue[700],
    borderRadius: '50%',
    color: '#fff',
    fontWeight: 500,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    fontSize: '11px',
    lineHeight: '10px',
    '&:hover': {
      background: '#FFF',
      color: PentairBlue[700],
    },
  },
});

export const ToolBar: React.FC<OwnProps> = (props) => {
  const { user, signOut, isPentairUser } = useAuth();
  const { isPermitted } = usePermissions();

  const classes = useStyles(props);
  const [eventHandler] = useEventHandler();

  const [{ response, error: proposalDataVersionError }, fetchProposalDataVersion] = useProposalDataVersion();
  const proposalDataVersions = response.data?.ProposalDataVersion;

  const location = useLocation();
  const [homepageUrl] = useRoute('app.homepage');
  const [accountUrl] = useRoute('user.account');
  const [administrationUrl] = useRoute('user.index');
  const { version } = React.useContext(VersionContext);
  const [editVersion, setEditVersion] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [viewVersion, setViewVersion] = useState(false);

  const userInitials = React.useMemo(() => {
    let firstInitial: string = '';
    let lastInitial = user?.attributes.family_name?.charAt(0);
    if (user?.attributes.given_name) {
      firstInitial = user?.attributes.given_name.charAt(0);
    } else if (user?.attributes.name) {
      firstInitial = user?.attributes.name.charAt(0);
    }

    return firstInitial + lastInitial;
  }, [user?.attributes.family_name, user?.attributes.given_name, user?.attributes.name]);

  const toggleFullscreen = React.useCallback(() => {
    const el: any = document.documentElement;
    if (!fullscreen) {
      setFullscreen(true);
      el.requestFullscreen();
    } else {
      setFullscreen(false);
      document.exitFullscreen();
    }
  }, [fullscreen]);

  React.useEffect(() => {
    fetchProposalDataVersion({});
  }, []);

  return (
    <aside className={classes.root}>
      {/* top of toolbar */}
      <nav>
        {homepageUrl !== location.pathname && (
          <IconButton color="primary" icon={faHome} iconSolid={faHomeSolid} to={homepageUrl} />
        )}
      </nav>

      {/* bottom of toolbar */}
      <nav>
        <Link className={classes.userIconWrap} to={accountUrl}>
          <div className={classes.userIcon}>{userInitials}</div>
        </Link>

        <CanView permission={'ui.toolbar.settings_icon'}>
          <IconButton color="primary" icon={faCog} iconSolid={faCogSolid} to={administrationUrl} />
        </CanView>

        <IconButton
          color="primary"
          icon={faExpandWide}
          iconSolid={faExpandWideSolid}
          onClick={() => {
            toggleFullscreen();
          }}
        />
        <IconButtonDropdown
          icon={faQuestionCircle}
          iconSolid={faQuestionCircleSolid}
          title={
            isPermitted('xpert.help.center.internal') && isPentairUser ? (
              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <span style={{ cursor: 'pointer' }} onClick={() => setViewVersion(true)}>
                  Xpert Version {version}
                </span>{' '}
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  onClick={() => setViewVersion(true)}
                  style={{ cursor: 'pointer', fontSize: 14, padding: 3 }}
                />
                {isPermitted('projections.xpert.update-version') && (
                  <FontAwesomeIcon
                    onClick={() => {
                      setEditVersion(true);
                    }}
                    icon={faEdit}
                    style={{ cursor: 'pointer', fontSize: 14, padding: 3, marginRight: -5 }}
                  />
                )}
              </div>
            ) : (
              'Xpert Version ' + version
            )
          }
          actions={[
            {
              label: 'Xpert Help Center',
              action: () => {
                if (isPermitted('xpert.help.center.internal')) {
                  window.open(
                    'https://teams.microsoft.com/l/team/19%3aaJkauOw1_ldmZ88BoVPgjpwjdehVALYyn0U5Y6gHLy01%40thread.tacv2/conversations?groupId=6b61ebe8-b2ab-451e-a466-7e0fee02d385&tenantId=8237194f-de9d-4e96-b705-d713a214c4ea',
                    '_blank'
                  );
                } else {
                  window.open('https://pentair.atlassian.net/servicedesk/customer/portal/8', '_blank');
                }
              },
              icon: faUserHeadset,
            },
            {
              label: 'Release Notes',
              action: () => {
                if (isPermitted('xpert.help.center.internal')) {
                  window.open(
                    'https://pentair.atlassian.net/wiki/spaces/XKB/pages/1324941315/Xpert+Release+Notes',
                    '_blank'
                  );
                } else {
                  window.open(
                    'https://pentair.atlassian.net/wiki/spaces/XKB/pages/1324941315/Xpert+Release+Notes ',
                    '_blank'
                  );
                }
              },
              icon: faHistory,
            },
            {
              label: 'Suggest a feature',
              action: () => {
                window.open(
                  'https://pentair.atlassian.net/servicedesk/customer/portal/8/group/33/create/132',
                  '_blank'
                );
              },
              icon: faPlusCircle,
            },
            {
              label: 'Report a bug',
              action: () => {
                window.open(
                  'https://pentair.atlassian.net/servicedesk/customer/portal/8/group/33/create/131',
                  '_blank'
                );
              },
              icon: faBug,
            },
          ]}
        />
        <IconButton color="primary" icon={faSignOut} iconSolid={faSignOutSolid} onClick={eventHandler(signOut)} />
        <EditVersionDialog
          open={editVersion}
          onClose={() => {
            setEditVersion(false);
          }}
        />
        <ViewVersionDetailsDialog
          open={viewVersion}
          onClose={() => {
            setViewVersion(false);
          }}
          version={version}
          proposalDataVersion={proposalDataVersions}
          error={proposalDataVersionError}
        />
      </nav>
    </aside>
  );
};
