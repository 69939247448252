/**
 *
 *  By Tim Steinz TSFL
 *
 */

import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  FormHelperText,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useStandbyMembraneElements } from '../../../../../../feature/projections/hooks/use-standby-membrane-elements';
import { CalculationValue } from '../../../../../v2/feature/solution/types/record';

//-----------------------------------------------------------------------------
//
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(0, 0, 3, 0),
    border: 'none',
    pageBreakInside: 'avoid',
  },
  tableRow: {
    border: 'none',
  },
  tableCell: {
    fontWeight: 600,
    padding: theme.spacing(0.2, 0, 0.2, 0),
    border: 0,
    verticalAlign: 'top',
  },
  tableValueCell: { fontWeight: 400 },
  before: {
    marginRight: theme.spacing(2),
  },
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(0),
  },
  suggestedValue: { opacity: 0.5 },
  listname: {
    paddingRight: theme.spacing(2),
    color: theme.palette.silverGray.main,
    fontWeight: 600,
    textAlign: 'left',
  },
  alignRight: {
    textAlign: 'right',
  },
  bold: { fontWeight: 700 },
  icon: {
    width: theme.spacing(2) + 'px !important',
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
})) as any;

// table cell component
const CustomTableCell: React.FC<{
  print?: boolean;
  text?: string;
  header?: boolean;
  colSpan?: number;
  maxWidth?: string;
  alignText?: 'left' | 'right' | 'center';
}> = (props) => {
  const classes = useStyles();
  return (
    <TableCell
      colSpan={props.colSpan}
      className={props.header ? classNames(classes.tableCell, classes.listname) : classes.tableCell}
      sx={{
        border: 'none',
        height: 'unset',
        maxWidth: props.maxWidth,
        padding: 0,
        paddingRight: props.header ? '3px' : '2px',
        textAlign: props.alignText || 'left',
      }}
    >
      <Typography
        gutterBottom
        variant={props.header ? undefined : 'body2'}
        fontSize={props.print ? 12 : 'inherit'}
        lineHeight={props.print ? '12px' : undefined}
        color="inherit"
        fontWeight={props.header ? 600 : undefined}
        paddingLeft={0.4}
      >
        {props.text}
      </Typography>
    </TableCell>
  );
};

//-----------------------------------------------------------------------------
// main component
export interface SystemConfigurationDutyStandbyProps {
  solution: string;
  print?: boolean;
  inputs: CalculationValue[];
  outputs: CalculationValue[];
  egu?: string;
}

export const SystemConfigurationDutyStandby: React.FC<SystemConfigurationDutyStandbyProps> = (props) => {
  const classes = useStyles();

  const {
    backwashTrains,
    designMargin,
    numberOfSpareMembraneElementsN,
    numberOfSpareMembraneElementsX,
    numberOfSpareMembraneElementsNX,
    numberOfMembraneElementsN,
    numberOfMembraneElementsX,
    numberOfMembraneElementsNX,
    numberOfMembraneRowsN,
    numberOfMembraneRowsX,
    numberOfMembraneRowsNX,
    numberOfMembraneHousingsN,
    numberOfMembraneHousingsX,
    numberOfMembraneHousingsNX,
    numberOfShorterRowsN,
    numberOfShorterRowsX,
    numberOfShorterRowsNX,
    totalMembraneAreaNX,
    totalMembraneAreaN,
    totalMembraneAreaX,
    totalMembraneElementsN,
    totalMembraneElementsNX,
    totalMembraneElementsX,
    totalUnitsN,
    totalUnitsNX,
    totalUnitsX,
    showAll,
  } = useStandbyMembraneElements(props.inputs, props.outputs, props.solution, props.egu);

  return (
    <Box component="div" className={classes.root} style={props.print ? { marginBottom: '14px' } : undefined}>
      <Box component="div" className={classes.titleWrap} style={{ marginBottom: props.print ? '6px' : undefined }}>
        <FontAwesomeIcon
          className={classes.icon}
          icon={faClipboardListCheck}
          style={
            props.print
              ? {
                  marginRight: '12px',
                  maxWidth: 20,
                  maxHeight: 20,
                }
              : undefined
          }
        />
        <Typography variant="h4" style={{ fontSize: props.print ? '12px' : undefined }}>
          System Configuration
        </Typography>
      </Box>

      {!showAll && !props.print && <Box sx={{ height: 20 }} />}

      <Table style={{ fontSize: props.print ? undefined : '0.825em' }}>
        {/* header */}
        <TableHead className={classes.tableRow}>
          <TableRow className={classes.tableRow} style={!showAll ? { minHeight: 20 } : undefined}>
            <CustomTableCell print={props.print} text={' '} header={true} alignText="right" />
            <CustomTableCell print={props.print} text={showAll ? 'N' : ' '} header={true} alignText="right" />
            {showAll && <CustomTableCell print={props.print} text={'X'} header={true} alignText="right" />}
            {showAll && <CustomTableCell print={props.print} text={'N+X'} header={true} alignText="right" />}
            <CustomTableCell print={props.print} text={' '} />
          </TableRow>
        </TableHead>

        <TableBody>
          {/* Total units */}
          <TableRow
            className={classes.tableRow}
            style={props.print ? { backgroundColor: 'rgba(0,0,0,0.03)' } : undefined}
          >
            <CustomTableCell
              print={props.print}
              text={showAll ? 'Total Duty (N) + Standby (X) Units' : 'Total Units*'}
              header={true}
            />
            <CustomTableCell
              print={props.print}
              text={new Intl.NumberFormat().format(Number(totalUnitsN))}
              alignText="right"
            />
            {showAll && (
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(totalUnitsX))}
                alignText="right"
              />
            )}
            {showAll && (
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(totalUnitsNX))}
                alignText="right"
              />
            )}
            <CustomTableCell print={props.print} text={''} />
          </TableRow>

          {/* membrane housings */}
          {props.solution === 'xiga' && (
            <TableRow className={classes.tableRow}>
              <CustomTableCell print={props.print} text={'Membrane Housings'} header={true} />
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(numberOfMembraneHousingsN))}
                alignText="right"
              />
              {showAll && (
                <CustomTableCell
                  print={props.print}
                  text={new Intl.NumberFormat().format(Number(numberOfMembraneHousingsX))}
                  alignText="right"
                />
              )}
              {showAll && (
                <CustomTableCell
                  print={props.print}
                  text={new Intl.NumberFormat().format(Number(numberOfMembraneHousingsNX))}
                  alignText="right"
                />
              )}
              <CustomTableCell print={props.print} text={'per unit'} />
            </TableRow>
          )}

          {/* membrane rows */}
          {props.solution === 'xline' && (
            <TableRow className={classes.tableRow}>
              <CustomTableCell print={props.print} text={'Membrane Rows'} header={true} />
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(numberOfMembraneRowsN))}
                alignText="right"
              />
              {showAll && (
                <CustomTableCell
                  print={props.print}
                  text={new Intl.NumberFormat().format(Number(numberOfMembraneRowsX))}
                  alignText="right"
                />
              )}
              {showAll && (
                <CustomTableCell
                  print={props.print}
                  text={new Intl.NumberFormat().format(Number(numberOfMembraneRowsNX))}
                  alignText="right"
                />
              )}
              <CustomTableCell print={props.print} text={'per unit'} />
            </TableRow>
          )}

          {/* membrane elements */}
          <TableRow className={classes.tableRow}>
            <CustomTableCell print={props.print} text={'Membrane Elements'} header={true} />
            <CustomTableCell
              print={props.print}
              text={new Intl.NumberFormat().format(Number(numberOfMembraneElementsN))}
              alignText="right"
            />
            {showAll && (
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(numberOfMembraneElementsX))}
                alignText="right"
              />
            )}
            {showAll && (
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(numberOfMembraneElementsNX))}
                alignText="right"
              />
            )}
            {props.solution === 'xline' && <CustomTableCell print={props.print} text={'per row'} />}
            {props.solution === 'xiga' && <CustomTableCell print={props.print} text={'per housing'} />}
            {props.solution !== 'xline' && props.solution !== 'xiga' && (
              <CustomTableCell print={props.print} text={'per unit'} />
            )}
          </TableRow>

          {/* total membrane elements */}
          <TableRow
            className={classes.tableRow}
            style={props.print ? { backgroundColor: 'rgba(0,0,0,0.03)' } : undefined}
          >
            <CustomTableCell print={props.print} text={'Total Membrane Elements'} header={true} />
            <CustomTableCell
              print={props.print}
              text={new Intl.NumberFormat().format(Number(totalMembraneElementsN))}
              alignText="right"
            />
            {showAll && (
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(totalMembraneElementsX))}
                alignText="right"
              />
            )}
            {showAll && (
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(totalMembraneElementsNX))}
                alignText="right"
              />
            )}
            <CustomTableCell print={props.print} text={''} />
          </TableRow>

          {/* spare membrane elements */}
          <TableRow className={classes.tableRow}>
            {props.solution === 'xiga' && (
              <CustomTableCell print={props.print} text={'Spare Membrane Housings'} header={true} />
            )}
            {props.solution !== 'xiga' && (
              <CustomTableCell print={props.print} text={'Spare Membrane Elements'} header={true} />
            )}
            <CustomTableCell
              print={props.print}
              text={new Intl.NumberFormat().format(Number(numberOfSpareMembraneElementsN))}
              alignText="right"
            />
            {showAll && (
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(numberOfSpareMembraneElementsX))}
                alignText="right"
              />
            )}
            {showAll && (
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(numberOfSpareMembraneElementsNX))}
                alignText="right"
              />
            )}
            <CustomTableCell print={props.print} text={props.solution === 'xline' ? 'per row' : 'per unit'} />
          </TableRow>

          {/* shorter rows */}
          {props.solution === 'xline' && (
            <TableRow className={classes.tableRow}>
              <CustomTableCell print={props.print} text={'Shorter Rows'} header={true} />
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(numberOfShorterRowsN))}
                alignText="right"
              />
              {showAll && (
                <CustomTableCell
                  print={props.print}
                  text={new Intl.NumberFormat().format(Number(numberOfShorterRowsX))}
                  alignText="right"
                />
              )}
              {showAll && (
                <CustomTableCell
                  print={props.print}
                  text={new Intl.NumberFormat().format(Number(numberOfShorterRowsNX))}
                  alignText="right"
                />
              )}
              <CustomTableCell print={props.print} text={'per unit'} />
            </TableRow>
          )}

          {/* total membrane area */}
          <TableRow
            className={classes.tableRow}
            style={props.print ? { backgroundColor: 'rgba(0,0,0,0.03)' } : undefined}
          >
            <CustomTableCell print={props.print} text={'Total Membrane Area'} header={true} />
            <CustomTableCell
              print={props.print}
              text={new Intl.NumberFormat().format(Number(totalMembraneAreaN))}
              alignText="right"
            />
            {showAll && (
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(totalMembraneAreaX))}
                alignText="right"
              />
            )}
            {showAll && (
              <CustomTableCell
                print={props.print}
                text={new Intl.NumberFormat().format(Number(totalMembraneAreaNX))}
                alignText="right"
              />
            )}
            <CustomTableCell print={props.print} text={props.egu === 'imperial' ? 'ft²' : 'm²'} />
          </TableRow>

          {/* backwash trains */}
          <TableRow className={classes.tableRow}>
            <CustomTableCell print={props.print} text={'Backwash Trains'} header={true} />
            <CustomTableCell print={props.print} text={backwashTrains} alignText="right" />
            {showAll && <CustomTableCell print={props.print} text="" />}
            {showAll && <CustomTableCell print={props.print} text={''} />}
            <CustomTableCell print={props.print} text="" />
          </TableRow>

          {/* design margin */}
          <TableRow
            className={classes.tableRow}
            style={props.print ? { backgroundColor: 'rgba(0,0,0,0.03)' } : undefined}
          >
            <CustomTableCell print={props.print} text={'Design Margin'} header={true} />
            <CustomTableCell
              print={props.print}
              text={new Intl.NumberFormat().format(Number(designMargin))}
              alignText="right"
            />
            {showAll && <CustomTableCell print={props.print} text="" />}
            {showAll && <CustomTableCell print={props.print} text={''} />}
            <CustomTableCell print={props.print} text={'%'} />
          </TableRow>
        </TableBody>
      </Table>
      {/* footnotes */}
      {!showAll && (
        <FormHelperText sx={{ paddingLeft: 0.5, paddingRight: 0.5 }}>
          {/* <Box component="span" sx={{ fontStyle: 'italic' }}> */}
          (*Duty units only. No standby considered.)
          {/* </Box> */}
        </FormHelperText>
      )}
    </Box>
  );
};
export default SystemConfigurationDutyStandby;
