import { Grid, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { ProjectionRecord } from '../../../../../application/resources/projection/projection';

import { DialogHover } from '../../../../../v2/feature/projection/view/design/partials/dialog-hover';
import { DialogMarker } from '../../../../../v2/feature/projection/view/design/partials/dialog-marker';
import { HiddenMarker } from '../../../../../v2/feature/projection/view/design/partials/hidden-marker';
import { useSolution } from '../../../../../v2/feature/solution/hooks/use-single';
import { StyleProjectionPages } from '../../styles-projection-pages';
import { ProjectionDataTable } from '../projection-data-table';
import { ProjectionDataTableCEBChemicals } from '../projection-table-ceb-chemicals';

import { ProjectionDataTableKeyValue } from '../projection-data-table-key-value';
import { AquaflexInteractiveImage } from '../interactive-image/aqueflex';
import { ProjectionContext } from '../../../../../v2/feature/projection/context/projection-context';
import { ProjectionClass } from '../../../../../application/resources/projection/v2/single';
import { KeyValueTable } from '../../key-value-table';

import { PageLoading } from '../../../../../v2/common/pages/page-loading';
import { calculationValueById } from './helpers/calculation-value-by-id';
import { projectionValueToString } from './helpers/projection-value-to-string';
import { CEBConsumptionsData } from './helpers/ceb-consumptions-data';
import { ProjectionDescription } from '../../../../../../feature/projections/components/projection-description';
import { ProjectionLegend } from '../../../../../../feature/projections/components/image/legend';
import { Divider } from '@mui/material';
import { CalculationValue } from '../../../../../v2/feature/solution/types/record';
import SystemConfigurationDutyStandby from './system-config-duty-standby';
import { usePermissions } from '../../../../../../feature/auth/auth-permissions';
import { useTranslation } from 'react-i18next';

export const parseData = (data: any[]) => data.filter((item) => item); // should clear all item === undefined (and emtpy strings/0/null/etc)

export const hideNumberLogic = (projection: ProjectionClass) => {
  return {
    '1': false,
    '2': false,
    '3': calculationValueById('chm_coagulant', projection)?.values[0] === '',
    '4': calculationValueById('ucp_chemical_agent_1', projection)?.values[0] === '',
    '5': calculationValueById('ucp_chemical_agent_2', projection)?.values[0] === '',
    '6': calculationValueById('ucp_chemical_agent_3', projection)?.values[0] === '',
    ucl_average_airflush_flow:
      parseFloat(String(calculationValueById('airflush_duration', projection)?.values[0]) || '0') <= 0,
    '8':
      projection.record.solution?.id === 'aquaflex-hs' ||
      calculationValueById('internal_recirculation', projection)?.values[0] === 'false',
  };
};

// end helper functions -----------------------------------

interface OwnProps extends StyleProjectionPages {
  onChange: (key: any, value: any) => void;
}

// styles
const useStyles = makeStyles((theme: Theme) => ({
  markerTitle: {
    fontWeight: 700,
  },
  legendContainer: {
    paddingTop: theme.spacing(4),
  },
  legendContainerPrint: {
    marginTop: theme.spacing(1),
  },
  legendItem: {},
  legendItemPrint: {},
  settingsContainer: {
    paddingTop: '24px',
    marginTop: '24px',
  },
  settingsContainerPrint: {
    display: 'table',
  },
  settingsItem: {},
  settingsItemPrint: {
    display: 'inline-block',
    width: 'calc(50% - 24px)',
    padding: '0 12px',
  },
  tooltip: {
    marginRight: '8px',
    overflow: 'hidden',
    border: '1px solid #fff',
    width: '40px',
    height: '40px',
    textAlign: 'center',
    borderRadius: '50%',
    background: theme.palette.green.main,
    position: 'relative',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editAction: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    cursor: 'pointer',
    display: 'none',
    color: theme.palette.primary.main,
    fontSize: theme.spacing(1.5),
  },
  projectionDescription: {
    border: '1px solid transparent',
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    '&:hover': {
      border: '1px dashed ' + theme.palette.softGray.main,
      '& $editAction': {
        display: 'block',
      },
    },
  },
  projectionDescriptionActive: {
    border: '1px dashed ' + theme.palette.softGray.main,
    '& $editAction': {
      display: 'block',
    },
  },
  interactiveImage: {
    marginBottom: theme.spacing(2),
  },
  printText: {
    fontSize: 12,
  },
  projectionInputContainerPrint: {
    paddingTop: '8px',
    display: 'table', // fix for break-inside; does not work with display: flex
  },
  printLegends: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  projectionInputItemPrint: {
    display: 'inline-block', // fix for break-inside; does not work with display: flex
    breakInside: 'avoid',
    width: 'calc(50% - 24px)',
    padding: '0 12px',
  },
}));

// container function
export const AquaflexDesignResult: React.FC<OwnProps> = (props) => {
  const { t } = useTranslation();
  const { onChange } = props;
  const projection = React.useContext(ProjectionContext);

  // no record
  // const { userProfile } = React.useContext(UserContext); // NO RECORD
  // (
  //   userProfile &&
  //   userProfile.record &&
  //   userProfile.record.preferences?.find((preference) => preference.id === 'engineering_unit')
  // )?.value || 'metric';

  const { userProfile } = usePermissions();
  const egu = userProfile.preferences?.find((p) => p?.id === 'engineering_unit')?.value || 'metric';

  const [loading, setLoading] = React.useState(false);
  const classes = (useStyles as any)();
  const solution = useSolution((projection.record.solution && projection.record.solution.id) || '');

  const handleChange = React.useCallback(
    (key: keyof ProjectionRecord, value: any) => {
      if (onChange && typeof onChange === 'function') {
        onChange(key, value);
      }
      if (key === 'description') {
        projection.change(key, value);
      }
    },
    [projection, onChange]
  );

  React.useEffect(() => {
    if ((!solution || Object.keys(solution).length === 0 || Object.keys(solution.record).length === 0) && !loading) {
      setLoading(true);
      solution.fetch().then(() => setLoading(false));
    }
  }, [solution, projection, loading]);

  if (loading) {
    return (
      <PageLoading
        message={t('projections:loadingProjection', { defaultValue: 'Loading projection preview en settings' })}
        iconScale={0.6}
        textVariant="body2"
      />
    );
  }

  return (
    <React.Fragment>
      <AquaflexDesignResultImage handleChange={handleChange} egu={egu} />

      <Grid container spacing={4} className={classes.legendContainer}>
        <Grid item xs={12} md={6}>
          <ProjectionDescription egu={egu} handleChange={handleChange} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AquaflexDesignResultImageLegend egu={egu} />
        </Grid>
      </Grid>

      <Divider sx={{ marginY: 2 }} />

      <AquaflexDesignResultSettings egu={egu} />
    </React.Fragment>
  );
};

//-----------------------------------------------

// image part
export const AquaflexDesignResultImage: React.FC<{
  handleChange: (key: any, value: any) => void;
  print?: boolean;
  egu?: string;
}> = (props) => {
  const { handleChange, egu } = props;
  const projection = React.useContext(ProjectionContext);
  const classes = (useStyles as any)(props);

  return (
    <>
      {projection.record.solution?.techImage && projection.record.solution.techImageMap && (
        <div className={classes.interactiveImage}>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <AquaflexInteractiveImage
              img={projection.record.solution.techImage}
              inputs={projection.record.inputs || []}
            />
            <div style={{ fontSize: props.print ? '70%' : 'calc(.8vw)' }}>
              {projection.record.solution.techImageMap.map((marker, index) => {
                return (
                  <React.Fragment key={index}>
                    {marker.type === 'dialog_form' && (
                      <React.Fragment>
                        <DialogMarker projection={projection} marker={marker} onChange={handleChange} />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}
              {projection.record.solution.techImageMap.map((marker, index) => {
                return (
                  <React.Fragment key={index}>
                    {marker.type === 'hover' && (
                      <DialogHover
                        projection={projection}
                        marker={marker}
                        onChange={handleChange}
                        print={props.print}
                      />
                    )}
                    {marker.type === 'key_value' &&
                      !hideNumberLogic(projection)[
                        (marker.key === 'ucl_average_airflush_flow' && 'ucl_average_airflush_flow') ||
                          (marker.key === 'internal_circulation_flow' && 8) ||
                          1
                      ] && (
                        <div
                          style={{
                            position: 'absolute',
                            fontSize: props.print ? '85%' : undefined,
                            left: marker.location[0] + '%',
                            top: marker.location[1] + '%',
                            textAlign: marker.centeredText === 'true' ? 'center' : 'left',
                            transform: 'rotate(-' + marker.rotation + 'deg)',
                          }}
                        >
                          {marker.splitKeyValue === 'true' && marker.title !== 'Internal Circulation flow' && (
                            <React.Fragment>
                              {marker.title + ': '}
                              <br />
                            </React.Fragment>
                          )}
                          {marker.splitKeyValue === 'false' && <React.Fragment>{marker.title + ': '}</React.Fragment>}
                          {marker.title === 'Internal Circulation flow' && (
                            <React.Fragment>
                              Internal Circulation: <br /> Flow:{' '}
                            </React.Fragment>
                          )}
                          {projectionValueToString(marker.key, projection, egu)}
                        </div>
                      )}
                    {marker.type === 'hidden_marker' && <HiddenMarker marker={marker} />}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

//-----------------------------------------------

export const AquaflexDesignResultImageLegend: React.FC<{
  print?: any;
  egu?: string;
}> = (props) => {
  const projection = React.useContext(ProjectionContext);

  const valueof = (key: string) => {
    return projectionValueToString(key, projection, props.egu);
  };

  return (
    <ProjectionLegend
      print={props.print}
      data={[
        {
          hidden: Boolean(hideNumberLogic(projection)[1]),
          title: 'Feed pump(s)',
          value: `${valueof('ucl_number_of_feed_pumps')}x  ${valueof('ucl_filtration_pump_flow')}`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)[2]),
          title: 'Backwash pump(s)',
          value: `${valueof('ucl_number_of_backwash_pumps')}x  ${valueof('ucl_backwash_pump_flow')}`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)[3]),
          title: 'Coagulant Dosing Pump:',
          // value: `${valueof('ucl_number_of_coagulant_dosing_pumps')}x  ${valueof('ucl_coagulant_dosing_flow')}`,
          value: `${valueof('ucl_coagulant_dosing_flow')} per complete UF system`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)[4]),
          title: valueof('ucp_chemical_agent_1') + ' Dosing Pump',
          value: `${valueof('ucl_number_of_backwash_pumps')}x  ${valueof('chem_dosing_pump_1')}`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)[5]),
          title: valueof('ucp_chemical_agent_2') + ' Dosing Pump',
          value: `${valueof('ucl_number_of_backwash_pumps')}x  ${valueof('chem_dosing_pump_2')}`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)[6]),
          title: valueof('ucp_chemical_agent_3') + ' Dosing Pump',
          value: `${valueof('ucl_number_of_backwash_pumps')}x  ${valueof('chem_dosing_pump_3')}`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)['ucl_average_airflush_flow']),
          title: 'Compressed Air for Airflush',
          value: `${valueof('ucl_number_of_backwash_pumps')}x  ${valueof('out_af_compressor')}`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)[8]),
          title: 'Internal Circulation Pump',
          value: `${valueof('total_units')}x  ${valueof('out_internal_circulation_pump')}`,
        },
      ]}
    />
  );
};

//-----------------------------------------------

// settings part
export const AquaflexDesignResultSettings: React.FC<{
  print?: any;
  egu?: string;
}> = (props) => {
  const projection = React.useContext(ProjectionContext);
  const { egu } = props;
  const classes = (useStyles as any)();
  const sizeXs = props.print ? 6 : 12;
  const sizeMd = 6;
  const sizeLg = props.print ? 6 : 4;

  const calculateBackWashFlow = React.useCallback(() => {
    /*
      This is calculated for Aquaflex by:
      ( number_of_membrane_elements * (plant_total_area/plant_total_membranes) * backwash_flux ) / 1000
    */
    let amountOfMembranes = calculationValueById('membrane_elements', projection)?.values[0] || 0;
    let plantTotalArea = calculationValueById('plant_total_area', projection)?.values[0] || 0;
    let plantTotalMembranes = calculationValueById('plant_total_membrane_elements', projection)?.values[0] || 0;
    let backwashFlux = calculationValueById('backwash_flux', projection)?.values[0] || 0;

    if (typeof amountOfMembranes === 'string') {
      amountOfMembranes = parseFloat(amountOfMembranes);
    }
    if (typeof plantTotalArea === 'string') {
      plantTotalArea = parseFloat(plantTotalArea);
    }
    if (typeof plantTotalMembranes === 'string') {
      plantTotalMembranes = parseFloat(plantTotalMembranes);
    }
    if (typeof backwashFlux === 'string') {
      backwashFlux = parseFloat(backwashFlux);
    }

    const CalculatedBackwashFlow = (
      (amountOfMembranes * (plantTotalArea / plantTotalMembranes) * backwashFlux) /
      1000
    ).toFixed(1);

    const backwashFlow: CalculationValue = {
      // @ts-ignore :: backwash_flow is not in the allowed input/output id-strings
      id: 'backwash_flow',
      // @ts-ignore :: backwash_flow is not in the allowed calculation_field id-strings
      calculation_field: 'backwash_flow',
      name: 'Backwash Flow',
      values: [CalculatedBackwashFlow],
      recommendedValues: [0],
      unit: 'm³/h',
      unitOptions: ['m³/h', 'GPM'],
      displayUnit: 'm³/h',
    };
    return backwashFlow;
  }, [projection]);

  return (
    <>
      <Grid
        container
        spacing={props.print ? 0 : 4}
        className={props.print ? classes.settingsContainerPrint : classes.settingsContainer}
      >
        {!props.print && (
          <>
            <Grid
              item
              xs={sizeXs}
              md={sizeMd}
              lg={sizeLg}
              className={props.print ? classes.settingsItemPrint : classes.settingsItem}
            >
              <ProjectionDataTable
                egu={egu}
                title={'Projection Inputs'}
                print={props.print}
                data={parseData([
                  calculationValueById('water_source', projection),
                  calculationValueById('flow', projection),
                  calculationValueById('flow_base', projection),
                  calculationValueById('design_turbidity', projection),
                  calculationValueById('membrane_element', projection),
                  projection.record.solution?.id !== 'aquaflex-hs' &&
                    calculationValueById('membrane_housing', projection),
                ])}
              />
            </Grid>
            <Grid
              item
              xs={sizeXs}
              md={sizeMd}
              lg={sizeLg}
              className={props.print ? classes.settingsItemPrint : classes.settingsItem}
            >
              <ProjectionDataTable
                egu={egu}
                print={props.print}
                title={'Main feed water parameters'}
                data={parseData([
                  calculationValueById('turbidity', projection),
                  calculationValueById('tss', projection),
                  calculationValueById('toc', projection),
                  calculationValueById('cod', projection),
                  calculationValueById('color', projection),
                ])}
              />
            </Grid>
            <Grid
              item
              xs={sizeXs}
              md={sizeMd}
              lg={sizeLg}
              className={props.print ? classes.settingsItemPrint : classes.settingsItem}
            >
              <ProjectionDataTable
                egu={egu}
                print={props.print}
                title={'Additional water parameters'}
                data={parseData([
                  calculationValueById('temperature', projection),
                  calculationValueById('alcalinity', projection),
                  calculationValueById('ph', projection),
                  calculationValueById('tds', projection),
                ])}
              />
            </Grid>
            <Grid
              item
              xs={sizeXs}
              md={sizeMd}
              lg={sizeLg}
              className={props.print ? classes.settingsItemPrint : classes.settingsItem}
            >
              <SystemConfigurationDutyStandby
                inputs={projection?.record?.inputs || []}
                outputs={projection?.record?.outputs || []}
                print={props.print}
                solution="aquaflex-new"
                egu={props.egu}
              />
            </Grid>
          </>
        )}
        <Grid
          item
          xs={sizeXs}
          md={sizeMd}
          lg={sizeLg}
          className={props.print ? classes.settingsItemPrint : classes.settingsItem}
        >
          <ProjectionDataTable
            egu={egu}
            title={'Main settings & calculations'}
            print={props.print}
            data={parseData([
              calculationValueById('filtration_flux', projection),
              calculationValueById('mb_out_calc_net_filtration_flux', projection),
              calculationValueById('filtration_duration', projection),
              calculationValueById('net_filtration_flux', projection),
              calculationValueById('mb_out_calc_recovery', projection),
              calculationValueById('mb_out_ceb_interval', projection),
              calculationValueById('mb_out_ceb_interval_2', projection),
              calculationValueById('ceb_1_counter', projection),
              calculationValueById('ceb_2_counter', projection),
            ])}
          />
        </Grid>
        {calculationValueById('chm_coagulant', projection)?.values[0] && (
          <Grid
            item
            xs={sizeXs}
            md={sizeMd}
            lg={sizeLg}
            className={props.print ? classes.settingsItemPrint : classes.settingsItem}
          >
            <ProjectionDataTableKeyValue
              title={'COAGULATION DETAILS'}
              print={props.print}
              data={[
                {
                  title: 'Coagulant Chemical',
                  value:
                    projectionValueToString('chm_coagulant', projection, egu) +
                    ' ' +
                    projectionValueToString('chm_coagulant_override_stock_conc_wtpercent', projection, egu) +
                    ' as ' +
                    projectionValueToString('chm_coagulant_stock_conc', projection, egu),
                },
                {
                  title: 'Max. Concentration',
                  value: projectionValueToString('user_max_coagulant_concentration', projection, egu),
                },
                {
                  title: 'Typical Concentration',
                  value: projectionValueToString('user_typ_coagulant_concentration', projection, egu),
                },
                {
                  title: 'Typical Consumption',
                  value: projectionValueToString('ucl_typ_usage_coagulant', projection, egu),
                },
              ]}
            />
          </Grid>
        )}
        <Grid
          item
          xs={sizeXs}
          md={sizeMd}
          lg={sizeLg}
          className={props.print ? classes.settingsItemPrint : classes.settingsItem}
        >
          <ProjectionDataTable
            egu={egu}
            title={'Hydraulic clean settings'}
            print={props.print}
            data={parseData([
              calculationValueById('forward_flush_flux', projection),
              calculationValueById('ff_flow', projection),
              calculationValueById('forward_flush_duration', projection),
              calculationValueById('backwash_flux', projection),
              calculateBackWashFlow(), /// custom formula !! should be moved to calculation engine
              calculationValueById('backwash_duration', projection),
              parseInt(
                String(
                  calculationValueById('airflush_duration', projection) &&
                    calculationValueById('airflush_duration', projection)?.values[0]
                )
              ) > 0 && calculationValueById('out_af_flow', projection),
              parseInt(
                String(
                  calculationValueById('airflush_duration', projection) &&
                    calculationValueById('airflush_duration', projection)?.values[0]
                )
              ) > 0 && calculationValueById('airflush_duration', projection),
              calculationValueById('valve_switching', projection),
              calculationValueById('backwash_pump_ramping', projection),
            ])}
          />
        </Grid>
        <Grid
          item
          xs={sizeXs}
          md={sizeMd}
          lg={sizeLg}
          className={props.print ? classes.settingsItemPrint : classes.settingsItem}
        >
          <ProjectionDataTable
            egu={egu}
            title={'CEB Settings'}
            print={props.print}
            data={parseData([
              calculationValueById('ceb_1_a_dosing_flux', projection),
              calculationValueById('ucp_chemical_dosing_flow', projection),
              calculationValueById('ceb_1_a_dosing_duration', projection),
              calculationValueById('ceb_1_a_post_dosing_duration', projection),
              calculationValueById('ceb_1_a_soaking_time', projection),
              calculationValueById('ceb_1_a_rinsing_flux', projection),
              calculationValueById('rinsing_flow', projection),
              calculationValueById('ceb_1_a_rinsing_duration', projection),
              calculationValueById('ceb_1_b_rinsing_duration', projection),
            ])}
          />
        </Grid>

        {/* {props.print ? <Grid item xs={12} sx={{ pageBreakAfter: 'always' }} /> : null} */}

        <Grid
          item
          xs={sizeXs}
          md={sizeMd}
          lg={sizeLg}
          className={props.print ? classes.settingsItemPrint : classes.settingsItem}
        >
          <ProjectionDataTableCEBChemicals
            print={props.print}
            title={'CEB Chemicals'}
            ceb_1a_chemical_1={calculationValueById('ceb_1_a_chemical_agent_1', projection)}
            ceb_1a_chemical_2={calculationValueById('ceb_1_a_chemical_agent_2', projection)}
            ceb_1b_chemical_1={calculationValueById('ceb_1_b_chemical_agent_1', projection)}
            ceb_1b_chemical_2={calculationValueById('ceb_1_b_chemical_agent_2', projection)}
            ceb_2a_chemical_1={calculationValueById('ceb_2_a_chemical_agent_1', projection)}
            ceb_2a_chemical_2={calculationValueById('ceb_2_a_chemical_agent_2', projection)}
            ceb_2b_chemical_1={calculationValueById('ceb_2_b_chemical_agent_1', projection)}
            ceb_2b_chemical_2={calculationValueById('ceb_2_b_chemical_agent_2', projection)}
          />
        </Grid>
        <Grid
          item
          xs={sizeXs}
          md={sizeMd}
          lg={sizeLg}
          className={props.print ? classes.settingsItemPrint : classes.settingsItem}
        >
          <KeyValueTable title={'CEB Consumptions'} print={props.print} data={CEBConsumptionsData(projection, egu)} />
        </Grid>
        {String(
          (calculationValueById('mit_interval', projection) &&
            calculationValueById('mit_interval', projection)?.values[0]) ||
            '0'
        ) !== '0' && (
          <Grid
            item
            xs={sizeXs}
            md={sizeMd}
            lg={sizeLg}
            className={props.print ? classes.projectionInputItemPrint : undefined}
          >
            <ProjectionDataTable
              egu={egu}
              print={props.print}
              title={'MIT Settings'}
              data={parseData([
                calculationValueById('mit_interval', projection),
                calculationValueById('mit_duration', projection),
              ])}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
