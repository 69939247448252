import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';

import * as React from 'react';
import { faClipboardListCheck } from '@fortawesome/pro-light-svg-icons';
import { SectionTitle } from '../../../../../../theme/components/section-title';

interface OwnProps {
  data: { key: string; value: any }[];
}

export const ProjectionCalculationValueList: React.FC<OwnProps> = (props) => {
  const { data } = props;

  return (
    <>
      <SectionTitle title="Summary of Inputs" icon={faClipboardListCheck} />
      <Table size="small">
        <TableBody>
          {data.map((row) => {
            return (
              <React.Fragment key={row.key}>
                {(typeof row.value === 'string' || typeof row.value === 'number') && (
                  <TableRow>
                    <TableCell padding="none" sx={{ border: 'none' }}>
                      <Typography fontWeight={600} color={'inherit'} fontSize={15} gutterBottom>
                        {row.key}
                      </Typography>
                    </TableCell>

                    <TableCell padding="none" sx={{ border: 'none' }}>
                      <Typography fontSize={15} gutterBottom>
                        {row.value || '-'}
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </React.Fragment>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
