import { cloneDeep, merge } from 'lodash';
import { CalculationValue } from '../../../../v2/feature/solution/types/record';
import { CalculationValueOptions } from '../../technology/calculation-values';
import { ProjectionRecord } from '../projection';
import { createProjection, InputProjection } from './functions/create';
import { deleteProjection } from './functions/delete';
import { fetchProjection } from './functions/fetch';
import { projectionCalculationCreate } from './functions/get-advised-values';
import { updateProjection } from './functions/update';

import { ProjectionCalculationCreateFragment, ProjectionCalculationUpdateFragment } from '../graphql/fragments';
import { projectionCalculationUpdate } from './functions/run-calculation';

export class ProjectionClass {
  private _record: Partial<ProjectionRecord> = {};
  private _changes: Partial<ProjectionRecord> = {};
  private _savedRecordState: Partial<ProjectionRecord> = {};

  public isFetching: boolean = false;
  public isDeleting: boolean = false;
  public isUpdating: boolean = false;
  public isCreating: boolean = false;

  public get record(): Partial<ProjectionRecord> {
    const record = { ...cloneDeep(this._record), ...this._changes };

    return record;
  }

  public set record(record: Partial<ProjectionRecord>) {
    if (record) {
      this._record = record;
    }
  }

  public get oriRecord(): Partial<ProjectionRecord> {
    return this._record;
  }

  public get savedRecordState(): Partial<ProjectionRecord> {
    return this._savedRecordState;
  }

  public setSavedRecordState = () => {
    this._savedRecordState = cloneDeep(this.record);
  };

  public get changes(): Partial<ProjectionRecord> {
    return this._changes;
  }

  constructor(public id?: string, public fragment?: string, private _setState?: (state: ProjectionClass) => void) {
    this.record.id = id;
  }

  public reset = () => {
    this._changes = cloneDeep(this._savedRecordState);
  };

  public setState = <T extends Record<string, any>>(obj?: T, k?: keyof T, value?: any) => {
    if (obj && k) {
      obj[k] = value;
    }

    if (this._setState) {
      this._setState({ ...this });
    }
  };

  public handleResponse = (record: Partial<ProjectionRecord> | null | undefined, errors: Error[] = []) => {
    this._record = merge(this._record, record);
    this._savedRecordState = cloneDeep(this._record);
    // this.errors = errors;
    this.setState();
  };

  public get input(): Partial<InputProjection> {
    // Bug in take function: Take function doesn't return false/undefined/null. Will be fixed with lodash in phase 2.

    return {
      id: this.record.id,
      name: this.record.name,
      project: this.record.project || undefined,
      inputs: this.record.inputs,
      outputs: this.record.outputs,
      advisedInputs: this.record.advisedInputs,
      version: this.record.version,
      status: this.record.status,
      pdf: this.record.pdf,
      units: this.record.units,
      createdBy: this.record.createdBy,
      approver: this.record.approver || undefined,
      solution: this.record.solution || undefined,
      note: this.record.note,
      calcState: this.record.calcState,
      description: this.record.description,
      guidelines: this.record.guidelines,
      awaitingReview: this.record.awaitingReview,
      designedWith: this.record.designedWith,
      stockChemicals: this.record.stockChemicals,
    } as InputProjection;
  }

  public change = (key: keyof ProjectionRecord, value: any) => {
    // DEBUG TIME
    // console.log('projection.change() has been called');
    // console.log('key', key, 'value', value);
    // try {
    //   // doesn't work, strict mode
    //   // console.log('called from', this.change.caller);

    //   throw new Error('get stacktrace');
    // } catch (err) {
    //   const yError = err as Error;
    //   console.log(
    //     'called from:',
    //     // @ts-ignore
    //     yError.stack?.substring(0, 1000)
    //   );
    // }

    this._changes[key] = value;
    this.setState();
  };

  public fetch = async (version?: string) => {
    if (!this.id) {
      throw new Error('ID is not set');
    }

    const res = await fetchProjection({
      variables: { id: this.id, version },
      query: { fragment: this.fragment },
    });

    this.handleResponse(res.data && res.data.Projection, res.errors);

    return res;
  };

  public create = async () => {
    const input: any = this.input;
    delete input.solution;
    input.solution = this.input.solution?.id || '';

    const res = await createProjection({
      variables: { input: { ...input } },
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.createProjection, res.errors);
    return res;
  };

  public update = async () => {
    const inputs: any = this.input;

    // inputs.advisedInputs = undefined;
    inputs.solution = undefined;
    inputs.project = undefined;
    inputs.createdBy = undefined;

    const res = await updateProjection({
      variables: { input: inputs },
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.updateProjection, res.errors);
    return res;
  };

  public delete = async () => {
    if (!this.id) {
      throw new Error('ID is not set');
    }
    const res = await deleteProjection({
      variables: { id: this.id },
      query: { fragment: this.fragment },
    });
    return res;
  };

  public value = (id: CalculationValueOptions) => {
    return {
      ...this.valueMap[id],
    };
  };

  public get code() {
    if (this.id) {
      return this.id.substr(0, 8).toUpperCase();
    }

    return null;
  }

  public updateValue = (id: string, valueIndex: number, value: string) => {
    const calcVal = (this.record.inputs || []).find((val) => val.id === id);

    if (calcVal) {
      const values = [...(calcVal.values || [])];
      values[valueIndex] = value;

      calcVal.values = values;
      this.valueMap[id].values = values;
    }
  };

  public getAdvisedInputs = async (inputs: string) => {
    const res = await projectionCalculationCreate({
      variables: { inputs },
      query: { fragment: ProjectionCalculationCreateFragment },
    });

    return res.data && res.data.projectionCalculationCreate;
  };

  public runCalculation = async (inputs: string, state: string) => {
    const res = await projectionCalculationUpdate({
      variables: { inputs, state },
      query: { fragment: ProjectionCalculationUpdateFragment },
    });

    return res.data && res.data.projectionCalculationUpdate;
  };

  public get categorizedValues() {
    const values = this.record.inputs || [];
    return values.reduce((result: Record<string, [CalculationValue]>, valueData: CalculationValue) => {
      if (valueData.category) {
        result[valueData.category] = result[valueData.category] || [];
        result[valueData.category].push(valueData);
      }
      return result;
    }, {});
  }

  public get valueMap() {
    const values = this.record.inputs || [];
    return values.reduce((result, valueData: CalculationValue) => {
      if (valueData.id) {
        result[valueData.id] = valueData;
      }
      return result;
    }, {} as Record<CalculationValueOptions, CalculationValue>);
  }
}
