import { createContext, useEffect, PropsWithChildren, useRef } from 'react';
import { useVersion } from '../hooks/use-version';

interface VersionContextRecord {
  version: string;
  loading: boolean;
  update: (version: string) => Promise<void>;
}

/// MAIN CONTEXT
export const VersionContext = createContext<VersionContextRecord>({
  version: '',
  update: async (version: string) => {},
  loading: false,
});

// MAIN CONTEXT PROVIDER
export const VersionContextProvider: React.FC<PropsWithChildren<{ disabled?: boolean }>> = ({ children, disabled }) => {
  const { version, fetch: fetchVersion, update, loading } = useVersion();

  const retryRef = useRef<number>(1); // do not set to 0

  // no version, let's get some
  useEffect(() => {
    if (!disabled) {
      if (!version && !loading && retryRef && retryRef.current < 5) {
        fetchVersion();
        retryRef.current = retryRef.current + 1;
      }
    }
  }, [version, fetchVersion, loading, disabled]);

  return (
    <VersionContext.Provider value={{ version: version || '-', update, loading }}>{children}</VersionContext.Provider>
  );
};
