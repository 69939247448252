import {
  CalculationValue,
  FeedWaterName,
  ModuleTypeName,
  PresetConfiguration,
} from '../../../solution/types/record';
import { getCalculationValue } from '../../helpers/calculation-values';
import { calcModuleNameFromInputs } from '../projection/membrane-module';

export const getSolutionIndex = (id?: string | null) => {
  // return (
  //   (id && ((id === 'aquaflex-new' && 1) || (id === 'xline' && 2) || (id === 'xiga' && 0))) || 0
  // );

  switch (id) {
    case 'xiga':
      return 0;

    case 'aquaflex-new':
    case 'aquaflex':
    case 'aquaflex-hs':
      return 1;

    case 'xline':
      return 2;

    default:
      throw new Error('No index provided for this solution');
  }

};

export const setupCalculationInputs = (
  projectionInputs: Partial<Record<CalculationValue['id'], CalculationValue>>,
  solutionId: string = ''
): PresetConfiguration => {
  return {
    all_solution: getSolutionIndex(solutionId),
    all_tds: parseFloat(getCalculationValue(projectionInputs, 'tds', 0, '')),
    all_high_solids: solutionId === 'aquaflex-hs',
    all_cap_based_on:
      getCalculationValue(projectionInputs, 'flow_base', 0, 'Permeate Production') ===
      'Permeate Production'
        ? 1
        : 0,
    all_capacity: parseFloat(getCalculationValue(projectionInputs, 'flow', 0, '0')),
    all_design_turbidity: parseFloat(
      getCalculationValue(projectionInputs, 'design_turbidity', 0, '0')
    ),
    all_feed_water: getCalculationValue(projectionInputs, 'water_source', 0, '') as FeedWaterName,
    all_temperature: parseFloat(getCalculationValue(projectionInputs, 'temperature', 0, '0')),
    all_module_name: calcModuleNameFromInputs(
      solutionId,
      getCalculationValue(projectionInputs, 'membrane_element', 0, '')
    ) as ModuleTypeName,
    chm_alkalinity: parseFloat(getCalculationValue(projectionInputs, 'alcalinity', 0, '0')),
    chm_ph: parseFloat(getCalculationValue(projectionInputs, 'ph', 0, '0')),
  };
};
