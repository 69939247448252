import { Box, Divider } from '@mui/material';
import { HeaderPropertyValue } from '../../../../__old__/v2/common/layout/header-prop-value';
import { CalculationTextField } from '../../../../__old__/v2/common/ui/calculation-textfield';
import { CalculationsRecord } from '../../../../__old__/v2/feature/projection/helpers/calculation-values';
import { CalculationValue } from '../../../../__old__/v2/feature/solution/types/record';

export interface CEBSettingsProps {
  inputs: Partial<CalculationsRecord>;
  advisedInputs: Partial<CalculationsRecord>;
  onChange(calculationValue: CalculationValue): void;
  onError?(id: string, error: string | false): void;
}

export const MITSettings: React.FC<CEBSettingsProps> = (props) => {
  const { inputs, advisedInputs, onChange, onError } = props;

  return (
    <Box width={500}>
      <Box display={'flex'}>
        <HeaderPropertyValue horizontal marginRight={1} calculationValue={inputs['ucl_overdesign']} />
      </Box>

      <Divider style={{ margin: '24px 0' }} />

      <CalculationTextField
        calculationValue={inputs['mit_interval']}
        advisedValue={advisedInputs['mit_interval']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:8760|numberMin:0'}
        type="number"
        inputProps={{ min: 0, max: 8760, step: 1 }}
        showEgu
        eguWidth={100}
      />

      <CalculationTextField
        calculationValue={inputs['mit_duration']}
        advisedValue={advisedInputs['mit_duration']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:60|numberMin:0'}
        type="number"
        inputProps={{ min: 0, max: 60, step: 1 }}
        showEgu
        eguWidth={100}
      />
    </Box>
  );
};
