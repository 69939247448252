/**
 *
 *
 *
 */

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Theme, Menu, MenuItem } from '@mui/material';
import { faCodeCommit, faEllipsisH } from '@fortawesome/pro-light-svg-icons';

import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface OwnProps {
  actions: {
    label: string;
    action: () => void;
    icon: IconProp;
    lineBreak?: boolean;
    disabled?: boolean;
  }[];
  icon?: IconProp;
  iconSolid?: IconProp;
  title?: string | ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  iconButton: {
    padding: 8,
    height: 48,
    width: 48,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 20,
    cursor: 'pointer',
    color: '#fff',
    '& >:first-of-type': {
      opacity: 1,
      position: 'absolute',
    },
    '& >:last-child': {
      opacity: 0,
      position: 'absolute',
    },
    '&:hover': {
      '& >:first-of-type': {
        opacity: 0,
      },
      '& >:last-child': {
        opacity: 1,
      },
    },
  },
  menuItemLabel: {
    paddingLeft: theme.spacing(1),
    flex: 1,
  },
  titleWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    paddingLeft: 16,
    paddingRight: 16,
    fontSize: 16,
    borderBottom: '1px solid #eaeaea',
  },
  iconWrap: {
    width: 28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.blue.main,
  },
}));

export const IconButtonDropdown: React.FC<OwnProps> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const classes = (useStyles as any)(props);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <a onClick={handleClick} className={classes.iconButton} href="##">
        {props.icon && <FontAwesomeIcon icon={props.icon || faEllipsisH} />}
        {props.iconSolid && <FontAwesomeIcon icon={props.iconSolid || faEllipsisH} />}
      </a>
      <Menu id="action-drop-down" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <div className={classes.titleWrap}>
          <div className={classes.iconWrap}>
            <FontAwesomeIcon icon={faCodeCommit} />
          </div>
          <span className={classes.menuItemLabel}>{props.title}</span>
        </div>

        {props.actions.map((action, index) => {
          return (
            <MenuItem onClick={action.action} disabled={action.disabled} key={index}>
              <div className={classes.iconWrap}>
                <FontAwesomeIcon icon={action.icon} />
              </div>
              <span className={classes.menuItemLabel}>{action.label}</span>
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};
