export const toFixedIfNecessary = (value: string, dp: number) => {
  let returnValue: number | string = value;

  if (value === undefined || value === null || Number.isNaN(value) || value === 'undefined') {
    returnValue = '';
  }

  if (!Number.isNaN(+value)) {
    returnValue = +parseFloat(value).toFixed(dp);
  }

  if (Number.isNaN(returnValue)) {
    returnValue = '';
  }

  // console.log({ value, returnValue });

  return returnValue;

  // if (typeof value === 'string' && [',', '.'].includes(value.charAt(value.length - 1))) {
  //   return value;
  // } else {
  //   return +parseFloat(value).toFixed(dp);
  // }
};
