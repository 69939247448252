import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps } from '@mui/material';
import { useCallback, useContext, useMemo, useState } from 'react';
import { MessageType } from '../../../../__old__/application/business/interactor/message-interactor';
import { useValidationErrors } from '../../hooks/use-validation-errors';

import { PreventCloseDialog } from '../../../../__old__/v2/common/ui/dialog/prevent-close-dialog';
import { Message } from '../../../../__old__/v2/common/ui/message';
import { PentairTabs, TabInfo } from '../../../../__old__/v2/common/ui/pentair-tabs';
import { ProjectionContext } from '../../../../__old__/v2/feature/projection/context/projection-context';
import { ProcessingCalculationDialog } from '../../../../__old__/v2/feature/projection/view/dialog/processing-calculation-dialog';
import { CEBSettings } from './ceb-settings';

import { GeneralCEBSettings } from './general-ceb-settings';
import { useDeprecatedProjection } from '../../hooks/use-deprecated-projection';

import CebStockChemicals from './ceb-stock-chemicals-v3';
import { DialogHeader } from '../../../../theme/components/dialog-header';
import { ProjectionRecord } from '../../../../__old__/application/resources/projection/projection';
import { faClipboardListCheck, faSpinner } from '@fortawesome/pro-light-svg-icons';

// ---------------------

export interface CEBDialogProps extends Omit<DialogProps, 'onChange'> {
  onChange: (key: keyof ProjectionRecord, value: any) => void;
}

export const CEBDialog: React.FC<CEBDialogProps> = (props) => {
  const { onChange } = props;
  return (
    <Dialog
      maxWidth={false}
      scroll={'paper'}
      {...props}
      onChange={() => {}}
      onClose={undefined}
      PaperProps={{ style: { maxWidth: 'none' } }}
      disableEnforceFocus
    >
      <CEBDialogContent onClose={props.onClose} onChange={onChange} />
    </Dialog>
  );
};

// ----------------------

type CEBDialogContentProps = {
  onClose: DialogProps['onClose'];
  onChange: (key: keyof ProjectionRecord, value: any) => void;
};
const CEBDialogContent: React.FC<CEBDialogContentProps> = (props) => {
  const { onClose, onChange } = props;
  const projection = useContext(ProjectionContext);
  const {
    inputs,
    changes,
    advisedInputs,
    updateCalculationValue,
    applyChanges,
    calculationRunning,
    activeProcess,
    calculationError,
    revertToAdvised,
  } = useDeprecatedProjection(projection, { onChange });
  const ceb1aEnabled = inputs['ceb_1_a_enabled']?.values[0] === 'true';
  const ceb1bEnabled = inputs['ceb_1_b_enabled']?.values[0] === 'true';
  const ceb2aEnabled = inputs['ceb_2_a_enabled']?.values[0] === 'true';
  const ceb2bEnabled = inputs['ceb_2_b_enabled']?.values[0] === 'true';

  const tabs = useMemo(() => {
    return [
      { value: 'ceb-all', label: 'CEB Settings', disabled: false },
      { value: 'ceb_1_a_enabled', label: 'CEB1A', disabled: !ceb1aEnabled },
      { value: 'ceb_1_b_enabled', label: 'CEB1B', disabled: !ceb1bEnabled },
      { value: 'ceb_2_a_enabled', label: 'CEB2A', disabled: !ceb2aEnabled },
      { value: 'ceb_2_b_enabled', label: 'CEB2B', disabled: !ceb2bEnabled },
      { value: 'ceb_stock_chemicals', label: 'Stock Chemicals', disabled: false },
    ];
  }, [ceb1aEnabled, ceb1bEnabled, ceb2aEnabled, ceb2bEnabled]);

  const [activeTab, setActiveTab] = useState<TabInfo>(tabs[0]);
  const [isClosing, setIsClosing] = useState(false);
  const [isWTCalculating, setIsWTCalculating] = useState(false);

  const hasChanges = Object.keys(changes).length > 0 ? true : false;

  const { validationErrors, handleValidationError } = useValidationErrors();

  const disabled = Boolean(isWTCalculating || calculationRunning || !hasChanges || validationErrors);

  const handleClose = useCallback(
    (e: any) => {
      if (calculationRunning) {
        e.preventDefault();
        return;
      }

      if (isClosing || !hasChanges) {
        onClose && onClose(e, 'backdropClick');
      } else {
        setIsClosing(true);
      }
    },
    [calculationRunning, hasChanges, isClosing, onClose]
  );

  return (
    <Box
      style={{
        transition: 'height 0.3s ease, width 0.3s ease',
      }}
    >
      <DialogHeader title={'CEB Section'} subtitle={activeTab.label} onClose={handleClose} onReset={revertToAdvised} />

      <PentairTabs tabs={tabs} onTabClick={setActiveTab} />

      <DialogContent style={{ paddingTop: 24 }}>
        <Message
          show={Boolean(calculationError)}
          type={MessageType.Error}
          children={calculationError}
          style={{ marginBottom: 24 }}
        />

        {activeTab.value === 'ceb-all' && (
          <GeneralCEBSettings
            inputs={inputs}
            advisedInputs={advisedInputs}
            onChange={updateCalculationValue}
            onError={handleValidationError}
          />
        )}

        {activeTab.value === 'ceb_1_a_enabled' && (
          <CEBSettings
            ceb={'ceb_1_a'}
            inputs={inputs}
            advisedInputs={advisedInputs}
            onChange={updateCalculationValue}
            onError={handleValidationError}
            onIsCalculatingChange={setIsWTCalculating}
          />
        )}

        {activeTab.value === 'ceb_1_b_enabled' && (
          <CEBSettings
            ceb={'ceb_1_b'}
            inputs={inputs}
            advisedInputs={advisedInputs}
            onChange={updateCalculationValue}
            onError={handleValidationError}
            onIsCalculatingChange={setIsWTCalculating}
          />
        )}

        {activeTab.value === 'ceb_2_a_enabled' && (
          <CEBSettings
            ceb={'ceb_2_a'}
            inputs={inputs}
            advisedInputs={advisedInputs}
            onChange={updateCalculationValue}
            onError={handleValidationError}
            onIsCalculatingChange={setIsWTCalculating}
          />
        )}

        {activeTab.value === 'ceb_2_b_enabled' && (
          <CEBSettings
            ceb={'ceb_2_b'}
            inputs={inputs}
            advisedInputs={advisedInputs}
            onChange={updateCalculationValue}
            onError={handleValidationError}
            onIsCalculatingChange={setIsWTCalculating}
          />
        )}

        {/* CEB Stock Chemicals tab */}
        {activeTab.value === 'ceb_stock_chemicals' && (
          <CebStockChemicals
            inputs={inputs}
            advisedInputs={advisedInputs}
            onChange={updateCalculationValue}
            onError={handleValidationError}
            onIsCalculatingChange={setIsWTCalculating}
            tabs={tabs}
          />
        )}
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          onClick={() => applyChanges(inputs)}
          disabled={disabled}
          startIcon={
            isWTCalculating || calculationRunning ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <FontAwesomeIcon icon={faClipboardListCheck} />
            )
          }
        >
          Update
        </Button>
      </DialogActions>

      <ProcessingCalculationDialog
        open={calculationRunning}
        activeMessage={activeProcess}
        title={'Just a few seconds'}
        subtitle={'Processing your inputs'}
      />

      <PreventCloseDialog
        title={'Warning'}
        subtitle={'Confirm your action'}
        message={
          <>
            One or more fields are changed but the calculations are not updated.
            <br />
            <br />
            Click <strong>UPDATE</strong> to run calculations with the changed values.
            <br />
            <br />
            If you do not want to update calculations click
            <strong> DISCARD</strong> to discard changes made and continue.
          </>
        }
        open={isClosing}
        onClose={handleClose}
        onConfirm={() => {
          applyChanges(inputs);
          setIsClosing(false);
        }}
        confirmDisabled={disabled}
        confirmButtonText={'UPDATE'}
        closeButtonText={'DISCARD'}
      />
    </Box>
  );
};
