import { Grid, Theme, Divider } from '@mui/material';

import { makeStyles } from '@mui/styles';
import * as React from 'react';
import { ProjectionRecord } from '../../../../../application/resources/projection/projection';

import { DialogHover } from '../../../../../v2/feature/projection/view/design/partials/dialog-hover';
import { DialogMarker } from '../../../../../v2/feature/projection/view/design/partials/dialog-marker';
import { HiddenMarker } from '../../../../../v2/feature/projection/view/design/partials/hidden-marker';
import { useSolution } from '../../../../../v2/feature/solution/hooks/use-single';
import { StyleProjectionPages } from '../../styles-projection-pages';
import { ProjectionDataTable } from '../projection-data-table';
import { ProjectionDataTableCEBChemicals } from '../projection-table-ceb-chemicals';

import { ProjectionDataTableKeyValue } from '../projection-data-table-key-value';
import { ProjectionContext } from '../../../../../v2/feature/projection/context/projection-context';
import { ProjectionClass } from '../../../../../application/resources/projection/v2/single';
import { KeyValueTable } from '../../key-value-table';
import { XlineInteractiveImage } from '../interactive-image/xline';
import { PageLoading } from '../../../../../v2/common/pages/page-loading';
import { toFixedIfNecessary } from '../../../../../application/resources/helper/to-fixed';
import { toDisplayUnit } from '../../../../components/form/fields/projection-calculation-value-convert';
import { CEBConsumptionsData } from './helpers/ceb-consumptions-data';
import { ProjectionDescription } from '../../../../../../feature/projections/components/projection-description';
import { ProjectionLegend } from '../../../../../../feature/projections/components/image/legend';
import { CalculationValue } from '../../../../../v2/feature/solution/types/record';
import SystemConfigurationDutyStandby from './system-config-duty-standby';
import { calculationValueById } from './helpers/calculation-value-by-id';
import { usePermissions } from '../../../../../../feature/auth/auth-permissions';

const projectionValueToString = (key: string, projection: ProjectionClass, egu?: string) => {
  const data = [...(projection.record.inputs || []), ...(projection.record.outputs || [])];
  const calculationValue = data.find((item) => item.id === key);
  let unit = (calculationValue && calculationValue.unit && ' ' + calculationValue.unit) || '';

  if (calculationValue && calculationValue.valueOptions && calculationValue.valueOptions.length > 0) {
    const valueToReturn = calculationValue.valueOptions.find(
      (valueOption) => valueOption.id === calculationValue.values[0]
    );

    return ((valueToReturn && valueToReturn.label) || '') + unit;
  }

  if (egu === 'imperial') {
    if (calculationValue && calculationValue.unitOptions && (calculationValue.unitOptions || []).length > 1) {
      const displayUnit = calculationValue.unitOptions[calculationValue.unitOptions.length - 1];
      calculationValue.displayUnit = displayUnit;
      unit = ' ' + displayUnit;

      const value = toDisplayUnit(calculationValue, parseFloat(String(calculationValue.values[0])));

      return toFixedIfNecessary(String(value), calculationValue.decimals || 0) + unit;
    }
  }

  return ((calculationValue && calculationValue.values[0]) || '') + unit;
};

const parseData = (data: any[]) => data.filter((item) => item); // should clear all item === undefined (and emtpy strings/0/null/etc)

const hideNumberLogic = (projection: ProjectionClass) => {
  return {
    '1': false,
    '2': false,
    '3': calculationValueById('chm_coagulant', projection)?.values[0] === '' ? true : false,
    '4': calculationValueById('ucp_chemical_agent_1', projection)?.values[0] === '' ? true : false,
    '5': calculationValueById('ucp_chemical_agent_2', projection)?.values[0] === '' ? true : false,
    '6': calculationValueById('ucp_chemical_agent_3', projection)?.values[0] === '' ? true : false,
    ucl_average_airflush_flow:
      parseFloat(String(calculationValueById('airflush_duration', projection)?.values[0]) || '0') > 0 ? false : true,
    '8': true,
  };
};

// end helper functions -----------------------------------

interface OwnProps extends StyleProjectionPages {
  onChange: (key: any, value: any) => void;
}

// styles
const useStyles = makeStyles((theme: Theme) => ({
  markerTitle: {
    fontWeight: 700,
  },
  legendContainer: {
    paddingTop: theme.spacing(4),
  },
  legendContainerPrint: {
    marginTop: theme.spacing(1),
  },
  legendItem: {},
  legendItemPrint: {},
  settingsContainer: {
    paddingTop: '24px',
    marginTop: '24px',
  },
  settingsContainerPrint: {
    display: 'table',
  },
  settingsItem: {},
  settingsItemPrint: {
    display: 'inline-block',
    width: 'calc(50% - 24px)',
    padding: '0 12px',
  },
  tooltip: {
    marginRight: '8px',
    overflow: 'hidden',
    border: '1px solid #fff',
    width: '40px',
    height: '40px',
    textAlign: 'center',
    borderRadius: '50%',
    background: theme.palette.green.main,
    position: 'relative',
    color: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  editAction: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    cursor: 'pointer',
    display: 'none',
    color: theme.palette.primary.main,
    fontSize: theme.spacing(1.5),
  },
  projectionDescription: {
    border: '1px solid transparent',
    padding: theme.spacing(1),
    paddingTop: 0,
    paddingBottom: 0,
    position: 'relative',
    '&:hover': {
      border: '1px dashed ' + theme.palette.softGray.main,
      '& $editAction': {
        display: 'block',
      },
    },
  },
  projectionDescriptionActive: {
    border: '1px dashed ' + theme.palette.softGray.main,
    '& $editAction': {
      display: 'block',
    },
  },
  interactiveImage: {
    marginBottom: theme.spacing(2),
  },
  printText: {
    fontSize: 12,
  },
  projectionInputContainerPrint: {
    paddingTop: '8px',
    display: 'table', // fix for break-inside; does not work with display: flex
  },
  printLegends: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  projectionInputItemPrint: {
    display: 'inline-block', // fix for break-inside; does not work with display: flex
    breakInside: 'avoid',
    width: 'calc(50% - 24px)',
    padding: '0 12px',
  },
}));

// container function
export const XLineDesignResult: React.FC<OwnProps> = (props) => {
  const { onChange } = props;
  const projection = React.useContext(ProjectionContext);
  // const { userProfile } = React.useContext(UserContext);
  // const egu =
  //   (userProfile.record && userProfile.record.preferences?.find((preference) => preference.id === 'engineering_unit'))
  //     ?.value || 'metric';
  const { userProfile } = usePermissions();
  const egu = userProfile.preferences?.find((p) => p?.id === 'engineering_unit')?.value || 'metric';
  const [loading, setLoading] = React.useState(false);
  const classes = (useStyles as any)();
  const solution = useSolution((projection.record.solution && projection.record.solution.id) || '');

  const handleChange = React.useCallback(
    (key: keyof ProjectionRecord, value: any) => {
      if (onChange && typeof onChange === 'function') {
        onChange(key, value);
      }
      if (key === 'description') {
        projection.change(key, value);
      }
    },
    [projection, onChange]
  );

  React.useEffect(() => {
    if ((!solution || Object.keys(solution).length === 0 || Object.keys(solution.record).length === 0) && !loading) {
      setLoading(true);
      solution.fetch().then(() => setLoading(false));
    }
  }, [solution, projection, loading]);

  if (loading) {
    return <PageLoading message="Loading projection preview en settings" iconScale={0.6} textVariant="body2" />;
  }

  return (
    <React.Fragment>
      <XLineDesignResultImage handleChange={handleChange} egu={egu} />

      <Grid container spacing={4} className={classes.legendContainer}>
        <Grid item xs={12} md={6}>
          <ProjectionDescription handleChange={handleChange} egu={egu} />
        </Grid>
        <Grid item xs={12} md={6}>
          <XLineDesignResultImageLegend egu={egu} />
        </Grid>
      </Grid>

      <Divider sx={{ marginY: 2 }} />

      <XLineDesignResultSettings egu={egu} />
    </React.Fragment>
  );
};

//-----------------------------------------------

// image part
export const XLineDesignResultImage: React.FC<{
  handleChange: (key: any, value: any) => void;
  print?: boolean;
  egu?: string;
}> = (props) => {
  const { handleChange, egu } = props;
  const projection = React.useContext(ProjectionContext);
  const classes = (useStyles as any)(props);

  return (
    <>
      {projection.record.solution?.techImage && projection.record.solution.techImageMap && (
        <div className={classes.interactiveImage}>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <XlineInteractiveImage img={projection.record.solution.techImage} inputs={projection.record.inputs || []} />
            <div style={{ fontSize: props.print ? '70%' : 'calc(.8vw)' }}>
              {projection.record.solution.techImageMap.map((marker, index) => {
                return (
                  <React.Fragment key={index}>
                    {marker.type === 'dialog_form' && (
                      <React.Fragment>
                        <DialogMarker projection={projection} marker={marker} onChange={handleChange} />
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              })}
              {projection.record.solution.techImageMap.map((marker, index) => {
                return (
                  <React.Fragment key={index}>
                    {marker.type === 'hover' && (
                      <DialogHover
                        projection={projection}
                        marker={marker}
                        onChange={handleChange}
                        print={props.print}
                      />
                    )}
                    {marker.type === 'key_value' &&
                      !hideNumberLogic(projection)[
                        (marker.key === 'ucl_average_airflush_flow' && 'ucl_average_airflush_flow') ||
                          (marker.key === 'internal_circulation_flow' && 8) ||
                          1
                      ] && (
                        <div
                          style={{
                            position: 'absolute',
                            left: marker.location[0] + '%',
                            top: marker.location[1] + '%',
                            fontSize: props.print ? '85%' : undefined,
                            textAlign: marker.centeredText === 'true' ? 'center' : 'left',
                            transform: 'rotate(-' + marker.rotation + 'deg)',
                          }}
                        >
                          {marker.splitKeyValue === 'true' && marker.title !== 'Internal Circulation flow' && (
                            <React.Fragment>
                              {marker.title + ': '}
                              <br />
                            </React.Fragment>
                          )}
                          {marker.splitKeyValue === 'false' && <React.Fragment>{marker.title + ': '}</React.Fragment>}
                          {marker.title === 'Internal Circulation flow' && (
                            <React.Fragment>
                              Internal Circulation: <br /> Flow:{' '}
                            </React.Fragment>
                          )}
                          {projectionValueToString(marker.key, projection, egu)}
                        </div>
                      )}
                    {marker.type === 'hidden_marker' && <HiddenMarker marker={marker} />}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

// settings part
export const XLineDesignResultImageLegend: React.FC<{
  print?: any;
  egu?: string;
}> = (props) => {
  const projection = React.useContext(ProjectionContext);

  const valueOf = (key: string) => {
    return projectionValueToString(key, projection, props.egu);
  };

  return (
    <ProjectionLegend
      print={props.print}
      data={[
        {
          hidden: Boolean(hideNumberLogic(projection)[1]),
          title: 'Feed pump(s)',
          value: `${valueOf('ucl_number_of_feed_pumps')}x  ${valueOf('ucl_filtration_pump_flow')}`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)[2]),
          title: 'Backwash pump(s)',
          value: `${valueOf('ucl_number_of_backwash_pumps')}x  ${valueOf('ucl_backwash_pump_flow')}`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)[3]),
          title: 'Coagulant Dosing Pump:',
          // value: `${valueOf('ucl_number_of_coagulant_dosing_pumps')}x  ${valueOf('ucl_coagulant_dosing_flow')}`,
          value: `${valueOf('ucl_coagulant_dosing_flow')} per complete UF system`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)[4]),
          title: valueOf('ucp_chemical_agent_1') + ' Dosing Pump',
          value: `${valueOf('ucl_number_of_backwash_pumps')}x  ${valueOf('chem_dosing_pump_1')}`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)[5]),
          title: valueOf('ucp_chemical_agent_2') + ' Dosing Pump',
          value: `${valueOf('ucl_number_of_backwash_pumps')}x  ${valueOf('chem_dosing_pump_2')}`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)[6]),
          title: valueOf('ucp_chemical_agent_3') + ' Dosing Pump',
          value: `${valueOf('ucl_number_of_backwash_pumps')}x  ${valueOf('chem_dosing_pump_3')}`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)['ucl_average_airflush_flow']),
          title: 'Compressed Air for Airflush',
          value: `${valueOf('total_units')}x  ${valueOf('out_af_compressor')}`,
        },
        {
          hidden: Boolean(hideNumberLogic(projection)[8]),
          title: 'Internal Circulation Pump',
          value: `${valueOf('total_units')}x  ${valueOf('out_internal_circulation_pump')}`,
        },
      ]}
    />
  );
};

//-----------------------------------------------

// settings part
export const XLineDesignResultSettings: React.FC<{
  print?: any;
  egu?: string;
}> = (props) => {
  const projection = React.useContext(ProjectionContext);
  const { egu } = props;
  const classes = (useStyles as any)();
  const sizeXs = props.print ? 6 : 12;
  const sizeMd = 6;
  const sizeLg = props.print ? 6 : 4;

  const get = (key: string) => calculationValueById(key, projection);

  const valueOf = (key: string) => {
    return projectionValueToString(key, projection, props.egu);
  };

  const calculateBackWashFlow = React.useCallback(() => {
    /*
    This is calculated for Xiga by:
    ( ((membrane_rows * membrane_elements ) - number_of_shorter_rows) * MembraneArea * backwash_flux ) / 1000
  */
    let plantTotalArea = calculationValueById('plant_total_area', projection)?.values[0] || 0;
    let plantTotalMembranes = calculationValueById('plant_total_membrane_elements', projection)?.values[0] || 0;

    let amountOfMembranes = calculationValueById('membrane_elements', projection)?.values[0] || 0;
    let amountOfMembranesRows = calculationValueById('all_user_housings', projection)?.values[0] || 0;
    let amountOfShorterRows = calculationValueById('all_shorter_rows', projection)?.values[0] || 0;
    let backwashFlux = calculationValueById('backwash_flux', projection)?.values[0] || 0;

    if (typeof plantTotalArea === 'string') {
      plantTotalArea = parseFloat(plantTotalArea);
    }
    if (typeof plantTotalMembranes === 'string') {
      plantTotalMembranes = parseInt(plantTotalMembranes);
    }
    if (typeof amountOfMembranes === 'string') {
      amountOfMembranes = parseFloat(amountOfMembranes);
    }
    if (typeof amountOfMembranesRows === 'string') {
      amountOfMembranesRows = parseFloat(amountOfMembranesRows);
    }
    if (typeof amountOfShorterRows === 'string') {
      amountOfShorterRows = parseFloat(amountOfShorterRows);
    }
    if (typeof backwashFlux === 'string') {
      backwashFlux = parseFloat(backwashFlux);
    }

    let membraneArea = plantTotalArea / plantTotalMembranes;

    const CalculatedBackwashFlow = (
      ((amountOfMembranesRows * amountOfMembranes - amountOfShorterRows) * membraneArea * backwashFlux) /
      1000
    ).toFixed(1);

    const backwashFlow: CalculationValue = {
      // @ts-ignore :: backwash_flow is not in the allowed input/output id-strings
      id: 'backwash_flow',
      // @ts-ignore :: backwash_flow is not in the allowed calculation_field id-strings
      calculation_field: 'backwash_flow',
      name: 'Backwash Flow',
      values: [CalculatedBackwashFlow],
      recommendedValues: [0],
      unit: 'm³/h',
      unitOptions: ['m³/h', 'GPM'],
      displayUnit: 'm³/h',
    };
    return backwashFlow;
  }, [projection]);

  return (
    <>
      <Grid
        container
        spacing={props.print ? 0 : 4}
        className={props.print ? classes.settingsContainerPrint : classes.settingsContainer}
      >
        {!props.print && (
          <>
            <Grid
              item
              xs={sizeXs}
              md={sizeMd}
              lg={sizeLg}
              className={props.print ? classes.settingsItemPrint : classes.settingsItem}
            >
              <ProjectionDataTable
                print={props.print}
                egu={egu}
                title={'Projection Inputs'}
                data={parseData([
                  get('water_source'),
                  get('flow'),
                  get('flow_base'),
                  get('design_turbidity'),
                  get('membrane_element'),
                  get('membrane_housing'),
                ])}
              />
            </Grid>
            <Grid
              item
              xs={sizeXs}
              md={sizeMd}
              lg={sizeLg}
              className={props.print ? classes.settingsItemPrint : classes.settingsItem}
            >
              <ProjectionDataTable
                egu={egu}
                print={props.print}
                title={'Main feed water parameters'}
                data={parseData([get('turbidity'), get('tss'), get('toc'), get('cod'), get('color')])}
              />
            </Grid>
            <Grid
              item
              xs={sizeXs}
              md={sizeMd}
              lg={sizeLg}
              className={props.print ? classes.settingsItemPrint : classes.settingsItem}
            >
              <ProjectionDataTable
                egu={egu}
                print={props.print}
                title={'Additional water parameters'}
                data={parseData([get('temperature'), get('alcalinity'), get('ph'), get('tds')])}
              />
            </Grid>
          </>
        )}

        {!props.print && (
          <Grid
            item
            xs={sizeXs}
            md={sizeMd}
            lg={sizeLg}
            className={props.print ? classes.settingsItemPrint : classes.settingsItem}
          >
            {/* <ProjectionDataTable
              egu={egu}
              print={props.print}
              title={'System configuration'}
              data={parseData([
                get('total_units'), /// XLINE ONLY
                get('all_user_housings'), /// XLINE ONLY
                get('membrane_elements'),
                get('plant_spare_membrane_elements'),
                // get("plant_max_membrane_elements"),
                parseFloat(String(get('all_shorter_rows') && get('all_shorter_rows')?.values[0])) > 0 &&
                  get('all_shorter_rows'),
                get('plant_total_membrane_elements'),
                get('plant_total_area'),
                get('backwash_train'),
                get('ucl_overdesign'),
              ])}
            /> */}
            <SystemConfigurationDutyStandby
              solution="xline"
              inputs={projection?.record?.inputs || []}
              outputs={projection?.record?.outputs || []}
              print={props.print}
              egu={props.egu}
            />
          </Grid>
        )}
        <Grid
          item
          xs={sizeXs}
          md={sizeMd}
          lg={sizeLg}
          className={props.print ? classes.settingsItemPrint : classes.settingsItem}
        >
          <ProjectionDataTable
            egu={egu}
            print={props.print}
            title={'Main settings & calculations'}
            data={parseData([
              get('filtration_flux'),
              get('mb_out_calc_net_filtration_flux'),
              get('filtration_duration'),
              get('net_filtration_flux'),
              get('mb_out_calc_recovery'),
              get('mb_out_ceb_interval'),
              get('mb_out_ceb_interval_2'),
              get('ceb_1_counter'),
              get('ceb_2_counter'),
            ])}
          />
        </Grid>

        {get('chm_coagulant')?.values[0] && (
          <Grid
            item
            xs={sizeXs}
            md={sizeMd}
            lg={sizeLg}
            className={props.print ? classes.settingsItemPrint : classes.settingsItem}
          >
            <ProjectionDataTableKeyValue
              title={'COAGULATION DETAILS'}
              print={props.print}
              data={[
                {
                  title: 'Coagulant Chemical',
                  value:
                    valueOf('chm_coagulant') +
                    ' ' +
                    valueOf('chm_coagulant_override_stock_conc_wtpercent') +
                    ' as ' +
                    valueOf('chm_coagulant_stock_conc'),
                },
                {
                  title: 'Max. Concentration',
                  value: valueOf('user_max_coagulant_concentration'),
                },
                {
                  title: 'Typical Concentration',
                  value: valueOf('user_typ_coagulant_concentration'),
                },
                {
                  title: 'Typical Consumption',
                  value: valueOf('ucl_typ_usage_coagulant'),
                },
              ]}
            />
          </Grid>
        )}

        {/* {props.print ? <Grid item xs={12} sx={{ pageBreakAfter: 'always', breakAfter: 'page' }} /> : null} */}

        <Grid
          item
          xs={sizeXs}
          md={sizeMd}
          lg={sizeLg}
          className={props.print ? classes.settingsItemPrint : classes.settingsItem}
        >
          <ProjectionDataTable
            egu={egu}
            print={props.print}
            title={'Backwash Settings'}
            data={parseData([
              get('backwash_flux'),
              // get('ucl_backwash_pump_flow'),
              calculateBackWashFlow(), // TODO: move this calculation to calculation engine
              get('backwash_duration'),
              get('valve_switching'),
              get('backwash_pump_ramping'),
              get('forward_flush_flux'),
              get('ff_flow'),
              get('forward_flush_duration'),
            ])}
            helperText={'(Filtration program starts with a Forward Flush)'}
          />
        </Grid>

        <Grid
          item
          xs={sizeXs}
          md={sizeMd}
          lg={sizeLg}
          className={props.print ? classes.settingsItemPrint : classes.settingsItem}
        >
          <ProjectionDataTable
            egu={egu}
            print={props.print}
            title={'CEB Settings'}
            data={parseData([
              get('ceb_1_a_dosing_flux'),
              get('ucp_chemical_dosing_flow'),
              get('ceb_1_a_dosing_duration'),
              get('ceb_1_a_post_dosing_duration'),
              get('ceb_1_a_soaking_time'),
              get('ceb_1_a_rinsing_flux'),
              get('rinsing_flow'),
              get('ceb_1_a_rinsing_duration'),
              get('ceb_1_b_rinsing_duration'),
            ])}
          />
        </Grid>

        <Grid
          item
          xs={sizeXs}
          md={sizeMd}
          lg={sizeLg}
          className={props.print ? classes.settingsItemPrint : classes.settingsItem}
        >
          <ProjectionDataTableCEBChemicals
            print={props.print}
            title={'CEB Chemicals'}
            ceb_1a_chemical_1={get('ceb_1_a_chemical_agent_1')}
            ceb_1a_chemical_2={get('ceb_1_a_chemical_agent_2')}
            ceb_1b_chemical_1={get('ceb_1_b_chemical_agent_1')}
            ceb_1b_chemical_2={get('ceb_1_b_chemical_agent_2')}
            ceb_2a_chemical_1={get('ceb_2_a_chemical_agent_1')}
            ceb_2a_chemical_2={get('ceb_2_a_chemical_agent_2')}
            ceb_2b_chemical_1={get('ceb_2_b_chemical_agent_1')}
            ceb_2b_chemical_2={get('ceb_2_b_chemical_agent_2')}
          />
        </Grid>

        <Grid
          item
          xs={sizeXs}
          md={sizeMd}
          lg={sizeLg}
          className={props.print ? classes.settingsItemPrint : classes.settingsItem}
        >
          <KeyValueTable title={'CEB Consumptions'} data={CEBConsumptionsData(projection, egu)} print={props.print} />
        </Grid>

        {String((get('mit_interval') && get('mit_interval')?.values[0]) || '0') !== '0' && (
          <Grid
            item
            xs={sizeXs}
            md={sizeMd}
            lg={sizeLg}
            className={props.print ? classes.projectionInputItemPrint : undefined}
          >
            <ProjectionDataTable
              egu={egu}
              print={props.print}
              title={'MIT Settings'}
              data={parseData([get('mit_interval'), get('mit_duration')])}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
