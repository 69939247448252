import * as React from "react";
import { useObserver } from "mobx-react";
import { usePresenter } from "../../../../../hooks/use-presenter";
import { ProjectionInteractiveImagePresenter } from "../projection-interactive-image-presenter";
import { CalculationValue } from "../../../../../v2/feature/solution/types/record";

interface OwnProps {
  img: Array<string>;
  inputs: CalculationValue[];
}

export const XlineInteractiveImage: React.FC<OwnProps> = (props) => {
  const presenter = usePresenter(
    () => new ProjectionInteractiveImagePresenter(props)
  );

  return useObserver(() => {
    const { imageLoaded, images } = presenter;
    const data = props.inputs || [];

    const calculationValueById = (id: string) => {
      return data.find((item) => item.id === id);
    };

    const hideNumberLogic = {
      "1":
        calculationValueById("chm_coagulant")?.values[0] === "" ? true : false,
      "2":
        calculationValueById("ucp_chemical_agent_1")?.values[0] === ""
          ? true
          : false,
      "3":
        calculationValueById("ucp_chemical_agent_2")?.values[0] === ""
          ? true
          : false,
      "4":
        calculationValueById("ucp_chemical_agent_3")?.values[0] === ""
          ? true
          : false,
    };

    return (
      <React.Fragment>
        {images.map((image: string, index) => {
          if (index === 0) {
            return (
              <img
                style={{ maxWidth: "100%", height: "auto", display: "block" }}
                key={index}
                src={image}
                onLoad={imageLoaded}
                alt="techimage"
              />
            );
          }

          if (index !== 0 && !hideNumberLogic[index]) {
            return (
              <img
                key={index}
                src={image}
                style={{
                  position: "absolute",
                  height: "auto",
                  top: 0,
                  left: 0,
                  maxWidth: "100%",
                }}
                onLoad={imageLoaded}
                alt="techimage"
              />
            );
          }
          return null;
        })}
      </React.Fragment>
    );
  });
};
