import { parse } from "graphql";
import { v4 as uuid } from "uuid";
import { GraphQLModel } from "./graphql-model";
import { GraphQLCollection, Collection } from "./graphql-collection";
import { GraphQLAPI, Provider, GraphQLModelClass } from "../types";
import { RecordData, Store, DefaultStore } from "../general/store";

export interface GraphQLProvider<
  TRecord extends RecordData,
  T extends GraphQLModel<TRecord> = GraphQLModel<TRecord>
> extends Provider<TRecord> {
  apiUrl?: string;
  model: GraphQLModelClass<TRecord>;
  get(id: string, version?: string): T;
  save(instance: T): Promise<void>;
  create: (data?: Partial<TRecord>, persist?: boolean) => T;
  createInstance: (data: Partial<TRecord>) => T;
  fetch(item: T, version?: string): Promise<void>;
  fetchList(): Promise<void>;
  update(item: T): Promise<void>;
  delete(item: T): Promise<void>;
  subscribe(key: string, handler: (model: T) => void): void;
  collect(items?: TRecord[]): Collection<T>;
}

export abstract class BaseGraphQLProvider<
  TRecord extends RecordData,
  T extends GraphQLModel<TRecord> = GraphQLModel<TRecord>
> {
  protected _cache: Record<string, T> = {};
  public subscribers: Record<string, Array<(model: T) => void>> = {};
  public useMock: boolean = false;
  public apiUrl: string | undefined = undefined;

  public store: Store<TRecord> = new DefaultStore();

  public abstract model: GraphQLModelClass<TRecord>;

  constructor(private _api: GraphQLAPI) {
    if ((this as any)._subscriptions) {
      (this as any)._subscriptions.forEach((subscribe: any) => {
        subscribe(this);
      });
    }
  }

  protected listOperation = (): any => {
    throw new Error("Fetch operation not set");
  };

  protected fetchOperation = (_item: T, version?: string): any => {
    throw new Error("Fetch operation not set");
  };

  protected createOperation = (_item: T): any => {
    throw new Error("Create operation not set");
  };

  protected updateOperation = (_item: T): any => {
    throw new Error("Update operation not set");
  };

  protected deleteOperation = (_item: T): any => {
    throw new Error("Delete operation not set");
  };

  public collect = (items?: TRecord[]) => {
    const collection: Collection<T> = new GraphQLCollection(items, this);
    return collection;
  };

  public get = (id: string, version?: string) => {
    return this.createInstance({ id } as Partial<TRecord>, version);
  };

  public create = (data: Partial<TRecord> = {}, persist: boolean = false) => {
    const instance: T = this.createInstance(data);
    instance.isNew = true;

    if (persist) {
      instance.save();
    }

    return instance;
  };

  public createInstance(data: Partial<TRecord>, version?: string): T {
    const id = data.id || uuid();

    if (!this._cache[id + version]) {
      this._cache[id + version] = new (this as any).model(
        { id, ...data },
        this
      );
    }
    return this._cache[id + version];
  }

  public subscribe(key: string, handler: (model: T) => void) {
    this.subscribers[key] = this.subscribers[key] || [];
    this.subscribers[key].push(handler);
  }

  public fetch = async (item: T, version?: string) => {
    const operation = this.fetchOperation(item, version);
    await this.query(item, operation);
  };

  public fetchList = async () => {
    const operation = this.listOperation();
    await this.query(null, operation, true);
  };

  public save = async (item: T) => {
    const operationFunc = (this as any).createOperation;
    const operation = operationFunc(item);

    if (operation) {
      await this.query(item, operation);
      this.store.setRecord(item.serialize());
    }
  };

  public async update(item: T) {
    const operation = this.updateOperation(item);

    await this.query(item, operation);
  }

  public delete = async (item: T) => {
    const operation = this.deleteOperation(item);
    await this.query(item, operation);
    this.store.deleteRecord(item.id);
  };

  public async query(
    item: T | null,
    operation: any,
    expectList: boolean = false
  ) {
    const response = await this._api.fetch(
      this.apiUrl,
      operation.query as string,
      operation.variables,
      this.useMock
    );

    let parsedQuery =
      (operation.query && parse(operation.query.toString())) || "";
    parsedQuery =
      parsedQuery ||
      (operation.mutation && parse(operation.mutation.toString()));

    let queryName = "";

    if (parsedQuery && "name" in parsedQuery.definitions[0]) {
      queryName =
        (parsedQuery.definitions[0].name &&
          parsedQuery.definitions[0].name.value) ||
        "";
    }

    const result =
      queryName && response.data && response.data[queryName]
        ? response.data[queryName]
        : response.data;

    if (item && !expectList && result && result.id) {
      this.store.setRecord({ ...result });
      item.record = result;
    }

    if (expectList && Array.isArray(result)) {
      this.store.injectList(result);
    }

    if (response.errors) {
      console.error(response.errors);
    }

    return result;
  }
}
