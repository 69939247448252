import {
  GraphQLModel,
  BaseGraphQLModel,
} from "../../base/graphql/graphql-model";
import { v4 as uuid } from "uuid";

export type ProfilePermissions = {
  allow: string[];
  deny: string[];
};

export type PermissionRecord = {
  id: string;
  name: string;
  description: string;
  field: string;
  type: string;
  resolver: string;
};

export interface Permission extends GraphQLModel<PermissionRecord> {
  resolved: boolean;
  checkPermissions(permission: string): boolean;
}

export class DefaultPermission
  extends BaseGraphQLModel<PermissionRecord>
  implements Permission {
  public resolved: boolean = false;

  public defaultValues: PermissionRecord = {
    id: uuid(),
    name: "Default name",
    description: "",
    type: "",
    field: "",
    resolver: "",
  };

  public checkPermissions = (_requested: string): boolean => {
    return true;
  };
}
