
// default wt% values
export const stockConcentrationTableCEB = {
  D2: 100,
  HCl: 33,
  H2SO4: 96,
  HNO3: 50,
  NaHSO3: 35,
  NaOCl: 12.5,
  NaOH: 48,
  H2O2: 35,
  H3PO4: 75,
};

// default g/l values that belong to the wt% value above
export const stockConcentrationTableAgentCEB = {
  D2: 0,
  HCl: 385,
  H2SO4: 1761,
  HNO3: 653,
  NaHSO3: 451,
  NaOCl: 150,
  NaOH: 724,
  H2O2: 396,
  H3PO4: 1189,
};

export const stockConcentrationTableCoagulant = {
  PACl: 5,
  FeCl3: 40,
  'Al2(SO4)3': 20,
};

export const stockConcentrationAgentCEB = {
  PACl: 60,
  FeCl3: 196,
  'Al2(SO4)3': 38,
};
