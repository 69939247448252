import { useState, useCallback } from 'react';

export const useValidationErrors = () => {
  const [validationErrors, setValdationErrors] = useState<
    Record<string, string> | false
  >(false);

  const handleValidationError = useCallback((id, error) => {
    setValdationErrors((_errors) => {
      const errors = { ..._errors };
      // Don't update if the error is the same
      if (error && errors[id] === error) {
        return _errors;
      }

      if (error) {
        // Add the error
        errors[id] = error;
      } else if (errors[id]) {
        // Remove the error
        delete errors[id];
      }

      // If there are no errors, return false else return the errors
      return Object.keys(errors).length ? errors : false;
    });
  }, []);

  return { validationErrors, handleValidationError, setValdationErrors };
};
