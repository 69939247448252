import { observable, computed } from "mobx";

export type RecordData<TRecord extends { id: string } = { id: string }> = Partial<TRecord> & {
  id: string;
};

export interface Store<TRecord extends RecordData<TRecord>> {
  records: Record<string, RecordData<TRecord>>;
  list: RecordData<TRecord>[];
  setRecord: (data: RecordData<TRecord>) => RecordData<TRecord>;
  deleteRecord: (id: string) => RecordData<TRecord> | undefined;
  injectList(list: RecordData<TRecord>[]): void;
  emptyStorage(): void;
}

export class DefaultStore<TRecord extends RecordData> implements Store<TRecord> {
  @observable public records: { [path: string]: RecordData<TRecord> } = {};
  @computed public get list() {
    return Object.keys(this.records).map((key) => this.records[key]);
  }

  public setRecord(record: RecordData<TRecord>) {
    this.records[record.id] = { ...this.records[record.id], ...record };
    return this.records[record.id];
  }

  public injectList(list: RecordData<TRecord>[]) {
    this.records = {
      ...this.records,
      ...list.reduce((result: Record<string, RecordData<TRecord>>, record: RecordData<TRecord>) => {
        result[record.id] = { ...(this.records[record.id] || {}), ...record };
        return result;
      }, {}),
    };
  }

  public deleteRecord = (id: string) => {
    const item = this.records[id];
    if (item) {
      delete this.records[id];
    }
    return item;
  };

  public emptyStorage = () => {
    this.records = {};
  };
}
