import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export type StyleProjectionPages = {
  classes?: Record<
    'root' | 'containedPaper' | 'containedPaperHeader' | 'title' | 'subTitle' | 'titleWrap' | 'icon' | 'section',
    string
  >;
};

export const useProjectionPageStyles = makeStyles((theme: Theme) => ({
  root: {
    maxWidth: '75vw',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(6),
  },
  containedPaperHeader: {
    color: theme.palette.darkGray.main,
    textAlign: 'left',
    display: 'flex',
    padding: theme.spacing(1.25),
  },
  containedPaper: {
    borderTop: `1px solid ${theme.palette.softGray.main}`,
    display: 'flex',
    padding: theme.spacing(4),
  },
  version: {
    marginLeft: 'auto',
    alignSelf: 'flex-end',
  },
  approver: {
    alignSelf: 'flex-end',
  },
  versionNo: {
    color: theme.palette.brightBlue.main,
  },
  section: {
    marginTop: theme.spacing(1),
  },
  interactiveImage: {},
  titleWrap: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(2) + 'px !important',
    height: theme.spacing(2),
    marginRight: theme.spacing(2),
    color: theme.palette.blue.main,
  },
})) as any;
