import { Tooltip, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';
import { ImageMap } from '../../../../solution/types/record';
import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProjectionMarkerFormDialog } from '../../dialog/projection-marker-form-dialog';
import { ProjectionClass } from '../../../../../../application/resources/projection/v2/single';
import { useCheckPermission } from '../../../../../../hooks/use-check-permission';
import { faInfo } from '@fortawesome/pro-solid-svg-icons';

interface OwnProps {
  projection: ProjectionClass;
  marker: ImageMap;
  onChange: (key: any, value: any) => void;
  print?: boolean;
}

const useStyles = makeStyles((theme: any) => ({
  root: {},
  markerTitle: {
    fontWeight: 700,
  },
  tooltip: {
    overflow: 'hidden',
    border: '1px solid #fff',
    width: 'calc(2vw)',
    height: 'calc(2vw)',
    maxWidth: '40px',
    maxHeight: '40px',
    minWidth: '20px',
    minHeight: '20px',
    // width: "40px",
    // height: "40px",
    textAlign: 'center',
    borderRadius: '50%',
    background: theme.palette.green.main,
    position: 'relative',
    color: '#fff',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover $tooltipHover': {
      display: 'flex',
    },
  },
  tooltipHover: {
    display: 'none',
    background: theme.palette.green.main,
    width: '100%',
    height: '100%',
    textAlign: 'center',
    zIndex: 2,
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    alignItems: 'center',
    justifyContent: 'center',
  },
  muiTooltip: {
    background: theme.palette.brightBlue.main,
    padding: theme.spacing(2),
    borderRadius: 0,
    maxWidth: '50vw',
  },
  muiTooltipArrow: {
    color: theme.palette.brightBlue.main,
  },
  tooltipTitle: {
    marginBottom: theme.spacing(1),
  },
}));

export const DialogHover: React.FC<OwnProps> = (props) => {
  const { projection, marker, onChange } = props;
  const classes = (useStyles as any)(props);
  const [openDialog, setOpenDialog] = React.useState(false);
  const checkPermission = useCheckPermission();

  const calculationValueById = (id: string) => {
    const inputs = [...(projection.record.inputs || []), ...(projection.record.outputs || [])];
    return (
      (inputs && inputs.find((item) => item.id === id)) || {
        values: [''],
      }
    );
  };

  const hideNumberLogic = () => ({
    '1': false,
    '2': false,
    '3': calculationValueById('chm_coagulant')?.values[0] === '' ? true : false,
    '4': calculationValueById('ucp_chemical_agent_1')?.values[0] === '' ? true : false,
    '5': calculationValueById('ucp_chemical_agent_2')?.values[0] === '' ? true : false,
    '6': calculationValueById('ucp_chemical_agent_3')?.values[0] === '' ? true : false,
    '7': parseFloat((calculationValueById('airflush_duration')?.values[0] as string) || '0') < 1 ? true : false,
    '8': calculationValueById('internal_recirculation')?.values[0] === 'true' ? false : true,
  });

  React.useEffect(() => {
    if (!projection.record.name) {
      projection.fetch();
    }
  }, [projection, marker, projection.record]);

  if (hideNumberLogic()[marker.number]) {
    return <></>;
  }

  return (
    <React.Fragment>
      <div
        style={{
          position: 'absolute',
          left: marker.location[0] + '%',
          top: marker.location[1] + '%',
        }}
      >
        <Tooltip
          title={
            (!props.print && !checkPermission('ui.basic.projection') && (
              <React.Fragment>
                <Typography gutterBottom variant={'h5'} className={classes.tooltipTitle} color="inherit">
                  {marker.title}
                </Typography>

                <Typography variant={'body2'} color="inherit">
                  {marker.description}
                </Typography>
              </React.Fragment>
            )) ||
            ''
          }
          placement="top"
        >
          <div className={classes.tooltip}>
            <div className={classes.tooltipHover} onClick={() => setOpenDialog(true)}>
              <FontAwesomeIcon className={classes.icon} icon={faInfo} />
            </div>
            <strong className={classes.markerTitle}>{marker.number}</strong>
          </div>
        </Tooltip>
      </div>
      {marker.dialog && (
        <ProjectionMarkerFormDialog
          marker={marker}
          open={openDialog}
          projection={projection}
          onClose={() => setOpenDialog(false)}
          onChange={onChange}
        />
      )}
    </React.Fragment>
  );
};
