import * as React from 'react';
import { ProjectionRecord } from '../../../../../application/resources/projection/projection';
import { CalculationValue } from '../../../solution/types/record';

import { faClipboardList } from '@fortawesome/pro-light-svg-icons';
import useAvailableMembraneHousings, {
  backwardCompatibleMembraneHousings,
} from '../../../../../../feature/projections/hooks/use-available-membrane-housings';
import useMembraneElements from '../../../../../../feature/projections/hooks/use-membranes-elements';
import { SectionTitle } from '../../../../../../theme/components/section-title';
import { FormSelectProjectionCalculationValue } from '../../../../../view/components/form/fields/select-projection-calculation-value';
import LoadingIcon from '../../../../common/ui/loading-icon';
import { CalculationValueOptions } from '../../../../../v2/feature/solution/types/record';
import { filterDeprecatedMembranes } from '../../business/projection/membrane-module';

export interface OwnProps {
  onChange: (key: keyof ProjectionRecord) => (value: any) => void;
  onValidate: (step: string, hasErrors: boolean) => void;
  data: CalculationValue[];
  errors?: any;
  title?: string;
  projection: Partial<ProjectionRecord>;
  solution: string;
  noHeader?: boolean;
  subtitle?: string;
}

export const FormProjectionInputsProducts: React.FC<OwnProps> = (props) => {
  // props
  const { onChange, onValidate, title, data, noHeader, subtitle, projection, solution } = props;

  const selectedMembraneElement = React.useMemo(() => {
    const calcValue = data.find((item) => item.id === 'membrane_element');
    if (calcValue && !calcValue.values[0]) {
      calcValue.values.push('XF75');
    }
    if (calcValue && calcValue.valueOptions && !calcValue.valueOptions[0]) {
      calcValue.valueOptions.push({
        id: 'XF75',
        label: 'XF75',
      });
    }
    return calcValue;
  }, [data]);

  const selectedMembraneElementHousing = React.useMemo(() => {
    let calcValue = data.find((item) => item.id === 'membrane_housing');
    // set default if no value is set
    if (!calcValue) {
      if (solution === 'xiga') {
        calcValue = {
          id: 'membrane_housing',
          name: 'Membrane Housing',
          values: ['Yes'], // backward compatibility will fix this value
          recommendedValues: ['Yes'],
          // @ts-expect-error: somebody typed this wrong, an orginal membrane_housing sets this to NULL which is not posible with the type CalculationValue
          calculation_field: null,
          unit: '',
        };
      } else {
        calcValue = {
          id: 'membrane_housing',
          name: 'Membrane Housing',
          values: ['No'],
          recommendedValues: ['No'],
          // @ts-expect-error: somebody typed this wrong, an orginal membrane_housing sets this to NULL which is not posible with the type CalculationValue
          calculation_field: null,
          unit: '',
        };
      }
    }
    // update old values
    // @ts-expect-error: 'calcValue can be undefined' error, which should be impossible here
    calcValue.values = [backwardCompatibleMembraneHousings(calcValue.values[0], selectedMembraneElement.values[0])];

    return calcValue;
  }, [data, selectedMembraneElement, solution]);

  const { loading, membraneElements } = useMembraneElements(props.solution);

  const flow = React.useMemo(() => {
    const flowCalcValue = projection?.inputs?.find((input) => input.id === 'flow');
    if (flowCalcValue && flowCalcValue.values.length > 0) {
      let value = flowCalcValue.values[0].toString();
      return parseFloat(value);
    }
    return undefined;
  }, [projection?.inputs]);

  const availableMembraneHousings = useAvailableMembraneHousings(
    props.solution,
    selectedMembraneElement?.values[0],
    flow
  );

  const dt = () => {
    const dtVal =
      props.projection &&
      props.projection.inputs &&
      props.projection.inputs.find((input) => input.id === 'design_turbidity');
    return (dtVal && (dtVal.values[0] as number)) || undefined;
  };

  const [validationErrors, setValidationErrors] = React.useState<Record<string, string>>({});

  const onValidationError = React.useCallback(
    (key: string) => (error: string | false) => {
      if (validationErrors[key] || false !== error) {
        if (error) {
          validationErrors[key] = error;
        } else {
          delete validationErrors[key];
        }

        setValidationErrors({ ...validationErrors });
        onValidate('products', Object.keys(validationErrors).length > 0 || false);
      }
    },
    [validationErrors, onValidate]
  );

  // this only exist for PXX-1393
  const alteredOnChange = React.useCallback(
    (calcValue: any) => {
      // set membrane elements value if membrane_housing is changed.
      if (calcValue && calcValue.id && calcValue.id === 'membrane_housing') {
        const val = calcValue.values[0];
        // also trigger an onChange for membrane_elements
        switch (val) {
          case '90L12-6':
          case '90S30-6 (Code Compliant)':
          case '80L15-6':
          case '90HXF12-6':
          case '80S15-6 (Non-coded)':
          case '80S15-6 (Code Compliant)':
            if (!projection || !projection.inputs) {
              break;
            }
            const me1 = projection.inputs.find((d) => d.id === 'membrane_elements');
            if (me1) {
              me1.values = ['4'];
              onChange('inputs')(me1);
            }
            break;
          case '80L15-3':
          case '90L12-3':
            if (!projection || !projection.inputs) {
              break;
            }
            const me2 = projection.inputs.find((d) => d.id === 'membrane_elements');
            if (me2) {
              me2.values = ['2'];
              onChange('inputs')(me2);
            }
            break;
          default:
            break;
        }
      }
      onChange('inputs')(calcValue);
    },
    [onChange, projection]
  );

  // pxx-1555
  // HFS60 not available anymore feb 2024, if already present the options is also still present
  // XF53 ?? also not available for new installations May 2024
  // const filterDeprecatedMembranes = React.useCallback((filters: CalculationValueOptions[] | undefined) => {
  //   let filteredMembranes: CalculationValueOptions[] = [];

  //   if (!filters) {
  //     return filteredMembranes;
  //   }

  //   filteredMembranes = filters.filter((membraneElement) => {
  //     switch (true) {
  //       case membraneElement.id === 'HFS60' && selectedMembraneElement?.values[0] !== 'HFS60':
  //       case membraneElement.id === 'XF53' && selectedMembraneElement?.values[0] !== 'XF53':
  //         return false;
  //       default:
  //         return true;
  //     }
  //   });

  //   return filteredMembranes;
  // }, []);

  return (
    <React.Fragment>
      <div>
        {!noHeader && <SectionTitle title={title || 'Product selection'} icon={faClipboardList} />}

        {loading && <LoadingIcon scale={0.6} />}

        {!loading && (
          <form>
            {subtitle && <strong>{subtitle}</strong>}
            {/* Membrane Element selector */}
            <FormSelectProjectionCalculationValue
              value={selectedMembraneElement}
              onChange={onChange('inputs')}
              onError={onValidationError('membrane_element')}
              textfieldProps={{ margin: 'dense' }}
              validations={'required'}
              valueOptions={filterDeprecatedMembranes(membraneElements, selectedMembraneElement?.values[0] || '')}
              dt={dt()}
            />

            {/* Membrane Element Housing selector; 
                should only show if the availableMembranehousings !== undefined in this case 
            */}
            {availableMembraneHousings && (
              <FormSelectProjectionCalculationValue
                value={selectedMembraneElementHousing}
                onChange={alteredOnChange}
                onError={onValidationError('membrane_housing')}
                textfieldProps={{ margin: 'dense' }}
                validations={'required'}
                valueOptions={availableMembraneHousings}
                dt={dt()}
              />
            )}
          </form>
        )}
      </div>
    </React.Fragment>
  );
};
