export const PermissionFragment = `
permissions{
  allow
  deny
}
`;

export const ProfileFragment = `
id
name
description
${PermissionFragment}
`;
