import { ProjectionRecord } from '../../../../../application/resources/projection/projection';
import { CalculationValue } from '../../../solution/types/record';
import * as React from 'react';
import { CalculationTextField } from '../../../../common/ui/calculation-textfield';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons';
import { SectionTitle } from '../../../../../../theme/components/section-title';

export interface OwnProps {
  onChange: (key: keyof ProjectionRecord) => (value: any) => void;
  onValidate: (step: string, hasErrors: boolean) => void;
  data: CalculationValue[];
  errors?: any;
  title?: string;
  validations?: string;
}

export const FormProjectionInputsCategory: React.FC<OwnProps> = (props) => {
  const { onChange, onValidate, data, title } = props;
  const [validationErrors, setValidationErrors] = React.useState<Record<string, string>>({});

  const onValidationError = React.useCallback(
    (key: string, error: string | false) => {
      if (validationErrors[key] || false !== error) {
        if (error) {
          validationErrors[key] = error;
        } else {
          delete validationErrors[key];
        }

        setValidationErrors({ ...validationErrors });
        onValidate('inputs', Object.keys(validationErrors).length > 0 || false);
      }
    },
    [validationErrors, setValidationErrors, onValidate]
  );

  const handleChange = React.useCallback(
    (value: Partial<CalculationValue>) => {
      if (value.id === 'flow') {
        value.values = value.values?.filter(Boolean);
      }
      onChange('inputs')(value);
    },
    [onChange]
  );

  return (
    <>
      <SectionTitle title={title || 'Flow inputs'} icon={faClipboardList} />

      {data.length &&
        data.map((input) => {
          const isSelect = (input.valueOptions || []).length > 1;
          const isNumber = input.id === 'flow';

          // pxx-1494
          if (input.id === 'flow_base') {
            if (input.values && input.values.length > 0) {
              if (input.values[0] === 1) {
                input.values[0] = 'Permeate Production';
              }
              if (input.values[0] === 0) {
                input.values[0] = 'Feed flow';
              }
            }
          }

          return (
            <CalculationTextField
              select={(input.valueOptions || []).length > 1}
              type={isNumber ? 'number' : undefined}
              showEgu={!isSelect}
              eguWidth={100}
              eguDisabled={true}
              calculationValue={input}
              onChange={handleChange}
              onError={onValidationError}
              validations={props.validations}
              key={input.id}
              highlighted={false}
            />
          );
        })}
    </>
  );
};
