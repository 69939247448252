import { TextField, Button, CircularProgress } from '@mui/material';
import { useState, useCallback } from 'react';
import { useAuth } from '../auth-provider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { AuthError } from '../auth-template';

export const SignInForm = () => {
  const { signIn, loading } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      signIn(username, password);
    },
    [signIn, username, password]
  );

  return (
    <form onSubmit={onSubmit} style={{ display: 'flex', flexFlow: 'column' }}>
      <TextField
        label="Username"
        id="username"
        name="username"
        value={username}
        onChange={(e: any) => setUsername(e.target.value)}
        placeholder="E-mail address"
        disabled={loading}
        margin="dense"
      />

      <TextField
        label="Password"
        id="password"
        name="password"
        type="password"
        value={password}
        onChange={(e: any) => setPassword(e.target.value)}
        placeholder="Password"
        disabled={loading}
        margin="dense"
      />

      <Button
        style={{ alignSelf: 'flex-end' }}
        type="submit"
        disabled={loading}
        endIcon={loading ? <CircularProgress size={20} /> : <FontAwesomeIcon icon={faArrowRight} />}
      >
        Login
      </Button>

      <AuthError />
    </form>
  );
};
