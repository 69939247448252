import { RouteOptions, RouteArguments } from "../application/business/provider/routes";
import { application } from "../application/application";
import { RouteNames } from "../config/application";

export function useRoute(routename: RouteNames, args?: RouteArguments): [string, RouteOptions] {
  const provider = application.provider.routes;
  const path = provider.generatePath(routename, args);
  const options = provider.getOptions(routename);
  return [path, options];
}
