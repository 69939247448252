import { Typography, DialogTitle, Box } from '@mui/material';
import { IconButton } from '../../__old__/view/components/mui-extended/icon-button';
import { faTimes, faUndo } from '@fortawesome/pro-light-svg-icons';
import { faTimes as faTimesSolid, faUndo as faUndoSolid } from '@fortawesome/pro-solid-svg-icons';

interface DialogHeaderProps {
  title: string;
  subtitle: string;
  onClose?: React.MouseEventHandler<HTMLButtonElement> & React.MouseEventHandler<HTMLAnchorElement>;
  onReset?: React.MouseEventHandler<HTMLButtonElement> & React.MouseEventHandler<HTMLAnchorElement>;
}

export const DialogHeader: React.FC<DialogHeaderProps> = ({ title, subtitle, onClose, onReset }) => {
  return (
    <DialogTitle paddingBottom={2} component="div">
      <Typography variant="h1" color="primary">
        {title}
      </Typography>

      <Typography variant="h1" color="secondary" fontWeight={400}>
        {subtitle}
      </Typography>

      <Box position="absolute" right={6} top={6}>
        {onReset && (
          <IconButton icon={faUndo} iconSolid={faUndoSolid} aria-label="close" onClick={onReset} size={'small'} />
        )}
        {onClose && (
          <IconButton icon={faTimes} iconSolid={faTimesSolid} aria-label="close" onClick={onClose} size={'small'} />
        )}
      </Box>
    </DialogTitle>
  );
};
