import { createContext, PropsWithChildren, useCallback, useContext, useRef } from 'react';
import { application } from '../../../../application/application';
import { UserInteractor } from '../../../../application/resources/user/user-interactor';
import { UserProfileClass } from '../../user-profile/single';

interface UserContextRecord {
  userInteractor: UserInteractor;
  userProfile: UserProfileClass | undefined;
}

export const UserContext = createContext<UserContextRecord>({
  userInteractor: {} as any,
  userProfile: undefined, // new UserProfileClass(),
});

export const UserContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const userContextRef = useRef({
    userInteractor: application.interactor.user,
    userProfile: new UserProfileClass(),
  });
  const userContext = userContextRef.current;

  return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
};

export const useUserCache = () => {
  const userCache = useContext(UserContext);

  const updateUserCache = useCallback(
    (key: keyof UserContextRecord, value: any) => {
      userCache[key] = value;
    },
    [userCache]
  );

  return { updateUserCache };
};

export const LoadUserContextData: React.FC = () => {
  // const { updateUserCache } = useUserCache();
  // const { user } = useAuth();
  // const userInteractor = application.interactor.user;
  // const userProfile = useUserProfile();

  // useEffect(() => {
  //   try {
  //     if (user && !userProfile.record.permissions) {
  //       userProfile.id = userInteractor.currentUser.id;

  //       userProfile.fetch().then(() => {
  //         updateUserCache('userInteractor', userInteractor);
  //         updateUserCache('userProfile', userProfile);
  //       });
  //     }
  //   } catch (error) {
  //     console.error('Context failed: ', error);
  //   }
  // }, [userProfile, updateUserCache, userInteractor]);

  return null;
};
