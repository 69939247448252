import { DrawerNotification } from './partials/drawer-notification';
import { Fade, Slide } from '@mui/material';
import { usePresenter } from '../../../hooks/use-presenter';
import { DrawerNotificationsPresenter } from './notification-presenter';

export const DrawerNotifications = () => {
  const presenter = usePresenter(({ interactor }) => new DrawerNotificationsPresenter(interactor.user));
  return (
    <div>
      {[1].map((notification, index) => (
        <Fade key={index} in style={{ transitionDelay: `${0.03 * index + 0.1}s` }}>
          <Slide
            in
            direction={'right'}
            style={{
              transitionDelay: `${0.03 * index + 0.1}s`,
            }}
          >
            <div>
              {presenter.notifications.map((notification) => (
                <DrawerNotification notification={notification.record} />
              ))}
            </div>
          </Slide>
        </Fade>
      ))}
    </div>
  );
};
