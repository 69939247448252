export const makeCapacityBasedOn = (value: number) => {
  switch (value) {
    case 0:
      return 'Feed flow';

    case 1:
      return 'Permeate Production';

    default:
      return '';
  }
};
