import { observable, computed } from "mobx";
import { v4 as uuid } from "uuid";

export enum MessageType {
  Error = "error",
  Warning = "warning",
  Info = "info",
}

export interface Message {
  id: string;
  type: MessageType;
  contents: string;
  acknowledge(id: string): void;
}

export interface MessageInteractor {
  messages: Message[];
  create(type: MessageType, contents: string): void;
  createError(contents: string): void;
  createWarning(contents: string): void;
  createInfo(contents: string): void;
  acknowledge(id: string): void;
  reset(): void;
}

export class DefaultMessageInteractor implements MessageInteractor {
  @observable public messages: Message[] = [];

  @computed public get errors() {
    return this.messages.filter((msg) => msg.type === MessageType.Error);
  }

  @computed public get warnings() {
    return this.messages.filter((msg) => msg.type === MessageType.Error);
  }

  @computed public get info() {
    return this.messages.filter((msg) => msg.type === MessageType.Error);
  }

  public createInfo = (contents: string) => {
    this.create(MessageType.Info, contents);
  };

  public createWarning = (contents: string) => {
    this.create(MessageType.Warning, contents);
  };

  public createError = (contents: string) => {
    this.create(MessageType.Error, contents);
  };

  public create = (type: MessageType, contents: string) => {
    this.messages.push({
      id: uuid(),
      contents,
      type,
      acknowledge: this.acknowledge,
    });
  };

  public acknowledge = (id: string) => {
    const index = this.messages.findIndex((msg) => msg.id === id);
    if (index >= 0) {
      this.messages.splice(index, 1);
    }
  };

  public reset = () => {
    this.messages = [];
  };
}
