import { ProjectionRecord } from '../../../../../application/resources/projection/projection';
import { CalculationValue } from '../../../solution/types/record';
import * as React from 'react';
import { useProjectionPageStyles } from '../../../../../view/pages/projection/styles-projection-pages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { PentairDarkGray } from '../../../../../config/theme/pentair-theme/colors';
import { FormFieldProjectionCalculationValueTableRowConvert } from '../../../../../view/components/form/table-row-calculation-value-convert';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons';

export interface OwnProps {
  onChange: (key: keyof ProjectionRecord) => (value: any) => void;
  onValidate: (step: string, hasErrors: boolean) => void;
  data: CalculationValue[];
  errors?: any;
  title?: any;
  waterSource?: CalculationValue;
}

export const FormProjectionsInputsCategoryTable: React.FC<OwnProps> = (props) => {
  const classes = useProjectionPageStyles(props);
  const { onChange, onValidate, data, title } = props;

  const [validationErrors, setValidationErrors] = React.useState<Record<string, string>>({});

  const onValidationError = React.useCallback(
    (key: string) => (error: string | false) => {
      if (validationErrors[key] || false !== error) {
        if (error) {
          validationErrors[key] = error;
        } else {
          delete validationErrors[key];
        }

        setValidationErrors({ ...validationErrors });
        onValidate('feed', Object.keys(validationErrors).length > 0 || false);
      }
    },
    [validationErrors, onValidate]
  );

  return (
    <React.Fragment>
      <div>
        <div className={classes.titleWrap}>
          <FontAwesomeIcon className={classes.icon} icon={faClipboardList} />
          <Typography variant="h4">{title || 'Flow inputs'}</Typography>
        </div>
        <form>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '300px' }}>
                  <div
                    style={{
                      textTransform: 'uppercase',
                      fontWeight: 600,
                      color: PentairDarkGray[600],
                      fontSize: '16px',
                      transform: 'translate(0, 1.5px) scale(0.75)',
                      transformOrigin: 'top left',
                    }}
                  >
                    Parameter
                  </div>
                </TableCell>
                <TableCell style={{ width: '100px' }}>
                  <div
                    style={{
                      textTransform: 'uppercase',
                      fontWeight: 600,
                      color: PentairDarkGray[600],
                      fontSize: '16px',
                      transform: 'translate(0, 1.5px) scale(0.75)',
                      transformOrigin: 'top left',
                    }}
                  >
                    Value
                  </div>
                </TableCell>
                <TableCell style={{ width: '85px' }}>
                  <div
                    style={{
                      textTransform: 'uppercase',
                      fontWeight: 600,
                      color: PentairDarkGray[600],
                      fontSize: '16px',
                      transform: 'translate(0, 1.5px) scale(0.75)',
                      transformOrigin: 'top left',
                    }}
                  >
                    EGU/UNITTT
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.length &&
                data.map((input) => {
                  return (
                    <FormFieldProjectionCalculationValueTableRowConvert
                      type={input.id}
                      value={input}
                      onChange={onChange('inputs')}
                      onError={onValidationError(input.id)}
                    />
                  );
                })}
            </TableBody>
          </Table>
        </form>
      </div>
    </React.Fragment>
  );
};
