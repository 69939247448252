import { Grid, GridSize, MenuItem, TextField, TextFieldProps } from '@mui/material';
import converter from '@pentair/xpert-convert-units';
import * as React from 'react';
import { usePermissions } from '../../../../../feature/auth/auth-permissions';
import { toFixedIfNecessary } from '../../../../application/resources/helper/to-fixed';
import { CalculationValue } from '../../../../v2/feature/solution/types/record';
import { useFormChange } from '../hooks/use-form-change';
import { useFormValidation } from '../hooks/use-form-validation';
import InfoLabel from '../info-label';
import { DefaultFormFieldProps } from '../types';

interface OwnProps extends DefaultFormFieldProps<Partial<CalculationValue>> {
  textfieldProps?: TextFieldProps;
  disabled?: boolean;
  type?: string;
  unitWidth?: GridSize[];
  placeholder?: string;
  customErrorMessage?: string | false;
}

export const fromDisplayUnit = (calculationValue: Partial<CalculationValue>, value: number) => {
  /** Convert a user input to the base unit */
  if (calculationValue.displayUnit === undefined || calculationValue.unit === calculationValue.displayUnit) {
    return value;
  }
  try {
    let fromUnit = calculationValue.displayUnit;
    let toUnit = calculationValue.unit;
    if (calculationValue.displayUnit === '°C') {
      fromUnit = 'degC';
      toUnit = 'degF';
    } else if (calculationValue.displayUnit === '°F') {
      fromUnit = 'degF';
      toUnit = 'degC';
    } else if (calculationValue.displayUnit === 'm²') {
      fromUnit = 'm2';
      toUnit = 'sqft';
    } else if (calculationValue.displayUnit === 'ft²') {
      fromUnit = 'sqft';
      toUnit = 'm2';
    }

    return converter
      .convert(value)
      .from(fromUnit as any)
      .to(toUnit as any);
  } catch (e) {
    console.error(`Conversion from ${calculationValue.displayUnit} to ${calculationValue.unit} is not supported`);
    return undefined;
  }
};

export const toDisplayUnit = (calculationValue: Partial<CalculationValue>, value: number) => {
  /** Convert value from the base unit to the user unit */
  if (calculationValue.displayUnit === undefined || calculationValue.unit === calculationValue.displayUnit) {
    return value;
  }
  try {
    let fromUnit = calculationValue.unit;
    let toUnit = calculationValue.displayUnit;
    if (calculationValue.unit === '°C') {
      fromUnit = 'degC';
      toUnit = 'degF';
    } else if (calculationValue.unit === '°F') {
      fromUnit = 'degF';
      toUnit = 'degC';
    } else if (calculationValue.unit === 'm²') {
      fromUnit = 'm2';
      toUnit = 'sqft';
    } else if (calculationValue.unit === 'ft²') {
      fromUnit = 'sqft';
      toUnit = 'm2';
    }

    // converter does not do Nm3/h to SCFM
    // pxx-1422 :: the used conversion rate is done at 15 degC
    if (calculationValue.displayUnit === 'SCFM') {
      return value * 0.589684;
    }

    return converter
      .convert(value)
      .from(fromUnit as any)
      .to(toUnit as any);
  } catch (e) {
    console.error(`Conversion from ${calculationValue.unit} to ${calculationValue.displayUnit} is not supported`);
    return undefined;
  }
};

export const filteredUnitOptions = (calculationValue?: Partial<CalculationValue> | null) => {
  if (!calculationValue) return [];

  /** Limit the list op units to only supported items */
  const { unitOptions, unit } = calculationValue;
  if (!unitOptions || unitOptions.length === 0) return [];
  if (!unit) return unitOptions;

  try {
    const converterOptions = converter.options(unit as any);
    return unitOptions.filter((i) => {
      let toUnit = i;
      if (toUnit === '°C') {
        toUnit = 'degC';
      }
      if (toUnit === '°F') {
        toUnit = 'degF';
      }
      if (toUnit === 'm²') {
        toUnit = 'm2';
      }
      if (toUnit === 'ft²') {
        toUnit = 'sqft';
      }
      if (converterOptions.indexOf(toUnit as any) >= 0) return true;
      console.warn(`Conversion between ${unit} and ${i} is not supported`);
      return false;
    });
  } catch (e) {
    console.debug(`Base unit ${unit} is not supported`);
    return unitOptions;
  }
};

export const FormFieldProjectionCalculationValueConvert: React.FC<OwnProps> = (props) => {
  /** A transparent component that lets the user interact with a value in a different unit than used internally.
   *
   * Basically, this component has a distinct text and value. The user interacts with the text, which is
   * parsed and converted before it's stored in the value. When the value is updated, it will do a reverse
   * conversion so the user will see the correct value.
   *
   * The user will interact with a value in the `value.displayUnit` unit,
   * the value will be stored in the `value.unit` unit.
   */
  const [calculationValue, setCalculationValue] = React.useState(props.value || {});
  const { unitWidth, placeholder } = props;

  const [unrealistic, setUnrealistic] = React.useState(false);
  const [tooSmall, setTooSmall] = React.useState(false);

  const [text, setText] = React.useState(() => {
    // determine the initial text, based on the
    let initialValue: number;
    if (calculationValue.values && calculationValue.values[0]) {
      initialValue = Number(calculationValue.values[0]);
    } else if (calculationValue.recommendedValues && calculationValue.recommendedValues[0]) {
      initialValue = Number(calculationValue.recommendedValues[0]);
    } else {
      return '';
    }
    if (calculationValue.displayUnit === undefined || calculationValue.unit === calculationValue.displayUnit) {
      return toFixedIfNecessary(initialValue.toString(), calculationValue.decimals || 0);
    }
    const textValue = toDisplayUnit(calculationValue, initialValue);
    if (textValue === undefined) {
      // if the conversion is not supported, there isn't much we can do
      return '';
    } else {
      return toFixedIfNecessary(textValue.toString(), calculationValue.decimals || 0);
    }
  });

  let error = useFormValidation(
    calculationValue?.values && calculationValue.values[0],
    props.validations || 'required|max:120',
    props.onError
  );

  if (error && props.customErrorMessage) {
    error = props.customErrorMessage;
  }

  const { onChange, changed } = useFormChange((e) => {
    const newCalcValue = calculationValue;
    if (e.target.id === newCalcValue.id) {
      // input on value field
      const userInput = Number(e.target.value);
      if (isNaN(userInput)) {
        setTooSmall(true);
      } else {
        const userInputBase = fromDisplayUnit(newCalcValue, userInput);
        if (userInputBase === undefined) {
          // conversion failed
          setTooSmall(true);
        } else {
          if (userInputBase >= 0.1) {
            setTooSmall(false);
            if (userInputBase > 1000000) {
              setUnrealistic(true);
            } else {
              setUnrealistic(false);
            }
            newCalcValue.values = [
              toFixedIfNecessary(String(userInputBase), parseFloat(String(newCalcValue.decimals || '0'))),
            ];
          } else if (userInputBase < 0) {
            newCalcValue.values = [
              toFixedIfNecessary(String(userInputBase), parseFloat(String(newCalcValue.decimals || '0'))),
            ];
            setTooSmall(true);
          } else {
            newCalcValue.values = [
              toFixedIfNecessary(String(userInputBase), parseFloat(String(newCalcValue.decimals || '0'))),
            ];
            // setTooSmall(true);
          }
        }
      }
      setText(String(toFixedIfNecessary(String(e.target.value), parseFloat(String(newCalcValue.decimals || '0')))));
    } else {
      // input on displayUnit field
      const userUnit = e.target.value;
      if (calculationValue.values && calculationValue.values[0] !== undefined) {
        // first check whether we can convert to the selected displayUnit
        const newTextValue = toDisplayUnit(
          {
            ...newCalcValue,
            displayUnit: userUnit,
          },
          Number(calculationValue.values[0])
        );

        if (newTextValue !== undefined) {
          //accept only if conversion is successful, revert otherwise
          newCalcValue.displayUnit = userUnit;
          setText(
            String(toFixedIfNecessary(newTextValue.toString(), parseFloat(String(newCalcValue.decimals || '0'))))
          );
        }
      } else {
        newCalcValue.displayUnit = userUnit;
        setText('');
      }
    }
    setCalculationValue({ ...newCalcValue });
    return calculationValue;
  }, props.onChange);

  React.useEffect(() => {
    if (props.onError) {
      if (unrealistic) {
        props.onError('Unrealistic flow');
      }
      if (tooSmall) {
        props.onError('Value has to be bigger than 0');
      }
    }
  }, [unrealistic, calculationValue]);

  // const { userProfile } = React.useContext(UserContext);
  // const egu =
  //   (userProfile.record && userProfile.record.preferences?.find((preference) => preference.id === 'engineering_unit'))
  //     ?.value || 'metric';
  const { userProfile } = usePermissions();
  const egu = userProfile.preferences?.find((p) => p?.id === 'engineering_unit')?.value || 'metric';

  React.useEffect(() => {
    if (
      calculationValue &&
      !calculationValue.displayUnit &&
      egu === 'imperial' &&
      calculationValue.unitOptions &&
      calculationValue.unitOptions?.length > 1
    ) {
      calculationValue.displayUnit = calculationValue.unitOptions[calculationValue.unitOptions.length - 1];
    } else if (egu !== 'imperial') {
      calculationValue.displayUnit = undefined;
    }
  }, [calculationValue, egu]);

  const unitOptions = filteredUnitOptions(calculationValue);

  return (
    <React.Fragment>
      <Grid container spacing={1} justifyContent={'space-between'}>
        <Grid
          item
          xs={
            (calculationValue.unitOptions &&
              calculationValue.unitOptions.length > 0 &&
              ((unitWidth && unitWidth[0]) || 10)) ||
            12
          }
        >
          <TextField
            type={props.type}
            disabled={props.disabled}
            name={calculationValue.id}
            id={calculationValue.id}
            inputProps={
              (props.type === 'number' && {
                min: 0,
                type: 'number',
                mode: 'numeric',
              }) || {
                maxLength: 121,
              }
            }
            value={text}
            onChange={onChange}
            placeholder={placeholder}
            fullWidth
            label={
              props.description ||
              (calculationValue.description && (
                <InfoLabel
                  title={props.label || calculationValue.name || 'Calculation value'}
                  description={props.description || calculationValue.description || 'Calculation value'}
                />
              )) ||
              props.label ||
              calculationValue.name ||
              'Calculation value'
            }
            error={changed && error ? true : false || (changed && unrealistic) || (changed && tooSmall)}
            helperText={
              (changed && error) ||
              (changed && unrealistic && 'Unrealistic flow') ||
              (changed && tooSmall && 'Value has to be bigger than 0')
            }
            margin="normal"
            {...props.textfieldProps}
          />
        </Grid>

        {unitOptions && unitOptions.length > 0 && (
          <Grid
            item
            xs={(unitWidth && unitWidth[1]) || 2}
            justifyContent={'flex-end'}
            style={{ display: 'flex', marginTop: '32px' }}
          >
            <TextField
              select={unitOptions.length > 1}
              disabled
              required
              fullWidth
              name={calculationValue.id + 'unit'}
              id={calculationValue.id + 'unit'}
              value={calculationValue.displayUnit || calculationValue.unit || unitOptions[0]}
              onChange={onChange}
              label={''}
              error={changed && error ? true : false || (changed && unrealistic)}
              helperText={(changed && '') || (changed && unrealistic && '')}
            >
              {unitOptions.map((unitOption: any, ix) => (
                <MenuItem key={ix} value={unitOption}>
                  {unitOption}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};
