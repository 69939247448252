import { ProfileFragment } from "./fragments";
import { UserProfileFragment } from "../../user-profile/graphql/fragments";

export const CreateProfile = `
mutation CreateProfile($input:inputProfile!) {
  createProfile(input:$input){
    ${ProfileFragment}
  }
}`;

export const UpdateProfile = `
mutation updateProfile($input:inputProfile!) {
  updateProfile(input:$input) {
    ${ProfileFragment}
  }
}
`;

export const DeleteProfile = `
mutation DeleteProfile($id:ID!) {
  deleteProfile(id:$id){
    ${ProfileFragment}
  }
}
`;

export const AssignProfile = `
mutation userAssignProfile($userID: ID!, $profileID: ID!) {
  userAssignProfile(userID: $userID, profileID: $profileID) {
    ${UserProfileFragment}
  }
}
`;
