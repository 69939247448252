import { Grid, Box, Typography, styled } from '@mui/material';

export type LegendData = {
  hidden: boolean;
  title: string;
  value: string;
};

export const ProjectionLegend: React.FC<{ print?: boolean; data: LegendData[] }> = ({ print, data }) => {
  return (
    <Grid container spacing={print ? 0 : 2}>
      {data.map((item, index) => (
        <ProjectionLegendItem
          key={item.title + '-' + index.toString()} // titles on their own are not always unique
          hidden={item.hidden}
          number={index + 1}
          print={print}
          title={item.title}
          value={item.value}
        />
      ))}
    </Grid>
  );
};

export type LegendDataProps = LegendData & {
  print?: boolean;
  number: number;
};

export const ProjectionLegendItem: React.FC<LegendDataProps> = ({ hidden, number, title, value, print }) => {
  if (hidden) {
    return null;
  }

  return (
    <Grid item xs={print ? 4 : 12} md={6} lg={4} marginTop={print ? 1 : undefined}>
      <Box display="flex">
        <NumberCircle marginRight={1}>{number}</NumberCircle>
        <Box>
          <Typography variant={'body1'} fontWeight={600} fontSize={print ? 12 : undefined}>
            {title}
          </Typography>
          <Typography variant={'body1'} fontSize={print ? 12 : undefined}>
            {value}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

const NumberCircle = styled(Box)(({ theme }) => ({
  width: '2.5em',
  height: '2.5em',
  display: 'flex',
  flexShrink: 0,
  flexGrow: 0,
  justifyContent: 'center',
  alignItems: 'center',
  background: theme.palette.green.main,
  color: theme.palette.green.contrastText,
  borderRadius: '100%',
  fontWeight: 700,
}));
