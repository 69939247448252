export const UserLegalFragment = `
    id
    agreements {
        accepted
        date
        id
        text
        link
        linkText
        required
    }
`;
