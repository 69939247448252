/**
 *
 *
 *
 */

import { GraphQLAPIClass, GraphQLResult } from '@aws-amplify/api-graphql';
import { Auth } from '@aws-amplify/auth';
import { DocumentNode } from 'graphql';

const graphql_headers = async () => {
  try {
    const session = await Auth.currentSession();
    return {
      Authorization: session.getIdToken().getJwtToken(),
    };
  } catch (err) {
    console.debug('Error getting session', err);
    throw err;
  }
};

export class AwsApi {
  private AmplifyApi: GraphQLAPIClass;
  constructor(
    private config = {
      aws_appsync_graphqlEndpoint: '',
      aws_appsync_region: 'us-east-1',
      cacheOptions: {
        addTypename: true,
      },
    }
  ) {
    this.AmplifyApi = new GraphQLAPIClass(config);
  }
  /**
   * @description sets headers for AppSync request based on ENV
   * @returns copy of the set config
   */
  private configure = async () => {
    const headers = await graphql_headers();
    // cognito user pool
    const config: any = {
      ...this.config,
      aws_appsync_authenticationType: headers ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
    };
    config.graphql_headers = () => headers;
    await this.AmplifyApi.configure(config);
    return config;
  };

  /**
   * @async
   * @description sends a GraphQL query to appsync backend
   * @param query gql query to run
   * @param variables variables to use in query
   * @param useApiKey force the use of an api key
   * @returns GraphQL query results
   */
  public query = async <TData>(query: DocumentNode, variables?: { [key: string]: any }) => {
    try {
      // need to update the headers
      await this.configure();
      const response = await this.AmplifyApi.graphql<TData>({
        query: query as any,
        variables,
      });
      if ('subscribe' in response) {
        throw new Error('Please use subscribe instead of query when calling subscriptions');
      }
      // run query
      return response;
    } catch (err: any) {
      console.error('Error ferching data: ', err);
      return err as GraphQLResult<TData>;
    }
  };

  public subscribe = async <TData>(querystring: any, variables: any, next: (payload: any) => void) => {
    // need to update the headers
    await this.configure();
    const subscription = await this.AmplifyApi.graphql<TData>({
      query: querystring,
      variables,
    });
    if (!('subscribe' in subscription)) {
      throw new Error('Please use query instead of subscription for mutations and queries');
    }
    return subscription.subscribe({
      next,
    });
  };
}

export const xpertApi = new AwsApi({
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_URL || '',
  aws_appsync_region: process.env.REACT_APP_APPSYNC_REGION || '',
  // aws_appsync_authenticationType: process.env.REACT_APP_APPSYNC_AUTH_TYPE || '',
  cacheOptions: {
    addTypename: true,
  },
});
