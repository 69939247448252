import { TextField, TextFieldProps } from '@mui/material';
import InfoLabel from './info-label';

export const PentairTextField: React.FC<TextFieldProps & { label?: string; description?: string }> = ({
  label,
  description,
  ...props
}) => {
  return (
    <TextField
      label={
        label && <InfoLabel title={label} description={description} error={props.error} disabled={props.disabled} />
      }
      {...props}
    />
  );
};
