import { CalculationValue } from '../../../solution/types/record';
import { getCalculationValue } from '../../helpers/calculation-values';
import dt, { Param, WaterFeed } from '../../helpers/dt';
import { CalculationValues } from '../projection/projection-values';

export const calculateDesignTurbidity = (inputs: CalculationValues<'id'>) => {
  // Prepare DT Requirements
  let waterSource = getCalculationValue(inputs, 'water_source', 0, '');
  let codValue = getCalculationValue(inputs, 'cod', 0, 0);
  let tocValue = getCalculationValue(inputs, 'toc', 0, 0);
  let tssValue = getCalculationValue(inputs, 'tss', 0, 0);
  let turbidity = getCalculationValue(inputs, 'turbidity', 0, 0);
  let color = getCalculationValue(inputs, 'color', 0, 0);

  // Calculate DT
  if (waterSource && codValue && tocValue && tssValue && turbidity && color) {
    const param: Param = {
      water_feed: waterSource as WaterFeed,
      cod: codValue,
      toc: tocValue,
      color: color,
      tss: tssValue,
      turbidity: turbidity,
    };

    return [dt(param)];
  }

  return [];
};

export const applyDesignTurbidity = (inputs: CalculationValues<'id'>) => {
  // Add DT if it doesn't exist
  const id = 'design_turbidity';

  // return updated Inputs
  return {
    ...inputs,
    [id]: { ...inputs[id], id, values: calculateDesignTurbidity(inputs) } as CalculationValue,
  };
};
