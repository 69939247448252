import { useRef, useEffect, ChangeEvent, useCallback } from 'react';
import { CalculationTextField } from '../../../../__old__/v2/common/ui/calculation-textfield';
import { updateCalculationValue } from '../../../../__old__/v2/feature/projection/helpers/calculation-values';
import { stockConcentrationTableCEB } from '../../../../__old__/v2/feature/solution/helpers/stock-concentration';
import { CalculationValue } from '../../../../__old__/v2/feature/solution/types/record';
import { CEBSettingsProps } from './ceb-settings';
import { useWTCalculation } from '../../hooks/use-wt-calculation';

export const CEBChemicalAgentFields: React.FC<
  CEBSettingsProps & {
    agent: 'agent_1' | 'agent_2';
  }
> = ({ ceb, agent, inputs, advisedInputs, onError, onChange, onIsCalculatingChange }) => {
  const { isConverting } = useWTCalculation();

  const agentNr = agent === 'agent_1' ? '1' : '2';
  const coagulantKey = `${ceb}_chemical_${agent}`;
  const stockKey = `${ceb}_chemical_stock_concentration_${agentNr}`;
  const stockWTKey = `${ceb}_chemical_stock_concentration_${agentNr}_wtpercent`;
  const concentrationKey = `${ceb}_concentration_${agent}`;
  const calculatedConcentrationKey = `chm_chemical_${agent}_${ceb.replaceAll('_', '')}_concentration`;

  const CEBChemicalAgent = inputs[coagulantKey];
  const CEBConcentration = inputs[concentrationKey];
  const CEBCalculatedConcentration = inputs[calculatedConcentrationKey];
  const CEBStockConcentration = inputs[stockKey];
  const CEBStockConcentrationWT = inputs[stockWTKey];

  if (CEBChemicalAgent?.values[0] === 'null') {
    CEBChemicalAgent.values = [''];
  }

  if (coagulantKey in advisedInputs && advisedInputs[coagulantKey]?.values[0] === 'null') {
    advisedInputs[coagulantKey].values = [''];
  }

  if (CEBStockConcentrationWT?.values[0] === 'NaN') {
    CEBStockConcentrationWT.values = ['0'];
  }

  if (CEBStockConcentration?.values[0] === 'NaN') {
    CEBStockConcentration.values = ['0'];
  }

  if (stockWTKey in advisedInputs && advisedInputs[stockWTKey].values[0] === 'NaN') {
    advisedInputs[stockWTKey].values = ['0'];
  }

  if (stockKey in advisedInputs && advisedInputs[stockKey]?.values[0] === 'NaN') {
    advisedInputs[stockKey].values = ['0'];
  }

  // const wtValue = parseFloat(String(CEBStockConcentrationWT?.values[0] || '0')) || 0;

  const chemical = CEBChemicalAgent?.values[0]?.toString() || '';
  // const isNA = chemical === 'null' || chemical === '';
  // const isCommercial = chemical === 'null' || chemical === '';
  // const isWtDisabled = isNA || isCommercial;
  const isConcentrationDisabled = !['D2', 'NaOCl', 'H2O2', 'NaHSO3'].includes(chemical);

  const advisedConcentration = advisedInputs[concentrationKey]?.values?.[0]?.toString() || '';

  if (!advisedConcentration || advisedConcentration === '0') {
    if (advisedInputs[concentrationKey]) {
      advisedInputs[concentrationKey].values = CEBCalculatedConcentration?.values;
    }
  }

  const handleCoagulantChange = useCallback(
    (cv: CalculationValue, e: ChangeEvent<HTMLInputElement>) => {
      let coagulantWTValue = stockConcentrationTableCEB[e.target.value] || 0;
      if (e.target.value === 'D2') {
        coagulantWTValue = 0;
      }

      onChange(updateCalculationValue(CEBStockConcentrationWT, [coagulantWTValue]));
      onChange(cv);
    },
    [CEBStockConcentrationWT, onChange]
  );

  const mapChemicalAgentValue = useCallback(
    (cv: CalculationValue, e: any) => {
      handleCoagulantChange(
        {
          ...cv,
          values: [cv.values[0] === 'null' ? '' : cv.values[0]],
        },
        e
      );
    },
    [handleCoagulantChange]
  );

  // const isMounted = useRef(false);
  const CEBStockConcentrationRef = useRef(CEBStockConcentration);
  CEBStockConcentrationRef.current = CEBStockConcentration;

  // // update stock concentration when wt% changes
  // useEffect(() => {
  //   // skip if wt% is disabled
  //   if (isWtDisabled) {
  //     return;
  //   }

  //   // function that updates the stock concentration
  //   const calculate = async () => {
  //     try {
  //       const glValue = await convertWtPercentage(chemical, wtValue);
  //       const updatedGL = updateCalculationValue(CEBStockConcentrationRef.current, [glValue]);

  //       onChange && onChange(updatedGL);
  //     } catch (e) {
  //       console.error(e);
  //     }
  //   };

  //   // get stock concentration
  //   const glvalueString = String(CEBStockConcentrationRef.current?.values[0]);
  //   const glValue = parseFloat(glvalueString || '0') || 0;

  //   // if component is mounted and stock concentration is 0 or empty
  //   if (isMounted.current || !glValue) {
  //     // calculate stock concentration
  //     calculate();
  //   }

  //   isMounted.current = true;
  // }, [chemical, convertWtPercentage, wtValue, onChange, isWtDisabled]);

  // inform parent that we are calculating
  useEffect(() => {
    if (onIsCalculatingChange) {
      onIsCalculatingChange(isConverting);
    }
  }, [onIsCalculatingChange, isConverting]);

  return (
    <>
      <CalculationTextField
        select
        defaultValue={'null'}
        calculationValue={inputs[coagulantKey]}
        advisedValue={advisedInputs[coagulantKey]}
        onChange={mapChemicalAgentValue}
        onError={onError}
        disabled={isConverting}
        validations=""
      />

      {isConcentrationDisabled || (
        <CalculationTextField
          calculationValue={CEBConcentration}
          advisedValue={advisedInputs[concentrationKey]}
          onChange={onChange}
          onError={onError}
          validations={'numberMax:30000'}
          disabled={isConverting}
        />
      )}
    </>
  );
};
