import gql from "graphql-tag";
import { useCallback } from "react";
import { runQuery, RunQueryOptions } from "../../../../network/run-query";
import { ProjectionFragment } from "../../graphql/fragments";

interface MutationDeleteProjectionArgs {
  id: string;
}

const buildQuery = (fragment: string = ProjectionFragment) => gql`
  mutation DeleteProjection($id: ID!) {
    deleteProjection(id: $id)
  }
`;

export const deleteProjection = async (
  options: RunQueryOptions<MutationDeleteProjectionArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    MutationDeleteProjectionArgs,
    { deleteProjection: boolean }
  >({
    query,
    variables: options.variables,
  });
};

export const useDeleteProjection = (
  id: string,
  handleResponse: any,
  fragment: string = ProjectionFragment
) => {
  return useCallback(async () => {
    const res = await deleteProjection({
      variables: { id },
      query: { fragment },
    });

    handleResponse(res.data && res.data.deleteProjection, res.errors);
    return res;
  }, [id, handleResponse, fragment]);
};
