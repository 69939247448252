import { GraphQLModel } from "./graphql-model";
import { computed, observable } from "mobx";
import { ComparisonOperators } from "../types";
import { RecordData } from "../general/store";
import { GraphQLProvider } from "./graphql-provider";

export interface Collection<T> {
  items: T[];
  loading: boolean;
  loaded: boolean;
  hasItems: boolean;
  fetch(): Promise<void>;
  find(id: string): T | undefined;
  where(key: string, value: any): Collection<T>;
  where(
    key: string,
    operator: ComparisonOperators,
    value: string | number | boolean
  ): Collection<T>;
}

interface ComparisonProps {
  key: string;
  operator: ComparisonOperators;
  value: string | number | boolean;
}

type CollectionFilters = Record<string, ComparisonProps>;

export class GraphQLCollection<T extends GraphQLModel<TRecord>, TRecord extends RecordData> {
  @observable public loading: boolean = false;
  @observable public loaded: boolean = false;
  @observable public filters: CollectionFilters = {};

  public get hasItems() {
    return this._provider.store.list.length ? true : false;
  }

  constructor(
    private _items: TRecord[] | undefined = undefined,
    private _provider: GraphQLProvider<TRecord, T>
  ) {}

  @computed public get items(): T[] {
    const items = (this._items || this._provider.store.list).map((record) =>
      this._provider.createInstance(record)
    );
    return this.applyFilters(items, this.filters) || [];
  }

  public applyFilters = (items: T[], _filters: CollectionFilters) => {
    return items;
  };

  public fetch = async () => {
    this.loading = true;
    await this._provider.fetchList();
    this.loading = false;
    this.loaded = true;
  };

  public where = (
    ...args:
      | [string, string | number | boolean]
      | [string, ComparisonOperators, string | number | boolean]
  ) => {
    let key = args[0];
    let value = typeof args[2] === "string" ? args[2] : args[1];
    let operator = (args.length === 3 ? args[2] : "===") as ComparisonOperators;

    this.filters[key + operator + value] = { key, operator, value };

    return this;
  };

  public find = (id: string) => {
    return this.items.find((item) => item.id === id);
  };
}
