import { ProjectionFragment } from './fragments';

export const ProjectionCollection = `
  query ProjectionCollection {
    ProjectionCollection {
      projections {
        id
        name
        solution {
          id
          name
          order
        }
        version
        owner {
          id
          attributes {
            given_name
            family_name
          }
        }
        approver {
          id
          attributes {
            given_name
            family_name
          }
        }
        status
        createdAt
        updatedAt
        updatedBy {
          id
          attributes {
            given_name
            family_name
          }
        }
      }
    }
  }
`;

export const ProjectionCollectionByProject = `
  query ProjectionCollection($projectId: ID!) {
    ProjectionCollection(projectId: $projectId) {
      projections {
        id
        name
        solution {
          id
          name
          order
        }
        version
        owner {
          id
          attributes {
            given_name
            family_name
          }
        }
        approver {
          id
          attributes {
            given_name
            family_name
          }
        }
        status
        createdAt
        updatedAt
        updatedBy {
          id
          attributes {
            given_name
            family_name
          }
        }
      }
    }
  }
`;

export const Projection = `
  query Projection($id:ID!, $version: String) {
    Projection(id:$id, version: $version){
    ${ProjectionFragment}
  }
  }
`;

export const ProjectionRevisions = `
  query ProjectionRevisions($id:ID!) {
    ProjectionRevisions(id: $id) {
      versionId
      designedBy {
        id
        attributes {
          given_name
          family_name
        }
      }
      approvedBy {
        id
        attributes {
          given_name
          family_name
        }
      }
      note
      isLatest
      lastModified
    }
  }
`;

export const ProjectionOwner = `
  query Projection($id:ID!) {
    Projection(id:$id){
    owner{
      id
      username
      email
    }
  }
  }
`;

export const ProjectionVersions = `
  query Projection($id:ID!) {
    Projection(id:$id){
      versions{
        isLatest
        lastModified
        versionId
      }
    }
  }
`;

export const ProjectionApprover = `
  query Projection($id:ID!) {
    Projection(id:$id){
    approver{
      id
      username
      email
    }
  }
  }
`;

export const ProjectionUsers = `
  query ProjectionUsers($id:ID!) {
    Projection(id:$id){
    users{
      id
      username
      email
    }
  }
  }
`;

export const ProjectionNotifications = `
query ProjectionNotification($id:ID!) {
  Projection(id:$id){
    notifications {
      id
      name
      description
      acknowledged
      annotations {
        id
        description
        user {
          id
          username
        }
      }
    }
  }
}
`;
