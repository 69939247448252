import { lazy } from 'react';
import { createRoute } from '../../application/helpers/route';

const Products = lazy(() => import('../../../pages/products-index'));

export const routesProducts = {
  // Products or price collection
  // Route for price list
  'products.index': createRoute({
    path: '/products/:segment',
    title: 'Products',
    component: Products,
    requiredPermissions: ['products.view.products'],
    breadcrumbs: [
      {
        route: 'products.index',
        label: 'View Products Pricelist',
      },
    ],
  }),
};
