import * as React from 'react';
import { Typography } from '@mui/material';
import { StyleDefaultPages, useErrorPageStyles } from './styles-error-pages';
import { PageContent } from '../../components/page/page-content';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons';

interface OwnProps extends StyleDefaultPages {}

export const ErrorUnauthorized: React.FC<OwnProps> = (props) => {
  const classes = useErrorPageStyles(props);

  return (
    <PageContent variant="centered" classes={{ root: classes.root }}>
      <div>
        <FontAwesomeIcon icon={faExclamationCircle} />
        <Typography className={classes.bigText} variant={'h1'} gutterBottom>
          Unauthorized
        </Typography>
      </div>
    </PageContent>
  );
};
