import gql from 'graphql-tag';
import { useCallback } from 'react';
import { runQuery, RunQueryOptions } from '../../../../network/run-query';
import { ProjectionFragment } from '../../graphql/fragments';
import { ProjectionRecord } from '../../projection';
import { InputProjection } from './create';
import { makeProjectionInput } from './make-input';

interface MutationUpdateProjectionArgs {
  input: InputProjection;
}

const buildQuery = (fragment: string = ProjectionFragment) => gql`
  mutation UpdateProjection($input: inputUpdatedProjection!) {
    updateProjection(input: $input) {
      ${fragment}
    }
  }
`;

export const updateProjection = async (options: RunQueryOptions<MutationUpdateProjectionArgs>) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<MutationUpdateProjectionArgs, { updateProjection: ProjectionRecord }>({
    query,
    variables: options.variables,
  });
};

export const useUpdateProjection = (
  record: Partial<ProjectionRecord>,
  handleResponse: any,
  fragment: string = ProjectionFragment
) => {
  useCallback(
    async (onMessage: (msg: string) => void = () => {}) => {
      const input = await makeProjectionInput(record);

      const res = await updateProjection({
        variables: { input },
        query: { fragment },
      });

      handleResponse(res.data && res.data.updateProjection, res.errors);

      return res;
    },
    [record, handleResponse, fragment]
  );
};
