import gql from 'graphql-tag';
import { useCallback } from 'react';
import { runQuery, RunQueryOptions } from '../../../../network/run-query';
import { ProjectionFragment } from '../../graphql/fragments';
import { ProjectionRecord } from '../../projection';

interface QueryProjectionArgs {
  id: string;
  version?: string;
}

const buildQuery = (fragment: string = ProjectionFragment) => gql`
  query Projection($id: ID!, $version: String) {
    Projection(id: $id, version: $version) {
      ${fragment}
    }
  }
`;

export const fetchProjection = async (options: RunQueryOptions<QueryProjectionArgs>) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<QueryProjectionArgs, { Projection: ProjectionRecord }>({
    query,
    variables: options.variables,
  });
};

export const useFetchProjection = (
  id: string,
  handleResponse: any,
  fragment: string = ProjectionFragment
) => {
  return useCallback(
    async (version?: string) => {
      const res = await fetchProjection({
        variables: { id, version },
        query: { fragment },
      });

      handleResponse(res.data && res.data.Projection, res.errors);
      return res;
    },
    [id, handleResponse, fragment]
  );
};
