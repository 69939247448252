import { ProjectionRecord } from '../../../../../application/resources/projection/projection';
import { CalculationValue } from '../../../solution/types/record';
import * as React from 'react';
import { Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
// import { FormTableRowFieldProjectionCalculationValue } from '../../../../../view/components/form/projection-table-row-calculation-value';
import { FormTableRowFieldProjectionCalculationValueConvert } from '../../../../../view/components/form/projection-table-row-calculation-value-convert';
import { SectionTitle } from '../../../../../../theme/components/section-title';
import { faClipboardList } from '@fortawesome/pro-light-svg-icons';

export interface OwnProps {
  onChange: (key: keyof ProjectionRecord) => (value: any) => void;
  onValidate: (step: string, hasErrors: boolean) => void;
  data: CalculationValue[];
  errors?: any;
  title?: any;
  waterSource?: CalculationValue;
  backwashWaterReuse: boolean;
  solution?: string;
}

export const FormTableProjectionsInputsCategory: React.FC<OwnProps> = (props) => {
  const { onChange, onValidate, data, title, solution } = props;
  const [validationErrors, setValidationErrors] = React.useState<Record<string, string>>({});

  const onValidationError = React.useCallback(
    (key: string) => (error: string | false) => {
      if (validationErrors[key] || false !== error) {
        if (error) {
          validationErrors[key] = error;
        } else {
          delete validationErrors[key];
        }

        setValidationErrors({ ...validationErrors });
        onValidate('feed', Object.keys(validationErrors).length > 0 || false);
      }
    },
    [validationErrors, onValidate]
  );

  return (
    <React.Fragment>
      <SectionTitle title={title || 'Flow inputs'} icon={faClipboardList} />

      <Grid container spacing={2} marginTop={-3}>
        <Grid item xs={12} md={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={'50%'}>Parameter</TableCell>
                <TableCell width={'20%'}>Value</TableCell>
                <TableCell width={'30%'}>EGU/UNIT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((input) => {
                if (['design_turbidity', 'turbidity', 'tss', 'toc', 'cod', 'bod', 'color'].includes(input.id)) {
                  return (
                    <FormTableRowFieldProjectionCalculationValueConvert
                      key={input.id}
                      type={input.id}
                      value={input}
                      onChange={onChange('inputs')}
                      onError={onValidationError(input.id)}
                      disabled={input.id === 'design_turbidity'}
                      backwashWaterReuse={props.backwashWaterReuse}
                      waterSource={
                        (props.waterSource && props.waterSource.values && (props.waterSource.values[0] as string)) || ''
                      }
                      solution={solution || ''}
                    />
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} md={6}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell width={'50%'}>Parameter</TableCell>
                <TableCell width={'20%'}>Value</TableCell>
                <TableCell width={'30%'}>EGU/UNIT</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((input) => {
                if (
                  [
                    'temperature',
                    'alcalinity',
                    'ph',
                    'tds',
                    'hardness',
                    // 'bod', // pxx-1246
                  ].includes(input.id)
                ) {
                  return (
                    <FormTableRowFieldProjectionCalculationValueConvert
                      key={input.id}
                      type={input.id}
                      value={input}
                      onChange={onChange('inputs')}
                      onError={onValidationError(input.id)}
                      backwashWaterReuse={props.backwashWaterReuse}
                      solution={solution || ''}
                      waterSource={(props.waterSource?.values && (props.waterSource?.values[0] as string)) || ''}
                    />
                  );
                }
                return null;
              })}
            </TableBody>
          </Table>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
