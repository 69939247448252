import { observable, computed } from "mobx";
import { Provider } from "../types";
import { RecordData } from "./store";

export interface Model<TRecord> {
  id: string;
  serialize(): TRecord;
  defaultValues: Partial<TRecord>;
  changes: Partial<TRecord>;
  applyChange(changes?: Partial<TRecord>, updateStore?: boolean): void;
  record: TRecord;
}

export abstract class BaseModel<
  TRecord extends RecordData,
  TProvider extends Provider<TRecord> = Provider<TRecord>
> implements Model<TRecord> {
  defaultValues: TRecord = {} as TRecord;

  @observable protected _record: Partial<TRecord>;
  @observable protected _provider: TProvider | undefined;
  @observable public changes: Partial<TRecord> = {};

  constructor(_record: RecordData<TRecord>, _provider: any = undefined) {
    this._record = _record;
    this._provider = _provider;
  }

  @computed public get record(): TRecord {
    const id = this._record.id || this.defaultValues.id;

    return {
      ...this.defaultValues,
      ...this._record,
      ...((this._provider && this._provider.store.records[id]) || {}),
      ...this.changes,
    };
  }

  public set record(record) {
    this._record = record;
    this.reset();
  }

  public get id(): string {
    return this.record.id;
  }

  public serialize = (): TRecord => {
    return this.record;
  };

  public applyChange = (
    changes: Partial<TRecord> = {},
    updateStore: boolean = false
  ) => {
    this.changes = { ...this.changes, ...changes };

    if (this._provider && (!changes || (changes && updateStore))) {
      this._provider.store.setRecord({ ...changes, id: this.id });
    }
  };

  public set provider(provider: TProvider) {
    this._provider = provider;
  }

  public reset = () => {
    this.changes = {};
  };
}
