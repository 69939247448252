import { guidelinesAquaflex } from './aquaflex';
import { guidelinesAquaflexHS } from './aquaflex-hs';
import { guidelinesXiga } from './xiga';
import { guidelinesXline } from './xline';

export const projectionGuidelines = {
  xline: guidelinesXline,
  aquaflex: guidelinesAquaflex,
  xiga: guidelinesXiga,
  'aquaflex-new': guidelinesAquaflex,
  'aquaflex-hs': guidelinesAquaflexHS,
};
