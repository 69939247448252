import { take } from "../../../application/resources/helper/take";
import { fetchSolution } from "./functions/fetch";
import { SolutionRecord } from "./types/record";

export class SolutionClass {
  private _record: Partial<SolutionRecord> = {};
  private _changes: Partial<SolutionRecord> = {};

  public isFetching: boolean = false;
  public isDeleting: boolean = false;
  public isUpdating: boolean = false;
  public isCreating: boolean = false;

  public get record(): Partial<SolutionRecord> {
    const record = { ...this._record, ...this._changes };

    return record;
  }

  public get changes(): Partial<SolutionRecord> {
    return this._changes;
  }

  constructor(
    public id?: string,
    public fragment?: string,
    private _setState?: (state: SolutionClass) => void
  ) {
    this.record.id = id;
  }

  public setState = <T extends Record<string, any>>(
    obj?: T,
    k?: keyof T,
    value?: any
  ) => {
    if (obj && k) {
      obj[k] = value;
    }

    if (this._setState) {
      this._setState({ ...this });
    }
  };

  public handleResponse = (
    record: Partial<SolutionRecord> | null | undefined,
    errors: object[] = []
  ) => {
    this._record = { ...this._record, ...(record || {}) };
    // this.errors = errors;
    this.setState();
  };

  public get input(): Partial<SolutionRecord> {
    return take(this.record, ["id", "name"]);
  }

  public change = (key: keyof SolutionRecord, value: any) => {
    this._changes = { ...this._changes, [key]: value };
    this.setState();
  };

  public fetch = async (id?: string) => {
    if (id && id.length) {
      this.id = id;
    }

    if (!this.id) {
      throw new Error("ID is not set");
    }

    const res = await fetchSolution({
      variables: { id: id || this.id },
      query: { fragment: this.fragment },
    });
    this.handleResponse(res.data && res.data.Solution, res.errors);
    return res;
  };

  public create = async () => {
    // const res = await createSolution({
    //   variables: { input: this.input },
    //   query: { fragment: this.fragment },
    // });
    // this.handleResponse(res.data && res.data.createSolution, res.errors);
    // return res;
    return;
  };

  public update = async () => {
    // if (this.id) {
    //   const res = await updateSolution({
    //     variables: { input: this.input },
    //     query: { fragment: this.fragment },
    //   });
    //   this.handleResponse(res.data && res.data.updateSolution, res.errors);
    //   return res;
    // }

    return;
  };

  public delete = async () => {
    // if (!this.id) {
    //   throw new Error("ID is not set");
    // }
    // const res = await deleteSolution({
    //   variables: { id: this.id },
    //   query: { fragment: this.fragment },
    // });
    // this.handleResponse(res.data && res.data.deleteSolution, res.errors);
    // return res;

    return;
  };
}
