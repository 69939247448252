export const guidelinesAquaflex = `
<p><b>1. FEEDWATER</b><br/>
1.1. Any feed water quality parameters not mentioned in the projection are considered to be absent and therefore disregarded<br/>
1.2. Any Oil & Grease present in the feed water is assumed to be emulsified. Dissolved O&G will pass through the UF membranes. Free O&G is not allowed to avoid irreversible membrane fouling<br/>
1.3. Any colloidal silica (mg/l) present in the feed water will contribute to the total suspended solids concentration and be added to the TSS parameter (mg/l)<br/>
1.4. In case of elevated ammonia concentrations in the feed water, the NaOCl concentration during CEB (mg/l) shall be increased by 5 mg/l per mg/l of ammonia<br/>
1.5. The use of any polymer which may end up in the UF feed is not allowed to avoid irreversible membrane fouling unless tested for representative performance data and/or approved by Pentair X-Flow<br/>
1.6. For membrane resistance to solvents and (cleaning) chemicals, refer to the respective Pentair X-Flow membrane element datasheets<br/>
1.7. The need for pH correction of UF feed water shall be assessed on reducing scaling potential (LSI to be <0.5) and achieving optimum pH for coagulation (if required). Depending on the coagulant selected and actual feed water quality, the optimum pH shall be verified with the coagulant supplier. Based on general Pentair X-Flow experience, optimum pH is around 7 for Aluminium-based coagulants and 6-8 for Iron-based coagulants<br/>
1.8. Typical and maximum coagulant concentrations indicated as mg Metal3+/l in the projection refers to the active Metal3+ concentrations required. Unless stated otherwise, the coagulant concentrations and consumption in the projection exclude consumption by phosphate precipitation. Final concentrations are subject to actual feed water quality<br/>
1.9. Coagulant consumption in the projection (l/day) is calculated based on the typical coagulant concentration. The coagulant dosing pump is designed based on the maximum coagulant concentration<br/>
1.10. Any coagulant selected must be free of impurities, such as heavy metal contaminants and inactive soluble Metal2+ ions such as Fe2+ and Mn2+, to avoid coagulant related membrane fouling and passage of dissolved metals to the UF permeate. For this reason, coagulants originating as byproducts or waste streams from an industrial manufacturing process, such as ferric chloride taken from a steel pickling process, shall be avoided. Instead, any coagulant must be manufactured from high purity raw materials by a qualified chemical company<br/>
1.11. For membrane protection, feedwater must be filtered with a strainer of the below rating for respective membrane elements:<br/>
1.11.1. maximum 300 µm for XF75, XF64, XF55, and XF40C membrane elements<br/>
1.11.2. maximum 600 µm for XF20C membrane elements<br/>
1.11.3. Moreover, a protective backwash strainer of a maximum of 2 mm for any membrane element type is strongly recommended.<br/>
1.12. For direct seawater/brackish water filtration, a feed strainer of a maximum of 100 µm is required to avoid the entrance of shellfish eggs, which could grow/accumulate in the UF system and harm the membranes unless stated otherwise.</p> 

<p><b>2. AIR QUALITY</b><br/>
2.1. Required air quality for AirFlush during Hydraulic Clean and Membrane Integrity Testing (MIT): ISO 8573-1, class 1/3/1 (oil/water/particles)</p>

<p><b>3. CHEMICAL ENHANCED BACKWASH</b><br/>
3.1. Calculated CEB acid and caustic concentrations in the projection (mg/l) are based on reaching the pH set-points during CEB dosing as selected on sheet "CEB-settings." Therefore, final concentrations are subject to actual feed water quality<br/>
3.2. Since CEB is critical for optimum UF performance, all projected CEB dosing pumps include a 50% safety margin to compensate for feed water quality fluctuations<br/>
3.3. Chemical dosing points for CEB shall be positioned as close as possible to the UF units to minimize chemical consumption and avoid a negative impact on recovery due to extended post-dosing times</p>

<p><b>4. PROCESS & AUTOMATION:</b><br/>
4.1. UF feed and backwash pump ramping, and pneumatic valve actions shall be established within a recommended timeframe of 4 to 10 seconds during automatic operation. Unless stated otherwise, 8 seconds pump ramping time and 10 seconds valve switching time are selected in the projection. Deviations might result in pressure shocks to the membranes or reduced UF recovery<br/>
4.2. Results from this projection may not be achieved on control systems with slow cycle and response times. Calculations are based on maximum 1 second response times. Pentair X-Flow cannot be held responsible for any deviations due to slow response times or improper software design</p>

<p><b>5. TMP CRITERIA DURING NORMAL OPERATION:</b><br/>
5.1. During normal operation of the Aquaflex units, the following TMP criteria shall be met:<br/>
5.1.1. For XF75, XF64, XF55, XF40C, and XF20C membrane elements, a maximum filtration TMP of 0.8 bar and a maximum backwash TMP of -2.0 bar shall be anticipated<br/>
5.1.2. For HFS60 membrane elements, a maximum filtration TMP of 2.5 bar and a maximum backwash TMP of -4.0 bar shall be anticipated</p>

<p><b>6. PRESSURE CRITERIA DURING NORMAL OPERATION:</b><br/>
6.1. During normal operation of the Aquaflex units, the following pressure criteria shall be met:<br/>
6.1.1. The maximum allowed pressure range: min. 0 barg (no negative pressures allowed in the UF membrane units) to the maximum allowed pressure according to the Pentair X-Flow membrane element datasheet<br/>
6.1.2. The maximum allowed rate of pressure change for any membrane is 0.5 bar/sec<br/>
6.1.3. The maximum TMP's according to guideline 5</p>

<p><b>7. PRESSURE CRITERIA DURING EMERGENCY:</b><br/>
7.1. During emergency scenarios (power failure, pump trip, valve failure, etc.), the following pressure criteria shall be met:<br/>
7.1.1 Peak pressure range allowed: minimum - 0.25 barg to the maximum allowed pressure according to Pentair X-Flow membrane element datasheet.<br/>
7.1.2. Peak TMPs allowed:<br/>
7.1.2.1. For XF75, XF64, XF55, XF40C, and XF20C membrane elements, 2 bar during filtration and -2.5 bar during backwash,<br/>
7.1.2.2. For HFS60 membrane elements, 4.0 bar during filtration and -4.0 bar during backwash,<br/>
7.2. Whether these pressure criteria during an emergency are exceeded and the need for protection measures to be implemented (surge vessels, flywheels on pumps, changes to piping layout, etc.) shall be determined by executing a hydraulic surge study.</p>

<p><b>8. MEMBRANE SYSTEM DESIGN</b><br/>
8.1. Design head of feed and backwash pumps is the customer's responsibility, taking into account the maximum TMP's during normal operation according to guideline 5 and additional system losses<br/>
8.2. Suppose the UF system is intended to operate "inline" with a downstream RO system (without intermediate break tank) at elevated permeate pressure. In that case, the normal design guidelines of this program do not apply. Instead, Pentair X-Flow shall be consulted for inline UF-RO design assistance</p>

<p><b>TERMINOLOGIES</b><br/>
Membrane fiber or tube - ​Polymeric material with the capability of removing substances from the water by a sieving mechanism where a particle is denied access through a pore or passageway that is smaller than the particle itself<br/>
​Membrane element - a replaceable element containing a set of membrane fibers or tubes<br/>
​Membrane housing - a pressure vessel for loading membrane elements<br/>
Membrane skid - one or more membrane elements installed to pipe headers with connection sets for forming a membrane filtration section<br/>
​                Manifold - ​a set of dedicated piping installed with additional components such as valves, instruments to connect a membrane skid for forming a membrane unit<br/>
Membrane​unit - ​one or more membrane elements installed in a skid connected to a manifold<br/>
​                Membrane train - one or more membrane units installed in series with common backwash & CEB section</p>

<p><b>ACRONYMS</b><br/>
BOD - Biochemical Oxygen Demand<br/>
CEB - Chemical Enhanced Backwash<br/>
COD - Chemical Oxygen Demand<br/>
MIT - Membrane Integrity Testing<br/>
N.A. - Not Applicable<br/>
TBD - To be decided<br/>
TDS - Total Dissolved Solids<br/> 
TOC - Total Organic Carbon<br/>
TMP - Trans Membrane Pressure<br/>
TSS - Total Suspended Solids</p>

<p>_________________________________________________________________________________</p>

<p>As an integral part of the Pentair X-Flow Membrane+ Approach, an extensive set of design, process, and technical documents and drawings are provided for every project phase for technological support in connection with the membrane filtration solutions. However, it is the responsibility of the OEMs and contractors to comply with the guidelines and recommendations and the local regulations for the quality, environment, health, and safety (QEHS), during the integration and operation of the membrane filtration system.</p>

<p><b>DEVELOPMENT PHASE</b><br/>
- Membrane element datasheet<br/>
- Membrane housing datasheet (if elements are installed inside housings as per the design)<br/>
- Connection Specifications datasheet<br/>
- X-Flow grooved couplings datasheet (if supplied)</p>

<p><b>DESIGN PHASE</b><br/>
- Process Basic & Principles<br/>
- General Design Guidelines<br/>
- Coagulation Design Guidelines (if required as per design)<br/>
- Basic Process Design<br/>
- UF Unit Piping and Instrumentation Drawing (P&ID)<br/>
- UF Unit General Arrangement (GA) drawing</p>

<p><b>EXECUTION PHASE</b><br/>
- Basic Process Controls<br/>
- Process Commissioning Overview<br/>
- Process Commissioning Checklist<br/>
- Wet Testing Without Membrane Elements<br/>
- Membrane Element Installation<br/>
- Membrane Element Flushing<br/>
- Wet Testing With Membrane Elements</p>

<p><b>OPERATION PHASE</b><br/>
- Aquaflex Data Logging & Trending<br/>
- Membrane Integrity Testing - Displaced Waterflow Integrity Test (if required as per design)<br/>
- Membrane Integrity Testing - Pressure Decay Integrity Test (if required as per design)<br/>
- Membrane Integrity Testing - Sonic Integrity Test<br/>
- Single Element Integrity Test and Repair<br/>
- Cleaning In Place (if required as per design)<br/>
- Membrane Elements Standstill and Storage</p>

<p>Unless otherwise agreed in writing with Pentair X-Flow, failure to adhere to any of the guidelines referred to herein and outlined in all related documents and drawings will void all membrane element warranties provided by Pentair X-Flow.</p>

<p>These documents available in other languages are for information purposes only, and in case of a discrepancy, the English original will prevail for legal validity.</p>
`;
