import { ProjectionRecord } from '../../../../../application/resources/projection/projection';
import { CalculationValue } from '../../../solution/types/record';
import { applyDesignTurbidity } from '../calculations/design-turbidity';
import { keyBy, chain, values } from 'lodash';
// import { CalculationCategories } from "./input-keys";
import { applyInternalRecirculation } from '../calculations/internal-recirculation';
import { toDisplayUnit } from '../../../../../view/components/form/fields/projection-calculation-value-convert';
import { toFixedIfNecessary } from '../../../../../application/resources/helper/to-fixed';
import { CalculationCategories } from '../../../../../../feature/projections/types/input-keys';

export type CalculationValues<BasedOn extends 'id' | 'calculation_field' = 'id'> = Partial<
  Record<CalculationValue[BasedOn], CalculationValue>
>;

export const makeProjectionInputs = (record: Partial<ProjectionRecord>): CalculationValues<'id'> => {
  let inputs = keyBy(record.inputs || [], 'id') as CalculationValues<'id'>;
  inputs = applyDesignTurbidity(inputs);
  inputs = applyInternalRecirculation(inputs);
  return inputs;
};

export const makeProjectionOutputs = (record: Partial<ProjectionRecord>): CalculationValues<'id'> => {
  return keyBy(record.outputs || [], 'id');
};

export const makeInputsBasedOnCalcField = (
  record: Partial<ProjectionRecord>
): CalculationValues<'calculation_field'> => {
  return keyBy(record.inputs || [], 'calculation_field');
};

export const makeOutputsBasedOnCalcField = (
  record: Partial<ProjectionRecord>
): CalculationValues<'calculation_field'> => {
  return keyBy(record.outputs || [], 'calculation_field');
};

export const makeCategorizedInputs = (
  record: Partial<ProjectionRecord>
): Record<CalculationCategories, CalculationValue[]> => {
  const inputs = makeProjectionInputs(record);

  return chain(values(inputs)).groupBy('category').value() as any;
};

export const formattedCalculationValue = (calculationValue: CalculationValue | undefined, egu?: string) => {
  let unit = (calculationValue && calculationValue.unit && ' ' + calculationValue.unit) || '';

  if (calculationValue && calculationValue.valueOptions && calculationValue.valueOptions.length > 0) {
    const valueToReturn = calculationValue.valueOptions.find(
      (valueOption: any) => valueOption.id === calculationValue.values[0]
    );

    return ((valueToReturn && valueToReturn.label) || '') + unit;
  }

  if (egu === 'imperial') {
    if (calculationValue && calculationValue.unitOptions && (calculationValue.unitOptions || []).length > 1) {
      const displayUnit = calculationValue.unitOptions[calculationValue.unitOptions.length - 1];
      calculationValue.displayUnit = displayUnit;
      unit = ' ' + displayUnit;

      const value = toDisplayUnit(calculationValue, parseFloat(String(calculationValue.values[0])));
      // const value = fromDisplayUnit(calculationValue, parseFloat(String(calculationValue.values[0])));

      return toFixedIfNecessary(String(value), calculationValue.decimals || 0) + unit;
    }
  }

  return ((calculationValue && calculationValue.values && calculationValue.values[0]) || '') + unit;
};
