import { Presenter } from "../hooks/use-presenter";
import {
  RouteProvider,
  RouteOptions,
  LocationState,
} from "../application/business/provider/routes";
import { RouteNames } from "../config/application";
import { computed } from "mobx";
import { match } from "react-router";

export class RoutesPresenter implements Presenter {
  constructor(private _routeProvider: RouteProvider<RouteNames>) {
    //
  }

  @computed public get routes(): RouteOptions[] {
    return this._routeProvider.routes;
  }

  @computed public get redirect(): boolean {
    return this._routeProvider.locationState === LocationState.Redirect;
  }

  @computed public get redirectLocation(): string | null {
    return this._routeProvider.redirectLocation;
  }

  public mount = () => {};
  public unmount = () => {};

  public validateLocation = this._routeProvider.validateLocation;
  public setMeta = (match?: match<any> | null) => {
    if (match) {
      return this._routeProvider.routes.find(
        (route) => route.path === match.path
      );
    }

    return undefined;
  };
}
