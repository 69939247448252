import { CircularProgress, MenuItem, TextField, TextFieldProps } from '@mui/material';
import * as React from 'react';
import { usePermissions } from '../../auth/auth-permissions';
import { useFetchProfileCollection } from '../../profile/api/fetch-list';

type PentairSelectProfileProps = TextFieldProps;

let cachedProfiles: any[] = [];

export const PentairSelectProfile: React.FC<PentairSelectProfileProps> = ({ ...props }) => {
  const [{ loading, response }, fetchCollection] = useFetchProfileCollection();
  const { userProfile } = usePermissions();
  const items = cachedProfiles.length ? cachedProfiles : response?.data?.ProfileCollection;

  if (!cachedProfiles.length && items?.length) {
    cachedProfiles = items;
  }

  React.useEffect(() => {
    if (!cachedProfiles.length) {
      fetchCollection();
    }
  }, [fetchCollection]);

  return (
    <TextField
      {...props}
      select={Boolean(items?.length && items?.length > 0)}
      value={props.value || ''}
      InputProps={{
        endAdornment: loading && <CircularProgress color="inherit" size={18} style={{ marginRight: '28px' }} />,
      }}
      SelectProps={{ MenuProps: { disablePortal: true } }}
    >
      {items?.map((profile, index) => {
        return (
          <MenuItem
            key={index}
            disabled={profile.id === 'xpert-admins' && userProfile?.profile?.id !== 'xpert-admins'}
            value={profile.id}
          >
            {profile.name}
          </MenuItem>
        );
      })}
    </TextField>
  );
};
