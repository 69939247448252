import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";

interface MutationConvertWTPercentArgs {
  inputs: string;
}

/**
 * Returns a Query
 *
 * @param fragment - Query fragment containing the data you wish to retrieve
 *
 * @returns DocumentNode containing the query string
 */
const buildQuery = () => gql`
  mutation projectionCalculationConvertWtPct($inputs: AWSJSON!) {
    projectionCalculationConvertWtPct(inputs: $inputs) {
      active_concentration
      density
      message
      status
      wtpct_conversion
    }
  }
`;

/**
 * Attempts to fetch a profile and expects to return a profile or error
 *
 * @param options - Options for creating the GraphQL Query.
 *
 * @returns GraphQL response
 */
export const convertWtPercent = async (
  options: RunQueryOptions<MutationConvertWTPercentArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  const result = await runQuery<
    MutationConvertWTPercentArgs,
    { projectionCalculationConvertWtPct: any }
  >({
    query,
    variables: options.variables,
  });
  return result;
};
