/**
 *
 *  By Tim Steinz
 *
 */

import { Box } from '@mui/material';
import { PentairTextField } from '../../../../theme/components/pentair-text-field';
import { CalculationsRecord } from '../../../../__old__/v2/feature/projection/helpers/calculation-values';
import { useStandbyMembraneElements } from '../../hooks/use-standby-membrane-elements';
import { CalculationValue } from '../../../../__old__/v2/feature/solution/types/record';
import { CalculationTextField } from '../../../../__old__/v2/common/ui/calculation-textfield';

export interface StandbyTabProps {
  inputs: Partial<CalculationsRecord>;
  outputs: Partial<CalculationsRecord>;
  onChange(calculationValue: CalculationValue): void;
  onError?(id: string, error: string | false): void;
  solution: string;
}

export const StandbyTab: React.FC<StandbyTabProps> = (props) => {
  // props
  const { inputs, outputs, onChange, onError, solution } = props;

  // hooks
  const { totalUnitsNX, totalMembraneElementsNX, totalMembraneAreaNX, backwashTrains } = useStandbyMembraneElements(
    inputs,
    outputs,
    solution
  );

  // JSX
  return (
    <Box width={500}>
      <CalculationTextField
        label="Total Standby Units (X)"
        calculationValue={inputs['membrane_elements_standby_units']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:100|numberMin:0'}
        type="number"
        inputProps={{ min: 0, max: 100, step: 1 }}
      />

      {backwashTrains && parseInt(backwashTrains) > 1 && (
        <CalculationTextField
          calculationValue={inputs['membrane_elements_standby_philosophy']}
          advisedValue={{
            id: 'membrane_elements_standby_philosophy',
            name: 'Standby Philosophy',
            values: ['per-bashwash-train'],
            recommendedValues: ['per-backwash-train'],
            unit: '',
            valueOptions: [
              {
                id: 'per-backwash-train',
                label: 'Unit(s) per train',
              },
              {
                id: 'common',
                label: 'Common Standby',
              },
            ],
            calculation_field: 'membrane_elements_standby_philosophy',
          }}
          onChange={onChange}
          onError={onError}
          validations={`required`}
          select
          inputProps={{ min: 0, max: 100, step: 1 }}
        />
      )}
      <PentairTextField label={'Total Units (N+X)'} value={totalUnitsNX} disabled />
      <PentairTextField label={'Total Number of Elements (N+X)'} value={totalMembraneElementsNX} disabled />
      <PentairTextField label={'Total Membrane Area (N+X)'} value={totalMembraneAreaNX} disabled />
    </Box>
  );
};
export default StandbyTab;
