import { TooltipClassKey } from '@mui/material/Tooltip';

import { PentairBrightBlue, PentairLightGray } from '../colors';
import { typography } from '../typography';

export const MuiTooltip: Partial<Record<TooltipClassKey, any>> = {
  tooltip: {
    ...typography('body2'),
    fontSize: '12px',
    backgroundColor: PentairBrightBlue[500],
    color: PentairLightGray[400],
  },
};
