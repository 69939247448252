import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import InfoLabel from '../info-label';
import * as React from 'react';
import { useFormValidation } from '../hooks/use-form-validation';
import { useFormChange } from '../hooks/use-form-change';
import { DefaultFormFieldProps } from '../types';
import { CalculationValue, CalculationValueOptions } from '../../../../v2/feature/solution/types/record';
import { cloneDeep } from 'lodash';

interface OwnProps extends DefaultFormFieldProps<Partial<CalculationValue>> {
  textfieldProps?: TextFieldProps;
  disabled?: boolean;
  items?: CalculationValueOptions[];
  type?: string;
  valueOptions?: CalculationValueOptions[];
  dt?: number;
  notAdvised?: boolean;
}

export const FormSelectProjectionCalculationValue: React.FC<OwnProps> = (props) => {
  let calculationValue = props.value || {};
  let items: CalculationValueOptions[] = [];
  if (calculationValue.valueOptions && !props.valueOptions) {
    items = calculationValue.valueOptions;
  } else if (props.valueOptions) {
    items = props.valueOptions;
  } else {
    items = props.items || [];
  }

  const { onChange, changed } = useFormChange((e) => {
    const calcValue = cloneDeep(calculationValue);
    calculationValue.values = [e.target.value];
    calcValue.values = [e.target.value];
    return calcValue;
  }, props.onChange);

  const [valError, setValError] = React.useState<string | undefined>('Required');

  const error = useFormValidation(
    (props.value && props.value.values && props.value.values[0]) || null,
    props.validations,
    props.onError
  );

  const { dt, value, valueOptions, onError, validations } = props;

  React.useEffect(() => {
    if (dt && dt > 30 && value && value.values && ['87L09-1.5', '87L09'].includes(String(value.values[0]))) {
      setValError('Membrane housing cannot be installed for above 30 NTU operation which needs AirFlush.');
      if (onError && valError) {
        onError(valError);
      }
    } else if (
      (validations?.includes('required') &&
        value &&
        value.values &&
        ['', undefined, null].includes(String(value.values[0]))) ||
      (validations?.includes('required') && value && value.values && value.values.length < 1)
    ) {
      setValError('Required');
    } else {
      setValError(undefined);
      if (onError && valError) {
        onError(false);
      }
    }

    if (
      value &&
      value.id === 'membrane_housing' &&
      valueOptions &&
      !valueOptions.find((option) => value?.values && option.id === value.values[0])
    ) {
      // is "No" an option?
      const opt = valueOptions.find((o) => value?.values && o.id === 'No');
      if (opt) {
        value.values = ['No'];
      }
      // select first option; should be the default
      else {
        if (valueOptions[0]) {
          value.values = [valueOptions[0].id];
        }
      }

      // update value in projection
      props.onChange && props.onChange(value, {});
    }
  }, [dt, value, valueOptions]);

  const parsedValue =
    (value &&
      value.id === 'membrane_housing' &&
      valueOptions &&
      !valueOptions.find((option) => value?.values && option.id === value.values[0]) &&
      'No') ||
    (calculationValue.values &&
      calculationValue.values[0] &&
      (calculationValue.values[0] !== '' || calculationValue.values[0] !== undefined) &&
      String(calculationValue.values[0])) ||
    (calculationValue.values &&
      calculationValue.valueOptions &&
      calculationValue.valueOptions.find((option) => option.id === 'null') &&
      calculationValue.values[0] === '' &&
      'null') ||
    (calculationValue.values &&
    calculationValue.valueOptions &&
    calculationValue.valueOptions.find((option) => option.id === 'true') &&
    calculationValue.values[0]
      ? 'true'
      : 'false') ||
    null;

  return (
    <TextField
      style={{ marginBottom: '14px' }}
      type={props.type}
      select
      disabled={props.disabled}
      name={(props && props.value && props.value.id) || 'field'}
      id={(props && props.value && props.value.id) || 'field'}
      value={parsedValue}
      onChange={onChange}
      fullWidth
      label={
        props.description ||
        ((calculationValue.description || calculationValue.tooltip) && (
          <InfoLabel
            title={props.label || calculationValue.name || 'Calculation value'}
            description={
              props.description || calculationValue.tooltip || calculationValue.description || 'Calculation value'
            }
          />
        )) ||
        props.label ||
        calculationValue.name ||
        'Calculation value'
      }
      error={
        !props.disabled && changed && error ? true : false || (!props.disabled && changed && valError) ? true : false
      }
      helperText={(!props.disabled && changed && error) || (!props.disabled && changed && valError)}
      {...props.textfieldProps}
    >
      {items.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.label}
        </MenuItem>
      ))}
    </TextField>
  );
};
