/*

  By Code.Rehab

*/

import { lazy } from 'react';
import { createRoute } from '../../application/helpers/route';

const PageProfileIndex = lazy(() => import('../../view/pages/profile/page-profile-index'));
const PageProfileSingle = lazy(() => import('../../view/pages/profile/page-profile-single'));

export const routesProfile = {
  'profile.index': createRoute({
    path: '/manage/profile/index',
    title: 'User profile management',
    component: PageProfileIndex,
    public: false,
    requiredPermissions: ['iam.profile.list'],
    breadcrumbs: [
      {
        route: 'app.administration',
        label: 'Manage',
      },
      {
        route: 'profile.index',
        label: 'Profiles',
      },
    ],
  }),
  'profile.create': createRoute({
    path: '/manage/profile/create',
    title: 'Create User profile',
    component: PageProfileSingle,
    public: false,
    requiredPermissions: ['iam.profile.create'],
    breadcrumbs: [
      {
        route: 'app.administration',
        label: 'Manage',
      },
      {
        route: 'profile.index',
        label: 'Profiles',
      },
      {
        route: 'profile.create',
        label: 'Create',
      },
    ],
  }),
  'profile.edit': createRoute({
    path: '/manage/profile/:profile',
    title: 'Edit User profile',
    component: PageProfileSingle,
    public: false,
    requiredPermissions: ['iam.profile.update'],
    breadcrumbs: [
      {
        route: 'app.administration',
        label: 'Manage',
      },
      {
        route: 'profile.index',
        label: 'Profiles',
      },
      {
        route: 'profile.edit',
        label: 'Edit',
      },
    ],
  }),
};
export default routesProfile;
