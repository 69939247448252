import i18next, { InterpolationOptions } from 'i18next';

export const I18Config = {
  debug: false,
  interpolation: {
    escapeValue: false,
    defaultVariables: {
      unit: '',
    },
  } as InterpolationOptions, // React already does escaping
  ns: ['glossary', 'projects', 'projections', 'global'],
  lng: 'en',
  fallbackNS: 'glossary',
  fallbackLng: 'en',
  // saveMissing: true,
};

i18next.on('missingKey', function (lngs, namespace, key, res) {
  console.warn(lngs, namespace, key, res);
});
