import * as React from 'react';

import { Box, Theme, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { usePresenter } from '../../../hooks/use-presenter';
import { PageContainerPresenter } from './page-container-presenter';
import { useObserver } from 'mobx-react';
import classnames from 'classnames';
import { useAuth } from '../../../../feature/auth/auth-provider';

interface OwnProps {
  classes?: Record<'root' | 'content' | 'footer' | 'interfaceActive', string>;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: '#fff',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  interfaceActive: {
    paddingLeft: theme.spacing(4),
    // paddingTop: theme.spacing(4),
  },
}));

export const PageContainer: React.FC<React.PropsWithChildren<OwnProps>> = (props) => {
  const classes = (useStyles as any)(props);
  const theme = useTheme();

  const presenter = usePresenter(
    ({ interactor }) => new PageContainerPresenter(interactor.interface, interactor.message)
  );

  const { isAuthenticated } = useAuth();
  const interfaceActive = isAuthenticated;

  return useObserver(() => {
    const { toolWidth } = presenter;
    let rootStyle: React.CSSProperties = {};

    if (interfaceActive) {
      rootStyle.paddingLeft = theme.spacing(4) + toolWidth;
    }

    return (
      <>
        <div className={classnames(classes.root, interfaceActive && classes.interfaceActive)} style={rootStyle}>
          <Box height={interfaceActive ? 48 : 0} />
          <main className={classes.content}>{props.children}</main>
        </div>
      </>
    );
  });
};
