import { GraphQLModel, BaseGraphQLModel } from "../../base/graphql/graphql-model";
import { v4 as uuid } from "uuid";

export type NotificationRecord = {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
};

export interface Notification extends GraphQLModel<NotificationRecord> {
  // Additional props
}

export class DefaultNotification extends BaseGraphQLModel<NotificationRecord>
  implements Notification {
  public defaultValues: NotificationRecord = {
    id: uuid(),
    name: "Default name",
    description: "",
    createdAt: "Unknown",
    updatedAt: "Unknown",
  };
}
