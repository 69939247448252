import { styled, Tabs, Tab, TabProps } from '@mui/material';
import { useState } from 'react';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: '#f2f2f2',
  color: '#2d3d48',
  padding: '0 12px',
}));

export type TabInfo = { value: string; label: string; disabled?: boolean };
interface PentairTabsProps {
  tabs: TabInfo[];
  onTabClick(tab: TabInfo, event: React.MouseEvent<any>): void;
}

export const PentairTab = styled(Tab)(({ disabled }: TabProps) => ({
  minWidth: 'auto',
  fontSize: '0.75rem',
  fontWeight: 600,
  opacity: disabled ? 0.4 : 1,
  transition: 'all 0.3s',
})) as typeof Tab;

export const PentairTabs: React.FC<PentairTabsProps> = ({ tabs, onTabClick }) => {
  const [activeTab, setActiveTab] = useState<TabInfo>(tabs[0]);

  return (
    <StyledTabs
      value={activeTab.value}
      textColor="secondary"
      TabIndicatorProps={{ style: { display: 'none' } }}
      variant="scrollable"
      scrollButtons="auto"
    >
      {Object.values(tabs).map((tab) => (
        <PentairTab
          {...tab}
          disableRipple
          disableTouchRipple
          disableFocusRipple
          key={tab.value}
          onClick={(e) => {
            setActiveTab(tab);
            onTabClick(tab, e);
          }}
        />
      ))}
    </StyledTabs>
  );
};
