import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import { FC, useContext, useState, useCallback, useEffect } from 'react';
import ContentEditable, { ContentEditableEvent } from 'react-contenteditable';
import { ProjectionContext } from '../../../__old__/v2/feature/projection/context/projection-context';
import { faCheck, faPencilAlt } from '@fortawesome/pro-light-svg-icons';

// styles
const useStyles = makeStyles((theme: Theme) => ({
  editAction: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    cursor: 'pointer',
    display: 'none',
    color: theme.palette.primary.main,
    fontSize: theme.spacing(1.5),
  },
  projectionDescription: {
    border: '1px dashed transparent',
    padding: 0,
    paddingTop: 12,
    paddingBottom: 12,
    position: 'relative',
    '&:hover': {
      '& $editAction': {
        display: 'block',
      },
    },
    transition: 'all 0.2s ease-in-out',
  },
  projectionDescriptionActive: {
    padding: 12,
    border: '1px dashed ' + theme.palette.softGray.main,

    '& $editAction': {
      display: 'block',
    },
  },
  printText: {
    fontSize: 12,
  },
}));

// image legend
export const ProjectionDescription: FC<{
  handleChange: (key: any, value: any) => void;
  print?: any;
  egu?: string;
}> = (props) => {
  const { handleChange } = props;
  const projection = useContext(ProjectionContext);
  const classes = (useStyles as any)(props);

  // states
  const [projectionDescription, setProjectionDescription] = useState(projection?.record?.description || '');
  const [editable, setEditable] = useState<boolean>(false);
  const handleDescription = useCallback((e: ContentEditableEvent) => {
    setProjectionDescription(e.target.value);
  }, []);

  useEffect(() => {
    if (editable) return;
    if (projectionDescription !== projection.record.description) {
      handleChange('description', projectionDescription);
    }
  }, [projectionDescription, handleChange, projection.record.description, editable]);

  return (
    <div className={classnames([classes.projectionDescription, editable && classes.projectionDescriptionActive])}>
      <Typography fontWeight={600} className={props.print ? classes.printText : undefined}>
        Projection description
      </Typography>
      <div className={classes.editAction} onClick={() => setEditable(!editable)}>
        <FontAwesomeIcon icon={editable ? faCheck : faPencilAlt} />
      </div>
      <div>
        <ContentEditable
          style={{
            outline: 'none',
            fontSize: props.print ? 12 : '0.875rem',
          }}
          html={projectionDescription} // innerHTML of the editable div
          disabled={!editable} // use true to disable editing
          onChange={handleDescription}
        />
      </div>
    </div>
  );
};
