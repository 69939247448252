import gql from 'graphql-tag';

export const userProfileFragment = gql`
  fragment UserProfileFragment on UserProfile {
    id
    role
    groups
    preferences {
      id
      value
    }
    profile {
      created_at
      description
      id
      name
      updated_at
      permissions {
        allow
        deny
      }
    }
    permissions {
      allow
      deny
    }
  }
`;
