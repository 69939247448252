import { MenuItem, TableCell, TableRow, TextField } from '@mui/material';
import converter from '@pentair/xpert-convert-units';
import * as React from 'react';
import { PentairRed } from '../../../config/theme/pentair-theme/colors';
import { CalculationValue } from '../../../v2/feature/solution/types/record';
import { useFormChange } from './hooks/use-form-change';
import { useFormValidation } from './hooks/use-form-validation';
import { DefaultFormFieldProps } from './types';

interface OwnProps extends DefaultFormFieldProps<Partial<CalculationValue>> {
  disabled?: boolean;
  type: string;
}

const fromDisplayUnit = (calculationValue: Partial<CalculationValue>, value: number) => {
  /** Convert a user input to the base unit */
  if (calculationValue.displayUnit === undefined || calculationValue.unit === calculationValue.displayUnit) {
    return value;
  }
  try {
    return converter
      .convert(value)
      .from(calculationValue.displayUnit as any)
      .to(calculationValue.unit as any);
  } catch (e) {
    console.error(`Conversion from ${calculationValue.displayUnit} to ${calculationValue.unit} is not supported`);
    return undefined;
  }
};
const toDisplayUnit = (calculationValue: Partial<CalculationValue>, value: number) => {
  /** Convert value from the base unit to the user unit */
  if (calculationValue.displayUnit === undefined || calculationValue.unit === calculationValue.displayUnit) {
    return value;
  }
  try {
    // converter does not do Nm3/h to SCFM
    // pxx-1422 :: the used conversion rate is done at 15 degC
    if (calculationValue.displayUnit === 'SCFM') {
      return value * 0.589684;
    }

    return converter
      .convert(value)
      .from(calculationValue.unit as any)
      .to(calculationValue.displayUnit as any);
  } catch (e) {
    console.error(`Conversion from ${calculationValue.displayUnit} to ${calculationValue.unit} is not supported`);
    return undefined;
  }
};
const filteredUnitOptions = (calculationValue: Partial<CalculationValue>) => {
  /** Limit the list op units to only supported items */
  const { unitOptions, unit } = calculationValue;
  if (!unitOptions || unitOptions.length === 0) return [];
  if (!unit) return unitOptions;
  try {
    const converterOptions = converter.options(unit as any);
    return unitOptions.filter((i) => {
      if (converterOptions.indexOf(i as any) >= 0) return true;
      console.warn(`Conversion between ${unit} and ${i} is not supported`);
      return false;
    });
  } catch (e) {
    console.error(`Base unit ${unit} is not supported`);
    return unitOptions;
  }
};

export const FormFieldProjectionCalculationValueTableRowConvert: React.FC<OwnProps> = (props) => {
  const [calculationValue, setCalculationValue] = React.useState(props.value || {});
  const [validationError, setValidationError] = React.useState<string | undefined>();

  const [text, setText] = React.useState(() => {
    // determine the initial text, based on the
    let initialValue: number;
    if (calculationValue.values && calculationValue.values[0]) {
      initialValue = Number(calculationValue.values[0]);
    } else if (calculationValue.recommendedValues && calculationValue.recommendedValues[0]) {
      initialValue = Number(calculationValue.recommendedValues[0]);
    } else {
      return '';
    }
    if (calculationValue.displayUnit === undefined || calculationValue.unit === calculationValue.displayUnit) {
      return initialValue.toString();
    }
    const textValue = toDisplayUnit(calculationValue, initialValue);
    if (textValue === undefined) {
      // if the conversion is not supported, there isn't much we can do
      return '';
    } else {
      return textValue.toString();
    }
  });

  const error = useFormValidation(
    (props.value && props.value.values && props.value.values[0]) || null,
    props.validations || 'required|max:120',
    props.onError
  );

  const { onChange, changed } = useFormChange((e) => {
    const newCalcValue = calculationValue;

    if (e.target.id === newCalcValue.id) {
      // input on value field
      const userInput = Number(e.target.value);
      const userInputBase = fromDisplayUnit(newCalcValue, userInput);
      if (userInputBase === undefined) {
        // conversion failed
      } else {
        newCalcValue.values = [userInputBase];
      }
      setText(e.target.value);
    } else {
      // input on displayUnit field
      const userUnit = e.target.value;
      if (calculationValue.values && calculationValue.values[0] !== undefined) {
        // first check whether we can convert to the selected displayUnit
        const newTextValue = toDisplayUnit(
          {
            ...newCalcValue,
            displayUnit: userUnit,
          },
          Number(calculationValue.values[0])
        );

        if (newTextValue !== undefined) {
          //accept only if conversion is successful, revert otherwise
          newCalcValue.displayUnit = userUnit;
          setText(newTextValue.toString());
        }
      } else {
        newCalcValue.displayUnit = userUnit;
        setText('');
      }
    }
    setCalculationValue({ ...newCalcValue });
    return calculationValue;
  }, props.onChange);

  React.useEffect(() => {
    if (props.onError) {
      if (validationError) {
        props.onError(validationError);
        setValidationError(undefined);
      }
    }
  }, [validationError, calculationValue]);

  const unitOptions = filteredUnitOptions(calculationValue);

  return (
    <React.Fragment>
      1
      <TableRow style={{ borderBottom: 'none' }}>
        <TableCell
          style={
            (((changed && error) ||
              (changed && validationError === 'Required') ||
              (validationError && validationError !== 'Required')) && {
              color: PentairRed[500],
            }) ||
            {}
          }
        >
          {calculationValue.name}*
        </TableCell>
        <TableCell>
          <TextField
            type={'number'}
            disabled={props.disabled}
            required
            fullWidth
            name={calculationValue.id}
            id={calculationValue.id}
            inputProps={{ maxLength: 121 }}
            value={text}
            onChange={onChange}
            error={changed && error ? true : false || (changed && validationError) ? true : false}
            helperText={(changed && error && '') || (validationError && '')}
          />
        </TableCell>
        <TableCell>
          {unitOptions && unitOptions.length > 1 && (
            <TextField
              select
              fullWidth
              disabled={props.disabled}
              required
              name={calculationValue.id + 'unit'}
              id={calculationValue.id + 'unit'}
              value={calculationValue.unit || unitOptions[0]}
              onChange={onChange}
              error={changed && error ? true : false || (changed && validationError) ? true : false}
              helperText={(changed && error && '') || (validationError && '')}
            >
              {unitOptions.map((unitOption) => (
                <MenuItem key={unitOption} value={unitOption}>
                  {unitOption}
                </MenuItem>
              ))}
            </TextField>
          )}
          {unitOptions && unitOptions.length === 1 && (
            <TextField
              fullWidth
              disabled
              required
              name={calculationValue.id + 'unit'}
              id={calculationValue.id + 'unit'}
              value={calculationValue.unit || unitOptions[0]}
              onChange={onChange}
              error={changed && error ? true : false || (changed && validationError) ? true : false}
              helperText={(changed && error && '') || (validationError && '')}
            />
          )}
        </TableCell>
      </TableRow>
      {((changed && error) ||
        (changed && validationError === 'Required') ||
        (validationError && validationError !== 'Required')) && (
        <TableRow style={{ borderBottom: 'none' }}>
          <TableCell colSpan={4} style={{ paddingTop: '0px', marginTop: '-8px' }}>
            <span style={{ color: PentairRed[500], fontSize: '0.8rem' }}>{validationError || error}</span>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};
