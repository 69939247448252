import { toFixedIfNecessary } from '../../../../application/resources/helper/to-fixed';
import { CalculationValue } from '../../solution/types/record';

export type CalculationsRecord = Record<CalculationValue['id'], CalculationValue>;

export const getCalculationProperty = <TKeys = CalculationValue['id']>(
  calculationValues: Partial<CalculationsRecord> = {},
  key: TKeys | undefined = undefined
): CalculationValue | undefined => {
  if (!key) {
    return undefined;
  }

  return calculationValues[key as any] as CalculationValue;
};

export const getCalculationPropertyBasedOnCalcField = (
  key: CalculationValue['id'] | string | undefined,
  calculationValuesBasedonCalcField: Partial<CalculationsRecord>,
  calculationValues: Partial<Record<CalculationValue['id'], CalculationValue>>
) => {
  const basedOnVal = getCalculationProperty(calculationValuesBasedonCalcField, key as any);
  const calcValId = basedOnVal && basedOnVal.id;
  return getCalculationProperty(calculationValues, calcValId);
};

export const getCalculationValue = <TValue extends any, TKeys = CalculationValue['id']>(
  calculationValues: Partial<CalculationsRecord> = {},
  key: TKeys | undefined = undefined,
  index: number = 0,
  defaultValue: TValue | undefined = undefined
): TValue => {
  const calcVal = getCalculationProperty(calculationValues, key);
  return (calcVal && calcVal.values[index]) || (defaultValue as any);
};

export const setCalculationValues = <TValue extends Array<number | string>>(
  calculationValues: Partial<CalculationsRecord> = {},
  key: CalculationValue['id'] | undefined = undefined,
  values: TValue
) => {
  const calcVal = { ...getCalculationProperty(calculationValues, key) };

  if (calcVal) {
    calcVal.values = [...values];

    if (calcVal.decimals) {
      const decimals = parseFloat(String(calcVal.decimals || '0'));

      calcVal.values = calcVal.values.map((val) => {
        return String(toFixedIfNecessary(String(val), decimals));
      }) as TValue;
    }

    calculationValues[key as any] = calcVal;
  }
};

export const updateCalculationValue = ({ ...calcVal }: CalculationValue, values: CalculationValue['values']) => {
  return { ...calcVal, values: [...values] };
};

export const correctDecimals = (val: string | number | boolean, decimals: number) => {
  return String(toFixedIfNecessary(String(val), decimals));
};
