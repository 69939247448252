import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, FormLabel } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { HeaderPropertyValue } from '../../../../__old__/v2/common/layout/header-prop-value';
import { CalculationTextField } from '../../../../__old__/v2/common/ui/calculation-textfield';
import {
  CalculationsRecord,
  updateCalculationValue,
} from '../../../../__old__/v2/feature/projection/helpers/calculation-values';
import { CalculationValue } from '../../../../__old__/v2/feature/solution/types/record';
import InfoLabel from '../../../../__old__/view/components/form/info-label';
import { useWTCalculation } from '../../hooks/use-wt-calculation';
import { faArrowToRight, faSpinner } from '@fortawesome/pro-light-svg-icons';

export interface CoagulantConfigurationProps {
  inputs: Partial<CalculationsRecord>;
  advisedInputs: Partial<CalculationsRecord>;
  onChange(calculationValue: CalculationValue): void;
  onError?(id: string, error: string | false): void;
  onIsCalculatingChange?(isCalculating: boolean): void;
}

export const CoagulantConfiguration: React.FC<CoagulantConfigurationProps> = (props) => {
  const { inputs, advisedInputs, onChange, onError } = props;
  const { convertWtPercentage, isConverting } = useWTCalculation();

  if (advisedInputs['chm_coagulant']?.values[0] === 'null') {
    advisedInputs['chm_coagulant'].values = [''];
  }

  const coagulant = String(inputs['chm_coagulant']?.values?.[0] || 'null');
  const coagulantNA = coagulant === 'TBD' || coagulant === 'null' || !coagulant;

  const stock = inputs['chm_coagulant_override_stock_conc'];
  const stockWT = inputs['chm_coagulant_override_stock_conc_wtpercent'];
  const stockWTDisabled = ['AlCH'].includes(String(inputs['ceb_1_a_chemical_agent_1']?.values[0]));

  const onBlur = useCallback(
    async (cv: CalculationValue, e: React.FocusEvent<HTMLInputElement>) => {
      const result = await convertWtPercentage(coagulant, Number(cv.values[0]), 'coagulant');

      if (stock && onChange) {
        onChange(updateCalculationValue(stock, [result]));
      }

      if (onChange) {
        onChange(cv);
      }
    },
    [convertWtPercentage, coagulant, stock, onChange]
  );

  const onCoagulantChange = useCallback(
    (cv: CalculationValue, e: React.ChangeEvent<HTMLInputElement>) => {
      if (stock && onChange) {
        onChange(updateCalculationValue(stock, [0]));
      }
      if (stockWT && onChange) {
        onChange(updateCalculationValue(stockWT, [0]));
      }
      if (onChange) {
        onChange(cv);
      }
    },
    [onChange, stock, stockWT]
  );

  useEffect(() => {
    if (isConverting) {
      props.onIsCalculatingChange?.(true);
    } else {
      props.onIsCalculatingChange?.(false);
    }
  });

  return (
    <Box width={500}>
      <Box display={'flex'}>
        <HeaderPropertyValue horizontal marginRight={1} calculationValue={inputs['ucl_overdesign']} />
      </Box>

      <Divider style={{ margin: '24px 0' }} />

      <CalculationTextField
        select
        calculationValue={inputs['chm_coagulant']}
        advisedValue={advisedInputs['chm_coagulant']}
        onChange={onCoagulantChange}
        onError={onError}
        validations={''}
        inputProps={{ step: 1 }}
      />

      <CalculationTextField
        calculationValue={inputs['user_max_coagulant_concentration']}
        advisedValue={advisedInputs['user_max_coagulant_concentration']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:100|numberMin:0'}
        type="number"
        inputProps={{ min: 0, max: 100, step: 1 }}
        showEgu
        eguWidth={120}
      />

      <CalculationTextField
        calculationValue={inputs['user_typ_coagulant_concentration']}
        advisedValue={advisedInputs['user_typ_coagulant_concentration']}
        onChange={onChange}
        onError={onError}
        validations={'required|numberMax:100|numberMin:0'}
        type="number"
        inputProps={{ min: 0, max: 100, step: 1 }}
        showEgu
        eguWidth={120}
      />

      {!coagulantNA && (
        <>
          <FormLabel style={{ fontSize: 12 }} disabled={isConverting}>
            <InfoLabel title={'Chemical Stock Concentration'} description={''} />
          </FormLabel>

          <Box display={'flex'}>
            <CalculationTextField
              showName={false}
              type="number"
              calculationValue={inputs['chm_coagulant_override_stock_conc_wtpercent']}
              advisedValue={advisedInputs['chm_coagulant_override_stock_conc_wtpercent']}
              onBlur={onBlur}
              onError={onError}
              validations={'numberMax:5000'}
              disabled={isConverting || stockWTDisabled}
              showEgu
            />

            <FontAwesomeIcon
              spin={isConverting}
              icon={isConverting ? faSpinner : faArrowToRight}
              style={{
                margin: '12px 16px 12px 16px',
                opacity: isConverting ? 0.5 : 1,
              }}
            />

            <CalculationTextField
              showName={false}
              type="number"
              calculationValue={inputs['chm_coagulant_override_stock_conc']}
              advisedValue={advisedInputs['chm_coagulant_override_stock_conc']}
              onChange={onChange}
              onError={onError}
              disabled={!stockWTDisabled}
              showEgu
            />
          </Box>
        </>
      )}
    </Box>
  );
};
