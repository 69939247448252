import * as React from 'react';
import { Link } from 'react-router-dom';
import { useRoute } from '../../../hooks/use-route';

interface OwnProps {
  appname?: string;
}

export const TopbarLogo: React.FC<OwnProps> = (props) => {
  const [homepageUrl] = useRoute('app.homepage');

  return (
    <Link to={homepageUrl} style={{ display: 'inline-flex', alignItems: 'center', paddingRight: 12 }}>
      <img src="/images/logo-x-flow.svg" height="28" alt="Pentair Xpert Logo" />
    </Link>
  );
};
