/*

  By Code.Rehab

*/

import { lazy } from 'react';
import { createRoute } from '../../application/helpers/route';

const PageProfileIndex = lazy(() => import('../../view/pages/profile/page-profile-index'));
const PageProfileSingle = lazy(() => import('../../view/pages/profile/page-profile-single'));

export const routesTeams = {
  'team.index': createRoute({
    path: '/manage/team/index',
    title: 'Team management',
    component: PageProfileIndex,
    public: false,
    requiredPermissions: ['iam.team.list'],
    breadcrumbs: [
      {
        route: 'app.administration',
        label: 'Manage',
      },
      {
        route: 'team.index',
        label: 'Teams',
      },
    ],
  }),
  'team.edit': createRoute({
    path: '/manage/team/:team',
    title: 'Edit Team',
    component: PageProfileSingle,
    public: false,
    requiredPermissions: ['iam.team.update'],
    breadcrumbs: [
      {
        route: 'app.administration',
        label: 'Manage',
      },
      {
        route: 'team.index',
        label: 'Team',
      },
      {
        route: 'team.edit',
        label: 'Edit',
      },
    ],
  }),
};
export default routesTeams;
