import { Profile, DefaultProfile, ProfileRecord } from "./profile";
import { GraphQLProvider, BaseGraphQLProvider } from "../../base/graphql/graphql-provider";
import { graphqlOperation } from "@aws-amplify/api";
import * as Query from "./graphql/queries";
import * as Mutation from "./graphql/mutations";
import { User } from "../user/user";

export interface ProfileProvider extends GraphQLProvider<ProfileRecord, Profile> {
  assignProfile(user: User, profile: Profile): Promise<ProfileRecord>;
}

export class DefaultProfileProvider extends BaseGraphQLProvider<ProfileRecord, Profile>
  implements ProfileProvider {
  public model = DefaultProfile;

  protected fetchOperation = (profile: Profile) => {
    return graphqlOperation(Query.Profile, { id: profile.id });
  };

  protected listOperation = () => {
    return graphqlOperation(Query.ProfileCollection, {});
  };

  protected createOperation = (profile: Profile) => {
    return graphqlOperation(Mutation.CreateProfile, { input: profile.serialize() });
  };

  protected updateOperation = (profile: Profile) => {
    return graphqlOperation(Mutation.UpdateProfile, { input: profile.serialize() });
  };

  protected deleteOperation = (profile: Profile) => {
    return graphqlOperation(Mutation.DeleteProfile, { id: profile.id });
  };

  protected assignProfileOperation = (user: User, profile: Profile) => {
    return graphqlOperation(Mutation.AssignProfile, {
      userID: user.id,
      profileID: profile.id,
    });
  };

  public assignProfile = async (user: User, profile: Profile) => {
    const operation = this.assignProfileOperation(user, profile);
    return await this.query(null, operation);
  };
}
