/*

  By Code.Rehab

*/

import { lazy } from 'react';
import { createRoute } from '../../application/helpers/route';

const ProjectsV2 = lazy(() => import('../../../pages/projects-v2'));
const ProjectV2 = lazy(() => import('../../../pages/project-v2'));
const ProjectionV2 = lazy(() => import('../../../pages/projection'));
const Proposal = lazy(() => import('../../../pages/proposal-single'));

export const routesProjectV2 = {
  // project collection
  'projectsV2.index': createRoute({
    path: '/projects',
    title: 'Projects',
    component: ProjectsV2,
    requiredPermissions: ['projects.projects.list'],
    breadcrumbs: [
      {
        route: 'projectsV2.index',
        label: 'Xpert Projects',
      },
    ],
  }),

  // single project with dashboard, projections, proposals and products
  'projectV2.index': createRoute({
    path: '/projects/:projectId/:segment?',
    title: 'Project',
    component: ProjectV2,
    requiredPermissions: ['ui.show.advanced.internal.project.dashboard'],
    breadcrumbs: [
      {
        route: 'projectsV2.index',
        label: 'Xpert Projects',
        paramsToRemoveFromRoute: ['projectId', 'segment'],
      },
      {
        route: 'projectV2.index',
        label: 'project',
        paramsToRemoveFromRoute: ['segment'],
      },
      {
        route: 'projectV2.index',
        label: ({ match }) => match.params.segment,
      },
    ],
  }),

  // single projection
  'projectV2.projection': createRoute({
    path: '/projects/:projectId/projections/:projectionId',
    title: 'Projections',
    component: ProjectionV2,
    breadcrumbs: [
      {
        route: 'projectsV2.index',
        label: 'Xpert Projects',
        paramsToRemoveFromRoute: ['projectId', 'view'],
      },
      {
        route: 'projectsV2.index',
        label: 'project',
        paramsToRemoveFromRoute: ['view'],
      },
      {
        route: 'projectV2.index',
        label: ({ match }) => match.params.view,
      },
      {
        route: 'projectV2.projection',
        label: 'Projection',
      },
    ],
  }),

  // single proposal
  'projectV2.proposal': createRoute({
    path: '/projects/:projectId/proposals/:proposalId/:segment',
    title: 'Proposal',
    component: Proposal,
    breadcrumbs: [
      {
        route: 'projectsV2.index',
        label: 'Xpert Projects',
        paramsToRemoveFromRoute: ['projectId', 'view'],
      },
      {
        route: 'projectV2.index',
        label: 'project',
        paramsToRemoveFromRoute: ['view', 'segment'],
      },
      {
        route: 'projectV2.index',
        label: 'Proposals',
        params: { segment: 'proposals' },
      },
      {
        route: 'projectV2.proposal',
        label: 'Proposal',
      },
    ],
  }),
  // single proposal revision
  'projectV2.proposalRevision': createRoute({
    path: '/projects/:projectId/proposals/:proposalId/:revisionId/:segment',
    title: 'Proposal',
    component: Proposal,
    breadcrumbs: [
      {
        route: 'projectsV2.index',
        label: 'Xpert Projects',
        paramsToRemoveFromRoute: ['projectId', 'view'],
      },
      {
        route: 'projectV2.index',
        label: 'project',
        paramsToRemoveFromRoute: ['view'],
      },
      {
        route: 'projectV2.index',
        label: 'Proposals',
      },
      {
        route: 'projectV2.proposal',
        label: 'Proposal',
      },
    ],
  }),
};
