import {
  BaseGraphQLModel,
  GraphQLModel,
} from "../../base/graphql/graphql-model";
import { v4 as uuid } from "uuid";

export type Agreements = {
  id: string;
  date: string;
  text: string;
  linkText?: string;
  link?: string;
  accepted: boolean;
  required: boolean;
};

export type UserLegalRecord = {
  id: string;
  agreements: Agreements[];
};

export interface UserLegal extends GraphQLModel<UserLegalRecord> {
  resolved: boolean;
}

export class DefaultUserLegal
  extends BaseGraphQLModel<UserLegalRecord>
  implements UserLegal {
  public resolved: boolean = false;

  public defaultValues = {
    id: uuid(),
    agreements: [],
  };
}
