/**
 *
 *  By Tim Steinz
 *
 */

import { keyBy } from 'lodash';
import { CalculationsRecord } from '../../../__old__/v2/feature/projection/helpers/calculation-values';
import { CalculationValue } from '../../../__old__/v2/feature/solution/types/record';

export type CalculatedStandby = {
  solution: string;
  totalUnitsN: string;
  totalUnitsX: string;
  totalUnitsNX: string;
  numberOfMembraneHousingsN: string;
  numberOfMembraneHousingsX: string;
  numberOfMembraneHousingsNX: string;
  numberOfMembraneRowsN: string;
  numberOfMembraneRowsX: string;
  numberOfMembraneRowsNX: string;
  numberOfMembraneElementsN: string;
  numberOfMembraneElementsX: string;
  numberOfMembraneElementsNX: string;
  numberOfSpareMembraneElementsN: string;
  numberOfSpareMembraneElementsX: string;
  numberOfSpareMembraneElementsNX: string;
  totalMembraneElementsN: string;
  totalMembraneElementsX: string;
  totalMembraneElementsNX: string;
  numberOfShorterRowsN: string;
  numberOfShorterRowsX: string;
  numberOfShorterRowsNX: string;
  totalMembraneAreaN: string;
  totalMembraneAreaX: string;
  totalMembraneAreaNX: string;
  backwashTrains: string;
  designMargin: string;
  showAll: boolean;
};

export const defaultCalcuatedStandby: CalculatedStandby = {
  solution: '',
  totalUnitsN: '',
  totalUnitsX: '',
  totalUnitsNX: '',
  numberOfMembraneHousingsN: '',
  numberOfMembraneHousingsX: '',
  numberOfMembraneHousingsNX: '',
  numberOfMembraneRowsN: '',
  numberOfMembraneRowsX: '',
  numberOfMembraneRowsNX: '',
  numberOfMembraneElementsN: '',
  numberOfMembraneElementsX: '',
  numberOfMembraneElementsNX: '',
  numberOfSpareMembraneElementsN: '',
  numberOfSpareMembraneElementsX: '',
  numberOfSpareMembraneElementsNX: '',
  totalMembraneElementsN: '',
  totalMembraneElementsX: '',
  totalMembraneElementsNX: '',
  numberOfShorterRowsN: '',
  numberOfShorterRowsX: '',
  numberOfShorterRowsNX: '',
  totalMembraneAreaN: '',
  totalMembraneAreaX: '',
  totalMembraneAreaNX: '',
  backwashTrains: '',
  designMargin: '',
  showAll: false,
};

// calculate standby membrane elements for Aquaflex
export const useStandbyMembraneElements = (
  inputs: Partial<CalculationsRecord> | CalculationValue[],
  outputs: Partial<CalculationsRecord> | CalculationValue[],
  solution: string,
  egu: string = 'metric'
) => {
  if (!inputs) {
    console.error('useStandbymembraneElements() error: no inputs or solution given');
    return defaultCalcuatedStandby;
  }

  // array or object --> we want the object
  if (Array.isArray(inputs)) {
    inputs = keyBy(inputs, 'id');
  }

  if (Array.isArray(outputs)) {
    outputs = keyBy(outputs, 'id');
  }

  // add value to inputs if the value does not exist
  if (!('membrane_elements_standby_units' in inputs)) {
    inputs['membrane_elements_standby_units'] = {
      id: 'membrane_elements_standby_units',
      name: 'Total Standby Units',
      values: [0],
      recommendedValues: [0],
      unit: 'number',
      calculation_field: 'membrane_elements_standby_units',
    };
  }

  // add value if it does not exist
  if (!('membrane_elements_standby_philosophy' in inputs)) {
    inputs['membrane_elements_standby_philosophy'] = {
      id: 'membrane_elements_standby_philosophy',
      name: 'Standby Philosophy',
      values: ['per-backwash-train'],
      recommendedValues: ['per-backwash-train'],
      unit: '',
      valueOptions: [
        {
          id: 'per-backwash-train',
          label: 'Unit(s) per train',
        },
        {
          id: 'common',
          label: 'Common Standby',
        },
      ],
      calculation_field: 'membrane_elements_standby_philosophy',
    };
  }

  let values = defaultCalcuatedStandby;

  // load solution values
  switch (solution) {
    case 'aquaflex':
    case 'aquaflex-new':
    case 'aquaflex-hs':
      values = { ...values, ...aquaflex(inputs, outputs, egu) };
      break;
    case 'xline':
      values = { ...values, ...xline(inputs, outputs, egu) };
      break;
    case 'xiga':
      values = { ...values, ...xiga(inputs, outputs, egu) };
      break;

    // set aquaflex calculation as default
    default:
      values = { ...values, ...aquaflex(inputs, outputs, egu) };
      break;
  }

  return values;
};

// calc aquaflex values
const aquaflex = (inputs, outputs, egu) => {
  let standbyUnits = Number(inputs.membrane_elements_standby_units?.values[0] || 0);
  let backwashTrains = Number(
    inputs.ucl_number_of_backwash_pumps?.values[0] || outputs.ucl_number_of_backwash_pumps?.values[0] || 1
  );
  let totalUnitsN = Number(inputs.total_units?.values[0] || 0);
  let totalUnitsX =
    inputs.membrane_elements_standby_philosophy.values[0] === 'common' ? standbyUnits : backwashTrains * standbyUnits;
  let totalUnitsNX = totalUnitsN + totalUnitsX;
  let numberOfMembraneElements = Number(inputs.membrane_elements?.values[0] || 0);
  let totalMembraneElementsN = Number(inputs.plant_total_membrane_elements?.values[0] || 0);
  let totalMembraneElementsX = numberOfMembraneElements * totalUnitsX;
  let totalMembraneElementsNX = totalMembraneElementsN + totalMembraneElementsX;
  let numberOfSpareMembraneElements = Number(inputs.plant_spare_membrane_elements?.values[0] || 0);
  let totalMembraneAreaN = Number(inputs.plant_total_area?.values[0] || 0);
  let totalMembraneAreaX = (totalMembraneAreaN / totalMembraneElementsN) * totalMembraneElementsX;
  let totalMembraneAreaNX = totalMembraneAreaN + totalMembraneAreaX;
  let designMargin = outputs.ucl_overdesign?.values[0] || '0';
  let showAll = totalUnitsX > 0 ? true : false;

  // m2 to ft2
  if (egu === 'imperial') {
    totalMembraneAreaN = totalMembraneAreaN * 10.764;
    totalMembraneAreaX = totalMembraneAreaX * 10.764;
    totalMembraneAreaNX = totalMembraneAreaNX * 10.764;
  }

  return {
    solution: 'aquaflex',
    totalUnitsN: String(totalUnitsN),
    totalUnitsX: String(totalUnitsX),
    totalUnitsNX: String(totalUnitsNX),
    numberOfMembraneElementsN: String(numberOfMembraneElements),
    numberOfMembraneElementsX: String(numberOfMembraneElements),
    numberOfMembraneElementsNX: String(numberOfMembraneElements),
    totalMembraneElementsN: String(totalMembraneElementsN),
    totalMembraneElementsX: String(totalMembraneElementsX),
    totalMembraneElementsNX: String(totalMembraneElementsNX),
    numberOfSpareMembraneElementsN: String(numberOfSpareMembraneElements),
    numberOfSpareMembraneElementsX: String(numberOfSpareMembraneElements),
    numberOfSpareMembraneElementsNX: String(numberOfSpareMembraneElements),
    totalMembraneAreaN: String(totalMembraneAreaN),
    totalMembraneAreaX: String(totalMembraneAreaX),
    totalMembraneAreaNX: String(totalMembraneAreaNX),
    backwashTrains: String(backwashTrains),
    designMargin: String(designMargin),
    showAll: showAll,
  };
};

// calc xline values
const xline = (inputs, outputs, egu) => {
  let standbyUnits = Number(inputs.membrane_elements_standby_units?.values[0] || 0);
  let backwashTrains = Number(
    inputs.ucl_number_of_backwash_pumps?.values[0] || outputs.ucl_number_of_backwash_pumps?.values[0] || 1
  );
  let totalUnitsN = Number(inputs.total_units?.values[0] || 0);
  let totalUnitsX =
    inputs.membrane_elements_standby_philosophy.values[0] === 'common' ? standbyUnits : backwashTrains * standbyUnits;
  let totalUnitsNX = totalUnitsN + totalUnitsX;
  let numberOfMembraneRows = Number(inputs.all_user_housings?.values[0] || outputs.all_user_housings?.values[0] || 1);
  let numberOfMembraneElements = Number(inputs.membrane_elements?.values[0] || 0);
  let numberOfSpareMembraneElements = Number(inputs.plant_spare_membrane_elements?.values[0] || 0);
  let numberOfShorterRows = Number(inputs.all_shorter_rows?.values[0] || 0);
  let totalMembraneElementsN = Number(inputs.plant_total_membrane_elements?.values[0]);
  if (!totalMembraneElementsN || isNaN(totalMembraneElementsN)) {
    totalMembraneElementsN =
      numberOfMembraneElements * numberOfMembraneRows * totalUnitsN - numberOfShorterRows * totalUnitsN;
  }
  let totalMembraneElementsX =
    numberOfMembraneElements * numberOfMembraneRows * totalUnitsX - numberOfShorterRows * totalUnitsX;
  let totalMembraneElementsNX = totalMembraneElementsN + totalMembraneElementsX;
  let totalMembraneAreaN = Number(inputs.plant_total_area?.values[0] || 0);
  let totalMembraneAreaX = (totalMembraneAreaN / totalMembraneElementsN) * totalMembraneElementsX;
  let totalMembraneAreaNX = totalMembraneAreaN + totalMembraneAreaX;
  let designMargin = outputs.ucl_overdesign?.values[0] || '0';
  let showAll = totalUnitsX > 0 ? true : false;

  // m2 to ft2
  if (egu === 'imperial') {
    totalMembraneAreaN = totalMembraneAreaN * 10.764;
    totalMembraneAreaX = totalMembraneAreaX * 10.764;
    totalMembraneAreaNX = totalMembraneAreaNX * 10.764;
  }

  return {
    solution: 'xline',
    totalUnitsN: String(totalUnitsN),
    totalUnitsX: String(totalUnitsX),
    totalUnitsNX: String(totalUnitsNX),
    numberOfMembraneRowsN: String(numberOfMembraneRows),
    numberOfMembraneRowsX: String(numberOfMembraneRows),
    numberOfMembraneRowsNX: String(numberOfMembraneRows),
    numberOfMembraneElementsN: String(numberOfMembraneElements),
    numberOfMembraneElementsX: String(numberOfMembraneElements),
    numberOfMembraneElementsNX: String(numberOfMembraneElements),
    numberOfSpareMembraneElementsN: String(numberOfSpareMembraneElements),
    numberOfSpareMembraneElementsX: String(numberOfSpareMembraneElements),
    numberOfSpareMembraneElementsNX: String(numberOfSpareMembraneElements),
    totalMembraneElementsN: String(totalMembraneElementsN),
    totalMembraneElementsX: String(totalMembraneElementsX),
    totalMembraneElementsNX: String(totalMembraneElementsNX),
    numberOfShorterRowsN: String(numberOfShorterRows),
    numberOfShorterRowsX: String(numberOfShorterRows),
    numberOfShorterRowsNX: String(numberOfShorterRows),
    totalMembraneAreaN: String(totalMembraneAreaN),
    totalMembraneAreaX: String(totalMembraneAreaX),
    totalMembraneAreaNX: String(totalMembraneAreaNX),
    backwashTrains: String(backwashTrains),
    designMargin: String(designMargin),
    showAll: showAll,
  };
};

// calc xiga values
const xiga = (inputs, outputs, egu) => {
  let standbyUnits = Number(inputs.membrane_elements_standby_units?.values[0] || 0);
  let backwashTrains = Number(
    inputs.ucl_number_of_backwash_pumps?.values[0] || outputs.ucl_number_of_backwash_pumps?.values[0] || 1
  );
  let totalUnitsN = Number(inputs.total_units?.values[0] || 0);
  let totalUnitsX =
    inputs.membrane_elements_standby_philosophy.values[0] === 'common' ? standbyUnits : backwashTrains * standbyUnits;
  let totalUnitsNX = totalUnitsN + totalUnitsX;
  let numberOfMembraneHousings = Number(inputs.membrane_housings?.values[0] || 1);
  let numberOfMembraneElements = Number(inputs.membrane_elements?.values[0] || 0);
  let numberOfSpareMembraneElements = Number(inputs.plant_spare_membrane_elements?.values[0] || 0);
  let totalMembraneElementsN = Number(inputs.plant_total_membrane_elements?.values[0]);
  let totalMembraneElementsX = numberOfMembraneElements * numberOfMembraneHousings * totalUnitsX;
  let totalMembraneElementsNX = totalMembraneElementsN + totalMembraneElementsX;
  let totalMembraneAreaN = Number(inputs.plant_total_area?.values[0] || 0);
  let totalMembraneAreaX = (totalMembraneAreaN / totalMembraneElementsN) * totalMembraneElementsX;
  let totalMembraneAreaNX = totalMembraneAreaN + totalMembraneAreaX;
  let designMargin = outputs.ucl_overdesign?.values[0] || '0';
  let showAll = totalUnitsX > 0 ? true : false;

  // m2 to ft2
  if (egu === 'imperial') {
    totalMembraneAreaN = totalMembraneAreaN * 10.764;
    totalMembraneAreaX = totalMembraneAreaX * 10.764;
    totalMembraneAreaNX = totalMembraneAreaNX * 10.764;
  }

  return {
    solution: 'xiga',
    totalUnitsN: String(totalUnitsN),
    totalUnitsX: String(totalUnitsX),
    totalUnitsNX: String(totalUnitsNX),
    numberOfMembraneHousingsN: String(numberOfMembraneHousings),
    numberOfMembraneHousingsX: String(numberOfMembraneHousings),
    numberOfMembraneHousingsNX: String(numberOfMembraneHousings),
    numberOfMembraneElementsN: String(numberOfMembraneElements),
    numberOfMembraneElementsX: String(numberOfMembraneElements),
    numberOfMembraneElementsNX: String(numberOfMembraneElements),
    numberOfSpareMembraneElementsN: String(numberOfSpareMembraneElements),
    numberOfSpareMembraneElementsX: String(numberOfSpareMembraneElements),
    numberOfSpareMembraneElementsNX: String(numberOfSpareMembraneElements),
    totalMembraneElementsN: String(totalMembraneElementsN),
    totalMembraneElementsX: String(totalMembraneElementsX),
    totalMembraneElementsNX: String(totalMembraneElementsNX),
    totalMembraneAreaN: String(totalMembraneAreaN),
    totalMembraneAreaX: String(totalMembraneAreaX),
    totalMembraneAreaNX: String(totalMembraneAreaNX),
    backwashTrains: String(backwashTrains),
    designMargin: String(designMargin),
    showAll: showAll,
  };
};
