export class AppModule<Modules> {
  private _modules: any = {
    modules: {},
  };

  protected clearModules = () => {
    this._modules = {};
  };

  protected loadModule<ClassName extends { new (...args: any): any }>(
    key: keyof Modules,
    ModuleClass: ClassName,
    ...providerProps: ConstructorParameters<ClassName>
  ) {
    if (!this._modules[key]) {
      this._modules[key] = new ModuleClass(...providerProps);
    }

    return this._modules[key];
  }
}
