import gql from "graphql-tag";
import {
  runQuery,
  RunQueryOptions,
} from "../../../../application/network/run-query";
import { SolutionFragment } from "../graphql/fragment";
import { SolutionRecord } from "../types/record";

export interface QuerySolutionCollectionArgs {
  pagination: { start: string; limit: string };
}

const buildQuery = (fragment: string = SolutionFragment) => gql`
    query SolutionCollection {
        SolutionCollection {
            solutions {
                ${fragment}
            }
        }
    }
`;

export const collectSolutions = async (
  options: RunQueryOptions<QuerySolutionCollectionArgs>
) => {
  const query = (options && options.query) || ({} as any);
  query.buildQuery = query.buildQuery || buildQuery;
  return await runQuery<
    QuerySolutionCollectionArgs,
    {
      SolutionCollection: {
        solutions: SolutionRecord[];
        pagination: {
          start: string;
          end: string;
        };
      };
    }
  >({ query, variables: options.variables });
};
