/*

  By Code.Rehab

*/

import { lazy } from 'react';
import { createRoute } from '../../application/helpers/route';

// lazy loading components
const PagePermissionIndex = lazy(() => import('../../view/pages/permission/page-permission-index'));

export const routesPermission = {
  'permission.index': createRoute({
    path: '/manage/permission/index',
    title: 'Permission management',
    component: PagePermissionIndex,
    requiredPermissions: ['iam.permission.list'],
    breadcrumbs: [
      {
        route: 'app.administration',
        label: 'Manage',
      },
      {
        route: 'permission.index',
        label: 'Permissions',
      },
    ],
  }),
};
export default routesPermission;
